import React from 'react';
import classes from './Checkbox.module.css';

const Checkbox = ({ label, checked, onChange }) => {
    const handleCheckboxChange = (event) => {
        onChange(event.target.checked);
    };

    const handleClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div className={classes.checkboxWrapper} onClick={handleClick}>
            <label className={classes.label}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                />
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
