import React from 'react'

//libraries
import { Box, Typography, Button, FormControl, InputLabel, InputBase, FormHelperText, CircularProgress } from '@mui/material'

const FormCreateNewChannel = ({ channel, handleSubmit, errors, handleOnChangeInput, creatingOrModifyingChannel, isEdit, storeData, credentialsData }) => {

    const handleClickTokensTutorial = (event) => {
        // Find closest Anchor (or self) and its href value
        const hrefValue = event.target.closest("a")?.getAttribute('href')
        if (!hrefValue){
            event.preventDefault()
        }
    }

  return (
    <>
    {channel?.config_mtd?.data?.length > 0 ?
        <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& > :not(style)': { mt: 2, mb: 2 }
                }}

                noValidate
                autoComplete="off"
            >
                {channel?.config_mtd?.data?.map( e => (
                    <FormControl variant="standard" fullWidth error={errors[e.name]}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            { e.mtdname }
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            value={credentialsData != null ? credentialsData[e.name] : undefined}
                            id={e.name}
                            name={e.name}
                            required
                            placeholder={`Ingresa el ${e.mtdname}`}
                            onChange={handleOnChangeInput}
                        />
                        {errors[e.name] ? <FormHelperText>Ingresa el {e.mtdname}</FormHelperText> : <></>}
                    </FormControl>
                )) || []

                }
                <a 
                    href={channel?.config_mtd.howToGetTokensTutorialURL}
                    onClick={handleClickTokensTutorial}
                    target="_blank"
                >
                     <span 
                        style={{ 
                            textAlign: 'center', fontSize: '16px', fontWeight: 600, color: "#57C2BD", cursor: "pointer"
                        }}>
                            ¿Como consigo los tokens?
                        </span>
                </a>
            <Button 
                variant="contained" 
                sx={{ fontSize: { xs: "16px", md: "24px"}, padding: { xs: "10px 50px", md: "10px 80px", lg: "10px 100px"} , borderRadius: "14px", mt:"50px"}}
                type="submit"
                disabled={creatingOrModifyingChannel}
            >
                {creatingOrModifyingChannel ? 
                     <CircularProgress
                     thickness={6}
                     size={33}
                     sx={{
                         color: "#68cd96",
                         position: 'absolute',
                         top: '43%',
                         right: '43%',
                         marginTop: '-12px',
                         marginLeft: '-12px',
                     }}
                 />
                :
                    null
                }
                <b>{isEdit ? "Modificar" : "Conectar"} tienda</b>
            </Button>
        </Box>
    :
        <Typography variant="h4" ><b>Error al obtener datos</b></Typography>
    }
    </>
  )
}

export default FormCreateNewChannel
