import React, { useRef, useState } from 'react'
import classes from './Filters.module.css'
import Select from '../UI/Select'

const Filters = ({ countries, handleCountries, setFilters }) => {
    const currentCountry = useRef("")
    const currentStore = useRef("")
    const currentCategory = useRef("")
    const currentBrand = useRef("")

    const [stores, setStores] = useState([])
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [selectedOptionCountry, setSelectedOptionCountry] = useState("")
    const [selectedOptionStore, setSelectedOptionStore] = useState("")
    const [selectedOptionCategory, setSelectedOptionCategory] = useState("")
    const [selectedOptionBrand, setSelectedOptionBrand] = useState("")

    const handleOptionsCountry = (value) => {
        setSelectedOptionCountry(value)
    }

    const handleOptionsStore = (value) => {
        setSelectedOptionStore(value)
    }

    const handleOptionsCategory = (value) => {
        setSelectedOptionCategory(value)
    }

    const handleOptionsBrand = (value) => {
        setSelectedOptionBrand(value)
    }

    const handleCountry = (value) => {
        currentCountry.current = value;
        handleOptionsCountry(countries.find(e => e.value === value)?.name ?? "");
        setStores(countries.find(c => c.value === value)?.stores ?? [])
    }

    const handleStore = (value) => {
        currentStore.current = value
        handleOptionsStore(stores.find(e => e.value === value)?.name ?? "");
        setCategories(stores.find(s => s.value === value)?.categories ?? [])
        setBrands(stores.find(s => s.value === value)?.brands ?? [])
    }

    const handleCategory = (value) => {
        currentCategory.current = value;
        handleOptionsCategory(categories.find(e => e.value === value)?.name ?? "");
    }

    const handleBrand = (value) => {
        currentBrand.current = value
        handleOptionsBrand(brands.find(e => e.value === value)?.name ?? "");
    }


    const clearFilters = () => {
        currentCountry.current = ""
        handleOptionsCountry("");
        currentStore.current = ""
        handleOptionsStore("");
        currentCategory.current = ""
        handleOptionsCategory("");
        currentBrand.current = ""
        handleOptionsBrand("");
    }

    return (
        <div className={classes.filters}>
            <div className={classes.inputLabel}>
                <label className={classes.label}>
                    País
                </label>
                <Select options={countries} handleOptions={handleOptionsCountry} selectedOption={selectedOptionCountry} handle={handleCountry} name="country" ref={currentCountry} parentOptions={countries} />
            </div>
            <div className={classes.inputLabel}>
                <label className={classes.label}>
                    Tienda
                </label>
                <Select search options={stores} handleOptions={handleOptionsStore} selectedOption={selectedOptionStore} handle={handleStore} name="store" parentOptions={countries}/>
            </div>
            <div className={classes.inputLabel}>
                <label className={classes.label}>
                    Categorías
                </label>
                <Select options={categories} handleOptions={handleOptionsCategory} selectedOption={selectedOptionCategory} handle={handleCategory} name="category" parentOptions={countries}/>
            </div>
            <div className={classes.inputLabel}>
                <label className={classes.label}>
                    Marcas
                </label>

                <Select options={brands} handleOptions={handleOptionsBrand} selectedOption={selectedOptionBrand} handle={handleBrand} value="id" name="name" parentOptions={countries}/>
            </div>
            <button className={classes.buttonFilter} onClick={() => setFilters(currentStore.current, currentCountry.current, currentCategory.current, currentBrand.current)}>
                <span className={classes.buttonFilterText}>Aplicar</span>
            </button>
            <button className={classes.buttonFilter} onClick={clearFilters}>
                <span className={classes.buttonFilterText}>Borrar filtros</span>
            </button>
        </div>

    )
}

export default Filters;