import React, { useEffect, useState } from "react"
import { getChannelsParameters } from "services/channels"
import { IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import SplashScreen from "components/UI/splash-screen";
import ChannelSwitch from "./ChannelSwitch";
import ModalsIntegrationChannels from "./ModalsIntegrationChannels";
import { channelImportsFromParameters } from "utils/channelsImagesImport";
import ModalNewChannel from "./ModalNewChannel";
import addStore from "../../../../assets/img/icons/addStore.png";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import atras from "../../../../assets/img/icons/despliegueAtras.png"
const AdminSalesChannels = ({ fetchStoreDetails, storeData, userEmail, redirectChannelAdmin, isNewChannel, setStoreData, backToStores }) => {

    const [parametersChannels, setParametersChannels] = useState([])
    const [channelsClient, setChannelsClient] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [newChannel, setNewChannel] = useState(null)
    const [displayModalIntegrations, setDisplayModalIntegrations] = useState(false);
    const [channelsCirclesImg, setChannelsCirclesImg] = useState(null)
    const [displayNewChannel, setDisplayNewChannel] = useState(null)
    const toggleNewChannel = () => setDisplayNewChannel(!displayNewChannel);
    const [showAlert, setshowAlert] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [storeDataCurrentComponent, setStoreDataCurrentComponent] = useState(storeData)
    const [isEditChannel, setIsEditChannel] = useState(false)
    const [channelBeingModified, setChannelBeingModified] = useState(null)

    useEffect(async () => {
        setIsLoading(true)
        if (Object.keys(storeData).length > 0) {
            setStoreDataCurrentComponent(storeData)
            await setParameters()
            setIsLoading(false)
        }
    }, [storeData])


    const toggleModalIntegration = (toggle) => setDisplayModalIntegrations(toggle);

    const handleSetUpdatingStoreDataForChannel = (channelId, modifying) => {
        const channel = { channelId, modifying }
        setChannelBeingModified({ ...channel })
    }


    const integrateNewChannel = (channel) => {
        setNewChannel(channel)
        toggleModalIntegration(true)
    } 

    const handleOpenModalEditChannelIntegration = (channelDataFromChild, storeDataFromChild) => {
        setNewChannel(channelDataFromChild?.channelParams != null ? { ...channelDataFromChild?.channelParams } : null)
        setIsEditChannel(true)
        setDisplayModalIntegrations(true)
    }

    const setParameters = async (channelsSelected = null, storeDataUpdated = null, updatingChannel =false) => {
        try {
            const channels = await getChannelsParameters()
            if(updatingChannel)
                handleSetUpdatingStoreDataForChannel({channelId: null, modifying: false})
            if(storeDataUpdated){
                setStoreDataCurrentComponent(storeDataUpdated)
                setStoreData(storeDataUpdated)
            }
            const updatedStoreData = (storeDataUpdated != null)
                ? storeDataUpdated
                : (Object.keys(storeDataCurrentComponent).length === 0)
                    ? storeData
                    : storeDataCurrentComponent;
            const actualChannels = updatedStoreData.channels?.map(obj => {
                return {
                    channelParams: channels.find(e => obj.channel_config.channelId === e.id),
                    channelConfig: obj.channel_config,
                    newChannel: false
                }
            })?.filter(e => e.channelConfig.code !== 400) || []
    
            if (channelsSelected) {
                updatedStoreData.canales_por_onbordear = [...channelsSelected]
            }
           const newChannels = updatedStoreData.canales_por_onbordear?.map(
                obj => {
                    return {
                        channelParams: channels.find(e => obj === e.id),
                        channelConfig: null,
                        newChannel: true
                    }
                }) || []

            const importCircles = channelImportsFromParameters(channels)
            const circleChannels = importCircles.map(obj => {
                if (!updatedStoreData.channels?.find(e => obj.channelData.id === e.channel_config.channelId)) {
                    return obj
                }
            }).filter(e => e) || []
            const circleChannelsFilteredByCountryOfClient = circleChannels?.filter(circleChannel => {
                const channelIsInCountry = circleChannel?.channelData?.paises?.find(country => storeData?.paises?.length && country?.id == storeData?.paises[0])
                return channelIsInCountry != undefined 
            }) || []

            setChannelsCirclesImg(circleChannelsFilteredByCountryOfClient)
            setChannelsClient([...actualChannels, ...newChannels])
            setParametersChannels(channels)

        } catch (error) {
            console.log("error", error)
            console.log(error)
        }
    }

    const createAlert = async (text, channelsSelected) => {
        setshowAlert(true)
        setAlertText(text)
        setIsLoading(true)
        await setParameters(channelsSelected)
        setIsLoading(false)
    }

    useEffect(() => {
        if (showAlert) {
          const timeoutId = setTimeout(() => {
            setshowAlert(false);
          }, 4500);
    
          return () => clearTimeout(timeoutId);
        }
      }, [showAlert]);


    return (
        <>
            {isLoading && <SplashScreen />}


            <Grid container sx={{ '& > :not(style)': { mb: 5 } }} >
                {showAlert ?
                    <Alert
                        icon={false}
                        sx={{ 
                            zIndex: 2, 
                            fontFamily: 'Montserrat', 
                            mt: "-69px", 
                            ml: "45%", 
                            borderRadius: "10px !important", 
                            color: "#22BB3D", 
                            backgroundColor: "#E9F9EC", 
                            position: 'absolute', 
                            minHeight: "80px", 
                            paddingTop: "10px", 
                            minWidth: "540px",
                            fontSize: "20px"
                        }}
                        action={
                            <CloseIcon
                                sx={{
                                    fontSize: "30px",
                                    color: "#24BB3E",
                                    cursor: "pointer",
                                    marginTop: "12px"                                   
                                }}
                                onClick={() => {
                                    setshowAlert(false);
                                }} />
                        }
                    >
                        <div style={{ marginTop: "8px" }}>
                            <b>{alertText}</b>
                        </div>
                        
                        <div
                            style={{
                                clipPath: 'polygon(17px 0px, 100% 0px, 98% 100%, 30% 100%, 50% 80%, 90% 100%, 23px 100%)',
                                position: 'absolute',
                                bottom: '0px',
                                left: -17,
                                right: 0,
                                height: '12%',
                                backgroundColor: '#24BB3E',
                            }}
                        />
                    </Alert>
                    : <></>}
                <Grid item xl={9} lg={8} md={7} xs={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Tooltip title="Volver" arrow>
                            <IconButton onClick={() => { backToStores() }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }

                                }}>
                                <img src={atras} alt="atras" />
                            </IconButton>
                        </Tooltip>
                        <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <Typography fontFamily='Montserrat' variant="h4"><b>{storeDataCurrentComponent?.tienda}</b></Typography>
                        </div>
                        
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={5} xs={12} >
                    <div style={{ paddingTop:"13px" }}>
                        <Button variant="contained" onClick={() => { toggleNewChannel() }}> <b> <img src={addStore} style={{ marginRight: 5 }} /> Nuevo Canal</b></Button>
                    </div>                    
                </Grid>
            </Grid>


            {channelsClient.length > 0 ?
                <Grid container sx={{ '& > :not(style)': { mt: 5, mb: 2 } }} >
                    {channelsClient.map((channel) => (
                        <Grid xs={12} lg={6} md={12} xl={4} key={channel.id} display="flex" justifyContent="center">
                            <ChannelSwitch
                                key={channel.id}
                                channelData={channel}
                                integrateNewChannel={integrateNewChannel}
                                storeData={storeDataCurrentComponent}
                                handleOpenModalEditChannelIntegration={handleOpenModalEditChannelIntegration}
                                channelBeingModified={channelBeingModified}
                            />
                        </Grid>
                    ))}
                </Grid>
                :
                <></>
            }

            {displayModalIntegrations ? 
                <ModalsIntegrationChannels
                    newChannel={newChannel}
                    storeData={storeDataCurrentComponent}
                    allChannels={parametersChannels}
                    setChannelsParameters={setParameters}
                    fetchStoreDetails={fetchStoreDetails}
                    isEdit={isEditChannel}
                    setIsEdit={setIsEditChannel}
                    setDisplayModalIntegrations={setDisplayModalIntegrations}
                    displayModalIntegrations={displayModalIntegrations}
                    handleSetUpdatingStoreDataForChannel={handleSetUpdatingStoreDataForChannel}
                /> 
            :
                <></>
            }
            {
                displayNewChannel &&
                <ModalNewChannel
                    displayNewChannel={displayNewChannel}
                    toggleNewChannel={toggleNewChannel}
                    channelsCirclesImg={channelsCirclesImg}
                    storeData={storeDataCurrentComponent}
                    createAlert={createAlert}
                />
            }

        </>
    )
}

export default AdminSalesChannels