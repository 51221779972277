import React from 'react'
import classes from './SearchPublicationCardLoader.module.css'

import { Skeleton } from '@mui/material';


const PublicationCardLoader = ({ cardWrapper, cardIconWrapper, cardPercentageWrapper, cardInfoWrapper  }) => {

    return (
        <>
            <div className={cardWrapper || classes.cardWrapper}>
                <div className={cardIconWrapper || classes.cardIconWrapper}>
                    <Skeleton width="31px" height="31px" style={{transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                </div>
                <div className={classes.cardImgWrapper}>
                    <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                </div>
                <div className={classes.cardTextWrapper}>
                    <div className={classes.textNameWrapper}>
                        <Skeleton width="100%" height="80%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>                    
                    <div className={classes.textidsWrapper}>
                        <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)",  opacity: 0.35}} />
                    </div>
                </div>
                <div className={cardPercentageWrapper || classes.cardPercentageWrapper}>
                    <div className={classes.cardPercentageTitle}>
                        <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                    <div className={classes.cardPercentageContent}>
                        <Skeleton width="60%" height="120%" style={{ opacity: 0.35 }} />
                    </div>
                </div>
                <div className={cardInfoWrapper || classes.cardInfoWrapper}>
                    <div className={classes.cardInfoTitle}>
                        <Skeleton width="100%" height="30%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                    <div className={classes.cardCenterTitle}>
                        <Skeleton width="100%" height="100%" style={{opacity: 0.35}} />
                    </div>
                    <div className={classes.unitWrapper}>
                        <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicationCardLoader;