import React from 'react';
import CustomModal from '../../UI/CustomModal';
import classes from '../AlertsCenter.module.css';
import secondModalStyles from '../../PublicationAlertModals/SecondAlertModal.module.css';


const SecondAlertModal = ({ 
    secondModal, 
    toggleSecondModal, 
    closeSecondModalCloseButton, 
    loadingAlert, 
    isButtonEnabled, 
    handleSaveChanges, 
    days, 
    activeDays, 
    toggleDay, 
    toggleDatePicker, 
    startDate, 
    endDate, 
    formatDateRange, 
    CalendarPicker, 
    formatDate, 
    showDatePicker,
    DatePicker,
    selectedDate,
    handleDateChange,
    setStartDate,
    setEndDate,
    firstClick,
    setFirstClick,
    CircularProgress
  }) => {

    return (
        <CustomModal
            isOpen={secondModal}
            toggle={toggleSecondModal}
            onClose={closeSecondModalCloseButton}
            title="Editar alerta"
            customStyles={{ box: secondModalStyles.customModal }}
            customClassNames={{
                form: secondModalStyles.modalForm,
                header: classes.crearAlertaParent,
                title: classes.crearAlerta,
                closeWrapper: classes.closeWrapper,
                closeIcon: classes.closeIcon,
                separatorContainer: secondModalStyles.separatorContainer,
                separator: secondModalStyles.separator,
                bodyContainer: secondModalStyles.bodyContainer,
                footerContainer: secondModalStyles.footerContainer,
                box: classes.afterElementNone
            }}
            footerActions={
                !loadingAlert && (
                    <div
                        className={`${secondModalStyles.botonLogin} ${isButtonEnabled ? secondModalStyles.enabled : ''}`}
                        onClick={isButtonEnabled ? handleSaveChanges : null}
                    >
                        <b className={secondModalStyles.siguiente}>Guardar cambios</b>
                    </div>
                )
            }
        >
            <div className={secondModalStyles.seleccionaLaFrecuencia}>Selecciona la frecuencia de alerta</div>
            <div className={secondModalStyles.elEnvoEst}>(El envío está sujeto a la lógica del tipo de alerta)</div>
            <div className={secondModalStyles.correoEdit}>Se repite el</div>
            <div className={secondModalStyles.rectangleParentComponent}>
                {days.map((day) => (
                    <div
                        key={day.key}
                        className={`${secondModalStyles.rectangleParent} ${activeDays[day.key] ? secondModalStyles.active : ''}`}
                        onClick={() => toggleDay(day.key)}
                    >
                        <div className={secondModalStyles.groupChild} />
                        <div className={`${secondModalStyles.dayText} ${activeDays[day.key] ? secondModalStyles.activeText : ''}`}>{day.label}</div>
                    </div>
                ))}
            </div>
            <div className={secondModalStyles.correo1}>Fecha</div>   
            <div className={secondModalStyles.rectangleParent2} onClick={toggleDatePicker}>
                <div className={secondModalStyles.groupChild2}>                                            
                <div className={secondModalStyles.seleccionaUnaFecha}>
                    {startDate || endDate ? formatDateRange(startDate, endDate) : "Selecciona una fecha"}
                </div>   
                <img className={secondModalStyles.calendarIcon} alt="Calendar" src={CalendarPicker} />                                                                                         
                </div>
                <div className={secondModalStyles.dynamicText}>
                    {activeDays && Object.values(activeDays).some(day => day) && startDate ? (
                        <span>
                            Se enviará todos los {days.filter(day => activeDays[day.key]).map(day => day.fullLabel.toLowerCase()).join(', ')} desde el {formatDate(startDate)}
                            {endDate ? ` hasta el ${formatDate(endDate)}` : ''}
                        </span>
                    ) : (
                        ''
                    )}
                </div>                                                                                                                      
            </div>                                    
            {showDatePicker && (
                <div className={secondModalStyles.datePickerOverlay}>
                    <DatePicker 
                        selectedDate={selectedDate} 
                        onDateChange={handleDateChange} 
                        onClose={toggleDatePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate} 
                        setEndDate={setEndDate}
                        firstClick={firstClick}
                        setFirstClick={setFirstClick}
                    />
                </div>
            )}
            {loadingAlert && (
                <div className={classes.botonLoadingContainerSecond}>
                    <div className={classes.botonLoading} disabled={true}>
                        <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                        />
                        <b >Cargando</b>
                    </div>
                </div>                  
            )}
        </CustomModal>
    );
};

export default SecondAlertModal;
