import React from 'react'
import classes from './ComboDetailDescription.module.css'
import { thousandSeparator } from "../../utils/utils"
const ComboDetailDescription = ({ combo, prices, stock }) => {

    return (
        <div className={classes.comboDescriptionWrapper}>
            <div className={classes.comboName} title={combo.name}>
                <span>{combo.name}</span>
            </div>

            <div className={classes.comboPrices}>
                {prices.priceWithDiscount != null && prices.basePrice != prices.priceWithDiscount 
                ? 
                    <span className={classes.comboBasePrice}>{`$ ${thousandSeparator(Math.floor(prices.basePrice),  prices.thousandSeparator) || ""}`}</span>
                :
                    null
                }
                <span className={classes.comboOfferPrice}>
                    {`$ ${ prices.priceWithDiscount != null && prices.basePrice != prices.priceWithDiscount
                    ?
                        thousandSeparator(Math.floor(prices.priceWithDiscount), prices.thousandSeparator)
                    :
                        thousandSeparator(Math.floor(prices.basePrice) || "", prices.thousandSeparator)
                    }`}
                </span>
            </div>

            <div className={classes.comboStock}>
                <span className={classes.comboStockTitle}>Stock disponible</span>
                <span className={classes.comboStockValue}>{stock.isNotAvailable ? "No disponible" : stock.stock}</span>
            </div>

            <div className={classes.comboCodes}>
                <div className={classes.comboSKU}>
                    <span className={classes.comboSKUTitle}>SKU</span>
                    <span className={classes.comboSKUValue}>{combo.sku}</span>
                </div>
                <div className={classes.comboSKU}>
                    <span className={classes.comboSKUTitle}>EAN</span>
                    <span className={classes.comboSKUValue}>{combo.ean}</span>
                    
                </div>
            </div>
        </div>
    )
}

export default ComboDetailDescription