import React from 'react';
import { Modal } from 'reactstrap';
import Box from '@mui/material/Box';
import classes from './SixthAlertModal.module.css';

import Interrogation from "../../../assets/img/icons/interrogation-plant.png"


const SixthAlertModal = ({ 
    sixthModal,
    toggleSixthModal,
    Close,
    closeSixthModal,
    handelDeleteAlertRequest,
    loadingAlert,
    CircularProgress
  }) => {

    return (
        <Modal isOpen={sixthModal} toggle={toggleSixthModal} backdrop="static">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: "540px",
                    height: "574px",
                    bgcolor: 'white',
                    overflow: "hidden",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '17px',
                    zIndex: 1,
                    border: '1px solid #d3d3d3',
                    '@media (max-width:700px)': {
                        width: '90%', // width when screen size is less than 700px
                    },
                }}
            >
                <div className={classes.sixthModal}>
                    <div className={classes.sixthModalHeader}>
                        <div className={classes.sixthModalHeaderClose}>
                            <img className={classes.sixthModalHeaderCloseIcon} src={Close} onClick={closeSixthModal} />
                        </div>                                
                    </div>
                    <div className={classes.sixthModalContent}>
                        <div className={classes.sixthModalContentImage}>
                            <img className={classes.sixthModalImage} src={Interrogation}/>
                        </div>
                        <div className={classes.sixthModalContentTitle}>
                            <span>¿Seguro que...</span>
                        </div>
                        <div className={classes.sixthModalContentText}>
                            <span>Deseas eliminar esta</span>
                        </div>
                        <div className={classes.sixthModalContentSmallText}>
                            <span>alerta?</span>
                        </div>
                    </div>
                    <div className={classes.sixthModalFooter}>
                        <div className={classes.sixthModalFooterButton} onClick={() => handelDeleteAlertRequest()}>
                            Eliminar
                        </div>
                        {loadingAlert && (
                            <div className={classes.botonLoading} disabled={true}>
                                <CircularProgress
                                    thickness={6}
                                    size={33}
                                    sx={{
                                        color: "#57C2BD",
                                        position: 'relative',
                                    }}
                                />
                                <b >Cargando</b>
                            </div>                
                        )}
                    </div>                   
                </div>
            </Box>
        </Modal>
    );
};

export default SixthAlertModal;
