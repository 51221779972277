const configFile = require("../config/url");
import {performanceMetric} from "../utils/utils";

export const getWmsParameters = async () => {
  const accessToken = localStorage.getItem("Token");

  const bearerToken = `Bearer ${accessToken}`;
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `${bearerToken}`);
  myHeaders.append('x-api-key', configFile.config.apiKey);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  try {
    const start = Date.now();  
    const response = await fetch(
      `${configFile.config.ambiente}config/parameters/wms`,
      requestOptions
    )
    performanceMetric("/config/parameters/wms", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
    const data = await response.json();

    return data.message;
  } catch (err) {
    console.log(err);
  }
};

export const createWms = async (storeId, body) => {
  const accessToken = localStorage.getItem("AccessToken");

  const bearerToken = `Bearer ${accessToken}`;
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", `${bearerToken}`);
  myHeaders.append("Content-Type", "text/plain");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  };
  try {
    const start = Date.now();  
    const response = await fetch(
      `${configFile.config.ambiente}store/config/wms?id=${storeId}`,
      requestOptions
    )
    performanceMetric("/store/config/wms", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
    const data = await response.json();

    return data.message;
  } catch (err) {
    console.log(err);
  }
};
export const deleteWms = async (storeId, channelId) => {
  const accessToken = localStorage.getItem("Token");

  const bearerToken = `Bearer ${accessToken}`;
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", `${bearerToken}`);
  myHeaders.append("Content-Type", "text/plain");
  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const start = Date.now(); 
    const response = await fetch(
      `${configFile.config.ambiente}store/config/wms?id=${storeId}&channel=${channelId}`,
      requestOptions
    )
    performanceMetric("/store/config/wms", "DELETE", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
    const data = await response.json();
    return data.message;
  } catch (err) {
    console.log(err);
  }
};
export const editWms = async (storeId, body) => {
  const accessToken = localStorage.getItem("Token");
  const bearerToken = `Bearer ${accessToken}`;
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", bearerToken);
  myHeaders.append("Content-Type", "text/plain");
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  };
  const start = Date.now(); 
  try {
    const start = Date.now(); 
    const response = await fetch(
      `${configFile.config.ambiente}store/config/wms?id=${storeId}`,
      requestOptions
    )
    performanceMetric("/store/config/wms", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

    const data = response.json();

    return data;
  } catch {
    console.log(error);
  }
};
