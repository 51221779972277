import React, { useEffect, useState } from 'react';
import classes from './PublicationComparison.module.css';
import PublicationComparisonLoader from './PublicationComparisonLoader'
import PublicationInformation from './PublicationInformation';
import PublicationModalFormat from '../../publications/Components/PublicationModalFormat';
import CompetitionTable from './CompetitionTable';
import PublicationsGraph from './PublicationsGraph';

// Services
import { getPublicationsAnalytics } from 'services/publications';

const PublicationComparison = ({ selectedPublication, loadingCompetition, setLoadingCompetition, setCopyResultText, setCopyCreationResult, setRivalId, setWantDelete, setSelectedRival }) => {
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [alertSByPublication, setAlertSByPublication] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('precio');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [rivals, setRivals] = useState([]);
    const [statistics, setStatistics] = useState([]);  
    const [newestRival, setNewestRival] = useState(null); 
    const [displayModalDetails, setDisplayModalDetails] = useState(false);
    const toggleDisplayModal = () => setDisplayModalDetails(!displayModalDetails); 

    useEffect(() => {
        const handleGetPublicationAnalytics = async () => {
            try {                
                const response = await getPublicationsAnalytics(`?ids=${selectedPublication.ean}&statistics=15days`); 
                let cleanRivals = response?.data?.publication_analytics.filter(rival => rival.rivals_result); 
                              
                setRivals(cleanRivals || []);                
                setStatistics(response?.data?.publication_statistics?.[0]?.rivals || []);  
                                
                const newestRival = cleanRivals?.reduce((latest, current) => {
                    return new Date(current.rivals_result.date) > new Date(latest.rivals_result.date)
                        ? current
                        : latest;
                }, cleanRivals[0]);

                setNewestRival(newestRival);

            } catch (error) {
                console.log("Error trying to get publication analytics!");
            } finally {
                setLoadingCompetition(false);
            }
        };

        if (loadingCompetition) {
            setSelectedRival(null);
            setRivals([]);
            setStatistics([]);             
            handleGetPublicationAnalytics();
        }
    }, [loadingCompetition]);

    const handleToggleModal = () => {
        setDisplayModalDetails(!displayModalDetails);
    };

    const alertsForProduct = alertSByPublication?.filter(
        (alert) => alert.id_publication === selectedPublication.id_publication
    );

    const createAlert = (text) => {
        setCopyCreationResult(true)
        setCopyResultText(text)
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'sin información';

        const date = new Date(dateString);
        const today = new Date();
        
        const isToday = date.toDateString() === today.toDateString();
        
        const hoursAndMinutes = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        if (isToday) {
            return `hoy a las ${hoursAndMinutes}`;
        } else {
            const dayMonth = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
            return `el ${dayMonth} a las ${hoursAndMinutes}`;
        }
    };

    // Map the rivals array to match the tableData format
    const tableData = rivals.map((rival) => {

        let originalPrice = rival.rivals_result.publication?.originalPrice;
        let priceWithDiscount = rival.rivals_result.publication?.priceWithDiscount;

        // Sanitize originalPrice if it's a string
        if (typeof originalPrice === 'string') {
            originalPrice = originalPrice.replace(/[^0-9]/g, '');
            originalPrice = parseInt(originalPrice, 10);
        }
    
        // Sanitize priceWithDiscount if it's a string
        if (typeof priceWithDiscount === 'string') {
            priceWithDiscount = priceWithDiscount.replace(/[^0-9]/g, '');
            priceWithDiscount = parseInt(priceWithDiscount, 10);
        }
    
        // Calculate word count from description
        const wordCount = rival.rivals_result.publication.description
            ? rival.rivals_result.publication.description.trim().split(/\s+/).length
            : null;
    
        return {
            id: rival.url_id,
            competencia: {
                imageUrl: rival.rivals_result.publication.images ? rival.rivals_result.publication.images[0] : null,
                category: rival.rivals_result.publication.category || selectedPublication.category,
                name: rival.rivals_result.publication.title ? rival.rivals_result.publication.title : '',
                code: rival.rivals_result.publication.code ? rival.rivals_result.publication.code : 'Sin información',
                iconUrl: rival.icon_url ? rival.icon_url : null,
                publicationUrl: rival.url ? rival.url : null,
            },
            marca: rival.rivals_result.publication.brand || null,
            vendedor: rival.rivals_result.publication.seller || null,
            // Use priceWithDiscount if it exists and is a valid number, otherwise fallback to originalPrice
            precio: (!isNaN(priceWithDiscount) && priceWithDiscount > 0) ? priceWithDiscount : (isNaN(originalPrice) ? null : originalPrice),
            stock: isNaN(rival.rivals_result.publication.stock) ? null : rival.rivals_result.publication.stock, 
            palabras: wordCount,
            imagenes: rival.rivals_result.publication.images?.length || null,
            reviews: rival.rivals_result.publication.reviews?.length || null,
            comentarios: rival.rivals_result.publication.comments?.length || null,
        };
    });
    

    tableData?.sort((a, b) => {
        if (a.precio === null) return 1;
        if (b.precio === null) return -1;
        return a.precio - b.precio;
    });

    const limitedTableData = tableData?.slice(0, 10);

    const graphData = statistics.map((rival) => {
        // Determine the key to extract based on selectedColumn
        const columnKey = {
            precio: 'price',
            stock: 'stock',
            palabras: 'word_count',
            imagenes: 'images_count',
            reviews: 'reviews_count',
            comments: 'comments_count',
        }[selectedColumn];


        return {
            rival_id: rival.rival_id,
            rival: rival.daily_statistics.map(stat => ({ [columnKey]: stat[columnKey] })),
            rivalSales: rival.rival_sales,
            myPublication: selectedPublication?.statistics?.various_data?.map(data => ({ [columnKey]: data[columnKey] })),
        };
    });
        

    return (
        <>
            {loadingCompetition ?
                (
                    <PublicationComparisonLoader />
                ) : (
                    <div className={classes.mainContainer}>
                        <div className={classes.publicationContainer} onClick={handleToggleModal}>
                            <PublicationInformation
                                selectedPublication={selectedPublication}
                                setCopyResultText={setCopyResultText}
                                setCopyCreationResult={setCopyCreationResult}
                            />
                        </div>
                        <div className={classes.secondContainer}>
                            <div className={classes.graphContainer}>
                                <div className={classes.innerGraphContainer}>
                                    <PublicationsGraph
                                        selectedPublication={selectedPublication}
                                        selectedRowId={selectedRowId}
                                        selectedColumn={selectedColumn}
                                        graphData={graphData}
                                        tableData={tableData}
                                    />
                                </div>
                            </div>
                            <div className={classes.tableContainer}>
                                <div className={classes.innerTableContainer}>
                                    <CompetitionTable
                                        selectedPublication={selectedPublication}
                                        tableData={limitedTableData}
                                        selectedColumn={selectedColumn}
                                        setSelectedColumn={setSelectedColumn}
                                        selectedRowId={selectedRowId}
                                        setSelectedRowId={setSelectedRowId}
                                        setRivalId={setRivalId}
                                        setWantDelete={setWantDelete}
                                        setSelectedRival={setSelectedRival}
                                    />
                                </div>
                            </div>                            
                        </div>
                        <div className={classes.lastUpdateInfoContainer}>
                            <span>Última actualización <span className={classes.lastUpdateText}>{formatDate(newestRival?.rivals_result?.date)}</span></span>                            
                        </div>
                        {displayModalDetails && (
                            <PublicationModalFormat
                                displayModalDetails={displayModalDetails}
                                toggleDisplayModal={toggleDisplayModal}
                                publication={selectedPublication}
                                createAlert={createAlert}
                                alertsForProduct={alertsForProduct}
                            />
                        )}                        
                    </div>
                )
            }
        </>
    );
}

export default PublicationComparison;
