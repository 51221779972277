import { format, isToday, isYesterday, parseISO } from 'date-fns';

const configFile = require("./../config/url");

export const thousandSeparator = (num, separator) => {
    if (!num)
        return num

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const excelFormater = (products) => {
    let item_error;

    try {
        console.log(products);
        const response = [];

        for (const item of products) {
            item_error = item;
            let publication = {
                identificador: item.id_publication,
                nombre: item.name,
                marca: item.brand,
                categoria: item.category,
                canal: item.channel,
                cliente: item.client,
                tipo_de_moneda: item.currency,
                precio: item.price,
                ean: item.ean,
                sku: item.sku,
                url_publicacion: item.publication_url,
                tipo_de_logistica: item.logistica,
            }

            if (item.products[0] && item.products[0]?.stock_wms[0]) {
                publication.stock = item.products[0].stock_wms[0].stock;
                publication.bodega = item.products[0].stock_wms[0].bodega;
                publication.descripcion_larga = item.products[0]?.long_description ?? "";
                publication.descripcion_corta = item.products[0]?.short_description ?? "";
            }

            if (item.has_buybox) {
                publication.buybox_current_price = item.buybox.current_price;
                publication.buybox_price_to_win = item.buybox.price_to_win;
                publication.buybox_status = item.buybox.status;
            }

            if (item.has_healthcheck) {
                publication.healthcheck = item.healthcheck.health;
            }

            if (!item.has_buybox && !item.has_healthcheck && item.products[0]) {
                publication.score = item.products[0].publication_score.total;
            }

            response.push(publication);
        }

        return response;
    } catch (error) {
        console.log(item_error);
        console.log(error);
    }
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


export function mapServicesNewClientToDataKey(clientData){
    return {
        customerService: clientData?.servicio || false,
        salesChannelsCreation: clientData?.canales || false,
        warehouseStorageInstance: clientData?.bodega || false,
        billing: clientData?.facturacion || false
    }
}

export function emailValidation (email) {
    // expresion regular para validar email
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}; 
function sendMetric(metric, accessToken){
    try{
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", configFile.config.apiKeyQueues);
        myHeaders.append(
            "Authorization",
            `Bearer ${accessToken}`
        );
        myHeaders.append(
            "Access-Control-Allow-Headers",
            "*"
        );
        myHeaders.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify(metric)
        };

        fetch(
            `${configFile.config.ambienteQueues}metric`,
            options
        ).then(()=> console.log("metric sent")
        ).catch((err)=> console.log(`metric failed ${JSON.stringify(err)}`));
    } catch (error) {
        console.log("failed sending metric");
    }
}

export function performanceMetric(endpoint, method, api, user, time, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "performance",
            data: {
                endpoint,
                method,
                api,
                time,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function clickMetric(elementId, user, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "click",
            data: {
                elementId,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function behaviourMetric(view, activity, time, user, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "behaviour",
            data: {
                view,
                activity,
                time,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function isObject(obj) {	
    return typeof obj === 'object' && obj !== null && ! Array.isArray(obj)
}

export function translateLogisticType(logisticType){
    try {
        const translationsMap = {
            "cross_docking": "Colecta",
            "custom": "Custom",
            "drop_off": "Places",
            "xd_drop_off": "Places",
            "self_service": "Flex",
            "fulfillment": "Fulfillment",
            "not_specified": "No Especificada"
        }

        return translationsMap[logisticType] || logisticType

    } catch (error) {
        return logisticType
    }
}


export function removeAccentsFromString(stringToRemoveAccentsFrom) {
    try {
        return stringToRemoveAccentsFrom?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || stringToRemoveAccentsFrom
    } catch (error) {
        console.log(error)
        return stringToRemoveAccentsFrom
    }
}

export function formatLastUpdateOfPublication(dateString) {
    if (!dateString) {
        return 'Sin información';
    }

    const date = parseISO(dateString);
    const dateFormatted = format(date, 'dd/MM/yyyy HH:mm');
    return dateFormatted;
}

export function formatLastUpdatePublicationsTotal(dateString) {
    if(!dateString){
        return 'Sin información'
    }

    const date = parseISO(dateString);
    const timeFormatted = format(date, 'HH:mm');
    
    if (isToday(date)) {
        return `hoy a las ${timeFormatted}`;
    } else if (isYesterday(date)) {
        return `ayer a las ${timeFormatted}`;
    } else {
        const dateFormatted = format(date, 'dd/MM');
        return `${dateFormatted} a las ${timeFormatted}`;
    }
};

export const transformTranslatedStatus = (status) => {
    if (status === 'Rechazado') {
        return 'Rechazada';
    }
    if (status === 'Publicado') {
        return 'Publicada';
    }
    return status;
};

export const toSpanishNumberFormat = (number) => {
    let numStr = number.toString().replace(/[^0-9.]/g, '');
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? ',' + parts[1] : '';

    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return formattedInteger + decimalPart;
};

export const toSpanishNumberFormatWithNull = (number, defaultValue = 'Sin información') => {
    if (number == null || isNaN(number)) { 
        return defaultValue;
    }

    let numStr = number.toString().replace(/[^0-9.]/g, '');
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? ',' + parts[1] : '';

    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return formattedInteger + decimalPart;
};


export const getPriceInfoToModal = (publication) => {
    let price = '';
    let priceWithDiscount = '';

    if (publication?.sale_price) {
        if (publication?.sale_price?.regular_price) {
            price = publication?.sale_price?.regular_price !== publication?.sale_price?.price 
                    ? publication?.sale_price?.regular_price 
                    : publication?.price;
        } else {
            price = publication?.original_price 
                    ? publication?.sale_price?.price !== publication?.original_price 
                        ? publication?.original_price 
                        : publication?.price
                    : publication?.price;
        }

        priceWithDiscount = publication?.sale_price?.price || publication?.price;
    } else {
        if (publication?.original_price) {
            price = publication?.price !== publication?.original_price 
                    ? publication?.original_price 
                    : publication?.price;
        } else {
            price = publication?.base_price && publication?.price < publication?.base_price 
                    ? publication?.base_price 
                    : publication?.price;
        }

        priceWithDiscount = publication?.price;
    }

    return {
        price,
        priceWithDiscount,
    };
};

export const formatDateForBarsGraphs = (dateString) => {
    if (!dateString) {
        return '';
    }
    
    const date = parseISO(dateString);
    return format(date, 'dd.MM'); // Format the date as "dd.MM"
};

export function formatConversionRate(conversionRate, multiplyBy100 = true, addPercentageSymbol = true) {
    if (conversionRate === null || conversionRate === undefined) {
        return 'Sin información';
    }

    // Multiply by 100 if multiplyBy100 is true
    const percentage = multiplyBy100 ? conversionRate * 100 : conversionRate;
    const decimals = percentage === 0 || Number.isInteger(percentage) ? 0 : 2;

    // Format the number with thousand separators
    const [integerPart, decimalPart] = percentage
        .toFixed(decimals)
        .split('.');
        
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Add the percentage symbol if addPercentageSymbol is true
    return decimalPart
        ? `${formattedInteger},${decimalPart}${addPercentageSymbol ? '%' : ''}`
        : `${formattedInteger}${addPercentageSymbol ? '%' : ''}`;
}
