import axios from "axios"
const configFile = require('../config/url');
import {performanceMetric} from "../utils/utils";

export const getFilters = async () => {
    try {
        const accessToken = localStorage.getItem("AccessToken")

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${configFile.config.quicksightAPIBaseURL}filters`,
            headers: {
                'x-api-key': configFile.config.quicksightAPIKey,
                'Authorization': 'Bearer ' + accessToken
            }
        };
        const start = Date.now(); 
        const response = await axios.request(config)
        performanceMetric("/filters", "GET", "quicksight", localStorage.getItem("name"), Date.now()-start, accessToken);

        return {
            response,
            error: null 
        }
    }
    catch(err) {
        return {
            error: err,
            response: null
        }
    }
}

export const getUserDashboards = async (queryString = "") => {
    try {
        const accessToken = localStorage.getItem("AccessToken")

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${configFile.config.quicksightAPIBaseURL}dashboards`+ queryString,
            headers: {
                'x-api-key': configFile.config.quicksightAPIKey,
                'Authorization': 'Bearer ' + accessToken
            }
        };
        const start = Date.now(); 
        const response = await axios.request(config)
        performanceMetric("/dashboards", "GET", "quicksight", localStorage.getItem("name"), Date.now()-start, accessToken);

        return {
            response,
            error: null 
        }
    }
    catch(err) {
        return {
            error: err,
            response: null
        }
    }
}

export const generateEmbedURLs = async (body) => {
    try {
        const accessToken = localStorage.getItem("AccessToken")

        let config = {
            method: 'post',
            url: `${configFile.config.quicksightAPIBaseURL}dashboards/embed-url`,
            headers: {
                'x-api-key': configFile.config.quicksightAPIKey,
                'Authorization': 'Bearer ' + accessToken
            },
            data: body
        };
        const start = Date.now(); 
        const response = await axios.request(config)
        performanceMetric("/dashboards/embed-url", "POST", "quicksight", localStorage.getItem("name"), Date.now()-start, accessToken);

        return {
            response,
            error: null 
        }
    }
    catch(err) {
        return {
            error: err,
            response: null
        }
    }
}