import React, { useState, useEffect } from 'react';
import Checkbox from './CheckboxAlert';
import emailCheckbox from './EmailCheckbox.module.css';

const EmailCheckbox = ({ email, checked = false, checkedEmails, setCheckedEmails }) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checkedEmails.includes(email));
    }, [checkedEmails, email]);

    const handleCheckboxChange = () => {
        toggleCheckbox();
    };

    const handleContainerClick = () => {
        toggleCheckbox();
    };

    const toggleCheckbox = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);

        if (newCheckedState) {
            setCheckedEmails([...checkedEmails, email]);
        } else {
            setCheckedEmails(checkedEmails.filter(id => id !== email));
        }
    };

    return (
        <div className={`${emailCheckbox.cardWrapper} ${isChecked ? emailCheckbox.checkedCard : ''}`} onClick={handleContainerClick}>
            <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <span className={emailCheckbox.cardTitle}>{email}</span>
        </div>
    );
};

export default EmailCheckbox;
