//react components
import React from 'react'

//librarys
import {
    Box,
    IconButton, Typography, Button, Grid
} from "@mui/material";

//Components
import PublicationStatus from '../../../views/publications/Components/PublicationStatus';
import { tooltipPublicationStateContent, tooltipPublicationUpdate } from '../../../utils/tooltipContent';

//Images
import copyData from "../../../assets/img/icons/copy-vector.svg"

const GeneralData = ({ publicationData, createAlert, origin, isKit, handleShowAlert, CustomTooltip }) => {

    const fontSizeMap = {
        publications: {
            sku: "12px",
            ean: "12px",
            store: "14px",
            category: "14px",
            name: "18px",
            priceWithDiscount: "14px",
            price: "25px",
        },
        products: {
            sku: "14px",
            ean: "14px",
            store: "15px",
            category: "15px",
            name: "20px",
            priceWithDiscount: "15px",
            price: "40px",
        },
    };

    const getFontSize = (field) => {
        return fontSizeMap[origin]?.[field] || "14px";
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        handleShowAlert("1, 2, 3 ¡Código copiado!", "success");
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
        }}>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontFamily='Montserrat' fontSize={getFontSize("sku")}>SKU: {publicationData.sku}</Typography>
                    <IconButton
                        value="button-copy"
                        onClick={() => handleCopyToClipboard(publicationData.sku)}
                        sx={{
                            padding: '1px',
                            fontSize: '1rem'
                        }}
                    >
                        <img src={copyData} alt="description" style={{ width: '90%', objectFit: 'cover' }} />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontFamily='Montserrat' fontSize={getFontSize("ean")}>EAN: {publicationData.ean}</Typography>
                    <IconButton
                        value="button-copy"
                        onClick={() => handleCopyToClipboard(publicationData.ean)}
                        sx={{
                            padding: '1px',
                            fontSize: '1rem'
                        }}
                    >
                        <img src={copyData} alt="description" style={{ width: '90%', objectFit: 'cover' }} />
                    </IconButton>
                </Box>

                <Typography fontFamily='Montserrat' fontSize={getFontSize("store")} sx={{ mt: "5px", whiteSpace: 'pre-line', fontWeight: 600 }}>
                    {publicationData.store}
                </Typography>

                <Typography fontFamily='Montserrat' fontSize={getFontSize("category")} sx={{ mt: "5px", whiteSpace: 'pre-line', fontWeight: 500 }}>
                    {publicationData.category}
                </Typography>

                <Typography 
                    fontFamily='Montserrat' 
                    fontSize={getFontSize("name")} 
                    sx={{ mt: "10px", whiteSpace: 'pre-line', fontWeight: 600, overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: origin === "publications" ? 4 : 'unset', // Limit to 4 lines if origin is "publications"
                        textOverflow: 'ellipsis', 
                    }}
                >
                   {isKit? "Kit:" : ""} {publicationData.name}
                </Typography>

                {publicationData.priceWithDiscount && publicationData.price &&
                    <Typography fontFamily='Montserrat' fontSize={getFontSize("priceWithDiscount")} sx={{ mt: "15px", whiteSpace: 'pre-line', fontWeight: 600, textDecoration: 'line-through' }}>
                        ${Number(publicationData.price).toLocaleString('es-CL')}
                    </Typography>
                }


                <Typography fontFamily='Montserrat' fontSize={getFontSize("price")} sx={{ mt: (publicationData.priceWithDiscount && publicationData.price) ? "-10px" : "15px", whiteSpace: 'pre-line', fontWeight: 600 }}>
                    ${publicationData.priceWithDiscount ?  Number(publicationData.priceWithDiscount).toLocaleString('es-CL') : Number(publicationData.price).toLocaleString('es-CL')}
                </Typography> 
                
                {origin === "publications" ? (
                    <Box sx={{mt:"10px", mb:"30px", maxWidth:"150px", maxHeight:"40px", width:"112px", height:"35px"}}>
                        <CustomTooltip tooltipContent={tooltipPublicationStateContent} showTooltip={origin === 'publications'}> 
                            <PublicationStatus statusData={publicationData.statusPublication} isHeightFull={true}/>
                        </CustomTooltip>

                        <CustomTooltip tooltipContent={tooltipPublicationUpdate} showTooltip={origin === 'publications'}> 
                            <Typography fontFamily='Montserrat' fontSize="10px" sx={{ mt: "10px", whiteSpace: 'pre-line', fontWeight: 500 }}>
                                {"Última modificación:"}
                            </Typography>
                            
                            <Typography fontFamily='Montserrat' fontSize="12px" sx={{ whiteSpace: 'pre-line', fontWeight: 700 }}>
                                {publicationData.lastChannelUpdate}
                            </Typography>
                        </CustomTooltip>
                    </Box>                   
                ) : (
                    <Box sx={{mt:"10px"}}>
                        <img src={publicationData.statusPublicationImg} alt="status" style={{ width: '40%', objectFit: 'cover' }} />
                    </Box>                    
                )}
            </Box>
        </Box >

    )
}

export default GeneralData