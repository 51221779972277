import meli from '../assets/img/Logos integraciones/Meli.png'
import amazon from '../assets/img/Logos integraciones/Amazon.png'
import walmart from '../assets/img/Logos integraciones/Walmart.png'
import shopify from '../assets/img/Logos integraciones/Shopify.png'
import magento from '../assets/img/Logos integraciones/Magento.png'
import ripley from '../assets/img/Logos integraciones/ripley.png'
import coppel from '../assets/img/Logos integraciones/Coppel.png'
import linio from '../assets/img/Logos integraciones/Linio.png'
import dafiti from '../assets/img/Logos integraciones/Dafiti.png'
import woo from '../assets/img/Logos integraciones/Woo.png'
import salesforce from '../assets/img/Logos integraciones/Salesforce.png'
import jumpseller from '../assets/img/Logos integraciones/Jumpseller.png'
import vtex from '../assets/img/Logos integraciones/Vtex.png'
import falabella from '../assets/img/Logos integraciones/Falabella.png'
import bigcommerce from "../assets/img/Logos integraciones/bigcommerce.png"
import liverpool from "../assets/img/Logos integraciones/Liverpool.png"

import { getChannelsParameters } from 'services/channels'

export const channelImports = async ()=>{
    const listOfChannels = await getChannelsParameters();

    return [
        {channelImg:meli, channelData : listOfChannels.find(e=> e.id === 2)},
        {channelImg:amazon, channelData : listOfChannels.find(e=> e.id === 18)},
        {channelImg:falabella, channelData : listOfChannels.find(e=> e.id === 13)},
        {channelImg:vtex, channelData : listOfChannels.find(e=> e.id === 7)},
        {channelImg:linio, channelData : listOfChannels.find(e=> e.id === 5)},
        {channelImg:walmart, channelData : listOfChannels.find(e=> e.id === 20)},
        {channelImg:shopify, channelData:listOfChannels.find(e=> e.id === 6)},
        {channelImg:magento, channelData:listOfChannels.find(e=> e.id === 9)},
        {channelImg:ripley, channelData:listOfChannels.find(e=> e.id === 4)},
        {channelImg:woo, channelData:listOfChannels.find(e=> e.id === 3)},
        {channelImg:coppel, channelData:listOfChannels.find(e=> e.id === 17)},
        {channelImg:dafiti, channelData:listOfChannels.find(e=> e.id === 16)},
        {channelImg:salesforce, channelData:listOfChannels.find(e=> e.id === 24)},
        {channelImg:jumpseller, channelData:listOfChannels.find(e=> e.id === 14)},
        {channelImg:bigcommerce, channelData:listOfChannels.find(e=> e.id === 25)},
        {channelImg:liverpool, channelData:listOfChannels.find(e=> e.id === 22)}
    ]
        
    
}

export const channelImportsFromParameters = (listOfChannels)=>{
    try {
        return [
            {channelImg:meli, channelData : listOfChannels.find(e=> e.id === 2)},
            {channelImg:amazon, channelData : listOfChannels.find(e=> e.id === 18)},
            {channelImg:falabella, channelData : listOfChannels.find(e=> e.id === 13)},
            {channelImg:vtex, channelData : listOfChannels.find(e=> e.id === 7)},
            {channelImg:linio, channelData : listOfChannels.find(e=> e.id === 5)},
            {channelImg:walmart, channelData : listOfChannels.find(e=> e.id === 20)},
            {channelImg:shopify, channelData:listOfChannels.find(e=> e.id === 6)},
            {channelImg:magento, channelData:listOfChannels.find(e=> e.id === 9)},
            {channelImg:ripley, channelData:listOfChannels.find(e=> e.id === 4)},
            {channelImg:woo, channelData:listOfChannels.find(e=> e.id === 3)},
            {channelImg:coppel, channelData:listOfChannels.find(e=> e.id === 17)},
            {channelImg:dafiti, channelData:listOfChannels.find(e=> e.id === 16)},
            {channelImg:salesforce, channelData:listOfChannels.find(e=> e.id === 24)},
            {channelImg:jumpseller, channelData:listOfChannels.find(e=> e.id === 14)},
            {channelImg:bigcommerce, channelData:listOfChannels.find(e=> e.id === 25)},
            {channelImg:liverpool, channelData:listOfChannels.find(e=> e.id === 22)}

        ]
    } catch (error) {
        console.log(error)
        return []
    }
        
    
}