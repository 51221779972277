
import MtdiTable from "views/Orders/mtdi-table.js";
import Products from "views/Products/Products";
import Promotions from "views/Promotions/Promotions";
import Publications from "views/publications/Publications";
import MetricsDashboardsView from "views/Acceleration/Metrics/MetricsDashboardsView"


const operatorRoutes = [
  {
    collapse: true,
    name: "Tiendas",
    icon: "nc-icon nc-box",
    state: "pagesCollapse",
    invisible: false,
    views: [
      {
        path: "/products",
        name: "Productos",
        icon: "nc-icon nc-layout-11",
        component: Products,
        layout: "/admin"
      },
      {
        path: "/promotions",
        name: "Promociones",
        icon: "nc-icon nc-tag-content",
        component: Promotions,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/orders",
    name: "Operación",
    icon: "nc-icon nc-single-copy-04",
    component: MtdiTable,
    layout: "/admin",
    invisible: false,
  },
  {
    collapse: true,
    name: "Aceleración",
    icon: "nc-icon nc-spaceship",
    state: "pagesCollapseAceleracion",
    views: [
      {
        path: "/publications",
        name: "Publicaciones",
        icon: "nc-icon nc-layout-11",
        component: Publications,
        layout: "/admin"
      },
      {
        path: "/metrics-dashboards",
        name: "Indicadores",
        icon: "nc-icon nc-ruler-pencil",
        component: MetricsDashboardsView,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/",
    name: "Operación",
    icon: "nc-icon nc-single-copy-04",
    component: MtdiTable,
    layout: "/admin",
    invisible: true
  },
];
export default operatorRoutes;
