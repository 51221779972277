import React from 'react';
import CustomModal from '../../UI/CustomModal';
import FirstAlertModalLoader from '../../../views/publications/Components/FirstAlertModalLoader';
import AlertTypeCard from '../../../views/publications/Components/AlertTypeCard';
import classes from '../AlertsCenter.module.css';
import firstModalStyles from '../../PublicationAlertModals/FirstAlertModal.module.css';


const FirstAlertModal = ({ 
    firstModal, 
    toggleFirstModal, 
    closeFirstModalCloseButton, 
    loadingAlertTypes, 
    loadingAlert, 
    checkedAlertTypes, 
    handleSaveChanges, 
    alertTypes, 
    setCheckedAlertTypes, 
    CircularProgress 
}) => {
    
    return (
        <CustomModal
            isOpen={firstModal}
            toggle={toggleFirstModal}
            onClose={closeFirstModalCloseButton}
            title="Editar alerta"
            customStyles={{ box: firstModalStyles.customModal }}
            customClassNames={{
                form: firstModalStyles.modalForm,
                header: classes.crearAlertaParent,
                title: firstModalStyles.crearAlerta,
                closeWrapper: classes.closeWrapper,
                closeIcon: classes.closeIcon,
                separatorContainer: firstModalStyles.separatorContainer,
                separator: firstModalStyles.separator,
                bodyContainer: firstModalStyles.modal1CreacinDeAlertaInner,
                footerContainer: firstModalStyles.botonLoginWrapper,
                box: classes.afterElementNone
            }}
            footerActions={
                !loadingAlertTypes && (
                    firstModal && (
                        !loadingAlert && (
                            <button
                                type="button"
                                className={firstModalStyles.submitButton}
                                disabled={checkedAlertTypes?.length < 1}
                                onClick={handleSaveChanges}
                            >
                                <b className={firstModalStyles.siguiente}>Guardar cambios</b>
                            </button>
                        )
                    )
                )
            }
        >
            {
                loadingAlertTypes ? (
                    <FirstAlertModalLoader />
                ) : (
                    <section className={firstModalStyles.modal1CreacinDeAlertaInner}>
                        <div className={firstModalStyles.frameParent}>
                            <div className={firstModalStyles.seleccionaAlMenosUnTipoDeWrapper}>
                                <div className={firstModalStyles.seleccionaAlMenos}>
                                    Selecciona al menos un tipo de alerta
                                </div>
                            </div>
                            <div className={firstModalStyles.frameGroup}>
                                {alertTypes && alertTypes.map((alert, index) => (
                                    <AlertTypeCard
                                        key={index}
                                        alertType={alert}
                                        isChecked={false}
                                        checkedAlertTypes={checkedAlertTypes}
                                        setCheckedAlertTypes={setCheckedAlertTypes}
                                    />
                                ))}
                            </div>
                        </div>
                    </section>
                )
            }
            {loadingAlert && (
                <div className={classes.botonLoadingContainerFirst}>
                    <div className={classes.botonLoading} disabled={true}>
                        <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                        />
                        <b >Cargando</b>
                    </div>
                </div>                  
            )}
        </CustomModal>
    );
};

export default FirstAlertModal;
