import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from "./components/Register/Login";
import AdminLayout from "layouts/Admin.js";
import AuthContext from "store/auth-context";
import {clickMetric, behaviourMetric, isObject} from "./utils/utils";

function getIfAnyElementHasOnClickProperty(target){
    if( target === null ||
        target.id === "root" ||
        target.nodeName === "BODY")
        return null;
    else if(target.onclick !== null) return target;
    return getIfAnyElementHasOnClickProperty(target.parentElement);
}

const App = () => {
    const { isLoggedIn } = useContext(AuthContext);

    // Clicks metric
    document.addEventListener("click", e => {
        resetTimer(true);
        const elementWithOnClick = getIfAnyElementHasOnClickProperty(e.target);
        if (elementWithOnClick && !isObject(elementWithOnClick.className)){
            const name = `${elementWithOnClick.className.split("__")[0].substring(0, 20)}:${elementWithOnClick.innerText.substring(0, 20)}`;
            clickMetric(name.trim(), localStorage.getItem("name"), localStorage.getItem('Token'))
        }
    });

    // Behaviour metric
    let time;
    let activityTime = Date.now();
    let inactivityTime;
    let totalActivityTime = 0;
    let totalInactivityTime = 0;
    let isActive = true;
    let oldPath = window.location.pathname;

    document.onload = resetTimer;

    function logout() {
        if(isActive){
            inactivityTime = Date.now();
            const view = window.location.pathname;
            totalActivityTime += Date.now() - activityTime;
            console.log(`You are now logged out. TIEMPO ACTIVO: ${Date.now() - activityTime} in ${view}`);
            isActive = false;
        }
    }

    function login(){
        if(!isActive){
            activityTime = Date.now();
            const view = window.location.pathname;
            totalInactivityTime += Date.now() - inactivityTime;
            console.log(`You are now logged in. TIEMPO INACTIVO: ${Date.now() - inactivityTime} in ${view}`);
            isActive = true;
        }
    }

    function pageChange(){
        // Send behaviour metric
        totalActivityTime += Date.now() - activityTime;
        if(totalActivityTime > 1000)
            behaviourMetric(oldPath, "active", totalActivityTime, localStorage.getItem("name"), localStorage.getItem('Token'));
        if(totalInactivityTime > 1000)
            behaviourMetric(oldPath, "inactive", totalInactivityTime, localStorage.getItem("name"), localStorage.getItem('Token'));

        // Reset activity timers
        activityTime = Date.now();
        inactivityTime = null;
        totalActivityTime = 0;
        totalInactivityTime = 0;

        // Change current view
        oldPath = window.location.pathname;
    }

    function resetTimer(stopInactivity = false) {
        if(stopInactivity && !isActive) login();
        if(oldPath !== window.location.pathname) pageChange();
        clearTimeout(time);
        time = setTimeout(() => {
            logout();
            resetTimer();
        }, 20000) // 20 sec timeout
    }

    // behaviour metric
    window.addEventListener('load', resetTimer, true);
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach((name) => {
        document.addEventListener(name, () => resetTimer(true), true);
    });
  
  return (
    <Router>
      <Route exact path="/">
        {isLoggedIn ? <Redirect to="/admin"/> : <Redirect to="/login"/>}
      </Route>
      <Route path="/login">
        {isLoggedIn ? <Redirect to="/admin"/> : <Login/>}
      </Route>
      <Route path="/*">
        {isLoggedIn ? <AdminLayout /> : <Redirect to="/login"/>}
      </Route>
    </Router>
  );
};
export default App;
