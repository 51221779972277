import ProfileHeader from 'components/UI/ProfileHeader'
import React, { useRef, useState } from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { connectBilling } from 'services/billings'
import classes from "./BsaleBilling.module.css"
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import logoBsale from "../../assets/img/logoBsale.png"
import { editBilling } from 'services/billings'
import { deleteBilling } from 'services/billings'

const textSucces = "Conexión creada con éxito."
const textModal = "¿Esta seguro de realizar la conexión a Bsale?"

const BsaleBilling = ({handleRoute, mode, myBillings, myNotBillings, storeData, updateStoreData}) => {
    
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
    const [success,setSuccess] = useState(false)
    const [token, setToken] = useState("")
    const currentChannel = useRef(undefined)
    const currentPrice = useRef(undefined)
    const currentUser = useRef(undefined)
    const [successToken, setSuccessToken] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingToken, setLoadingToken] = useState(false)
    const [priceList, setPriceList] = useState([])
    const [users, setUsers] = useState([])
    const countriesBilling = [1]

    const handleModalConfirm = () =>  setShowModalConfirm(prevState => !prevState)
    const handleModalConfirmDelete = () =>  setShowModalConfirmDelete(prevState => !prevState)

    const handleChannel = (event) => {
        event.preventDefault()
        currentChannel.current = event.target.value
    }

    const handlePriceList = (event) => {
        event.preventDefault()
        currentPrice.current = event.target.value
    }

    const handleUser = (event) => {
        event.preventDefault()
        currentUser.current = event.target.value
    }

    const handleToken = (event) => {
        event.preventDefault() 
        setToken(event.target.value)
    }

    const sendToken = async (event) => {

        if (currentChannel.current === 0) {return}

        event.preventDefault()
        setLoadingToken(true)
        const body = [{
            channel: currentChannel.current,
            token: token,
            billing: {
                declareSii: 0,
                billingCompanyId: 1,
              }
        }]
        const response = await connectBilling( storeData.id, body)
        console.log("bsale response", response.message )
        if (response.message.results[0]?.result === "Success") {
            setPriceList(response.message.results[0]?.pricelist)
            setUsers(response.message.results[0]?.users)
            setSuccessToken(true)
            setLoadingToken(false)
        }
    }

    const handleSubmit = async (event) => {
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const body = [{
            channel: currentChannel.current,
            priceList: currentPrice.current,
            token: token,
            billing: {
                declareSii: 0,
                billingCompanyId: 1,
                idbsale: currentUser.current
              }
        }]
        const response = await connectBilling( storeData.id, body)
        if (response.message.results[0]?.result === "Success") {
            setSuccess(true)
        }
        setLoading(false)
    }

    const handleSubmitEdit = async (event) => {
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const body = [{
            channel: currentChannel.current,
            priceList: currentPrice.current,
            billing: {
                declareSii: 0,
                billingCompanyId: 1,
                idbsale: currentUser.current
              }
        }]
        const response = await editBilling( storeData.id, body)
        if (response.message.results[0]?.result === "Success") {
            setSuccess(true)
        }
        setLoading(false)
    }

    const handleSubmitDelete = async (event) =>{
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const response = await deleteBilling( storeData.id, currentChannel.current)
        if (response === "Store config billing delete") {
            setSuccess(true)
        }
        setLoading(false)
      }

    return (
        <>
            <div className={classes.wrapper}>
                <ProfileHeader backTo={() => handleRoute(mode === "edit" ? "current" : 'add')}>Bsale</ProfileHeader>
                {loadingToken && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
                {!successToken && !loadingToken && (
                    <>
                        <div className={classes.form}>
                            <div className={classes.inputLabel}>
                                <label className={classes.label}>
                                    Elige un canal de venta
                                </label>
                                <select 
                                    name="channel" 
                                    onChange={(choice) =>  handleChannel(choice)}
                                    className={classes.select}
                                >
                                    <option value="">Canales disponibles</option>
                                    {mode === "edit" &&
                                        myBillings.filter((b)=> b.billing === "Bsale").map((channel, i) => (
                                            <option key={i} value={channel.channelId}>{channel.channelName}</option>
                                        ))
                                    }
                                    {mode === "connect" &&
                                        myNotBillings.map((channel, i) => (
                                            channel.paises.some( c => countriesBilling.includes(c)) &&
                                                <option key={i} value={channel.channelId}>{channel.channelName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={classes.inputLabel}>
                                    <label className={classes.label}>
                                        Token
                                    </label>
                                    <input 
                                        type="text"
                                        name="Token"
                                        value={token}
                                        className={classes.input}
                                        onChange={event => handleToken(event)}
                                    />
                            </div>
                        </div>
                        <button className={classes.buttonToken} onClick={sendToken}>
                            <span className={classes.buttonText}>Enviar Token</span>
                        </button>
                        {mode === "edit" &&
                            <button className={classes.buttonDelete} onClick={handleModalConfirmDelete}>
                                <span className={classes.buttonTextDelete}>Eliminar facturación</span>
                            </button>
                        }
                    </>
                )}
                { (successToken && !loadingToken && priceList.length > 0 && users.length > 0) && 
                    <>
                        <div className={classes.form}>
                            <div className={classes.inputLabel}>
                                <label className={classes.label}>
                                    User
                                </label>
                                <select 
                                    name="user" 
                                    onChange={(event) => handlePriceList(event)}
                                    className={classes.select}
                                >
                                    <option value="">Selecciona un Usuario</option>
                                    {users.map((user, i) => (
                                        <option key={i} value={user.id}>{user.name}</option>
                                    ))}
                                    
                                </select>
                            </div>
                            <div className={classes.inputLabel}>
                                <label className={classes.label}>
                                    Price list
                                </label>
                                <select 
                                    name="priceList" 
                                    onChange={(event) => handleUser(event)}
                                    className={classes.select}
                                >
                                    <option value="">Selecciona un Price list</option>
                                    {priceList.map((price, i) => (
                                        <option key={i} value={price.id}>{price.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        { mode === "connect" &&
                            <button className={classes.button} onClick={handleModalConfirm}>
                                <span className={classes.buttonText}>Conectar con Bsale</span>
                            </button>
                        }
                        { mode === "edit" &&
                            <button className={classes.button} onClick={handleModalConfirm}>
                                <span className={classes.buttonText}>Editar facturación</span>
                            </button>
                        }
                    </>
                }
            </div>
            { showModalConfirm && (
            <Modal isOpen={showModalConfirm} toggle={handleModalConfirm} size="lg">
                <ModalHeader>
                    <div className={classes.modalHeader}>
                        <button className={classes.modalExitButton} onClick={handleModalConfirm}>
                        x
                        </button>
                     </div>
                </ModalHeader>
                {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
                {!loading && 
                    (<div className={classes.wrapperModal}>
                    <h3 className={classes.textModal}>{mode === "edit" ? "Editar facturación" : "Conectar con"}</h3>
                    <div className={classes.wrapperLogo}>
                    <img src={logoBsale} className={classes.imgLogo}/>
                    </div>
                    {mode === "connect" && <p className={classes.textBsale}>{success ? textSucces : textModal }</p>}
                    {mode === "edit" &&
                        <p className={classes.textBsale}>
                            {success ? 
                                "Datos cambiados correctamente." 
                                : "Se van a cambiar los datos de conexión. ¿Deseas continuar con el cambio?" }
                        </p>}
                    {success ? 
                        <button
                            className={classes.button}
                            onClick={() => {
                                handleModalConfirm()
                                handleRoute('current')
                                updateStoreData(storeData.id)
                            }}>
                            <span className={classes.buttonText}>Regresar a mi panel</span>
                        </button> 
                        : 
                        <button
                            type="submit"
                            className={classes.button}
                            onClick={mode === "edit" ? handleSubmitEdit : handleSubmit}>
                            <span className={classes.buttonText}>Si, seguro</span>
                        </button>
                    }
                    {!success && <span className={classes.textBack} onClick={handleModalConfirm}>No, quiero volver atrás</span>}                 
                    </div>)
                }
            </Modal>
        )}
        { showModalConfirmDelete && (
        <Modal isOpen={showModalConfirmDelete} toggle={handleModalConfirmDelete} size="lg">
            <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={handleModalConfirmDelete}>
                    x
                    </button>
                </div>
            </ModalHeader>
            {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
            {!loading && 
                (<div className={classes.wrapperModal}>
                <div className={classes.wrapperLogo}>
                  <img src={logoBsale} className={classes.imgLogo}/>
                </div>
                <p className={classes.textBsale}>{success ? "Se ha eliminado correctamente" : "¿Estas seguro que deseas eliminar esta facturación?" }</p>
                {success ? 
                    <button
                        className={classes.button}
                        onClick={() => {
                            handleModalConfirmDelete()
                            handleRoute('current')
                            updateStoreData(storeData.id)
                        }}>
                        <span className={classes.buttonText}>Regresar a mi panel</span>
                    </button> 
                    : <button
                        type="submit"
                        className={classes.button}
                        onClick={handleSubmitDelete}>
                        <span className={classes.buttonText}>Eliminar facturación</span>
                    </button>
                }
                {!success && <span className={classes.textBack} onClick={handleModalConfirmDelete}>No, quiero volver atrás</span>}                 
                </div>)
            }
        </Modal>
        )}
        </>
    )
}

export default BsaleBilling