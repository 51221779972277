import React from 'react'
import classes from './WelcomeCatalog.module.css'
import { useHistory } from "react-router-dom";

import InstanceLogo from "../../assets/img/logo-instanceazul.png";

const title = "¡Bienvenido a Instance!"
const subtitle = "Este es tu administador de catálogo"
const detail = "Te encuentras en tu panel principal. Tu cuenta ha sido creada con éxito y en breve te contactará nuestro equipo de Customer Success para finalizar tu proceso de OnBoarding"
const secondDetail = "Te invitamos a cargar tu catálogo y tus productos en la  barra de “Opciones”. ¿Te quedan más dudas? Utiliza el chat de asistencia en línea que aparece en la esquina inferior derecha o escríbenos directamente a "

const WelcomeCatalog = () => {

  const history = useHistory();

  return (
    <div className={classes.wrapper}>
        <div className={classes.imgWrapper}>
            <img className={classes.img} src={InstanceLogo} />
        </div>
        <h2 className={classes.title}>{title}</h2>
        <h3 className={classes.title}>{subtitle}</h3>
        <p className={classes.text}>{detail}</p>
        <p className={classes.text}>
            {secondDetail}
            <span className={classes.link} href="mailto:sacchile@instancelatam.com">sacchile@instancelatam.com</span>
        </p>
        <button className={classes.button} onClick={() => history.push('manage-catalog/upload')}>
            <span className={classes.buttonText}>Ir a carga de catálogo</span>
        </button>
    </div>
  )
}

export default WelcomeCatalog