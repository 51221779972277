import React from "react";
import classes from "./currentFulfillment.module.css";
import ProfileHeader from "components/UI/ProfileHeader";
import CardItem from "components/UI/CardItem";

import spinnerGif from "../../assets/img/spinnerLogos.gif";
import empty from "../../assets/img/channelstore-emptymessage.png";
import iconInstance from "../..//assets/img/icons/iconInstance.svg";

const CurrentFulfillment = ({
  loading,
  backToStoreDetail,
  instanceWms,
  handleCurrent,
  handleRoute,
  parameters,
  allWmsConfiguration,
  isAllWmsDisabled,
}) => {
 

  return (
    <div className={classes.wrapper}>
      <ProfileHeader backTo={backToStoreDetail}>Fulfillment</ProfileHeader>
      <p className={classes.introductionText}>
        En esta sección encontrarás tus WMS que se encuentran activos. Desde
        aquí puedes controlar todo lo respectivo con tu proceso de Fulfillment
        en tus diferentes canales de venta. También puedes cambiar, editar o
        incluso eliminar el WMS
      </p>
      {loading && (
        <div className={classes.spinner}>
          <img src={spinnerGif} className={classes.imgSpinner} />
        </div>
      )}
      {!loading && (
        <div className={classes.billings}>
            {(allWmsConfiguration.length === 0)  && (
            <div className={classes.empty}>
              <img
                src={empty}
                className={classes.imgEmpty}
                onClick={() => handleRoute("add")}
              />
              <span className={classes.textEmpty}>
                No hay ningún WMS activo actualmente
              </span>
            </div>
          )}
          {allWmsConfiguration.length === 0 || isAllWmsDisabled === true && (
            <div className={classes.empty}>
              <img
                src={empty}
                className={classes.imgEmpty}
                onClick={() => handleRoute("add")}
              />
              <span className={classes.textEmpty}>
                No hay ningún WMS activo actualmente
              </span>
            </div>
          )}
          {allWmsConfiguration.length !== 0 &&
            allWmsConfiguration.some((b) =>
           b.wmsCompanyName ===  "Instance"
            )&& (
              <CardItem
                name="Instance"
                icon={iconInstance}
                onClick={() => handleRoute("editinstance")}
              />
            )}

          {allWmsConfiguration.length !== 0 &&
            parameters.map(
              (wms, i) =>
                allWmsConfiguration.filter((b) => b.wmsCompanyName === wms.name)
                  .length !== 0 && (
                  <CardItem
                    key={i}
                    name={wms.name.charAt(0).toUpperCase() + wms.name.slice(1)}
                    icon={wms.config_mtd?.icon}
                    onClick={() => {
                      handleRoute("editform");
                      handleCurrent(wms.name);
                    }}
                  />
                )
            )}
        </div>
      )}
      {!isAllWmsDisabled && (
        <button className={classes.button} onClick={() => handleRoute("add")}>
          <span className={classes.buttonText}>Agregar otro Wms</span>
        </button>
      )}
      {isAllWmsDisabled && (
        <button className={classes.button} onClick={() => handleRoute("add")}>
          <span className={classes.buttonText}>Agregar WMS</span>
        </button>
      )}
    </div>
  );
};

export default CurrentFulfillment;
