import React, { useEffect, useState } from "react";
import { Select, MenuItem} from "@material-ui/core";
import {Button} from "reactstrap";
var configFile = require('../../config/url');
import {ErrorBoundary} from 'react-error-boundary';
import ErrorHandler from "components/ErrorHandler/error-handler";
import {performanceMetric} from "../../utils/utils";

//images

import noDashboardsFoundImg from "../../assets/img/searches/no-dashboards-found.png"

const DataStudio = () => {
  const initialUrl = "https://lookerstudio.google.com/embed/reporting/e3f777f9-cfba-45bb-a2d3-7499c3eb29b7/page/RkYTC"
  const [country, setcountry] = useState("");
  const [store, setstore] = useState("");
  const [filteredCountryData, setfilteredCountryData] = useState([]);
  const [filteredStoreData, setfilteredStoreData] = useState([]);
  const [storeId, setstoreId] = useState(0);
  const [iframeUrl, setiframeUrl] =useState();
  useEffect(() => {
    fetchFilterData();
    fetchIframes();
  }, []);
  const handleCountryChange = (event) => {
    setcountry(event.target.value);

    const val = filteredCountryData.filter(function (item) {
      if (item.country === event.target.value) {
        return item;
      }
    });
  
    if (localStorage.getItem("ut") === "2" || localStorage.getItem("ut") === "3") {
      const kamstore = localStorage.getItem("st");
    
      var b = kamstore.split(",").map(function (item) {
        return parseInt(item, 10);
      });
    
      let finalKamStore = [];
      for (let i = 0; i <= b.length - 1; i++) {
        let x = val[0].stores.filter((item) => {
          return item.value === b[i];
        });
        finalKamStore.push(x);
      }
      var flattened = [].concat.apply([], finalKamStore);
     
      setfilteredStoreData(flattened);
      
    }
    if (localStorage.getItem("ut") === "1") {
      setfilteredStoreData(val[0].stores);
    }
  };
  const handleStoreChange = (event) => {
    setstore(event.target.value);

    const val = filteredStoreData.filter(function (item) {
      if (item.store === event.target.value) {
        return item;
      }
    });
   
    setstoreId(val[0].value);
  };
  const applyIframes = () => {
   
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
      "Authorization",
      `Bearer ${configFile.config.bearerToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url =  `${configFile.config.ambiente}store?id=${storeId}`
    const start = Date.now();    

    fetch(url, requestOptions)
    .then((response) => {
      performanceMetric("/store", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, configFile.config.bearerToken);
     return response.text();
  })
      .then((result) => {
      
        let ob = JSON.parse(result);
    
        setiframeUrl(ob.message.store_data.datastudio_iframe);
      
      })
      .catch((error) => console.log("error", error));
   
  }
  
  const fetchIframes = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
      "Authorization",
      `Bearer ${configFile.config.bearerToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "";
    if (localStorage.getItem("ut") === "1") {
      setiframeUrl(initialUrl)
      return
    }
    if (localStorage.getItem("ut") === "2") {
    
      let userStoreId = localStorage.getItem("st");
      var b = userStoreId.split(",").map(function (item) {
        return parseInt(item, 10);
      });
      let parseduserStoreId = b[0];
   
      url = `${configFile.config.ambiente}store?id=${parseduserStoreId}`;
    }
    if (localStorage.getItem("ut") === "3") {
   
      let userStoreId = localStorage.getItem("st");
      var b = userStoreId.split(",").map(function (item) {
        return parseInt(item, 10);
      });
      let parseduserStoreId = b[0];
     
      url = `${configFile.config.ambiente}store?id=${parseduserStoreId}`;
     
    }
    const start = Date.now();
    fetch(url, requestOptions)
    .then((response) => {
      performanceMetric("/store", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
     return response.text();
  })
      .then((result) => {
        let ob = JSON.parse(result);
        setiframeUrl(ob.message.datastudio_iframe);
      })
      .catch((error) => console.log("error", error));
  };
  const fetchFilterData = async () => {
    let myHeaders = new Headers();
    const accessToken = localStorage.getItem('Token')
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
      "Authorization",
      `Bearer ${accessToken}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const start = Date.now();
    fetch(
      `${configFile.config.ambiente}dashboard/filtersorders`,
      requestOptions
    )
    .then((response) => {
      performanceMetric("/dashboard/filtersorders", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
     return response.text();
  })
      .then((result) => {
        const obj = JSON.parse(result);
        setfilteredCountryData(obj.message);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <React.Fragment>
         <ErrorBoundary FallbackComponent={ErrorHandler}>
      <div style={{ width: "100%", height: "100px", marginLeft: "100px", marginTop: "70px" }}>
        <label htmlFor="select-country">
          <h5
            style={{
              color: "black",
              width: "30px",
              fontSize: "12px",
              fontWeight: "800",
              marginLeft: "1em",
              marginBottom: "0px",
            }}
          >
            País
          </h5>
          <Select
            labelId="select-country"
            id="select-country"
            style={{
              width: "193px",
              height: "46px",
              marginLeft: "1em",
              backgroundColor: "white",
              borderRadius: "17px",
              marginBottom: "1em",
              fontSize: "10px",
              marginTop: "1em",
              overflow:'hidden'
            }}
            value={country}
            label="Country"
            placeholder="&nbsp;&nbsp; Seleccione un país"
            onChange={handleCountryChange}
          >
            {Array.from(new Set(filteredCountryData.map((obj) => obj))).map(
              (period) => {
                return (
                  <MenuItem
                    style={{
                      width: "193px",
                      height: "46px",
                      backgroundColor: "white",
                    }}
                    value={period.country}
                  >
                    {period.country}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </label>
        <label htmlFor="select-tienda">
          <h5
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "800",
              marginLeft: "1em",
              marginBottom: "0px",
              marginTop: "1em",
            }}
          >
            Tienda
          </h5>
          <Select
            labelId="select-tienda"
            id="select-tienda"
            style={{
              width: "193px",
              height: "46px",
              backgroundColor: "white",
              borderRadius: "17px",
              fontSize: "10px",
              marginLeft: "1em",
              marginTop: "1em",
              overflow:'hidden'
            }}
            value={store}
            // label="select-canal"
            placeholder="&nbsp;&nbsp; Seleccione una tienda"
            onChange={handleStoreChange}
          >
            {Array.from(new Set(filteredStoreData.map((obj) => obj.store))).map(
              (period) => {
                return <MenuItem value={period}>{period}</MenuItem>;
              }
            )}
          </Select>
        </label>
        <Button
          color="primary"
          id="tableButtons"
          style={{
            borderRadius: "22px",
            color: "#FFFFFF",
            marginLeft: "1em",
            textTransform: "none",
            letterSpacing: "1px",
            width: "120px",
            height: "46px",
            fontWeight: "600",
          }}
          onClick={applyIframes}
        >
          Aplicar
        </Button>
      </div>
      {!iframeUrl ?
        <div className="no-data-found-container">
          <div>
            <img src={noDashboardsFoundImg} className={"no-results-image"} />
          </div>
          <span className="no-data-found-text">Aún no tienes elementos para medir</span>
        </div>
      :
        <div style={{ backgroundColor: "white", width: "100%", height: "100vh" }}>
          <iframe
            src={iframeUrl}
            style={{
              position: "relative",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              width: "95%",
              height: "100%",
              marginLeft: "50px",
              border: "none",
              padding: "0",
              overflow: "hidden",
        
            }}
          ></iframe>
        </div>
      }
      </ErrorBoundary>
    </React.Fragment>
  );
};
export default DataStudio;

