import React, { useState, useEffect, useRef } from 'react'
// import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useMediaQuery } from "@mui/material";
import Close from "../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import CountryCircles from './CountryCircles';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import FormHelperText from '@mui/material/FormHelperText';
import ChannelCircles from './ChannelsCircles';
import { Modal } from 'reactstrap'
import atras from "../../../../assets/img/icons/DespliegueBlack.png"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Upload from "../../../../assets/img/icons/upload.png"
import FormGroup from '@mui/material/FormGroup';
import stepImage from "../../../../assets/img/icons/step.png"
import stepSelectedImage from "../../../../assets/img/icons/stepSelected.png"
//styles
import classes from "./displayEditClientStores.module.css";
import { getUsersByProfile } from 'services/users';
import { getBusinessModels } from 'services/stores';

import { mapServicesNewClientToDataKey } from "../../../../utils/utils"

function CreateStoreMobile({ openMobileModal, toggleMobilModal, setCountry, setCliente, clientData, resetValues, modalTittle, allStores, setContrat, listOfChannels, loadingClient, contratoCliente, handleSubmitFinal }) {
    const [actualStep, setActualStep] = useState(1)
    const isMobile = useMediaQuery('(max-width:600px)');
    const [kam, setKam] = useState('');
    const [kamsOptions, setKamsOptions] = useState([])
    const [countryCode, setCountryCode] = useState(clientData.client_country)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [selectedFile, setSelectedFile] = useState(contratoCliente);
    const [channelsOnboardingSelected, setChannelsOnboardingSelected] = useState(clientData.channelsOnboarding)
    const [businessModels, setBusinessModels] = useState([])
   
    // represents if there's an error fetching business models.
    // Will be used to not validate combinations in case the error exists 
    const [existsErrorFetchingBusinessModels, setExistsErrorFetchingBusinessModels] = useState(false)

    const inputRef = useRef(null)

    const [error, setError] = useState({
        nameError: false,
        namelegalError: false,
        emailError: false,
        countryError: false,
        kamError: false,
        addressError: false,
        identityError: false,
        social_reasonError: false,
        type_of_sellingError: false,
        contratError: false,
        servicesCombination: false
    })

    const [clientModal, setClientModal] = useState(clientData);

    const handleCheck = (e) => {
        const newClientData = { ...clientData, [e.target.name]: !clientData[e.target.name] }
        setCliente(newClientData);
        const servicesCombinationExists = checkServicesCombination(newClientData)

        if(!servicesCombinationExists){
            setError({ ...error, servicesCombination: true})
        }
        else{
            setError({ ...error, servicesCombination: false})
        }
    }

    const checkServicesCombination = (clientData, dataFromInitialFetch=null) => {
        try {
            const businessModelsData = dataFromInitialFetch || businessModels
            const servicesObjectSelectedMapped = mapServicesNewClientToDataKey(clientData)

            const serviceFound = businessModelsData.find(businessModelFromAPI => {
                
                for(const serviceSelectedKey of Object.keys(servicesObjectSelectedMapped)){
                   
                    if(servicesObjectSelectedMapped[serviceSelectedKey] !== businessModelFromAPI[serviceSelectedKey]){
                        return false
                    }

                }

                return true
            })

            return serviceFound != undefined
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(async () => {

        await Promise.allSettled([
            getKams(),
            getBusinessModelsForValidation()
        ]) 
        
        if (clientData.name && clientData.namerlegal && clientData.email && clientData.client_country){
            setButtonDisabled(false)
        }
    }, [])

    useEffect(() => {
        setCliente({ ...clientData, channelsOnboarding: channelsOnboardingSelected });
    }, [channelsOnboardingSelected]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientModal({ ...clientModal, [name]: value });

        if (e.target.name === "name" && error.nameError)
            setError({ ...error, nameError: false })
        if (e.target.name === "namerlegal" && error.namelegalError)
            setError({ ...error, namelegalError: false })
        if (e.target.name === "email" && error.emailError)
            setError({ ...error, emailError: false })
        if (clientModal.name && clientModal.namerlegal && clientModal.email && countryCode && value) {
            setButtonDisabled(false)
        }
        if (e.target.name === "address" && error.addressError)
            setError({ ...error, addressError: false })
        if (e.target.name === "identity" && error.identityError)
            setError({ ...error, identityError: false })
        if (e.target.name === "social_reason" && error.social_reasonError)
            setError({ ...error, social_reasonError: false })
        if (e.target.name === "type_of_selling" && error.type_of_sellingError)
            setError({ ...error, type_of_sellingError: false })

        if (clientModal.name && clientModal.namerlegal && clientModal.email && countryCode && value && actualStep === 1) {
            setButtonDisabled(false)
        } else if (clientModal.address && clientModal.identity && clientModal.social_reason && value && actualStep === 2) {
            setButtonDisabled(false)
        } else if (clientModal.type_of_selling && value && (selectedFile || clientData.contratFileName) && value && actualStep === 3) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

    };

    useEffect(async () => {
        if (clientModal.name && clientModal.namerlegal && clientModal.email && countryCode && actualStep === 1) {
            setButtonDisabled(false)
        } else if (clientModal.address && clientModal.identity && clientModal.social_reason  && actualStep === 2) {
            setButtonDisabled(false)
        } else if (clientModal.type_of_selling && (selectedFile || clientData.contratFileName) && actualStep === 3) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }, [actualStep])

    useEffect(() => {
        setCountry(countryCode)
        setClientModal({ ...clientModal, client_country: countryCode })
        if (clientModal.name && clientModal.namerlegal && clientModal.email && countryCode && actualStep === 1) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
        if (error.countryError)
            setError({ ...error, countryError: false })
    }, [countryCode])

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            if (file.type === 'application/pdf') {
                if (error.contratError) {
                    setError({ ...error, contratError: false })
                }
                setContrat(file)
                setSelectedFile(file)
                if (clientModal.address && clientModal.identity && clientModal.social_reason && clientModal.type_of_selling) {
                    setButtonDisabled(false)
                } else {
                    setButtonDisabled(true)
                }
            } else {
                alert('Por favor, selecciona un archivo PDF.');
            }
        }
        console.log(file)

    };

    const rutValidation = (rut) => {
        try {
            // Eliminar puntos y guiones del RUT
            rut = rut.replace(/[.-]/g, '');

            // Separar el número y el dígito verificador
            const rutNumero = rut.slice(0, -1);
            const rutDV = rut.slice(-1).toUpperCase();

            // Verificar el formato del número y el dígito verificador
            if (!/^\d+$/.test(rutNumero) || !/^[0-9Kk]{1}$/.test(rutDV)) {
                return false;
            }

            // Calcular el dígito verificador esperado
            let suma = 0;
            let multiplicador = 2;

            for (let i = rutNumero.length - 1; i >= 0; i--) {
                suma += parseInt(rutNumero.charAt(i)) * multiplicador;

                multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
            }

            const digitoVerificadorEsperado = String(11 - suma % 11);

            // Manejar casos especiales para K (10) y 0 (11)
            const dv = digitoVerificadorEsperado === '10' ? 'K' : digitoVerificadorEsperado === '11' ? '0' : digitoVerificadorEsperado;

            // Verificar el dígito verificador
            return rutDV === dv;
        } catch (error) {
            console.log(error)
        }

    }

    const getKams = async () => {
        try {
            const data = await getUsersByProfile(2);
            if (!data)
                Swal.fire({
                    icon: 'error',
                    title: 'Error inesperado',
                    text: 'Por favor vuelve a intentar',
                    closeButtonHtml: `<img src="${Close}" />`
                })
            else {
                setKamsOptions(data)
                setKam(clientData.kam_selected)
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error inesperado',
                text: 'Por favor vuelve a intentar',
                closeButtonHtml: `<img src="${Close}" />`
            })
        }
    }

    const getBusinessModelsForValidation = async () => {
        try {
            const businessModelsData = await getBusinessModels()
            if(businessModelsData === null)
                setExistsErrorFetchingBusinessModels(true)
            else{
                setBusinessModels(businessModelsData.data?.message?.businessModels || [])
                const servicesCombinationExists = checkServicesCombination(clientData, businessModelsData.data?.message?.businessModels || [])

                if(!servicesCombinationExists){
                    setError({ ...error, servicesCombination: true})
                }
                else{
                    setError({ ...error, servicesCombination: false})
                }
            }
        } catch (error) {
            console.log(error)
            setExistsErrorFetchingBusinessModels(true)
        }
    }

    const handleChangeKam = (event) => {
        handleChange(event)
        setKam(event.target.value);
        if (clientModal.name && clientModal.namerlegal && clientModal.email && countryCode) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
        if (error.kamError)
            setError({ ...error, kamError: false })
    };


    const emailValidation = (email) => {
        // expresion regular para validar email
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    };

    const formValidation = () => {
        let errorForm = false
        let errors = {};
        if (allStores.find(e => e.name === clientModal.name) && modalTittle !== "Editar Cliente" && actualStep === 1) {
            errors = { ...errors, nameError: true }
            errorForm = true
        }

        if (!emailValidation(clientModal.email) && actualStep === 1) {
            errors = { ...errors, emailError: true }
            errorForm = true
        }

        if (!rutValidation(clientModal.identity) && actualStep === 2) {
            errors = { ...errors, identityError: true }
            errorForm = true
        }

        if (!selectedFile && modalTittle !== "Editar Cliente" && actualStep === 3) {
            errors = { ...errors, contratError: true }
            errorForm = true
        }
        setError({ ...error, ...errors, servicesCombination: actualStep !== 4 ? false : (error.servicesCombination || false) })
        return errorForm
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("primer paso")
        if (actualStep !== 4) {
            let errorForm = formValidation();

            if (!errorForm) {
                setButtonDisabled(true)
                setCliente({ ...clientData, ...clientModal })
                setActualStep(actualStep + 1)
            }

        } else {

            handleSubmitFinal(e)
        }


    };


    const handleClose = () => {
        resetValues()
        setKam("");
        toggleMobilModal();
    };

    return (


        <Modal
            isOpen={openMobileModal}
            toggle={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',

                    transform: 'translate(-50%)',
                    width: "120%",

                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 5,
                    zIndex: 1,
                    borderRadius: 4,

                    '@media (max-width: 600px)': {
                        width: '110%',

                    },



                }}
            >

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {actualStep !== 1 ?
                        <Tooltip title="Volver atrás" arrow>

                            <IconButton onClick={() => { setError({ ...error, servicesCombination: false}); setActualStep(actualStep - 1) }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ml: isMobile ? "-20px" : "",
                                    pointerEvents: loadingClient ? 'none' : '',
                                    opacity: loadingClient ? 0.5 : ''

                                }}>
                                <img src={atras} alt="atras" width={isMobile && "70%"} />

                            </IconButton></Tooltip> : <div></div>}

                    <Typography fontFamily='Montserrat' fontSize={isMobile ? "24px" : "33px"} sx={{ ml: isMobile ? "7%" : "8%" }} ><b>{modalTittle}</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <IconButton onClick={handleClose} sx={{
                            mr: isMobile ? "-20px" : "",
                            '&:hover': {
                                backgroundColor: 'transparent'

                            }
                        }}>
                            <img src={Close} width={isMobile && "70%"} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <div className={classes.lineWrapper} >
                    <span style={{ width: "30%", height: "5px", background: " #57C2BD" }}></span>
                </div>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { mt: 2, mb: 2 }
                    }}

                    noValidate
                    autoComplete="off"
                >
                    {actualStep === 1 ? <Step1 error={error} clientData={clientData} handleChange={handleChange} setCountryCode={setCountryCode} /> : <></>}
                    {actualStep === 2 ? <Step2 error={error} clientData={clientData} handleChange={handleChange} kam={kam} handleChangeKam={handleChangeKam} kamsOptions={kamsOptions} /> : <></>}
                    {actualStep === 3 ? <Step3 error={error} clientData={clientData} handleChange={handleChange}
                        inputRef={inputRef} handleFileChange={handleFileChange} selectedFile={selectedFile} handleCheck={handleCheck} /> : <></>}

                    {actualStep === 4 ?
                        <>
                            <Typography fontFamily='Montserrat' style={{ marginBottom: "-10px", fontSize: "17px" }} align='left'  >Canales de venta por aperturar</Typography>
                            <ChannelCircles
                                setChannels={setChannelsOnboardingSelected}
                                channelsSelected={channelsOnboardingSelected}
                                listOfChannels={listOfChannels}
                            />
                            <Button disabled={!( !loadingClient && !error.servicesCombination && ( (!existsErrorFetchingBusinessModels && businessModels.length > 0) || existsErrorFetchingBusinessModels) )} type="submit" variant="contained" sx={{ fontSize: '22px', width: '200px', height: '49px', borderRadius: "20px !important", mt: '30px' }} >
                                {loadingClient && (
                                    <CircularProgress
                                        thickness={6}
                                        size={33}
                                        sx={{
                                            color: "#68cd96",
                                            position: 'absolute',
                                            top: '43%',
                                            right: '75%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                                <b> {modalTittle === "Editar Cliente" ? "Editar Cliente" : "Crear Cliente"}</b>
                            </Button>
                        </> :

                        <Button disabled={buttonDisabled || error.servicesCombination || ( (existsErrorFetchingBusinessModels && businessModels.length === 0) && !existsErrorFetchingBusinessModels ) } type="submit" variant="contained" sx={{ fontSize: '22px', width: '200px', height: '49px', borderRadius: "20px !important" }} ><b>Siguiente</b></Button>
                    }

                    <Box sx={{ '& > :not(style)': { ml: 1, mr:1 } }}>
                        
                            {actualStep === 1 ?  <img src={stepSelectedImage} />: <img src={stepImage} />}
                            {actualStep === 2 ?  <img src={stepSelectedImage} />: <img src={stepImage} />}
                            {actualStep === 3 ?  <img src={stepSelectedImage} />: <img src={stepImage} />}
                            {actualStep === 4 ?  <img src={stepSelectedImage} />: <img src={stepImage} />}
                        
                    </Box>



                </Box>
            </Box>
        </Modal >
    )
}

const Step1 = ({ error, clientData, handleChange, setCountryCode }) => {
    return (
        <>
            <FormControl variant="standard" fullWidth error={error.nameError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Nombre del cliente
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="name" name="name" defaultValue={clientData.name}
                    required
                    placeholder="Ingresa el nombre de cliente"
                    onChange={handleChange} />
                {error.nameError ? <FormHelperText>El nombre del cliente ya existe</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.namelegalError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Nombre de la contraparte
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="namerlegal" name="namerlegal" defaultValue={clientData.namerlegal}
                    placeholder="Ingresa el nombre de la contraparte" onChange={handleChange} />
                {error.namelegalError ? <FormHelperText>El nombre del cliente no es valido</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.emailError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Correo de la contraparte
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }} id="email" defaultValue={clientData.email}
                    name="email" placeholder="Ingresa el correo de la contraparte" onChange={handleChange} />
                {error.emailError ? <FormHelperText style={{ color: "black" }}>El email no es valido</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.countryError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    País de cliente
                </InputLabel>
                <div style={{ marginBottom: "24px", zIndex: 1 }}></div>
                <CountryCircles setCountry={setCountryCode} country={clientData.client_country} />
                {error.countryError ? <FormHelperText style={{ color: "black" }}>Debe seleccionar un país</FormHelperText> : <></>}
            </FormControl>
        </>
    )
}

const Step2 = ({ error, clientData, handleChange, kam, handleChangeKam, kamsOptions }) => {
    return (
        <>
            <FormControl variant="standard" fullWidth error={error.kamError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Kam a cargo de cliente
                </InputLabel>
                <div style={{ marginBottom: "30px", zIndex: 1 }}></div>
                <Select
                    sx={{ pt: 1, pb: 1, pl: 1 }}
                    name="kam_selected"
                    labelId="kam-label"
                    variant="standard"
                    id="kam-select"
                    value={kam}
                    label="Kam"
                    onChange={handleChangeKam}
                    displayEmpty
                    style={{ color: kam === '' ? 'gainsboro' : 'inherit' }}

                >
                    <MenuItem sx={{ color: "#E8E8E8" }} value="" disabled>
                        {kamsOptions.length === 0 ? "Cargando lista de Kams....." : "Selecciona un Kam para este cliente"}
                    </MenuItem>
                    {kamsOptions.map((kam) => (
                        <MenuItem key={kam.email} value={kam.email}>{kam.first_name} {kam.last_name}</MenuItem>
                    ))}

                </Select>
                {error.kamError ? <FormHelperText>Debe seleccionar un/a kam</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.addressError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Dirección de cliente
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="address" name="address" defaultValue={clientData.address}
                    placeholder="Ingresa la dirección del cliente"
                    onChange={handleChange} />
                {error.addressError ? <FormHelperText>La dirección del cliente no es valida</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.identityError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    RUT, RUN o RFC de cliente (sin puntos ni guión)
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="identity" name="identity" defaultValue={clientData.identity}
                    placeholder="Ingresa RUT, RUN o RFC del cliente (sin puntos ni guión)" onChange={handleChange} />
                {error.identityError ? <FormHelperText>El rut no es valido</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.social_reasonError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Razón social del cliente
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="social_reason" name="social_reason" defaultValue={clientData.social_reason}
                    placeholder="Ingresa la razón social del cliente" onChange={handleChange} />
                {error.social_reasonError ? <FormHelperText>La razón social del cliente no es válida</FormHelperText> : <></>}
            </FormControl>


        </>
    )
}

const Step3 = ({ error, clientData, handleChange, inputRef, handleFileChange, selectedFile, handleCheck }) => {
    return (
        <>
            <FormControl variant="standard" fullWidth error={error.type_of_sellingError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Giro del cliente
                </InputLabel>
                <InputBase sx={{ mt: 3.5, pl: "10px" }}
                    id="type_of_selling" name="type_of_selling" defaultValue={clientData.type_of_selling}
                    placeholder="Ingresa el giro del cliente" onChange={handleChange} />
                {error.type_of_sellingError ? <FormHelperText>El giro del cliente no es válido</FormHelperText> : <></>}
            </FormControl>

            <FormControl variant="standard" fullWidth error={error.contratError}>
                <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                    Contrato del cliente
                </InputLabel>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputBase sx={{ mt: 3.5, pl: "10px" }}
                        id="contrat" name="contrat"
                        value={selectedFile ? selectedFile.name :
                            clientData.contratFileName ? clientData.contratFileName : ""
                        }
                        placeholder="Carga el contrato del cliente"
                        readOnly
                        endAdornment={
                            // <Button sx={{ mr: "20px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            //     <input accept=".pdf" type="file" onChange={handleFileChange} style={{ display: 'none' }} /> Cargar
                            // </Button>
                            <IconButton
                                onClick={() => inputRef.current.click()}
                                sx={{
                                    margin: '-2px 0 0', '&:hover': {
                                        backgroundColor: 'transparent',
                                    }, mr: 1
                                }}>
                                <img style={{ height: 25, fontWeight: 25 }} src={Upload} alt="upload-contrat" />
                                <input ref={inputRef} accept=".pdf" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                            </IconButton>
                        }
                    />

                </div>
                {error.contratError ? <FormHelperText>Debe subir un archivo pdf</FormHelperText> : <></>}
            </FormControl>

            <Typography style={{ marginBottom: "-10px", fontSize: "17px", fontFamily: 'Montserrat', color: error.servicesCombination? "#d32f2f" : "black" }} align='left'  >Selecciona los servicios</Typography>
            <FormControl variant="standard" fullWidth error={error.servicesCombination}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="facturacion" onChange={handleCheck} checked={clientData.facturacion} />} label="Facturación por Instance" />
                    <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="bodega" onChange={handleCheck} checked={clientData.bodega} />} label="Almacenamiento de productos en Bodega Instance" />
                    <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="canales" onChange={handleCheck} checked={clientData.canales} />} label="Creación de cuenta en canales de venta por Instance" />
                    <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="servicio" onChange={handleCheck} checked={clientData.servicio} />} label="Servicio al cliente por Instance" />
                </FormGroup>
                {error.servicesCombination ? 
                    <FormHelperText style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>Vaya la combinación de servicios no existe</span>
                        <strong style={{ display: "block" }}>¡Inténtalo de nuevo!</strong>
                    </FormHelperText>
                : 
                    <></>
                }
            </FormControl>
        </>
    )
}

export default CreateStoreMobile

