import { useState } from 'react'
import classes from "./TagsInput.module.css"
import Input from "./Input"

function TagsInput(props){
    const [tags, setTags] = useState([])

    function handleKeyDown(e){
        if(e.key !== props.onKeyDown) return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        props.handleKeyDown(value)
        e.target.value = ''
    }

    function removeTag(index){
        props.handleDeleteKeywords(props.tags.filter((el, i) => i !== index))
    }

    return (
        <div>
            <label className={classes.labelInput}>{props.label}</label>
            <div className={classes.tagsInputContainer} style={props.tags.length > 0 ? {padding : "4px"} : undefined}>
                { props.tags.map((tag, index) => (
                    <div className={classes.tagItem} key={index}>
                        <span className="text">{tag}</span>
                        <span className="close" onClick={() => removeTag(index)}>&times;</span>
                    </div>
                )) }
                <Input onKeyDown={handleKeyDown} width={props.width} handle={props.handle} type={props.type} className={classes.tagInput} placeholder={props.placeholder} />
            </div>
        </div>
    )
}

export default TagsInput