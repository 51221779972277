import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { ChartsLegend } from '@mui/x-charts/ChartsLegend';

const BarsAndLinesChart = ({
    data,
    xAxisKey,
    yAxisBarKey,
    yAxisLineKey,
    yAxisBarLabel,
    yAxisLineLabel,
    barColor,
    lineColor,
    tooltipContent,
    isHidden = false
}) => {
    
    // Calculate dynamic margin left based on the largest value in yAxisLineKey with more than one digits
    const dynamicMarginLeft = useMemo(() => {
        const largestValue = Math.max(...data.map(item => item[yAxisLineKey] || 0));
        const digitCount = largestValue > 9 ? largestValue.toString().length : 1;
        return 20 + 13 * (digitCount - 1);
    }, [data, yAxisLineKey]);

    // Calculate dynamic right margin based on the largest value in yAxisBarKey
    const dynamicMarginRight = useMemo(() => {
        const largestBarValue = Math.max(...data.map(item => item[yAxisBarKey] || 0));
        const barDigitCount = largestBarValue > 9 ? largestBarValue.toString().length : 1;
        return 30 + 5 * (barDigitCount - 1);
    }, [data, yAxisBarKey]);

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <ResponsiveChartContainer
                series={[
                    {
                        type: 'line',
                        id: 'lineDataSeriesForModal',
                        color: lineColor || '#000000',
                        dataKey: yAxisLineKey,
                        yAxisId: 'leftAxis',
                        label: yAxisLineLabel,
                        showMark: false,
                    },
                    {
                        type: 'bar',
                        id: 'barDataSeriesForModal',
                        color: barColor || '#CDEDEC',
                        dataKey: yAxisBarKey,
                        yAxisId: 'rightAxis',
                        label: yAxisBarLabel,
                    }                    
                ]}
                xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: xAxisKey,
                        disableTicks: true,
                        categoryGapRatio: 0.5
                    }
                ]}
                yAxis={[
                    { id: 'leftAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL') },
                    { id: 'rightAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL') }
                ]}
                dataset={data}
                margin={{ left: dynamicMarginLeft, bottom: 60, right: dynamicMarginRight }}
            >
                <ChartsGrid horizontal />
                <BarPlot borderRadius={5} />
                <LinePlot />
                <ChartsXAxis />
                <ChartsYAxis axisId="leftAxis" position="left" />
                <ChartsYAxis axisId="rightAxis" position="right" />
                <ChartsAxisHighlight x="band" />
                <ChartsTooltip
                    trigger="axis"
                    slots={{
                        axisContent: tooltipContent || (({ axisData, series }) => (
                            <div 
                                style={{
                                    padding: '18px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ccc',
                                    display: 'flex',
                                    position: 'relative',
                                    flexDirection: 'column',
                                    borderRadius: '10px'
                                }}
                            >
                                <span>{axisData.x.value}</span>
                                <span>{series[0].label}: {series[0].data[axisData.x.index]}</span>
                                <br />
                                <span>{series[1].label}: {series[1].data[axisData.x.index]}</span>
                            </div>
                        ))
                    }}
                />
                <ChartsLegend
                    direction="row"
                    position={{ vertical: 'bottom', horizontal: 'middle' }}
                    padding={0}
                    slotProps={{
                        legend: {
                            labelStyle: { fontSize: 14, fontFamily: 'Montserrat', fill: '#D3D3D3' },
                            itemMarkWidth: 10,
                            itemMarkHeight: 10,
                            hidden: isHidden
                        }
                    }}                    
                />
            </ResponsiveChartContainer>
        </Box>
    );
};

export default BarsAndLinesChart;
