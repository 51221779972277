
//////////////////////////////////////////////////////////////////////////////////////
//---------------------------- TOOLTIP CONTENT STORAGE -----------------------------//
//////////////////////////////////////////////////////////////////////////////////////
// This file centralizes the definition of tooltip content used across various      //
// components in the application. Each tooltip provides contextual information to   //
// users about specific elements, such as publication updates, stock availability,  //
// BuyBox status, healthcheck, alerts, and more. The tooltips are formatted using   //
// inline styles to ensure consistent presentation and use Montserrat font for      //
// visual coherence. Dynamic tooltips are also supported, with content adjusted     //
// based on input data (e.g., alerts for a product). This structure helps in keeping//
// the tooltip logic separate, improving maintainability and consistency.           //
//////////////////////////////////////////////////////////////////////////////////////


export const tooltipPublicationsLastUpdate = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Aquí te traemos la información de cuando fue la última actualización de toda la información...              
            </span>
        </p>
    </div>
</div>`;

export const tooltipHealthcheckContent = `
<div style="position: relative; display: flex; width: 315px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    ¡Esta es la calidad de tu publicación!
                </span>
                <br>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Es fundamental para tener un mejor posicionamiento... Te desafío a llegar al 100%, no creo que sea tan difícil no?
                </span>                
            </span>
        </p>
    </div>
</div>`;

export const tooltipBuyboxContent = `
<div style="position: relative; display: flex; width: 315px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    BuyBox solo tiene 3 estados:
                </span>
                <br>
                <br>
                <span style="font-weight: 700;">
                    Ganando:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Tu publicación aparece sobre la de tu competencia.
                </span>  
                <br>
                <span style="font-weight: 700;">
                    Empatando:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Tu publicación puede o no aparecer antes que la de tu competencia.
                </span>  
                <br>   
                <span style="font-weight: 700;">
                    Perdiendo:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    La publicación de tu competencia está antes que la tuya...
                </span>  
                <br>  
                <br>  
                <span style="font-weight: 700;">
                    ¡No te dejes ganar!
                </span>         
            </span>
        </p>
    </div>
</div>`;

export const suggestionsTooltip = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 250px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    ¡Tenemos sugerencias para esta publicación!
                </span>
                <br>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Instance Analytics se basa en la competencia para generar sugerencias de mejora en tus publicaciones
                </span>                
            </span>
        </p>
    </div>
</div>`;

export const tooltipPublicationStateContent = `
<div style="position: relative; display: flex; width: 315px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    Existen solo 3 estados:
                </span>
                <br>
                <br>
                <span style="font-weight: 700;">
                    Publicada:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Cuando tu publicación está apta para la venta.
                </span>  
                <br>
                <span style="font-weight: 700;">
                    Pendiente:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    El canal de venta está revisando tu publicación.
                </span>  
                <br>   
                <span style="font-weight: 700;">
                    Rechazada:
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    El canal de venta rechazó tu publicación...
                </span>  
                <br>  
                <br>  
                <span style="font-weight: 700;">
                    ¡Revisa las razones y publica!
                </span>         
            </span>
        </p>
    </div>
</div>`;

export const tooltipPublicationUpdate = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Aquí te traemos la información de la última actualización de tu publicación en el canal de venta...             
            </span>
        </p>
    </div>
</div>`;

export const tooltipPublicationRedirect = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Este es el canal de venta en el cual está publicada tu publicación :)            
            </span>
        </p>
    </div>
</div>`;

export const tooltipPublicationStock = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Este es el stock disponible que tiene tu publicación           
            </span>
        </p>
    </div>
</div>`;

export const tooltipContentBell = (alertsForProduct) => {
    return `
        <div style="width: 273px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #57c2bd; font-family: Montserrat;">
            <p style="margin: 0; font-weight: 500; color: #000;">Esta publicación tiene estas alertas:</p>
            <p style="margin: 0; font-weight: 500; color: #000;">&nbsp;</p>
            ${alertsForProduct
                ?.map(
                    (alert) => `<p style="margin: 0; font-weight: 500;">${alert.alert_name}</p>`
                )
                .join('')}
        </div>
    `;
};

export const tooltipEachAlertContent = (alert, alertTypeNames, alertDays, alertRecipients, publicationsCount) => {
    return `
        <div style="position: relative; display: flex; width: 330px;">
            <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
                <p style="margin: 0;">
                    <span>
                        <span style="font-weight: 500; font-family: Montserrat;">
                            ${alert.alert_name}
                        </span>
                        <br><br>
                        <span style="font-weight: 500; font-family: Montserrat;">
                            - ${alertTypeNames}
                        </span>
                        <br>
                        <span style="font-weight: 500; font-family: Montserrat;">
                            - ${alertDays}
                        </span>
                        <br>
                        <span style="font-weight: 500; font-family: Montserrat;">
                            - ${alertRecipients}
                        </span>
                        <br>
                        <span style="font-weight: 500; font-family: Montserrat;">
                            - ${publicationsCount} publicaciones
                        </span>
                    </span>
                </p>
            </div>
        </div>
    `;
};

export const tooltipAlertCenterContent = `
<div style="position: relative; display: flex; width: 330px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
    <p style="margin: 0;">
        <span>
            <span style="font-weight: 500; font-family: Montserrat;">
                Aquí podrás gestionar todas tus alertas ya creadas.
            </span>
            <br>
            <br>
            <span style="font-weight: 500; font-family: Montserrat;">
                Recuerda que...
            </span>
            <br>
            <span style="font-weight: 500;">
                ¡Para crear una alerta primero debes seleccionar al menos 1 publicación!
            </span>
        </span>
    </p>
    </div>
</div>`;

export const tooltipPublicationLogistica = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Este es el tipo de envío que tiene tu publicación           
            </span>
            <span style="font-weight: 500;">
                (a la derecha verás las unidades disponibles)            
            </span>
        </p>
    </div>
</div>`;

export const tooltipPublicationStockModal = `
<div style="position: relative; display: flex; width: 300px;">
    <div style="width: 280px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 10px;">
        <p style="margin: 0;">
            <span style="font-weight: 700;">
                Este es el stock disponible que tiene tu publicación         
            </span>
            <span style="font-weight: 500;">
                (a la izquierda verás el tipo de envío)            
            </span>
        </p>
    </div>
</div>`;

export const tooltipBuyboxAdvice = `
<div style="position: relative; display: flex; width: 315px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    Te traemos el análisis de la competencia,
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    en tus publicaciones BuyBox que estén perdiendo o empatando.
                </span> 
                <br>
                <span style="font-weight: 700;">
                    ¡Supera el precio de la competencia para ganar!
                </span>               
            </span>
        </p>
    </div>
</div>`;

export const tooltipHealthcheckAdvice = `
<div style="position: relative; display: flex; width: 315px;">
    <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 700;">
                    Como buscamos que llegues a 100% en Healthcheck...
                </span>
                <span style="font-weight: 500; font-family: Montserrat;">
                    Te dejamos los elementos por cumplir.
                </span> 
                <br>
                <span style="font-weight: 700;">
                    ¡Soluciónalos y acércate al 100%!
                </span>               
            </span>
        </p>
    </div>
</div>`;

export const subtitlesPieChartTooltipContent = (subtitles) => {
    return `
        <div style="font-size: 15px; text-align: left; color: #000; font-family: Montserrat;">
            ${subtitles
                ?.map((subtitle, index) => `
                    <p style="margin: 0; font-weight: 500;">${subtitle.text}</p>
                    ${index < subtitles.length - 1 ? '<br />' : ''}
                `)
                .join('')}
        </div>
    `;
};

export const remedyPieChartTooltipContent = (problem, remedy) => {
    return `
    <div style="position: relative; display: flex; width: 330px;">
            <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
                <p style="margin: 0;">
                    <span>
                        <span>El problema es... "<span>${problem}</span>"</span>
                        <br>
                        <br>
                        <span>La solución es... <span style="color: #57C2BD;">"${remedy}"</span></span>
                    </span>
                </p>
            </div>
        </div>
    `;
};

export const notificationTooltipContent = (nameTitle, nameValue, date, status, additionalDetailTitle, additionalDetailContent) => {
    return `
    <div style="position: relative; display: flex; width: 330px;">
            <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
                <p style="margin: 0;">
                    <span>
                        <span>${nameTitle}: <span style="font-weight: 600;">${nameValue}</span></span>
                        <br>                        
                        <span>Fecha: <span style="font-weight: 600;">${date}</span></span>
                        <br>                        
                        <span>Estado: <span style="font-weight: 600;">${status}</span></span>
                        <br>                        
                        <span>${additionalDetailTitle}: <span style="font-weight: 600;">${additionalDetailContent}</span></span>
                    </span>
                </p>
            </div>
        </div>
    `;
};
