import React, { useState } from 'react'
import classes from './NewButton.module.css'

import plusMath from '../../assets/img/icons/plus-math.svg'
import plusMathWhite from '../../assets/img/icons/plus-math-white.svg'

const NewButton = (props) => {

    const [hover, setHover] = useState(false)

    return (
        <div 
            className={classes.buttonGreen} 
            onMouseOver={() => {setHover(true)}} 
            onMouseOut={() => {setHover(false)}}
        >
            <div className={classes.plusWrapper}>
                <img src={ hover? plusMathWhite : plusMath} className={classes.icon} />
            </div>
            <p className={classes.textButton}>
                {props.children}
            </p>
        </div>
    )
}

export default NewButton