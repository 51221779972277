// Libraries
import React from 'react';

// MUI Libraries
import { Box, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

// Components
import { subtitlesPieChartTooltipContent, remedyPieChartTooltipContent } from '../../utils/tooltipContent';

// Images
import Question from "../../assets/img/icons/question.png";
import Ballons from '../../assets/img/ballons-image.png';

const CustomPieChart = ({ reputation, problems, subtitles, CustomTooltip }) => {
    const determineColorAndMessage = (value) => {
        if (value >= 90) return { color: '#22BB3D', message: 'Fenomenal' };
        if (value >= 80) return { color: '#22BB3D', message: '¡Muy bien!' };
        if (value >= 60) return { color: '#FFD88C', message: 'Casi super...' };
        if (value >= 30) return { color: '#FF6059', message: 'Mejorable...' };
        return { color: '#FF6059', message: '¡Caótico!' };
    };

    const reputationValue = reputation?.value || 0;
    const { color, message } = determineColorAndMessage(reputationValue);

    const chartData = [
        { id: 0, value: reputationValue, color: color },
        { id: 1, value: 100 - reputationValue, color: '#d3d3d3' }
    ];

    // Helper function to get problem text
    const getProblemText = (problem) => {
        return problem.level_three?.title?.text || problem.level_two?.title?.text || 'Sin información';
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top', width: '100%', height: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: '100%', paddingRight: '10px' }}>
                {/* Pie chart container with overlay text */}
                <Box sx={{ display: 'flex', width: '100%', height: '70%', position: 'relative' }}>
                    {/* Semi-circle pie chart */}
                    <PieChart
                        series={[
                            {
                                data: chartData,
                                innerRadius: 170,
                                outerRadius: 190,
                                startAngle: -90,
                                endAngle: 90,
                                paddingAngle: 2,
                                cornerRadius: 10,
                                cx: '65%',
                                cy: '70%'
                            },
                        ]}
                        width={400}
                        height={200}
                        tooltip={{ trigger: 'none' }}
                    />

                    {/* Overlay text inside the semi-circle */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '65%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* Reputation score */}
                        <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ color: '#000000', fontWeight: 700 }}>
                            {reputationValue}%
                        </Typography>

                        {/* Message based on reputation score */}
                        <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ color: '#000000', fontWeight: 500, mt: 0.5 }}>
                            {message}
                        </Typography>                        
                    </Box>                    
                </Box>        
                <Box sx={{ display: 'flex', width: '100%', height: '20%', position: 'relative' }}>
                    {/* Subtitles under the pie chart with custom tooltip for full text */}
                    <CustomTooltip tooltipContent={subtitlesPieChartTooltipContent(subtitles)}>
                        <Box 
                            sx={{ 
                                fontFamily: 'Montserrat',                             
                                ml: '10px', 
                                textAlign: 'left', 
                                width: '90%', 
                                overflow: 'hidden', 
                                display: '-webkit-box', 
                                WebkitLineClamp: 3, 
                                WebkitBoxOrient: 'vertical', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {subtitles?.[0]?.text}
                        </Box>
                    </CustomTooltip> 
                </Box>      
            </Box>

            {/* Vertical separator with customizable width, color, and border radius */}
            <Box sx={{ width: '5px', height: '20%', bgcolor: '#cccccc', borderRadius: '10px', alignSelf:'center', mx: 2 }} />

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '46%', height: '100%', overflowY: 'auto' }}>
                {/* Display problem details if available */}
                {problems?.length > 0 ? (
                    <Box sx={{ mt: 2, p: 2, width: '100%' }}>
                        {problems.map((problem, idx) => (
                            <Box key={idx} sx={{ display: 'flex', flexDirection: 'row', mb: 1, alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', height: '100%' }}>
                                    <Typography fontFamily='Montserrat' fontSize={"12px"} sx={{ fontWeight: 700 }}>
                                        {`${problem.quantity} por: `}
                                    </Typography>

                                    <Typography fontFamily='Montserrat' fontSize={"12px"} sx={{ fontWeight: 500 }}>
                                        {getProblemText(problem)}
                                    </Typography>
                                </Box>

                                {/* Tooltip icon for remedy text */}
                                <CustomTooltip tooltipContent={remedyPieChartTooltipContent(getProblemText(problem), problem.level_three?.remedy?.text)}>
                                    <img
                                        src={Question}
                                        alt="tooltip"
                                        style={{ minWidth: "15px", minHeight: "15px", objectFit: 'contain' }}
                                    />
                                </CustomTooltip>                              
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        {reputation?.text === 'Buena' ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%', alignItems: 'center' }}>
                                <img
                                    src={Ballons}
                                    alt="globos"
                                    style={{ width: "100px", height: "100px", objectFit: 'contain', marginBottom: '8px' }}
                                />
                                <Typography fontFamily='Montserrat' fontSize="12px" fontWeight={500} textAlign={'center'}>
                                    ¡Magnífico! No tienes problemas
                                </Typography>
                            </Box>
                        ) : (
                            <Typography fontFamily='Montserrat' fontSize="12px" fontWeight={500} sx={{ color: '#fff' }}>
                                No hay problemas reportados.
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>            
        </Box>
    );
};

export default CustomPieChart;
