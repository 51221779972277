import React from 'react'
import classes from './ComboMainImage.module.css'

import emptyImages from "../../assets/img/empty-images.png"

const ComboMainImage = ({ activeImage }) => {

    return (
       
        <div className={classes.comboMainImage}>
            {activeImage ? 
                <img className={classes.imageNotEmpty} src={activeImage.url} />
            :
                <div className={classes.wrapperEmptyImage}>
                    <img className={classes.imageEmpty} src={emptyImages}/>
                    <h2 className={classes.messageEmptyImage}>Producto sin imágenes </h2>
                </div>
            }
        </div>
    )
}

export default ComboMainImage