import Modal from "../UI/Modal";
import Chips from "./chips";
import React, { useEffect, useState } from "react"
import { getStoreByName } from "services/stores";

const SendMail = (props) => {

  const [store, setStore] = useState(null);

  useEffect(() => {
    getStore(props.purchaser.store != "" ? props.purchaser.store : props.purchaser.officialStore);
  }, [])

  const getStore = async (tienda) => {
    let store = await getStoreByName(tienda);
    setStore(store);
  }

  return (
    <Modal onhideModal={props.onhideModal}>
      <Chips
        onhideModal={props.onhideModal}
        purchaser={props.purchaser}
        logo={store?.company_data?.company_logo ?? ""}
      ></Chips>
    </Modal>
  );
};
export default SendMail;
