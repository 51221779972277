import React from 'react'
import styled from 'styled-components'

import iconGreaterThan from "../../assets/img/icons/iconGreaterThan.svg"

const CardItem = ({enabled = true, icon, name, onClick}) => {
  return (
    <Wrapper onClick={onClick}>
        <Content>
            <IconItemWrapper>
                <ItemIconImg src={icon} enabled={enabled}/>
            </IconItemWrapper>
            <ItemName enabled={enabled}>{name}</ItemName>
            { !enabled &&
                <DisabledTag>
                    <DisabledTagText>Habilitar nuevamente</DisabledTagText>
                </DisabledTag>
            }
        </Content>
        <IconWrapper>
            <IconImg src={iconGreaterThan} />
        </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 48px;
    cursor: pointer;
    background: #F2F0F059;
    border-radius: 17px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`

const IconItemWrapper = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ItemIconImg = styled.img`
    height: 100%;
    display: block;
    object-fit: cover;
    filter: ${p => p.enabled ? undefined : "grayscale(1)"};
`

const ItemName = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${p => p.enabled ? "#232A38" : "#858F99"};
`

const IconWrapper = styled.div`
    height: 12px;
`

const IconImg = styled.img`
    height: 100%;
    display: block;
`

const DisabledTag = styled.div`
    background-color: #051B2F;
    border-radius: 5px;
    padding: 2px 8px;
    color: white;
`

const DisabledTagText = styled.span`
    font-size: 10px;
    font-weight: 700;
    display: block;
`

export default CardItem