
import React from 'react'
import classes from './Catalog.module.css'

import avatar from "../../assets/img/User.png";
import WelcomeCatalog from 'components/AdminCatalog/WelcomeCatalog';
import UploadCatalog from 'components/AdminCatalog/UploadCatalog';
import { Link, Route ,Switch, useRouteMatch } from 'react-router-dom';
import UploadImages from 'components/AdminCatalog/UploadImages';

const Catalog = () => {

    const { path ,url } = useRouteMatch()

    return (
        <div className={classes.wrapper} >
            <div className={classes.mainGrid} >
                <div className={classes.colLeft}>
                    <div className={classes.profileWrapper}>
                        <div className={classes.profileContent}>
                            <div className={classes.imgWrapper}>
                                <img src={avatar} className={classes.img} />
                            </div>
                            <span className={classes.profileName}>
                                {localStorage.getItem("first")}
                            </span>
                        </div>
                    </div>
                    <div className={classes.optionsWrapper}>
                        <span className={classes.optionsTitle}>Opciones</span>
                        <div className={classes.option}>
                            <div className={classes.iconWrapper}>
                                <i className="nc-icon nc-tile-56" />
                            </div>
                            <Link to={`${url}/upload`}>
                                <span className={classes.optionText}>Cargar catálogo</span>
                            </Link>
                        </div>
                        <div className={classes.option}>
                            <div className={classes.iconWrapper}>
                                <i className="nc-icon nc-image" />
                            </div>
                            <Link to={`${url}/images`}>
                            <span className={classes.optionText}>Cargar las fotos de tu producto</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.colRight}>
                    <div className={classes.welcomeWrapper}>
                        <Switch>
                            <Route path={`${path}/upload`} component={UploadCatalog}/>
                            <Route path={`${path}/images`} component={UploadImages}/>
                            <Route path={`${path}/`} component={WelcomeCatalog}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Catalog