import ProductsList from 'components/Products/ProductsList'
import React from 'react'
import classes from './Products.module.css'

const Products = () => {
  return (
    <div className={classes.wrapper}>
        <ProductsList />
    </div>
  )
}

export default Products