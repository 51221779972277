import React from 'react'

//images
import successImg from "../../../../../assets/img/error-integration.svg"

//libraries
import { Typography, Button } from '@mui/material'

const ErrorIntegrationChannel = ({ handleToggleModalIntegration, setDisplayModalIntegrations }) => {


  const handleClickClose = () => {
    setDisplayModalIntegrations(false)
    handleToggleModalIntegration(false)
      
  }
  return (
    <div style={{ textAlign: "center" }}>
        <img
            style={{ marginTop: 60 }}
            src={successImg}
            height={200}
            alt="error integration"

        />
        <Typography
            fontSize={40}
        >
            <b>Que mal...</b>
        </Typography>
        <span style={{ fontSize: 18, display: "block" }}>Algo no funcionó</span>
        <span style={{ fontSize: 18, display: "block" }}>intentalo nuevamente...</span>
        <Button
            variant="outlined"
            sx={{ fontSize: '22px', padding: '10px 25%', borderRadius: "14px", mt: '60px' }}
            onClick={handleClickClose}
        >
            <b>Cerrar</b>
        </Button>
    </div>
  )
}

export default ErrorIntegrationChannel 
