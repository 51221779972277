import React from 'react'
import step from "../../../../assets/img/icons/step.png"
import stepSelected from "../../../../assets/img/icons/stepSelected.png"
import { Box, IconButton } from "@mui/material";
const CircleSteps = ({setMovilStep, movilStep}) => {
    return (
        <Box sx={{ '& > :not(style)': { m: 0 }, zIndex: 1,  alignSelf: 'flex-end', justifyContent: 'center', display: 'flex' }}>
            <IconButton onClick={() => { setMovilStep(0) }} sx={{

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            }}>
                {movilStep !== 0 ? <img src={step} /> : <img src={stepSelected} />}
            </IconButton>
            <IconButton onClick={() => { setMovilStep(1) }} sx={{

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            }}>
                {movilStep !== 1 ? <img src={step} /> : <img src={stepSelected} />}
            </IconButton>
            <IconButton onClick={() => { setMovilStep(2) }} sx={{

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            }}>
                {movilStep !== 2 ? <img src={step} /> : <img src={stepSelected} />}
            </IconButton>
            <IconButton onClick={() => { setMovilStep(3) }} sx={{

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            }}>
                {movilStep !== 3 ? <img src={step} /> : <img src={stepSelected} />}
            </IconButton>
            <IconButton onClick={() => { setMovilStep(4) }} sx={{

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            }}>
                {movilStep !== 4 ? <img src={step} /> : <img src={stepSelected} />}
            </IconButton>
        </Box>
    )
}

export default CircleSteps