//libraries
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

//componets
import MeliModal from './ModalChannels/MeliModal';
import ChannelByCredentialsIntegrationModal from "./ModalChannels/ChannelByCredentialsIntegrationModal"
import AmazonModal from "./ModalChannels/AmazonModal"

//images
import pendiente from '../../../../assets/img/onboarding/Pendiente.png'
import Close from "../../../../assets/img/icons/close_black.png"

const ModalsIntegrationChannels = ({ 
    newChannel,
    storeData,
    allChannels,
    fetchStoreDetails,
    getUpdatedStoreData,
    setChannelsParameters,
    isEdit,
    setDisplayModalIntegrations,
    displayModalIntegrations,
    setIsEdit,
    handleSetUpdatingStoreDataForChannel
}) => {

    const [displayModalMeli, setDisplayModalMeli] = useState(false);
    const [displayModalChannelCredentialsIntegration, setDisplayModalChannelCredentialsIntegration] = useState(false)
    const toggleMeliModal = () => setDisplayModalMeli(!displayModalMeli);
    const [displayAmazonModal, setDisplayAmazonModal] = useState(false)

    useEffect(async () => {
        if(!newChannel?.config_mtd?.enabled){
            showAddChannelNotEnabled().then(() => {
                window.location.href = window.location.origin + '/admin/clientProfile?storeId=' + storeData.id;
            })
            return 
        }

        handleIntegrationModal()

    }, [displayModalIntegrations])

    const showAddChannelNotEnabled = () => {
        return Swal.fire({
            title: 'Canal no disponible',
            text: "La integración automatica para el canal seleccionado no está disponible",
            imageWidth: 150,
            imageHeight: 150,
            imageUrl: pendiente,
            closeButtonHtml: `<img src="${Close}" />`
        })
    }

    const  handleIntegrationModal = () => {
        const channelIdsEnabled = allChannels?.filter(e => e != null && e.config_mtd.enabled)?.map(e => e?.id) || []

        if (newChannel.id == 2) {
            toggleMeliModal()
        }
        else if(newChannel.id == 18){
            setDisplayAmazonModal(true)
        }
        else if(channelIdsEnabled?.includes(newChannel?.id)){
            handleToggleModalByCredentialsIntegration(true)
        }
        else{
            showAddChannelNotEnabled().then(() => {
                window.location.href = window.location.origin + '/admin/clientProfile?storeId=' + storeData.id;
            })
        }
    }

    const handleToggleModalByCredentialsIntegration = (showModal = false) => {
        setDisplayModalChannelCredentialsIntegration(showModal)
        if(!showModal){
            setIsEdit(false)
            setDisplayModalIntegrations(false)
        }
    }

    const handleToggleModalAmazon = (showModal = false) => {
        setDisplayAmazonModal(showModal)
        if(!showModal){
            setIsEdit(false)
            setDisplayModalIntegrations(false)
        }
    }

    return (
        <>
            {newChannel?.id == 2 ?
                <MeliModal displayModalMeli={displayModalMeli} toggleMeliModal={toggleMeliModal} channel={newChannel} storeData={storeData} />
            :
                null
            }
            {newChannel?.id != 2 && newChannel?.id != 18  && displayModalChannelCredentialsIntegration ?
                <ChannelByCredentialsIntegrationModal 
                    showModal={displayModalChannelCredentialsIntegration}
                    channel={newChannel}
                    storeData={storeData}
                    handleToggle={handleToggleModalByCredentialsIntegration}
                    setChannelsParameters={setChannelsParameters}
                    fetchStoreDetails={fetchStoreDetails}
                    isEdit={isEdit}
                    setDisplayModalIntegrations={setDisplayModalIntegrations}
                    setIsEdit={setIsEdit}
                    handleSetUpdatingStoreDataForChannel={handleSetUpdatingStoreDataForChannel}
                />
            :
                null
            }
            {newChannel?.id == 18 ?
                <AmazonModal 
                    displayModal={displayAmazonModal}
                    handleToggleModal={handleToggleModalAmazon}
                    channel={newChannel}
                    storeData={storeData}
                    isEdit={isEdit}
                />
            :
                null
            }
        </>
    )
}

export default ModalsIntegrationChannels