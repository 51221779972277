import React from "react";
import InstanceIcon from "../../assets/img/logoInstanceNegro.png";
import classes from "./welcomeClientEdit.module.css";
import { useHistory } from "react-router-dom";

const WelcomeClientEdit = () => {
  const history = useHistory();

  const redirectToOrderTableHandler = () => {
    history.push("/admin/orders");
  };

  return (
    <div className={classes.welcomeWrapper}>
      <img src={InstanceIcon} width="200px" height="35" />
      <h5 style={{marginTop:"30px"}} className={classes.editWelcomeText}>
        Bienvenido a tu centro de operaciones.
      </h5>
      <p className={classes.editParaText}>
        Estás en la sección “Perfil de usuario”
      </p>
      <p
        style={{
          color: "#232A38",
          fontSize: "15px",
          fontWeight: "500",
          lineHeight: "24px",
        }}
      >
        Acá podrás controlar, editar y manejar tu cuenta y tus tiendas. <br />
        Puedes personalizar tus datos personales y tributarios. <br />
        Además, puedes configurar  todas tus  integraciones y manejar el end to end de todas tus tiendas.
      </p>
      <p
        style={{
          color: "#232A38",
          fontSize: "15px",
          fontWeight: "500",
        }}
      >
        ¿Te quedan más dudas? Utiliza el chat de asistencia en línea que aparece en la  <br />esquina inferior derecha o escríbenos directamente a {" "}
        <span >
          <b>sacchile@instancelatam.com</b>
        </span>
      </p>
    </div>
  );
};

export default WelcomeClientEdit;
