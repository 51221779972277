import React, { useEffect, useState } from 'react'
import {
    Box, Typography, useMediaQuery
} from '@mui/material'

import Close from "../../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardTask from './CardTask';
import { Modal } from 'reactstrap'
import { sendOnboardingEmail } from 'services/stores';

const SetupStep = ({ displaySetupModal, toggleSetupModal, clientDetails, actualStep, editOnboardingSteps, parametersChannels }) => {

    const [checks, setChecks] = useState(
        {
            channelsAccounts: clientDetails.onboarding_steps[3].tasks?.channelsAccounts,
            channelIntegration: clientDetails.onboarding_steps[3]?.tasks.channelIntegration,
            catalogCreated: clientDetails.onboarding_steps[3].tasks?.catalogCreated,
            upCatalogPim: clientDetails.onboarding_steps[3].tasks?.upCatalogPim,
            warehauseClient: clientDetails.onboarding_steps[3].tasks?.warehauseClient,
            productsWms: clientDetails.onboarding_steps[3].tasks?.productsWms,
            productsSync: clientDetails.onboarding_steps[3].tasks?.productsSync,
            trainingTeam: clientDetails.onboarding_steps[3].tasks?.trainingTeam,
            panelsCreation: clientDetails.onboarding_steps[3].tasks?.panelsCreation,
            setUpCompleteMail: clientDetails.onboarding_steps[3].tasks?.setUpCompleteMail
        }
    )

    const textChecks = {
        "channelsAccounts": "Creación de cuenta en canales de venta",
        "channelIntegration": "Integraciones de canales de venta",
        "catalogCreated": "Creación de catálogo",
        "upCatalogPim": "Carga de catálogo en PIM",
        "warehauseClient": "Creación de Bodega Cliente",
        "productsWms": "Carga de productos WMS",
        "productsSync": "Sincronización de productos en canal de venta",
        "trainingTeam": "Integración SAC",
        "panelsCreation": "Creación de panel",
        "setUpCompleteMail": "Envío automático de Correo de Set-up Completo"
    }

    const autoCheck = ["channelIntegration", "warehauseClient", "panelsCreation", "setUpCompleteMail"]

    useEffect(() => {
        try {
            let wmsEnabled = clientDetails.onboarding_steps[3].tasks.warehauseClient
            let newChecks = { ...checks }
            if (parametersChannels?.length > 0) {
                newChecks.channelIntegration = true
                for (const parameter of parametersChannels) {
                    if (parameter?.channel_config?.wms) {
                        for (const wmsConfig of parameter?.channel_config?.wms) {
                            if (wmsConfig?.habilitado) {
                                wmsEnabled = true
                            }
                        }
                    }
                }
            }
            newChecks.warehauseClient = wmsEnabled
            if (clientDetails?.store_data?.datastudio_iframe) {
                newChecks.panelsCreation = true
            }
            setChecks(newChecks)
            editOnboardingSteps(clientDetails.onboarding_steps[3].id, newChecks)
        } catch (error) {
            console.log(error)
        }

    }, [])


    const isMobile = useMediaQuery('(max-width:600px)');
    const handleCheck = async (e) => {
        const newCheck = { ...checks, [e.target.name]: !checks[e.target.name] }
        if (newCheck.channelIntegration && newCheck.panelsCreation && newCheck.trainingTeam && newCheck.upCatalogPim && newCheck.warehauseClient) {
            let response = await sendOnboardingEmail(clientDetails.id, 8)
            if (response?.status === 200) {
                newCheck.setUpCompleteMail = true
            }
        }
        setChecks(newCheck);
        editOnboardingSteps(clientDetails.onboarding_steps[3].id, newCheck)
    }

    const handleChannelIntegration = () => {
        window.location.href = window.location.origin + '/admin/clientProfile?storeId=' + clientDetails.id;
    }

    return (

        <Modal
            isOpen={displaySetupModal}
            toggle={toggleSetupModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: isMobile ? '100%' : '120%',
                    maxWidth: '570px',
                    bgcolor: 'white',
                    boxShadow: 24,
                    pl: 6,
                    pr: 6,
                    pb: 10,
                    pt: 2,
                    zIndex: 1,
                    borderRadius: 4,


                }}
            >

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: "30px" }}>
                    <div></div>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Typography style={{ color: "#D3D3D3", fontSize: isMobile ? '17px' : '25px', fontFamily: 'Montserrat' }} ><b>Etapa</b></Typography>
                        <Typography fontFamily='Montserrat' variant={isMobile ? "h5" : "h4"} ><b>Set-up</b></Typography>
                    </Box>
                    <Box sx={{ mr: -4 }}>
                        <Tooltip title="Salir" arrow>
                            <IconButton onClick={toggleSetupModal} sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                }
                            }}>
                                <img src={Close} width={'80%'} alt="cerrar" />
                            </IconButton>
                        </Tooltip>
                    </Box>

                </Box>

                <Box
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > :not(style)': { mt: 2, mb: 2 },
                        justifyContent: 'center',
                    }}
                >
                    {Object.keys(checks).map(key => (
                            checks[key] !== undefined && (
                                <CardTask
                                    key={key}
                                    name={key}
                                    text={textChecks[key]}
                                    canCheck={autoCheck.includes(key) ? false :  clientDetails.onboarding_steps[3].id === actualStep}
                                    checkValue={checks[key]}
                                    handleCheck={handleCheck}
                                    buttonComplete={key === "channelIntegration" && clientDetails.onboarding_steps[3].id === actualStep}
                                    actionComplete={key === "channelIntegration" && handleChannelIntegration}
                                />
                            )
                        ))}

                </Box>

            </Box>

        </Modal>


    )
}

export default SetupStep

