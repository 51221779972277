import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import firstModalStyles from '../../../components/PublicationAlertModals/FirstAlertModal.module.css';

const SkeletonLoader = () => (
    <div >
        <form >
            <section className={firstModalStyles.modalSectionLoader}>
                <div className={firstModalStyles.frameParent}>
                    <div className={firstModalStyles.seleccionaAlMenosUnTipoDeWrapper}>
                        <div className={firstModalStyles.seleccionaAlMenos}>
                            Selecciona al menos un tipo de alerta
                        </div>
                    </div>
                    <div className={firstModalStyles.frameGroup}>
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                        <Skeleton variant="rectangular" width="100%" height={39} style={{ marginBottom: '5px' }} />
                    </div>
                </div>
            </section>
        </form>
    </div>
);

export default SkeletonLoader;
