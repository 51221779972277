import React from 'react'
import styled from 'styled-components'
import iconLessThan from "../../assets/img/icons/iconLessThan.svg"

const ProfileHeader = (props) => {
  return (
    <Wrapper>
        <Circle onClick={props.backTo}>
            <IcoWrapper>
                <IconImg src={iconLessThan}/>
            </IcoWrapper>
        </Circle>
        <Title >{props.children}</Title>
        <FakeCicle/>
    </Wrapper>
  )
}


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
`

const Circle = styled.div`
    background-color: #51CBCE;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const FakeCicle = styled.div`
    width: 44px;
    height: 44px;    
`

const Title = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #232A38;
    font-weight: 700;
`

const IcoWrapper = styled.div`
    height: 12px;
`

const IconImg = styled.img`
    height: 100%;
    display: block;
`

export default ProfileHeader