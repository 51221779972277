import React, { useState } from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import classes from './ModalURL.module.css'
import * as XLSX from 'xlsx'
import { saveImage } from 'services/catalog'
import GlobalError from './GlobalError'

import spinnerGif from "../../assets/img/spinnerLogos.gif"
import atention from '../../assets/img/atention.png'
import successIcon from '../../assets/img/success.png'

const ModalURL = ({ currentStore , showModalURL, handleModalURL, templateURL }) => {

    const [success, setSuccess] = useState(false)
    const [excelData, setExcelData] = useState(undefined)
    const [globalError, setGlobalError] = useState(undefined)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [uploadErrors, setUploadErrors] = useState([])
    const [imagesSuccess, setImagesSuccess] = useState(0)

    const handleFile = async (event) => {
        try {
            const file = event.target.files[0]
            const data = await file.arrayBuffer()
            const workbook = XLSX.read(data)
            const workSheet = workbook.Sheets[workbook.SheetNames[0]]
            const jsonData = XLSX.utils.sheet_to_json(workSheet)
            const rows = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
            if (rows[0].length !== 21 || rows[0][0] !== "SKU") {
                throw new Error('Archivo de maestra incorrecto. Descarga la plantilla e inténtelo nuevamente')
            } else {
                setExcelData(jsonData)
            }
        } catch (error) {
            setExcelData(undefined)
            setGlobalError(error.message)
            setUploadLoading(false)
        }
    }

    const ImagesUpload = async () => {

        try {
            setImagesSuccess(0)
            setUploadLoading(true)
            setUploadErrors([])
            let data = []
            if (!excelData) return
            excelData.forEach((row) => {
                const sku = row.SKU.toString()
                const auxArray = Object.entries(row)
                const URLs = auxArray.splice(1)
                URLs.forEach((URL) => {
                    const split = URL[0].split("_")
                    const bodySaveImage = {
                        url: URL[1],
                        description: "",
                        position: split[1],
                        sku: sku,
                        store_id: parseInt(currentStore)
                    }
                    data.push(bodySaveImage)
                })
            })
            const max = 1000
            for (let i = 0; i < data.length; i += max) {
                const aux = data.slice(i, i + max);
                await submitURLs(aux)
            }
            setUploadLoading(false)
            setSuccess(true)
        } 
        catch (error) {
            setGlobalError(error.message)
            setUploadLoading(false)
        }
    }

    const codeErrors = (code) => {
        switch (code) {
            case "not_found_sku":
                return "Producto no existe."
            case "product_doesnt_belong_store":
                return "Producto no existe en tienda."
            case "user_cant_modify_product":
                return  "Este usuario no tiene los permisos para modificar el producto."
            case "store_not_found":
                return "Tienda no encontrada."
            case "field_required_empty":
                return "Uno o más atributos requeridos están vacíos."
            default:
                return "Error en el servidor. Intenta más tarde."
        }
    }

    const submitURLs = async (array) => {
        const URLsbody = { data : array }
        try {
            const response = await saveImage(URLsbody)
            if(response instanceof Error) throw response
            for (let i = 0; i < response.data?.errors.length ; i++){
                if (uploadErrors.find(f => f.sku === response.data?.errors[i].sku)) continue
                setUploadErrors(prevState => [...prevState, { nameError: codeErrors(response.data?.errors[i].internal_code) , sku: response.data?.errors[i].sku }] )
            }
            setImagesSuccess(prevState => prevState + response.data?.images_created.length)
        } catch (error) {
            setGlobalError(error.message)
            setUploadLoading(false)
        }
    }

    const closedModal = () => {
        setSuccess(false)
        setExcelData(undefined)
        setGlobalError(undefined)
        setUploadErrors([])
        setImagesSuccess(0)
    }

    return (
        <Modal isOpen={showModalURL} toggle={handleModalURL} size="lg" onExit={closedModal}>
            <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={() => {
                            handleModalURL()
                    }}>
                    x
                    </button>
                </div>
            </ModalHeader>
                {uploadLoading && 
                    <div className={classes.spinner}>
                        <img src={spinnerGif} className={classes.imgSpinner} />
                        <h2 className={classes.titleSpinner}>Subiendo imágenes...</h2>
                        <p className={classes.textSpinner}>Esta acción podría tardar un momento;<br/>
                        por favor, no cierres esta pestaña mientras se efectúa la carga.</p>
                    </div> 
                }
                {!uploadLoading && (
                    <div className={classes.wrapperModal}>
                        {!success &&
                            <>
                                <h3 className={classes.titleModal}>Subir imágenes por URL</h3>
                                {templateURL && 
                                    <a href={templateURL} className={classes.buttonGreen} target="_blank" >
                                        <span  className={classes.buttonText}>Descargar formato URLs</span>
                                    </a>
                                }
                                {globalError && <GlobalError message={globalError} />}
                                <p className={classes.textModal}>Recuerda que la columna SKU es requerimiento obligatorio en el archivo de carga de URLs.</p>
                                <div className={classes.formFile}>
                                    <label className={classes.label}>Archivo de URLs</label>
                                    <input 
                                        type="file" 
                                        name="file" 
                                        onChange={(e) => handleFile(e)} 
                                        className={classes.inputFile}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </div>
                                <button className={classes.buttonModal} onClick={ImagesUpload} disabled={excelData ? false : true}>
                                    <span className={classes.buttonModalText}>Cargar URLs</span>
                                </button>
                            </>
                        }
                        {success  && imagesSuccess > 0 &&
                                <div className={classes.successWrapper}>
                                    <div className={classes.iconSuccessWrapper}>
                                        <img src={successIcon} />
                                    </div>
                                    <h5 className={classes.titleSuccess}>{imagesSuccess} imágenes se han cargado correctamente.</h5>
                                    <div className={classes.successButtons}>
                                        <button
                                            className={classes.button}
                                            onClick={() => {
                                                closedModal()
                                                handleModalURL()
                                            }}
                                            >
                                            <span className={classes.buttonText}>Volver a página de carga</span>
                                        </button>
                                        <button
                                            className={classes.buttonGreenSuccess}
                                            >
                                            <span className={classes.buttonGreenText}>Ir a mi panel de productos</span>
                                        </button> 
                                    </div>
                                </div>
                            }
                            {success && (uploadErrors.length > 0) &&
                                <div className={classes.successErrorWrapper}>
                                    <div className={classes.atentionWrapper}>
                                        <img className={classes.img} src={atention} />
                                    </div>
                                    <h3 className={classes.titleSuccessError}>
                                        Una o más de las imágenes presentan errores y no se cargaron correctamente. Revisa el listado a continuación y corrige
                                        el error para volver a intentarlo.
                                    </h3>
                                </div>
                            }
                            {uploadErrors.length > 0 && 
                                <div className={classes.errorWrapper}>
                                    <h5 className={classes.titleError}>Listado de errores:</h5>
                                    {uploadErrors.map((error, i) => (
                                            <p key={i} className={classes.textError}>
                                            {`Error en SKU:  ${error.sku}. Detalle del error: ${error.nameError}`}
                                            </p>
                                    ))}
                                </div>
                            }
                    </div>
                )}
                    
        </Modal>
    )
}

export default ModalURL