import React, { useState, useEffect, useRef } from 'react'
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useMediaQuery } from "@mui/material";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Close from "../../../../assets/img/icons/close_black.png"
import Upload from "../../../../assets/img/icons/upload.png"
import atras from "../../../../assets/img/icons/DespliegueBlack.png"
import trash from "../../../../assets/img/icons/trash.png"
import { Modal } from 'reactstrap'

import {
    validateChile,
    validateColombia,
    validateMexico,
    validatePeru
} from '../../../../utils/identityValidator'

//styles
import classes from "./displayEditClientStores.module.css";

function CreateStoreSecondModal({ displayModalSecond, toggleSecondModal, setCliente, toggleFirstModal, toggleThirdModal, resetValues, clientData, setContrat, contratoCliente, modalTittle, closeConfirmationModal }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [selectedFile, setSelectedFile] = useState(contratoCliente);
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [uploading, setUploading] = useState(!!selectedFile);
    const inputRef = useRef(null)
    const [error, setError] = useState({
        addressError: false,
        identityError: false,
        social_reasonError: false,
        type_of_sellingError: false,
        contratError: false
    })

    const [clientDataSecond, setClientDataSecond] = useState({
        address: clientData.address,
        identity: clientData.identity,
        social_reason: clientData.social_reason,
        type_of_selling: clientData.type_of_selling,
    });

    useEffect(() => {
        if (clientDataSecond.address && clientDataSecond.identity && clientDataSecond.social_reason && clientDataSecond.type_of_selling && (selectedFile || clientData.contratFileName)) {
            setButtonDisabled(false);
            setUploading(true);
        } else {
            setButtonDisabled(true);
            !selectedFile ? setUploading(false) : setUploading(true) ;
        }
    }, [clientDataSecond, selectedFile, clientData.contratFileName]);

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            if (file.type === 'application/pdf') {
                if (error.contratError) {
                    setError({ ...error, contratError: false })
                }
                setContrat(file)
                setSelectedFile(file)
                setUploading(true);
                if (clientDataSecond.address && clientDataSecond.identity && clientDataSecond.social_reason && clientDataSecond.type_of_selling) {
                    setButtonDisabled(false)
                } else {
                    setButtonDisabled(true)
                }
            } else {
                alert('Por favor, selecciona un archivo PDF.');
            }
        }
        console.log(file)

    };

    const handleDelete = () => {
        setSelectedFile(null);
        setUploading(false);
        setButtonDisabled(true);
        inputRef.current.value = '';
        clientData.contratFileName = '';
    };

    const rutValidation = (identity, idCountry) => {
        try {
            if (idCountry === 1) {
                return validateChile(identity)
            } else if (idCountry === 2) {
                return validateColombia(identity)
            } else if (idCountry === 3) {
                return validatePeru(identity)
            } else if (idCountry === 4) {
                return validateMexico(identity)
            }
            return false
        } catch (error) {
            console.log(error)
        }
    }

    const handleClose = async () => {
        const modalResult = await closeConfirmationModal()
        if (modalResult){
            resetValues()
            toggleSecondModal()
        }
       
    }

    const handleChangeSecondModal = (e) => {
        const { name, value } = e.target;
        setClientDataSecond({ ...clientDataSecond, [name]: value });
        if (e.target.name === "address" && error.addressError)
            setError({ ...error, addressError: false })
        if (e.target.name === "identity" && error.identityError)
            setError({ ...error, identityError: false })
        if (e.target.name === "social_reason" && error.social_reasonError)
            setError({ ...error, social_reasonError: false })
        if (e.target.name === "type_of_selling" && error.type_of_sellingError)
            setError({ ...error, type_of_sellingError: false })
        if (clientDataSecond.address && clientDataSecond.identity && clientDataSecond.social_reason && clientDataSecond.type_of_selling && value && (selectedFile || clientData.contratFileName)) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    };

    const formValidation = () => {
        let error = false
        let errors = {}
        if (!rutValidation(clientDataSecond.identity, clientData.client_country)) {
            errors = { ...errors, identityError: true }
            error = true
        }
        if (!clientDataSecond.address) {
            errors = { ...errors, addressError: true }
            error = true
        }
        if (!clientDataSecond.social_reason) {
            errors = { ...errors, social_reasonError: true }
            error = true
        }
        if (!clientDataSecond.type_of_selling) {
            errors = { ...errors, type_of_sellingError: true }
            error = true
        }
        if (!selectedFile && modalTittle !== "Editar Cliente") {
            errors = { ...errors, contratError: true }
            error = true
        }

        setError({ ...error, ...errors })
        return error
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let errorForm = formValidation();

        if (!errorForm) {
            setCliente({ ...clientData, ...clientDataSecond })
            toggleSecondModal()
            toggleThirdModal()
        }
    };

    return (
        <Modal
            isOpen={displayModalSecond}
            toggle={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',

                    transform: 'translate(-50%)',
                    width: "120%",

                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 5,
                    zIndex: 1,
                    borderRadius: 4,
                    '@media (max-width: 600px)': {
                        width: '90%',
                    },
                    '&::after': {
                        content: '""',
                        borderBottom: '10px solid #51CBCE', // Color ajustado
                        width: '66.66%', // Ajusta este valor según tus necesidades
                        position: 'absolute',
                        bottom: 0,
                        left: "0%",
                        borderRadius: 4,
                        borderBottomRightRadius: 4,
                        height: 24
                    }

                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title="Volver atrás" arrow>
                        <IconButton onClick={() => { toggleSecondModal(); toggleFirstModal() }}
                            sx={{
                                ml: isMobile ? "-20px" : "",
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                }
                            }}>
                            <img src={atras} alt="atras" width={isMobile && "70%"} />
                        </IconButton>
                    </Tooltip>
                    <Typography fontSize={isMobile ? "24px" : "33px"} sx={{ ml: isMobile ? "25%" : "" }} fontFamily='Montserrat'><b>{modalTittle}</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <IconButton onClick={handleClose} sx={{
                            mr: isMobile ? "-20px" : "",
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }}>
                            <img src={Close} width={isMobile && "70%"} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <div className={classes.lineWrapper} >
                    <span className={classes.line}></span>
                </div>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { mt: 2, mb: 2 }
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography fontSize={isMobile ? "14px" : "20px"} align='center' fontFamily='Montserrat'><b> {modalTittle === "Editar Cliente" ? "Edita la información del cliente" : "Completa la información con los datos del cliente"}</b></Typography>

                    <FormControl variant="standard" fullWidth error={error.addressError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Dirección de cliente
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="address" name="address" defaultValue={clientData.address}
                            placeholder="Ingresa la dirección del cliente"
                            onChange={handleChangeSecondModal} />
                        {error.addressError ? <FormHelperText>La dirección del cliente no es valida</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.identityError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            RUT, RUN o RFC de cliente (sin puntos ni guión)
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="identity" name="identity" defaultValue={clientData.identity}
                            placeholder="Ingresa RUT, RUN o RFC del cliente (sin puntos ni guión)" onChange={handleChangeSecondModal} />
                        {error.identityError ? <FormHelperText>El rut no es valido</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.social_reasonError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Razón social del cliente
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="social_reason" name="social_reason" defaultValue={clientData.social_reason}
                            placeholder="Ingresa la razón social del cliente" onChange={handleChangeSecondModal} />
                        {error.social_reasonError ? <FormHelperText>La razón social del cliente no es valida</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.type_of_sellingError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Giro del cliente
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="type_of_selling" name="type_of_selling" defaultValue={clientData.type_of_selling}
                            placeholder="Ingresa el giro del cliente" onChange={handleChangeSecondModal} />
                        {error.type_of_sellingError ? <FormHelperText>El giro del cliente no es valido</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.contratError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Contrato del cliente
                        </InputLabel>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <InputBase sx={{ mt: 3.5, pl: "10px" }}
                                id="contrat" name="contrat"
                                value={selectedFile ? selectedFile.name :
                                    clientData.contratFileName ? clientData.contratFileName : ""
                                }
                                placeholder="Carga el contrato del cliente"
                                readOnly
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            if (uploading) {
                                                handleDelete();
                                            } else {
                                                inputRef.current.click();
                                            }
                                        }}
                                        sx={{
                                            margin: '-2px 0 0', '&:hover': {
                                                backgroundColor: 'transparent',
                                            }, mr: 1
                                        }}>
                                        {uploading ? <img style={{ height: 25, fontWeight: 25 }} src={trash} alt="upload-contrat" /> : <img style={{ height: 25, fontWeight: 25 }} src={Upload} alt="upload-contrat" /> }
                                        <input ref={inputRef} accept=".pdf" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                                    </IconButton>
                                }
                            />
                        </div>
                        {error.contratError ? <FormHelperText>Debe subir un archivo pdf</FormHelperText> : <></>}
                    </FormControl>
                    <Button disabled={buttonDisabled} type="submit" variant="contained" sx={{ fontSize: '26px', padding: '10px 27%', width: '333px', height: '67px', borderRadius: "20px !important" }} ><b>Siguiente</b></Button>
                </Box>

            </Box>
        </Modal>
    )
}


export default CreateStoreSecondModal