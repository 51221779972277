import React from 'react';
import CustomModal from '../UI/CustomModal';
import styles from '../UI/CustomModal.module.css';
import secondModalStyles from './SecondAlertModal.module.css';
import CalendarPicker from "../../assets/img/icons/calendar-picker.svg";
import DatePicker from '../UI/DatePicker';

const SecondAlertModal = ({ 
  secondModal, 
  toggleSecondModal, 
  isButtonEnabled, 
  handleSecondModal, 
  handleCloseSecondModal, 
  days, 
  toggleDay, 
  activeDays, 
  toggleDatePicker, 
  startDate, 
  endDate, 
  formatDateRange, 
  formatDate, 
  showDatePicker,
  selectedDate,
  handleDateChange,
  setStartDate,
  setEndDate,
  handleButtonClick,
  firstClick,
  setFirstClick 
}) => {
  return (
    <CustomModal
      isOpen={secondModal}
      toggle={toggleSecondModal}
      onBack={handleSecondModal}
      onClose={handleCloseSecondModal}
      title="Crear alerta"
      customStyles={{ box: secondModalStyles.customModal }}
      customClassNames={{
        form: secondModalStyles.modalForm,
        header: secondModalStyles.crearAlertaParent,
        title: secondModalStyles.crearAlerta,
        closeWrapper: secondModalStyles.closeWrapper,
        backIcon: secondModalStyles.backIcon,
        closeIcon: secondModalStyles.closeIcon,
        separatorContainer: secondModalStyles.separatorContainer,
        separator: secondModalStyles.separator,
        bodyContainer: secondModalStyles.bodyContainer,
        footerContainer: secondModalStyles.footerContainer,
        box: isButtonEnabled ? styles.afterElementSecond : styles.afterElement
      }}
      footerActions={
        <div
          className={`${secondModalStyles.botonLogin} ${isButtonEnabled ? secondModalStyles.enabled : ''}`}
          onClick={isButtonEnabled ? handleButtonClick : null}
        >
          <b className={secondModalStyles.siguiente}>Siguiente</b>
        </div>
      }
    >
      <div className={secondModalStyles.innerContainer}>
        <div className={secondModalStyles.subtitleContainer}>
            <div className={secondModalStyles.seleccionaLaFrecuencia}>Selecciona la frecuencia de alerta</div>
            <div className={secondModalStyles.elEnvoEst}>(El envío está sujeto a la lógica del tipo de alerta)</div>
        </div>
        <div className={secondModalStyles.repeatContainer}>
            <div className={secondModalStyles.correo}>Se repite el</div>
        </div>
        <div className={secondModalStyles.rectangleParentComponent}>
            {days.map((day) => (
            <div
                key={day.key}
                className={`${secondModalStyles.rectangleParent} ${activeDays[day.key] ? secondModalStyles.active : ''}`}
                onClick={() => toggleDay(day.key)}
            >
                <div className={secondModalStyles.groupChild} />
                <div className={`${secondModalStyles.dayText} ${activeDays[day.key] ? secondModalStyles.activeText : ''}`}>{day.label}</div>
            </div>
            ))}
        </div>
        <div className={secondModalStyles.repeatContainer}>
            <div className={secondModalStyles.correo}>Fecha</div>  
        </div>         
        <div className={secondModalStyles.rectangleParent2} onClick={toggleDatePicker}>
            <div className={secondModalStyles.groupChild2}>                                            
                <div className={secondModalStyles.seleccionaUnaFecha}>
                    {startDate || endDate ? formatDateRange(startDate, endDate) : "Selecciona una fecha"}
                </div>   
                <img className={secondModalStyles.calendarIcon} alt="" src={CalendarPicker} />                                                                                         
            </div>
            <div className={secondModalStyles.dynamicText}>
            {activeDays && Object.values(activeDays).some(day => day) && startDate ? (
                <span>
                Se enviará todos los {days.filter(day => activeDays[day.key]).map(day => day.fullLabel.toLowerCase()).join(', ')} desde el {formatDate(startDate)}
                {endDate ? ` hasta el ${formatDate(endDate)}` : ''}
                </span>
            ) : (
                ''
            )}
            </div>                                                                                                                      
        </div>   
      </div>                                 
      {showDatePicker && (
        <div className={secondModalStyles.datePickerOverlay}>
          <DatePicker 
            selectedDate={selectedDate} 
            onDateChange={handleDateChange} 
            onClose={toggleDatePicker}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate} 
            setEndDate={setEndDate}
            firstClick={firstClick}
            setFirstClick={setFirstClick}
          />
        </div>
      )}
    </CustomModal>
  );
};

export default SecondAlertModal;
