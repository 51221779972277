import React, { useEffect } from 'react';
import { Alert, IconButton } from '@mui/material';
import closeAlert from '../../assets/img/icons/closeAlert.svg';
import closeAlertError from '../../assets/img/icons/closeAlertError.svg';

const typeAlerts = [
    { id: "success", color: "#E9F9EC", btColor:"#24BB3E", closeIcon: closeAlert },
    { id: "error", color: "#FDEBE9", btColor:"#EB3223", closeIcon: closeAlertError }
];

const AlertSeller = ({ showAlert, text, setShowAlert, time, type }) => {

    useEffect(() => {
        if (showAlert) {
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, time);

            return () => clearTimeout(timeoutId);
        }
    }, [showAlert, setShowAlert, time]);

    return (
        <Alert
            icon={false}
            sx={{
                zIndex: 1400,
                fontFamily: 'Montserrat',
                position: 'fixed',  
                top: 30,             
                right: 30,          
                borderRadius: "10px !important",
                color: typeAlerts.find(alert => alert.id === type)?.btColor || "#24BB3E",
                backgroundColor: typeAlerts.find(alert => alert.id === type)?.color || "#E9F9EC",
                minHeight: "97px",
                paddingTop: "10px",
                minWidth: "540px",
                fontSize: "20px",
                display: 'flex',        
                alignItems: 'center',   
                justifyContent: 'space-between',
                '&::after': {
                    content: '""',
                    borderBottom: `10px solid ${typeAlerts.find(alert => alert.id === type)?.btColor || '#24BB3E'}`,
                    width: `100%`,
                    position: 'absolute',
                    bottom: 0,
                    left: "0%",
                    borderRadius: 4,
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    height: 24
                },
                // Responsive styles for smaller screens
                '@media (max-width: 600px)': {
                    minWidth: '80vw', // Take 80% of the viewport width on smaller screens
                    right: '10vw',
                    top: 35,
                    fontSize: '16px',
                    minHeight: "70px",
                }
            }}
            action={

                <IconButton
                    onClick={() => {
                        setShowAlert(false);
                    }}
                    sx={{
                        cursor: "pointer",
                        marginTop: "-7px",
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}>
                    <img src={typeAlerts.find(alert => alert.id === type)?.closeIcon || closeAlert} alt="closeIcon"/>
                </IconButton>
            }
        >
            <b>{text}</b>
         
        </Alert>
    );
};

export default AlertSeller;
