import Select from 'components/UI/Select'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { getProducts } from 'services/products'
import { getStores } from 'services/stores'
import Input from './Input'
import classes from './ModalProduct.module.css'

import successIcon from '../../assets/img/success.png'
import spinnerGif from "../../assets/img/spinnerLogos.gif"

const ModalProduct = ({ comboType, showModalProduct, handleModalProduct, handleAddProduct }) => {

    const [stores, setStores] = useState([])
    const [productsOptions, setProductsOptions] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [selectedProduct, setSelectedProduct] = useState(undefined)
    const [stockProduct, setStockProduct] = useState(undefined)
    const [clientPaid, setClientPaid] = useState(undefined)
    const [discountProduct, setDiscountProduct] = useState(undefined)
    const [openOptions, setOpenOptions] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedOptionStore, setSelectedOptionStore] = useState("")
    const searchUpdate = useRef(true)
    const storeSelected = useRef("")
    const sectionRef = useRef(null)
    const email = localStorage.getItem("name")

    useEffect(() => {
        myStores()
    }, [])


    useEffect(() => {
        if (searchUpdate.current) {
            const timeoutId = setTimeout(() => {
                if (searchInput.length > 4) {
                    searchProducts()
                }
            }, 1000);
            return () => clearTimeout(timeoutId);
        } else {
            searchUpdate.current = true
        }
    }, [searchInput])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sectionRef.current && !sectionRef.current.contains(event.target)) {
                setOpenOptions(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [sectionRef])

    const myStores = async () => {
        const response = await getStores(email)
        setStores(response.message)
    }

    const searchProducts = async () => {
        setSearchLoading(true)
        setOpenOptions(true)
        const newQuery = `?store_id=${storeSelected.current}&product_format=unitary&general_search_fields=product_name,sku&general_search_value=${searchInput}&page_size=30`
        const response = await getProducts(newQuery)
        setProductsOptions(response.data.products)
        setSearchLoading(false)
        if (response.data.products.length > 0) {
            setOpenOptions(true)
        } else {
            setOpenOptions(false)
        }
    }

    const handleOptionsStore = (value) => {
        setSelectedOptionStore(value)
    }

    const handleStore = (value) => {
        storeSelected.current = value
    }

    const handleStock = (event) => {
        event.preventDefault()
        setStockProduct(event.target.value)
    }

    const handleDiscount = (event) => {
        event.preventDefault()
        setDiscountProduct(event.target.value)
    }

    const handleClientPaid = (event) => {
        event.preventDefault()
        setClientPaid(event.target.value)
    }

    const handleSearchInput = (event) => {
        event.preventDefault()
        setSearchInput(event.target.value)
    }

    return (
        <Modal isOpen={showModalProduct} toggle={handleModalProduct} size="lg">
            <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={() => {
                        handleModalProduct()
                    }}>
                        x
                    </button>
                </div>
            </ModalHeader>
            {success &&
                <div className={classes.successWrapper}>
                    <div className={classes.iconSuccessWrapper}>
                        <img src={successIcon} />
                    </div>
                    <h2 className={classes.title}>Producto agregado al {comboType} exitosamente</h2>
                    <button className={classes.buttonGreen} onClick={() => handleModalProduct()}>
                        <p className={classes.textButton}>
                            Entendido
                        </p>
                    </button>
                </div>
            }
            {!success &&
                <>
                    <div className={classes.wrapper}>
                        <h2 className={classes.title}>Agregar nuevo producto al {comboType}</h2>
                        <div className={classes.inputsWrapper}>
                            <div className={classes.labelSelect}>
                                <label className={classes.label}>Tienda / Cliente</label>
                                <Select search handleOptions={handleOptionsStore} selectedOption={selectedOptionStore} handle={handleStore} options={stores} value="id" name="name" />
                            </div>
                            <div ref={sectionRef} className={classes.searchWrapper}>
                                <Input
                                    handle={handleSearchInput}
                                    width="100%"
                                    name="Nombre producto o SKU"
                                    type="text"
                                    value={searchInput}
                                />
                                {openOptions &&
                                    <>  {searchLoading &&
                                        <div className={classes.options}>
                                            <img src={spinnerGif} className={classes.imgSpinner} />
                                        </div>
                                    }
                                        {productsOptions.length > 0 && !searchLoading &&
                                            <div className={classes.options}>
                                                {productsOptions.map((product) => (
                                                    <div
                                                        key={product.id_mtdi}
                                                        className={classes.option}
                                                        onClick={() => {
                                                            searchUpdate.current = false
                                                            setSelectedProduct(product)
                                                            setOpenOptions(false)
                                                            setSearchInput(product.name)
                                                        }} >
                                                        <div className={classes.optionWrapper}>
                                                            <div className={classes.imgWrapper}><img className={classes.img} src={product.images.length > 0 ? product.images[0].url : undefined} /></div>
                                                            <div className={classes.storeAndName} title={product.name}>
                                                                <h4 className={classes.productName}>{product.name}</h4>
                                                                <p className={classes.storeSearching}>{product.store?.tienda}</p>
                                                            </div>
                                                            <p className={classes.productSKU} title={product.item_ids?.filter(id => id.type === "sku")[0]?.value.toUpperCase()}>{product.item_ids?.filter(id => id.type === "sku")[0]?.value.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </>
                                }
                                {selectedProduct && <p className={classes.storeOfProduct}>Tienda: {selectedProduct.store?.tienda}</p>}
                            </div>
                        </div>
                        <div className={comboType === "mxn" ? classes.inputsWrapperThree : classes.inputsWrapper}>
                            <Input disabled width={comboType === "mxn" ? "200px" : "300px"} widthInput="120px" name="Stock disponible" type="text" placeholder={selectedProduct?.stock_wms.length > 0 ? selectedProduct.stock_wms[0].stock : "No disponible"} />
                            {comboType !== "mxn" && <Input width={comboType === "mxn" ? "350px" : "300px"} widthInput="100px" name="¿Cuántas unidades utilizarás para este combo?" type="number" value={stockProduct} handle={handleStock} />}
                            {comboType === "mxn" &&
                                <>
                                    <Input width="200px" widthInput="100px" name="Productos en promoción" type="number" value={stockProduct} handle={handleStock} min="0" />
                                    <Input width="250px" widthInput="100px" name="Productos que paga cliente" type="number" value={clientPaid} handle={handleClientPaid} min="0" />
                                </>
                            }
                        </div>
                        <div className={comboType === "mxn" ? classes.inputsWrapperThree : classes.inputsWrapper}>
                            <Input disabled width="200px" widthInput="120px" name="Precio de venta producto" type="text" placeholder={selectedProduct ? selectedProduct.base_price : "No disponible"} />
                            <Input width="300px" widthInput="100px" name="¿Cuánto % de descuento le darás?" type="number" value={discountProduct} handle={handleDiscount} min="0" max="100" />
                            {comboType === "mxn" && <div className={classes.fake}></div>}
                        </div>
                    </div>
                    <div className={classes.buttonsWrapper}>
                        <button className={classes.buttonOutlined} onClick={() => handleModalProduct()}>
                            <p className={classes.textButton}>
                                Cancelar
                            </p>
                        </button>
                        <button
                            disabled={comboType === "mxn" ? !(selectedProduct && stockProduct && clientPaid && discountProduct) : !(selectedProduct && stockProduct && discountProduct)}
                            className={classes.buttonGreen}
                            onClick={() => {
                                if (comboType === "mxn") {
                                    handleAddProduct(selectedProduct, stockProduct, discountProduct, clientPaid)
                                } else {
                                    handleAddProduct(selectedProduct, stockProduct, discountProduct)
                                }
                                setSuccess(true)
                            }}>
                            <p className={classes.textButton}>
                                Agregar Producto
                            </p>
                        </button>
                    </div>
                </>}
        </ Modal>
    )
}

export default ModalProduct