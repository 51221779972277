import React, { useEffect, useState } from 'react'

import { Table, Card } from 'reactstrap'
import moment from 'moment';

import { OutlinedInput, MenuItem, FormControl, ListItemText, Checkbox, Button, FormGroup, FormControlLabel } from '@mui/material';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

import { getCatalog, getDocumentDownloads } from 'services/catalog'

import classes from './DownloadCatalog.module.css'


const DownloadCatalog = ({ stores, refreshCatalogDownload, setRefreshCatalogDownload }) => {

  const [storeIdsSelected, setStoreIdsSelected] = useState([]);
  const [isGeneratingDownload, setIsGeneratingDownload] = useState(false)
  const [catalogDownloads, setCatalogDownloads] = useState([])
  const [firstLoadHasHappened, setFirstLoadHasHappened] = useState(false)
  const [checkingStateThroughButton, setCheckingStateThroughButton] = useState(false)
  const [isSelectAllStoresChecked, setIsSelectAllStoresChecked] = useState(false)

  let catalogDownloadsCopy = catalogDownloads

  const handleChangeStoreSelect = (event) => {
    setStoreIdsSelected(event.target?.value || []);

    console.log(event.target?.value, stores)
    if(stores?.length > 0){
      if(stores.length === event.target?.value?.length){
        setIsSelectAllStoresChecked(true)
      }else{
        setIsSelectAllStoresChecked(false)
      }
    }
  };

  const handleCheckSelectAllStores = (event) =>{
    setIsSelectAllStoresChecked(event?.target?.checked)
    if(event?.target?.checked){
      setStoreIdsSelected(stores?.map( e => e?.id)?.filter(e => e != null) || [])
    }
    else{
      setStoreIdsSelected([])
    }
    
  }

  const getCatalogDownloads = async () => {
    const response = await getDocumentDownloads("?type=catalog")
    setFirstLoadHasHappened(true)
    if (!response.error) {
      catalogDownloadsCopy = response.data?.data || []
      setCatalogDownloads(response.data?.data || [])
    }
  }

  const generateCatalogDownload = async () =>{
    setIsGeneratingDownload(true)
    const response = await getCatalog(storeIdsSelected?.join(",") || "")
    setIsGeneratingDownload(false)
    if (response.error) {
        Swal.fire({
            icon: 'error',
            title: 'Error inesperado',
            text: 'Por favor vuelve a intentar',
        })
    }
    else {
      getCatalogDownloads()
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const renderValueFunction = (selectedValues) => {
    if(selectedValues?.length === 0)
      return <em style={{ opacity: 0.75 }} >Seleccionar tienda (s)</em>;
   
    const storeNames = stores?.filter(e => selectedValues.includes(e.id)).map(e => e.name)
    return storeNames.join(", ")
  }

  const downloadExcelFile = (fileURL) => {
    if(fileURL)
      window.open(fileURL)
  }

  const getUpdatedStateCatalogThoughButton = async () => {
    setCheckingStateThroughButton(true)
    await getCatalogDownloads()
    setCheckingStateThroughButton(false)
  }

  useEffect(() => {
    getCatalogDownloads()
  }, [])
  

  useEffect(() => {
    if(refreshCatalogDownload){
      getCatalogDownloads()
      setRefreshCatalogDownload(false)
    }
  }, [refreshCatalogDownload])
  return (
    <>
      <Card style={{ marginTop: "20px" }}>
        <h6 style={{marginTop: 25, marginBottom: 10}} className={classes.downloadsTitle}>Seleccionar tiendas(s) para descargar catálogo</h6>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControl sx={{ m: 1, width: 250 }}>
            <Select
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              value={storeIdsSelected}
              onChange={handleChangeStoreSelect}
              input={<OutlinedInput />}
              renderValue={renderValueFunction}
              MenuProps={MenuProps}
              size="small"
            >
              {stores.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  <Checkbox checked={storeIdsSelected.find(e => e == store.id) != undefined} />
                  <ListItemText primary={store.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <FormGroup>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={isSelectAllStoresChecked}
                  onChange={handleCheckSelectAllStores}
                  disabled={stores?.length === 0 || stores?.length == undefined}
                />
              }
              label={<small style={{ color: "black", opacity: 1 }}>{isSelectAllStoresChecked ? "Deseleccionar todas" : "Seleccionar todas"}</small>}
            />
          </FormGroup>
        </div>
        <span style={{ display: "flex", justifyContent: "center", opacity: 0.6}}>{storeIdsSelected.length} tiendas seleccionadas</span>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button 
            disabled={!storeIdsSelected.length}
            onClick={generateCatalogDownload}
            variant="contained" sx={{ fontSize:"15px", fontWeight: "600", marginTop: "20px", marginBottom: "30px" }}
          >
            {!isGeneratingDownload ?
              "Generar descarga de catálogo" 
            :
              <CircularProgress
                size={24}
                style={{ color: "white" }}
              />
            }
          </Button>
        </div>
      </Card>
      
      <hr style={{ color: "#BFC1BF" }} />
      {catalogDownloads.length > 0 ?
        <em>
          <small>
            * La generación de descargas de catálogos puede demorarse hasta 2 minutos, utiliza el botón "comprobar estado", para comprobar si se generó el archivo a descargar
          </small>
        </em>
      : 
        null
      }
      <Card style={{ marginTop: "20px" }}>
        <h6 style={{ marginTop: 20 }} className={classes.downloadsTitle}>Últimas 5 descargas de catálogos generadas</h6>
        <Table borderless className="download-catalog-table" style={{ fontFamily: "'Montserrat' !important", textAlign: "center" }}>
          <thead>
            <tr>
              <th className='th-download-catalog-table'>Fecha</th>
              <th className='th-download-catalog-table'>Estado</th>
              <th className='th-download-catalog-table'>Acciones</th>
            </tr>
          </thead>
          {!catalogDownloads.length ?
            null
          :
            <tbody>
              {catalogDownloads.map( catDownload => (
                <tr key={catDownload.id}>
                  <td>{moment(catDownload.created_at).format("DD-MM-YYYY HH:mm")}</td>
                  <td>{catDownload.status === "processing" ? "Procesando" : catDownload.status === "done" ? "Listo para descargar" : "Con error" }</td>
                  <td>
                    {catDownload.status === "processing" ?
                      <button
                        onClick={getUpdatedStateCatalogThoughButton}
                        className={classes.buttonDownloadFile}
                      >
                        {checkingStateThroughButton ? 
                          <CircularProgress
                            size={24}
                            style={{ color: "white" }}
                          />
                        : 
                          "Comprobar estado"
                        }
                       
                      </button>
                    :
                      <button
                        onClick={() => { downloadExcelFile(catDownload.url) }}
                        className={catDownload.url != "" && catDownload.url != null ? classes.buttonDownloadFile : classes.buttonDownloadFileDisabled}
                      >
                        Descargar
                      </button>
                    }
                    
                  </td>
                </tr>
              ))

              }
            </tbody>
          }

        </Table>
        {!catalogDownloads.length ?
          <div style={{ textAlign: "center" }}>
            <em>{firstLoadHasHappened ? "No se han generado descargas de catálogo" : ""}</em>
          </div>
        :
          null
        }
      </Card>
    </>
  )
}

export default DownloadCatalog
