import React, { useState, useEffect } from 'react'
// import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import { useMediaQuery } from "@mui/material";
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from 'reactstrap'
import Close from "../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
//styles
import classes from "./displayEditClientStores.module.css";
import ChannelCircles from './ChannelsCircles';
import atras from "../../../../assets/img/icons/DespliegueBlack.png"

import { mapServicesNewClientToDataKey } from "../../../../utils/utils"

import { getBusinessModels } from 'services/stores';

function CreateStoreThirdModal({ displayModalThird, toggleThirdModal, toggleSecondModal, cliente, setCliente, listOfChannels, resetValues, handleSubmit, modalTittle, loadingClient, closeConfirmationModal }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [channelsOnboardingSelected, setChannelsOnboardingSelected] = useState(cliente.channelsOnboarding)
    const [businessModels, setBusinessModels] = useState([])
    const [error, setError] = useState({ 
        servicesCombination: false
    })
    const [listOfchannelsCountry, setListOfChannelsCountry] = useState(listOfChannels)

    // represents if there's an error fetching business models.
    // Will be used to not validate combinations in case the error exists 
    const [existsErrorFetchingBusinessModels, setExistsErrorFetchingBusinessModels] = useState(false)
    
    useEffect(async () => {
        getBusinessModelsForValidation()
        
    }, [])

    useEffect(() => {
        filterListOfchannelsByCountryOfClient()
    }, [cliente])
    
    const filterListOfchannelsByCountryOfClient = () => {
        const channelsFiltered = listOfChannels?.filter(circleChannel => {
            const channelIsInCountry = circleChannel?.channelData?.paises?.find(country => country?.id == cliente?.client_country)
            return channelIsInCountry != undefined 
        }) || []

        setListOfChannelsCountry(channelsFiltered)
    }
    

    const handleCheck = (e) => {
        const newClientData = { ...cliente, [e.target.name]: !cliente[e.target.name] }
        setCliente(newClientData);
        const servicesCombinationExists = checkServicesCombination(newClientData)

        if(!servicesCombinationExists){
            setError({ ...error, servicesCombination: true})
        }
        else{
            setError({ ...error, servicesCombination: false})
        }
    }

    useEffect(() => {
        setCliente({ ...cliente, channelsOnboarding: channelsOnboardingSelected });
    }, [channelsOnboardingSelected]);


    const handleClose = async () => {
        const modalResult = await closeConfirmationModal()
        if (modalResult){
            resetValues()
            toggleThirdModal()
        }
        
    }

    const getBusinessModelsForValidation = async () => {
        try {
            const businessModelsData = await getBusinessModels()
            if(businessModelsData === null)
                setExistsErrorFetchingBusinessModels(true)
            else{
                setBusinessModels(businessModelsData.data?.message?.businessModels || [])
                const servicesCombinationExists = checkServicesCombination(cliente, businessModelsData.data?.message?.businessModels || [])

                if(!servicesCombinationExists){
                    setError({ ...error, servicesCombination: true})
                }
                else{
                    setError({ ...error, servicesCombination: false})
                }
            }
        } catch (error) {
            console.log(error)
            setExistsErrorFetchingBusinessModels(true)
        }
    }

    const checkServicesCombination = (clientData, dataFromInitialFetch=null) => {
        try {
            const businessModelsData = dataFromInitialFetch || businessModels
            const servicesObjectSelectedMapped = mapServicesNewClientToDataKey(clientData)

            const serviceFound = businessModelsData.find(businessModelFromAPI => {
                
                for(const serviceSelectedKey of Object.keys(servicesObjectSelectedMapped)){
                   
                    if(servicesObjectSelectedMapped[serviceSelectedKey] !== businessModelFromAPI[serviceSelectedKey]){
                        return false
                    }

                }

                return true
            })

            return serviceFound != undefined
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal
            isOpen={displayModalThird}
            toggle={toggleThirdModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',

                    transform: 'translate(-50%)',
                    width: "120%",

                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 5,
                    zIndex: 1,
                    borderRadius: 4,
                    '@media (max-width: 600px)': {
                        width: '90%',
                    },
                    '&::after': {
                        content: '""',
                        borderBottom: '10px solid #51CBCE', // Color ajustado
                        width: '100%', // Ajusta este valor según tus necesidades
                        position: 'absolute',
                        bottom: 0,
                        left: "0%",
                        borderRadius:4,
                    
                        height:24
                    }

                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title="Volver atrás" arrow>

                        <IconButton onClick={() => { toggleThirdModal(); toggleSecondModal() }}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                ml: isMobile ? "-20px": "",
                                pointerEvents: loadingClient ? 'none' : '',
                                opacity: loadingClient ? 0.5 : ''

                            }}>
                            <img src={atras} alt="atras" width={isMobile && "70%"}/>
                        </IconButton>


                    </Tooltip>
                    <Typography fontSize={isMobile? "24px": "33px"} sx={{ ml: isMobile? "25%": "" }} fontFamily='Montserrat'><b>{modalTittle}</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <Tooltip title="Salir" arrow>
                            <IconButton onClick={handleClose} sx={{
                                 mr: isMobile ? "-20px": "",
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                pointerEvents: loadingClient ? 'none' : '',
                                opacity: loadingClient ? 0.5 : '',
                            }}>
                                <img src={Close} width={isMobile && "70%"}/>
                            </IconButton>
                        </Tooltip>
                    </Tooltip>
                </Box>

                <div className={classes.lineWrapper} >
                    <span className={classes.line}></span>
                </div>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{

                        flexDirection: 'column',

                        '& > :not(style)': { mt: 2, mb: 2 }
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography fontSize={isMobile ? "14px" : "20px"} align='center' fontFamily='Montserrat'><b> {modalTittle === "Editar Cliente" ? "Edita la información del cliente" : "Completa la información con los datos del cliente"}</b></Typography>
                    <Typography style={{ marginBottom: "-10px", fontSize: "17px", fontFamily: 'Montserrat', color: error.servicesCombination? "#d32f2f" : "black" }} align='left'  >Selecciona los servicios</Typography>
                    <FormControl variant="standard" fullWidth error={error.servicesCombination}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="facturacion" onChange={handleCheck} checked={cliente.facturacion} />} label="Facturación por Instance" />
                            <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="bodega" onChange={handleCheck} checked={cliente.bodega} />} label="Almacenamiento de productos en Bodega Instance" />
                            <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="canales" onChange={handleCheck} checked={cliente.canales} />} label="Creación de cuenta en canales de venta por Instance" />
                            <FormControlLabel control={<Checkbox style={{ color: "#D3D3D3" }} name="servicio" onChange={handleCheck} checked={cliente.servicio} />} label="Servicio al cliente por Instance" />
                        </FormGroup>
                        {error.servicesCombination ? 
                            <FormHelperText style={{ textAlign: "center" }}>
                                <span style={{ textAlign: "center" }}>Vaya la combinación de servicios no existe</span>
                                <strong style={{ display: "block" }}>¡Inténtalo de nuevo!</strong>
                            </FormHelperText>
                        : 
                            <></>
                        }
                    </FormControl>
                    <Typography fontFamily='Montserrat' style={{ marginBottom: "-10px", fontSize: "17px" }} align='left'  >Canales por aperturar</Typography>
                    <ChannelCircles
                        setChannels={setChannelsOnboardingSelected}
                        channelsSelected={channelsOnboardingSelected}
                        listOfChannels={listOfchannelsCountry}
                    />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Button disabled={!( !loadingClient && !error.servicesCombination && ( (!existsErrorFetchingBusinessModels && businessModels.length > 0) || existsErrorFetchingBusinessModels) )} type="submit" variant="contained" sx={{ fontSize: '26px', mt: '30px', width: '333px', height: '67px', borderRadius: "20px !important" }} >
                            {loadingClient && (
                                <CircularProgress
                                    thickness={6}
                                    size={33}
                                    sx={{
                                        color: "#68cd96",
                                        position: 'absolute',
                                        top: '43%',
                                        right: '75%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            {(!existsErrorFetchingBusinessModels && businessModels.length > 0) || existsErrorFetchingBusinessModels ?
                                 <b> {modalTittle === "Editar Cliente" ? "Editar Cliente" : "Crear Cliente"}</b>
                            :
                                <b>Cargando...</b>
                            }
                           
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Modal>
    )
}

export default CreateStoreThirdModal