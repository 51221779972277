import ProfileHeader from 'components/UI/ProfileHeader'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { connectBilling } from 'services/billings'
import classes from './AddBillingForm.module.css'
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import { editBilling } from 'services/billings'
import { deleteBilling } from 'services/billings'

const AddBillingForm = ({handleRoute, currentBilling, parameters, mode, myBillings, myNotBillings, storeData, updateStoreData}) => {

    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
    const [success,setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [billing, setBilling] = useState({})
    const [inputs, setInputs] = useState([])
    const [countriesBilling, setCountriesBilling] = useState([])
    const currentChannel = useRef("")

    useEffect(() => {
        createInputs()
    }, [])

    const createInputs = async () => {
        const data = await parameters.find((parameter) => parameter.id === currentBilling)
        setCountriesBilling(data.paises)
        setBilling(data)
        let config = data.config_mtd?.data
        for (let i = 0; i < config.length; i++){
            config[i].value = ""
        }
        setInputs(config)
    }

    const handleInputsChange = (i, event) => {
        event.preventDefault()
        let auxArray = [...inputs]
        auxArray[i].value = event.target.value
        setInputs(auxArray)
    }

    const handleChannel = (event) => {
        event.preventDefault()
        currentChannel.current = event.target.value
      }

    const handleSubmit = async (event) => {
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const body = inputs.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {})
        body.channel = parseInt(currentChannel.current)
        body.billing =  {
            declareSii: 0,
            billingCompanyId: billing.id,
          }
        const response = await connectBilling( storeData.id, [body])
        if (response.message.results[0]?.result === "Success") {
            setSuccess(true)
        }
        setLoading(false)
    }

    const handleSubmitEdit = async (event) => {
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const body = inputs.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {})
        body.channel = parseInt(currentChannel.current)
        body.billing =  {
            declareSii: 0,
            billingCompanyId: billing.id,
          }
        const response = await editBilling( storeData.id, [body])
        if (response.message.results[0]?.result === "Success") {
            setSuccess(true)
        }
        setLoading(false)
    }

    const handleSubmitDelete = async (event) =>{
        if ( currentChannel.current === "") { return }
        event.preventDefault()
        setLoading(true)
        const response = await deleteBilling( storeData.id, currentChannel.current)
        if (response === "Store config billing delete") {
            setSuccess(true)
        }
        setLoading(false)
      }

    const handleModalConfirm = () =>  setShowModalConfirm(prevState => !prevState)
    const handleModalConfirmDelete = () =>  setShowModalConfirmDelete(prevState => !prevState)

    return (
        <>    
                {inputs.length > 0 && (
                    <div className={classes.wrapper}>
                        <ProfileHeader backTo={() => handleRoute(mode === "edit" ? "current" : 'add')}>
                            {billing.name?.charAt(0).toUpperCase() + billing.name?.slice(1)}
                        </ ProfileHeader>
                        <div className={classes.inputLabel}>
                            <label className={classes.label}>
                                Elige un canal de venta
                            </label>
                            <select 
                                name="channel" 
                                onChange={(choice) =>  handleChannel(choice)}
                                className={classes.select}
                            >
                                <option value="">Canales disponibles</option>
                                {mode === "edit" &&
                                    myBillings.filter((b)=> b.billingCompanyId === currentBilling).map((channel, i) => (
                                        <option key={i} value={channel.channelId}>{channel.channelName}</option>
                                    ))
                                }
                                {mode === "connect" &&
                                    myNotBillings.map((channel, i) => (
                                        channel.paises.some( c => countriesBilling.includes(c)) &&
                                            <option key={i} value={channel.channelId}>{channel.channelName}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className={classes.inputs}>
                            {inputs.map( (item, i) => (
                                <div className={classes.inputLabel} key={i}>
                                    <label className={classes.label}>
                                        {item.name}
                                    </label>
                                    <input 
                                        type="text"
                                        name={item.name}
                                        value={inputs[i].value}
                                        className={classes.input}
                                        onChange={event => handleInputsChange(i,event)}
                                    />
                                </div>
                            ))}
                        </div>
                        { mode === "connect" &&
                            <button className={classes.button} onClick={handleModalConfirm}>
                                <span className={classes.buttonText}>Conectar con {billing.name?.charAt(0).toUpperCase() + billing.name?.slice(1)}</span>
                            </button>
                        }       
                        {mode === "edit" &&
                            <>
                                <button className={classes.button} onClick={handleModalConfirm}>
                                    <span className={classes.buttonText}>Editar facturación</span>
                                </button>
                                <button className={classes.buttonDelete} onClick={handleModalConfirmDelete}>
                                    <span className={classes.buttonTextDelete}>Eliminar facturación</span>
                                </button>
                            </>
                        }
                    </div>
                )}
            { showModalConfirm && (
            <Modal isOpen={showModalConfirm} toggle={handleModalConfirm} size="lg">
                <ModalHeader>
                    <div className={classes.modalHeader}>
                        <button className={classes.modalExitButton} onClick={handleModalConfirm}>
                        x
                        </button>
                     </div>
                </ModalHeader>
                {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
                {!loading && 
                    (<div className={classes.wrapperModal}>
                    {mode === "edit" &&
                        <h3 className={classes.textModal}>
                            {success? "Facturación editada correctamente" : "¿Está seguro que desea editar esta facturación?"}
                        </h3>
                    }
                    {mode === "connect" && 
                        <h3 className={classes.textModal}>
                            {success? "Facturación agregada correctamente" : "¿Está seguro que desea agregar esta facturación?"}
                        </h3>
                    }
                    {success ? 
                        <button
                            className={classes.button}
                            onClick={() => {
                                handleModalConfirm()
                                handleRoute('current')
                                updateStoreData(storeData.id)
                            }}>
                            <span className={classes.buttonText}>Entendido</span>
                        </button> 
                        : <button
                            type="submit"
                            className={classes.button}
                            onClick={mode === "edit" ? handleSubmitEdit : handleSubmit}>
                            <span className={classes.buttonText}>Si, seguro</span>
                        </button>
                    }
                    {!success && <span className={classes.textBack} onClick={handleModalConfirm}>No, quiero volver atrás</span>}                 
                    </div>)
                }
            </Modal>
            )}
            { showModalConfirmDelete && (
            <Modal isOpen={showModalConfirmDelete} toggle={handleModalConfirmDelete} size="lg">
                <ModalHeader>
                    <div className={classes.modalHeader}>
                        <button className={classes.modalExitButton} onClick={handleModalConfirmDelete}>
                        x
                        </button>
                    </div>
                </ModalHeader>
                {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
                {!loading && 
                    (<div className={classes.wrapperModal}>
                    <p className={classes.textConfirm}>{success ? "Se ha eliminado correctamente" : "¿Estas seguro que deseas eliminar esta facturación?" }</p>
                    {success ? 
                        <button
                            className={classes.button}
                            onClick={() => {
                                handleModalConfirmDelete()
                                handleRoute('current')
                                updateStoreData(storeData.id)
                            }}>
                            <span className={classes.buttonText}>Regresar a mi panel</span>
                        </button> 
                        : <button
                            type="submit"
                            className={classes.button}
                            onClick={handleSubmitDelete}>
                            <span className={classes.buttonText}>Eliminar facturación</span>
                        </button>
                    }
                    {!success && <span className={classes.textBack} onClick={handleModalConfirmDelete}>No, quiero volver atrás</span>}                 
                    </div>)
                }
            </Modal>
            )}
        </>
    )
}

export default AddBillingForm