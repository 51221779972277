import {performanceMetric} from "../utils/utils";

const configFile = require('../config/url');

export const getLabel = async (channelId, orderNo, store) => {
  const accessToken = localStorage.getItem("Token")
  const bearerToken = `Bearer ${accessToken}`
  const labelData = { orderNo: orderNo, idChannel: channelId, idStore: store }

  let myHeaders = new Headers()
  myHeaders.append("Authorization", bearerToken)
  myHeaders.append("Content-Type", "text/plain")
  myHeaders.append("x-api-key", configFile.config.apiKey)
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(labelData),
    redirect: "follow",
  }

  try {
    const start = Date.now();
    const response = await fetch(`${configFile.config.ambiente}store/order/label`, requestOptions)
    performanceMetric("/store/order/label", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

    if (response.ok) {
      return await response.json()
    }
    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.message || 'Algo salió mal. Inténtalo de nuevo más tarde.')
    }
  } catch (error) {
    return error
  }
}

export const getOrderId = async (orderNo) => {
  let userEmail = localStorage.getItem("dtm");
  const accessToken = localStorage.getItem("Token");
  const bearerToken = `Bearer ${accessToken}`;

  if (userEmail.includes("+")) {
    const updatedEmail = userEmail.replace("+", "%2B");
    userEmail = updatedEmail;
  }
  let myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", bearerToken);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: myHeaders,
  }
  try {
    const start = Date.now();
    const response = await fetch(`${configFile.config.ambiente}store/order?orderNo=${orderNo}&user=${userEmail}`, requestOptions)
    performanceMetric("/store/order", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken)

    const data = await response.json()
    return data
  } catch (error) {
    return error
  }
};

export const getOrders = async (pageCount, limit, channelId, storeId, countryId, selectedDateFrom, selectedDateTo, searchOrderId) => {
  let userEmail = localStorage.getItem("dtm");
  const accessToken = localStorage.getItem('Token')
  const bearerToken = `Bearer ${accessToken}`
  if (userEmail.includes("+")) userEmail = userEmail.replace("+", "%2B");
  let url = `${configFile.config.ambiente}store/orders?qty=${limit}&user=${userEmail}&channel=${channelId}&store=${storeId}&page=${pageCount}&country=${countryId}&dateFrom=${selectedDateFrom}&dateTo=${selectedDateTo}`
  if (searchOrderId !== "") url = url + `&orderNo=${searchOrderId}`;

  let myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", bearerToken);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: myHeaders,
  }
  try {
    const start = Date.now();
    const response = await fetch(url, requestOptions)
    performanceMetric("/store/orders", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

    if (response.ok) {
      return await response.json()
    }
    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.message || 'Algo salió mal. Inténtalo de nuevo más tarde.')
    }
  } catch (error) {
    return error
  }
};

export const getFilters = async () => {
  let myHeaders = new Headers();
  const accessToken = localStorage.getItem('Token')
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append(
    "Authorization",
    `Bearer ${accessToken}`
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const start = Date.now();
    const response = await fetch(`${configFile.config.ambiente}dashboard/filtersorders`, requestOptions)
    performanceMetric("/dashboard/filtersorders", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

    if (response.ok) {
      return await response.json()
    }
    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.message || 'Algo salió mal. Inténtalo de nuevo más tarde.')
    }
  } catch (error) {
    return error
  }
};

export const putOrder = async (body) => {
  const accessToken = localStorage.getItem("Token");
  const bearerToken = `Bearer ${accessToken}`;
  let myHeaders = new Headers();
  myHeaders.append("x-api-key", configFile.config.apiKey);
  myHeaders.append("Authorization", bearerToken);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(body),
    redirect: "follow",
    headers: myHeaders,
  }

  try {
    const start = Date.now();
    const response = await fetch(`${configFile.config.ambiente}store/order`, requestOptions)
    performanceMetric("/store/order", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
    
    return response
  } catch (error) {
    return error
  }
}

export const putEnviameLabel = async (EnviameLabelBody) => {
  const accessToken = localStorage.getItem("Token")
  const bearerToken = `Bearer ${accessToken}`

  let myHeaders = new Headers()
  myHeaders.append("Authorization", bearerToken)
  myHeaders.append("Content-Type", "text/plain")
  myHeaders.append("x-api-key", configFile.config.apiKey)
  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(EnviameLabelBody),
    redirect: "follow",
  }

  try {
    const start = Date.now();
    const response = await fetch(`${configFile.config.ambiente}store/order/package`, requestOptions)
    performanceMetric("/store/order/package", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

    if (response.ok) return await response.json()
    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.message || 'Algo salió mal. Inténtalo de nuevo más tarde.')
    }
  } catch (error) {
    return error
  }
}
