import React from 'react'
import classes from "./CurrentBackoffice.module.css"
import ProfileHeader from 'components/UI/ProfileHeader'
import CardItem from 'components/UI/CardItem'

import spinnerGif from "../../assets/img/spinnerLogos.gif"
import empty from "../../assets/img/channelstore-emptymessage.png"
import iconInstance from "../..//assets/img/icons/iconInstance.svg"
import iconBsale from "../..//assets/img/icons/iconBsale.svg"

const CurrentBackoffice = ({loading, backToStoreDetail, myBillings, handleCurrent, handleRoute, parameters}) => {
  return (
    <div className={classes.wrapper}>
        <ProfileHeader backTo={backToStoreDetail}>Backoffice</ProfileHeader>
        <p className={classes.introductionText}>
        En esta sección encontrarás tus métodos de facturación  que se 
        encuentran activos. Desde aquí puedes controlar todo lo respectivo 
        con tu proceso de factura en tus diferentes canales de venta. 
        También puedes cambiar, editar o incluso eliminar el el método de 
        facturación.

        </p>
        {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
        {!loading && (
            <div className={classes.billings}>
                {myBillings.length === 0 && 
                    <div className={classes.empty} >
                        <img src={empty} className={classes.imgEmpty} onClick={() => handleRoute('add')}/>
                        <span className={classes.textEmpty}>No hay métodos de facturación activos</span>
                    </div>   
                }
                {myBillings.filter((b)=> b.billing === "Instance").length > 0 &&
                    <CardItem
                        name="Instance"
                        icon={iconInstance}    
                        onClick={() => handleRoute('editinstance')}
                    />
                }
                {myBillings.filter((b) => b.billing === "Bsale").length > 0 &&
                    <CardItem
                        name="Bsale"
                        icon={iconBsale}    
                        onClick={() => handleRoute('editbsale')}
                    />
                }
                {parameters.map( (billing, i) => (
                    myBillings.filter((b) => b.billingCompanyId === billing.id).length > 0 && (
                        <CardItem 
                            key={i}
                            name={billing.name.charAt(0).toUpperCase() + billing.name.slice(1)}
                            icon={billing.config_mtd?.icon}
                            onClick={() => {
                                handleRoute('editform')
                                handleCurrent(billing.id)
                            }}
                        />
                    )
                ))
                    
                }
            </div>
        )}
        <button className={classes.button} onClick={() => handleRoute('add')}>
            <span className={classes.buttonText}>Agregar un nuevo  método de facturación</span>
        </button>
    </div>
  )
}

export default CurrentBackoffice