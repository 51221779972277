import { forwardRef } from "react";
import Alert from "react-bootstrap/Alert"

const ErrorAlert = forwardRef((props, ref) => {
  return (
    <>
      <Alert
        ref={ref}
        variant="danger"
        style={{ position: "fixed", left: "60%", zIndex: "13",bottom:'70%' }}
        onClose={props.onClose}
        dismissible
      >
        <Alert.Heading>oops!</Alert.Heading>
        <p>{props.errorAlertMessage}</p>
      </Alert>
    </>
  );
});
export default ErrorAlert;
