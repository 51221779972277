import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import {
    Box,
    Card,
    CardContent,
    Button
} from '@mui/material'
import check from "../../../../assets/img/icons/checkGreen.png"
import Swal from "sweetalert2";
import WelcomeStep from './ModalSteps/WelcomeStep';
import KickOffStep from './ModalSteps/KickOffStep';
import CredentialsStep from './ModalSteps/CredentialsStep';
import SetupStep from './ModalSteps/SetupStep';
import PrimeraVentaStep from './ModalSteps/PrimeraVentaStep';

import Error from '../../../../assets/img/onboarding/Error.png'
import Close from "../../../../assets/img/icons/close_black.png"
import { patchOnboardingStep, getStoreDetails } from '../../../../services/stores'


import classes from "./OnboardingClientView.module.css";


const OnboardingStep = ({ identifier, text, clientDetails, actualStep, isMobile, calculateActualStep, parametersChannels }) => {

    const [complete, setComplete] = useState(false)
    const [daySent, setDaySent] = useState(null)
    const [hourSent, setHourSent] = useState(null)

    const [client, setClient] = useState(clientDetails)

    const [displayBienvenidaModal, setDisplayBienvenidaModal] = useState(false);
    const toggleBienvenidaModal = () => setDisplayBienvenidaModal(!displayBienvenidaModal);

    const [displayKickOffModal, setDisplayKickOffModal] = useState(false);
    const toggleKickOffModal = () => setDisplayKickOffModal(!displayKickOffModal);

    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const toggleCredentialsModal = () => setDisplayCredentialsModal(!displayCredentialsModal);

    const [displaySetupModal, setDisplaySetupModal] = useState(false);
    const toggleSetupModal = () => setDisplaySetupModal(!displaySetupModal);

    const [displayPrimeraVentaModal, setDisplayPrimeraVentaModal] = useState(false);
    const togglePrimeraVentaModal = () => setDisplayPrimeraVentaModal(!displayPrimeraVentaModal);

    const id = identifier

    useEffect(() => {

        try {
            let stepData = client?.onboarding_steps?.find(e => e.id === identifier)
            if (stepData?.tasks) {
                setComplete(Object.keys(stepData?.tasks).every(key => {
                    if (key === 'kam') return true;
                    return stepData?.tasks[key] === true;
                }))
            }
            setDaySent(null)
            let emailData = client?.emails_sent.find(e => e.id === identifier ||
                ((e.id === "Setup v1" || e.id === "Setup v2") && identifier === "SetUp") || (e.id === "Primera venta" && identifier === "PrimeraVenta"))
            if (emailData) {
                let date = new Date(emailData.date_sent)
                setDaySent(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)
                setHourSent(`${date.getHours()}:${date.getMinutes()}`)
            }
        } catch (error) {
            Swal.fire({
                title: 'Error..',
                text: `cargando los datos del cliente`,
                imageWidth: 100,
                imageHeight: 150,
                imageUrl: Error,
                closeButtonHtml: `<img src="${Close}" />`
            })
        }

    }, [id])

    const editOnboardingSteps = (id, tasks) => {
        try {
            client.onboarding_steps.find(e => e.id === id).tasks = tasks
            const response = patchOnboardingStep(client.id, client.onboarding_steps)

            const stepData = client.onboarding_steps.find(e => e.id === id).tasks

            if (Object.keys(stepData).every(key => {
                if (key === 'kam') return true;
                return stepData[key] === true;
            })) {
                setComplete(true)
                calculateActualStep(client)
            }

        } catch (error) {
            console.log(error)
        }

    }



    const handleClickCard = async () => {

        if (client?.onboarding_steps) {
            calculateActualStep(client)
            setClient(client)
            let stepData = client.onboarding_steps?.find(e => e.id === identifier)
            if (stepData?.tasks) {
                setComplete(Object.keys(stepData?.tasks).every(key => {
                    if (key === 'kam') return true;
                    return stepData?.tasks[key] === true;
                }))
            }


            if (identifier === "Bienvenida")
                toggleBienvenidaModal()

            if (identifier === "KickOff")
                toggleKickOffModal()

            if (identifier === "Credenciales")
                toggleCredentialsModal()

            if (identifier === "SetUp")
                toggleSetupModal()

            if (identifier === "PrimeraVenta")
                togglePrimeraVentaModal()
        }


    }

    return (
        <>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                width:"100%"

            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '10px',
                    mr: '6px'
                }}>
                    <Typography fontFamily='Montserrat'
                        align='center'
                        style={{ fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                            __html: daySent
                                ? `Enviado el ${daySent}<br />a las ${hourSent}`
                                : "Pendiente",
                        }}
                    >
                        {/* {daySent ? `Enviado el ${daySent}<br />a las ${hourSent}` : "Pendiente"} */}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        '& > :not(style)': { mt: 2, mb: 2 }
                    }}
                >


                    <Card onClick={handleClickCard}
                        sx={{
                            pt: "10px",
                            width: !isMobile ? '90%' : '200px',
                            border: '1px solid #dcdcdc ',
                            '&:hover': {
                                cursor: 'pointer'
                            },
                            overflow: 'hidden',
                            maxHeight: '83px',
                            minHeight: '83px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                        <CardContent
                            id="cardContent"
                            sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}
                        >
                            <Typography align='center' style={{ color: complete ? "#D3D3D3" : "black", fontSize: '15px', fontFamily: 'Montserrat' }} ><b>{text}</b></Typography>
                        </CardContent>
                    </Card>
                    {complete ?
                        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '15%', bottom: '155px', zIndex: 1 }}>
                            <img src={check} alt="check" />
                        </div> : <></>
                    }

                    <Box sx={{
                        display: 'flex',
                        height: '70px',
                        width: !isMobile ? "90%" : "200px",
                        marginLeft: !isMobile ? "0" : "-30px"
                    }}>
                        {identifier === "Bienvenida" ? <ListBienvenida /> : <></>}
                        {identifier === "KickOff" ? <ListKickOff /> : <></>}
                        {identifier === "Credenciales" ? <ListCredenciales /> : <></>}
                        {identifier === "SetUp" ? <ListSetup /> : <></>}
                        {identifier === "PrimeraVenta" ? <ListPrimeraVenta /> : <></>}
                    </Box>

                    <Button sx={{ width: !isMobile ? "90%" : "200px", fontSize: "12px", borderRadius: "10px" }} disabled={true}  >
                        <b>{daySent ? "Correo Enviado" : "Automatico"}</b>
                    </Button>


                </Box>

            </Box>
            {displayBienvenidaModal &&
                <WelcomeStep
                    displayBienvenidaModal={displayBienvenidaModal}
                    toggleBienvenidaModal={toggleBienvenidaModal}
                    clientDetails={client}
                    editOnboardingSteps={editOnboardingSteps}
                />
            }

            {displayKickOffModal &&
                <KickOffStep
                    displayKickOffModal={displayKickOffModal}
                    toggleKickOffModal={toggleKickOffModal}
                    clientDetails={client}
                    actualStep={actualStep}
                    editOnboardingSteps={editOnboardingSteps}
                />
            }

            {displayCredentialsModal &&
                <CredentialsStep
                    displayCredentialsModal={displayCredentialsModal}
                    toggleCredentialsModal={toggleCredentialsModal}
                    clientDetails={client}
                    actualStep={actualStep}
                    editOnboardingSteps={editOnboardingSteps}
                />
            }

            {displaySetupModal &&
                <SetupStep
                    displaySetupModal={displaySetupModal}
                    toggleSetupModal={toggleSetupModal}
                    clientDetails={client}
                    actualStep={actualStep}
                    editOnboardingSteps={editOnboardingSteps}
                    parametersChannels={parametersChannels}
                />
            }

            {displayPrimeraVentaModal &&
                <PrimeraVentaStep
                    displayPrimeraVentaModal={displayPrimeraVentaModal}
                    togglePrimeraVentaModal={togglePrimeraVentaModal}
                    clientDetails={client}
                    actualStep={actualStep}
                    editOnboardingSteps={editOnboardingSteps}
                />
            }
        </>


    )
};
const ListBienvenida = () => {
    return (
        <Box sx={{ mb: 4.1, mr: 6 }}>
            <ul>
                <li style={{ fontSize: '11px' }}>Correo Bienvenida</li>
            </ul>
        </Box>
    )
}

const ListKickOff = () => {
    return (
        <Box sx={{ mr: 2 }}>
            <ul>
                <li style={{ fontSize: '11px' }}>Descarga de maestra</li>
                <li style={{ fontSize: '11px' }}>Agendamiento</li>
                <li style={{ fontSize: '11px' }}>Recordatorio</li>
            </ul>
        </Box>
    )
}

const ListCredenciales = () => {
    return (
        <Box sx={{ mb: 2, mr: 1 }}>
            <ul>
                <li style={{ fontSize: '11px' }}>Correo de Credenciales</li>
            </ul>
        </Box>
    )
}
const ListSetup = () => {
    return (
        <Box sx={{ mb: 4, mr: 4 }}>
            <ul>
                <li style={{ fontSize: '11px' }}>Correo de Set-up</li>
            </ul>
        </Box>
    )
}

const ListPrimeraVenta = () => {
    return (
        <Box sx={{ mb: 4, ml: 2 }}>
            <lu>
                <li style={{ fontSize: '11px' }}>Correo de Primera venta</li>
                <li style={{ fontSize: '11px' }}>Correo de CSAT</li>
            </lu>
        </Box>
    )
}


export default OnboardingStep

