//react components
import React, {useEffect} from 'react'

//librarys
import {
    Box,
    IconButton, Typography, Button, Grid, useMediaQuery
} from "@mui/material";

import BoxImages from './BoxImages';
import GeneralData from './GeneralData';
import PublicationHealthcheck from './PublicationHealthcheck';
import PublicationBuybox from './PublicationBuybox';
import PublicationGraphs from './PublicationGraphs';

const ModalDetailsBody = ({ publicationData, origin, createAlert, isKit, handleShowAlert, CustomTooltip }) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    return (
        <Grid container display="flex" sx={{ mt: "20px" }}>
            <Grid 
                item
                xs={12}
                sm={isTablet ? 6 : 8}
                md={isTablet ? 6 : 8}
                lg={isTablet ? 6 : 8}
                xl={isTablet ? 6 : 7.9}
                sx={{
                    flexBasis: isMobile ? '100%' : 'auto',
                    maxWidth: isMobile ? '100%' : 'none',
                }} 
            >
                <BoxImages
                    images={publicationData.images}
                    origin={origin}
                />
                {origin === "products" &&
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            p: "20px",
                            border: "1px solid #D3D3D3",
                            borderRadius: "17px",
                            minWidth: isTablet ? '300px' : isMobile ? '200px' : '469px',
                            maxWidth: "469px",
                            minHeight: "243px",
                            mt: "20px"
                        }}
                    >
                        <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: "#D3D3D3" }}>
                            {"Descripción larga de publicación"}
                        </Typography>

                        <Typography fontFamily='Montserrat' fontSize="15px" sx={{ mt: "15px", whiteSpace: 'pre-line' }}>
                            {publicationData.description}
                        </Typography>
                    </Box>
                }
                {origin === "publications" &&
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",                            
                                border: "1px solid #D3D3D3",
                                borderRadius: "17px",
                                minWidth: isTablet ? '300px' : isMobile ? '200px' : '469px',
                                maxWidth: "469px",
                                height: "260px",
                                overflow: "hidden",
                                mt: "20px"
                            }}
                        >
                            <PublicationGraphs
                                salesXUnits={publicationData.salesXUnits} 
                                trafficXConversion={publicationData.trafficXConversion}
                                priceXSales={publicationData.priceXSales}
                                purchaseExperience={publicationData.purchaseExperience}
                                origin={origin}
                                channelId={publicationData.channelId}
                                CustomTooltip={CustomTooltip}
                                statusPublication={publicationData.statusPublication}
                                hasActualSalesData={publicationData.hasActualSalesData}
                                hasActualConversionData={publicationData.hasActualConversionData}
                                hasActualPriceData={publicationData.hasActualPriceData}
                            />

                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",                            
                                border: "1px solid #D3D3D3",
                                borderRadius: "17px",
                                minWidth: isTablet ? '300px' : isMobile ? '200px' : '469px',
                                maxWidth: "469px",
                                minHeight: "260px",
                                maxHeight: "400px",
                                overflow: "hidden",
                                mt: "20px"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    p: "20px",
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "auto",
                                }}
                            >
                                <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: "#D3D3D3" }}>
                                    {"Descripción larga de publicación"}
                                </Typography>

                                <Typography fontFamily='Montserrat' fontSize="15px" sx={{ mt: "15px", whiteSpace: 'pre-line' }}>
                                    {publicationData.description}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
            </Grid>

            <Grid 
                item
                xs={12}
                sm={isTablet ? 6 : 4}
                md={isTablet ? 6 : 4}
                lg={isTablet ? 6 : 4}
                xl={isTablet ? 6 : 4}
                sx={{
                    flexBasis: isMobile ? '100%' : 'auto',
                    maxWidth: isMobile ? '100%' : 'none',
                    paddingLeft: isTablet ? '30px' : isMobile ? '0px' : 'none'
                }} 
            >
                <GeneralData
                    publicationData={publicationData}
                    origin={origin}
                    createAlert={createAlert}
                    isKit={isKit}
                    handleShowAlert={handleShowAlert}
                    CustomTooltip={CustomTooltip}
                />
                {origin === "products" &&
                    <ProductAttributes
                        publicationData={publicationData}
                    />
                }

                {origin === "publications" &&
                    <>
                        {publicationData.buyboxHealthcheck.hasBuybox && (
                            <PublicationBuybox
                                buyboxData={publicationData.buyboxHealthcheck.buybox}
                                publicationChannel={publicationData.channel}
                                CustomTooltip={CustomTooltip}
                            />
                        )}
                        {publicationData.buyboxHealthcheck.hasHealthcheck && (
                            <PublicationHealthcheck
                                healthcheckData={publicationData.buyboxHealthcheck.healthcheck} 
                                CustomTooltip={CustomTooltip}                               
                            />
                        )}
                    </>
                }

            </Grid>
        </Grid>
    )
}

export default ModalDetailsBody