import { useState, useEffect, useRef } from "react";
import "./personalize-store.css";
import {
  Card,
  // Row,
  Col,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Container,
  FormCheck,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import addImage from "../../assets/img/add-image.png";
import deleteImage from "../../assets/img/delete-image.png";
import imagePreview from "../../assets/img/image-preview.png";
import CheckboxDropdown from "components/CheckboxDropdown/CheckboxDropdown";
import SplashScreen from "components/UI/splash-screen";
import {performanceMetric} from "../../utils/utils";
var configFile = require("../../config/url");
const PersonalizeClientStore = (props) => {
  const [showeditSuccessfulModal, setshoweditSuccessfulModal] = useState(false);
  const [showEditStoreDataModal, setshowEditStoreDataModal] = useState(false);
  const [selectedCategoryId, setselectedCategoryId] = useState("");
  
  const [corporateName, setCorporateName] = useState(
    props.companyData.company_data.company_name
  );
  const [base64logo, setbase64logo] = useState("");
  const [base64banner, setbase64banner] = useState("");
  const [storeId, setstoreId] = useState(props.id);
  const [mail, setMail] = useState(
    props.companyData.company_data.representative_email
  );
  // useState(props.companyData.storeData.email_color);
  const [storeName, setstoreName] = useState(props.storeName);
  const [bannerpreview, setbannerpreview] = useState("");
  const [banner, setbanner] = useState(
    props.companyData.company_data.company_banner
  );
  const [preview, setpreview] = useState("");
  const [rut, setRut] = useState(props.companyData.company_data.company_rut);
  const [address, setAddress] = useState(
    props.companyData.company_data.company_address
  );
  const [name, setName] = useState(
    props.companyData.company_data.representative_name
  );
  const [phone, setPhone] = useState(
    props.companyData.company_data.representative_phone
  );
  const [logo, setlogo] = useState(props.companyData.company_data.company_logo);
  const [emailColor, setemailColor] = useState(
    props.companyData.storeData.email_color
  );
  const [emailText, setemailText] = useState(
    props.companyData.storeData.email_text
  );
  const [isLoading, setisLoading] = useState(false);
  const [allCategories, setallCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [Categories, setCategories] = useState(
    props.companyData.storeData.categories
  );
  const [showLogoPreview, setshowLogoPreview] = useState(false);
  const [showBannerPreview, setshowBannerPreview] = useState(false);
  const toggle2 = () => setshowLogoPreview(!setshowLogoPreview);
  const toggle3 = () => setshowBannerPreview(!setshowBannerPreview);
  const toggle4 = () => setshowEditStoreDataModal(!setshowEditStoreDataModal);
  const toggle5 = () => showeditSuccessfulModal(!setshoweditSuccessfulModal);
  const editModalHandler = () => {
    setshowEditStoreDataModal(true);
  };
  const imageRef = useRef(null);
  const bannerRef = useRef(null);
  useEffect(() => {
    getCategories();
  }, []);


  const imageChange = (event) => {
    imageRef.current.click();


  };
  const bannerChange = (event) => {
    bannerRef.current.click();


  };
  const LogoPreviewModalHandler = () => {
    setshowLogoPreview(true);
  };
  const BannerPreviewModalHandler = () => {
    setshowBannerPreview(true);
  };
  const selectCategoriesHandler = (event) => {
  
    let selectedCategory = event;
 
    const selectedCategoryId = selectedCategory.map((item) => {
      return item.id;
    });
   
    setselectedCategoryId(selectedCategoryId);
  };
  const getCategories = async () => {
    setisLoading(true);
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    var myHeaders = new Headers();
      myHeaders.append('x-api-key', configFile.config.apiKey);

    myHeaders.append(
      "Authorization",
       bearerToken
    );
    myHeaders.append("Content-Type", "text/plain");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const start = Date.now();

    await fetch(
        `${configFile.config.ambiente}dashboard/category?id=0`,
      requestOptions
    )
      .then((response) => {
        performanceMetric(`/dashboard/category`, "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        return response.text()
      })
      .then((result) => {
       
        let resultCategories = JSON.parse(result);
        let selectedCatego = [];
        for (let i = 0; i <= Categories.length - 1; i++) {
       

          let storeCategories = resultCategories.message.filter((item) => {
            return item.id === Categories[i];
          });
          selectedCatego.push(storeCategories);
        }

        var flattened = [].concat.apply([], selectedCatego);
        let MappedSelectedCategories = flattened.map((item) => {
          return [{ id: item.id, category: item.category }];
        });
        let flattenedMappedCategory = [].concat.apply(
          [],
          MappedSelectedCategories
        );

       

        setallCategories(JSON.parse(result).message);
        setisLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setbase64logo(reader.result);
 
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function getBase64Banner(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setbase64banner(reader.result);
      // setbase64logo(reader.result);
   
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    getBase64(fileObj);
    if (event.target.files && event.target.files.length > 0) {
    
      setpreview(event.target.files[0]);
      setlogo(event.target.files[0].name);
      //setselectedImageLink(e.target.files[0].name);
    }
    if (!fileObj) {
      return;
    }
  };

  const handleBannerChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    getBase64Banner(fileObj);
    if (event.target.files && event.target.files.length > 0) {
     
      setbannerpreview(event.target.files[0]);
      setbanner(event.target.files[0].name);
      //setselectedImageLink(e.target.files[0].name);
    }
    if (!fileObj) {
      return;
    }
  };

  const editStoreDataHandler = (event) => {
    let storeDetails = {};

    event.preventDefault();

    if (
      logo.includes("https://instancebucket") &&
      !banner.includes("https://instancebucket")
    ) {
      if (selectedCategoryId === "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_banner: base64banner,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
          },
        };
      }
      if (selectedCategoryId !== "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_banner: base64banner,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
            categories: selectedCategoryId,
          },
        };
      }
    }

    if (
      !logo.includes("https://instancebucket") &&
      banner.includes("https://instancebucket")
    ) {
      if (selectedCategoryId === "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_logo: base64logo,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
          },
        };
      }
      if (selectedCategoryId !== "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_logo: base64logo,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
            categories: selectedCategoryId,
          },
        };
      }
    }
    if (
      !logo.includes("https://instancebucket") &&
      !banner.includes("https://instancebucket")
    ) {
      if (selectedCategoryId === "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_logo: base64logo,
            company_banner: base64banner,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
          },
        };
      }
      if (selectedCategoryId !== "") {
        storeDetails = {
          tienda: storeName,
          company_data: {
            company_logo: base64logo,
            company_banner: base64banner,
          },
          store_data: {
            email_color: emailColor,
            email_text: emailText,
            categories: selectedCategoryId,
          },
        };
      }
    }
    if (
      logo.includes("https://instancebucket") &&
      banner.includes("https://instancebucket")
    ) {
      if (selectedCategoryId === "") {
        storeDetails = {
          tienda: storeName,

          store_data: {
            email_color: emailColor,
            email_text: emailText,
          },
        };
      }
      if (selectedCategoryId !== "") {
        storeDetails = {
          tienda: storeName,

          store_data: {
            email_color: emailColor,
            email_text: emailText,
            categories: selectedCategoryId,
          },
        };
      }
    }
    //    if(!banner.includes('https://instancebucket')){
    //     storeDetails = {
    //      tienda: storeName,
    //     company_data: {
    //       company_name: corporateName,
    //       company_rut: rut,
    //       company_address: address,
    //       representative_name: name,
    //       representative_email: mail,
    //       representative_phone: phone,
    //       // company_logo: base64logo,
    //          company_banner: base64banner,
    //     },
    //     store_data: {

    //      email_color: emailColor,
    //       email_text:emailText,
    //        categories:selectedCategoryId,
    //     },
    //   };
    //  }
    //  if(!logo.includes('https://instancebucket') && !banner.includes('https://instancebucket')){
    //   storeDetails = {
    //    tienda: storeName,
    //   company_data: {
    //     // company_name: corporateName,
    //     // company_rut: rut,
    //     // company_address: address,
    //     // representative_name: name,
    //     // representative_email: mail,
    //     // representative_phone: phone,
    //      company_logo: base64logo,
    //      company_banner: base64banner,
    //   },
    //   // store_data: {

    //   //  email_color: emailColor,
    //   //   email_text:emailText,
    //   //    categories:selectedCategoryId,
    //   // },
    // };
    // }
   
    let accessToken = localStorage.getItem("Token");
    let bearerToken = `Bearer ${accessToken}`;
   

   

    var myHeaders = new Headers();
   
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append("Authorization", bearerToken);
    myHeaders.append("Content-Type", "text/plain");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(storeDetails),
      redirect: "follow",
    };

    fetch(`${configFile.config.ambiente}store?id=${storeId}`, requestOptions)
      .then((response) => {
        performanceMetric(`/store`, "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        return response.text()
      })
      .then((result) => {
        let Storestatus = JSON.parse(result);
     
        if (typeof Storestatus.message === "number") {
          setshowEditStoreDataModal(false);
          setshoweditSuccessfulModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigateToAllStoreViewHandler = () => {
    props.displayStoreHandler();
  };
  return (
    <>
      {isLoading && <SplashScreen />}
      <div id="editHeader">
        <h4
          id="edittribu"
          style={{
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "24px",
            textAlign: "center",
            marginLeft: "20px",
            marginBottom: "5px",
          }}
        >
          <button
            onClick={navigateToAllStoreViewHandler}
            id="bttnSubmit"
            type="button"
            style={{
              justifyContent: "flex-start",
              // float:'left',
              backgroundColor: "#051B2F",
              textAlign: "center",
              color: "white",
              // width: "296px",
              // height: "64px",
              padding: "10px 20px",
              borderRadius: "50%",
              color: "#FFFFFF",
              marginLeft: "1em",
              marginRight: "5em",
              textTransform: "none",
              fontWeight: "bold",
              border: "0",
            }}
          >
            &#60;
          </button>
          {/* <span>&#60;&nbsp;&nbsp;</span> */}
          Personalizar tienda
        </h4>
      </div>
      <p style={{ fontSize: "12px", fontWeight: "500", marginTop: "20px" }}>
        En esta sección puedes agregar todo lo relacionado con la parte visual,
        diseño y categorización de tu tienda. Esta información nos servirá para
        la creación de correos, creación de vistas e identificación de tu marca.
      </p>
      <Form>
        <FormGroup>
          <FormLabel
            id="title-label-datos-tributarios"
            style={{ fontWeight: "bold", color: "black", marginTop: "10px" }}
          >
            {" "}
            Logo{" "}
          </FormLabel>
          <br />
          <div style={{ display: "flex" }}>
            <FormControl
              type="text"
              value={logo}
              placeholder="Ej. logo.png "
              className="input-taxdata"
            />
            <div>
              <img
                src={imagePreview}
                style={{ padding: "5px", marginTop: "5px" }}
                onClick={LogoPreviewModalHandler}
              />
            </div>
            <div>
              <img
                onClick={imageChange}
                src={addImage}
                style={{ padding: "5px", marginTop: "5px" }}
              />
              <input
                style={{ display: "none" }}
                ref={imageRef}
                type="file"
                onChange={handleFileChange}
              />
            </div>
            <div>
              <img
                src={deleteImage}
                style={{ padding: "5px", marginTop: "5px" }}
                onClick={() => {
                  setlogo("");
                }}
              />
            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <FormLabel
            id="title-label-datos-tributarios"
            style={{ fontWeight: "bold", color: "black" }}
          >
            {" "}
            Banner{" "}
          </FormLabel>
          <div style={{ display: "flex" }}>
            <FormControl
              type="text"
              value={banner}
              placeholder="Ej. logo.png "
              className="input-taxdata"
            />
            <div>
              <img
                src={imagePreview}
                style={{ padding: "5px", marginTop: "5px" }}
                onClick={BannerPreviewModalHandler}
                //   onClick={() => {
                //     setSelectedImage("");
                //   }}
              />
            </div>
            <div>
              <img
                onClick={bannerChange}
                src={addImage}
                style={{ padding: "5px", marginTop: "5px" }}
              />
              <input
                style={{ display: "none" }}
                ref={bannerRef}
                type="file"
                onChange={handleBannerChange}
              />
            </div>
            <div>
              <img
                src={deleteImage}
                style={{ padding: "5px", marginTop: "5px" }}
                //   onClick={() => {
                //     setSelectedImage("");
                //   }}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <FormLabel
            id="title-label-datos-tributarios"
            style={{ fontWeight: "bold", color: "black" }}
          >
            {" "}
            Color representativo de tu tienda{" "}
          </FormLabel>
          <div style={{ display: "flex" }}>
            <input
              type="color"
              class="form-control form-control-color"
              id="exampleColorInput"
              value={emailColor}
              // value={color}
              onChange={(e) => setemailColor(e.target.value)}
              // onChange={(e) => setColor(e.target.value)}
              title="Choose your color"
              style={{ margin: "10px" }}
            ></input>
            <FormControl
              style={{ width: "300px" }}
              type="text"
              placeholder="#051B2F"
              value={emailColor}
              name="color"
              className="input-taxdata"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <FormLabel
            id="title-label-datos-tributarios"
            style={{ fontWeight: "bold", color: "black" }}
          >
            {" "}
            Texto para personalizar tu correo de envío DTE:{" "}
          </FormLabel>
          <div style={{ display: "flex" }}>
            <FormControl
              id="mailText"
              as="textarea"
              rows={4}
              placeholder="Personaliza el texto que aparecerá en el correo que envías a tus consumidores. Haz la prueba escribiendo aquí y previsualizando tu correo en el lado drecho"
              name="text"
              // id="input-text"
              value={emailText}
              onChange={(event) => setemailText(event.target.value)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <FormLabel
            id="title-label-datos-tributarios"
            style={{ fontWeight: "bold", color: "black" }}
          >
            {" "}
            Categorías de tu tienda{" "}
          </FormLabel>

          <CheckboxDropdown
            defaultValue={Categories}
            placeholder="Selccione un categoría"
            options={allCategories}
            handleSelectChange={selectCategoriesHandler}
            // // defaultValue={props.profileInfo.stores}
            //  defaultValue={[
            //   {id: 2, category: 'Tecnología'},
            //   {id: 15, category: 'Industrias'}]}
            // defaultValue={Categories}
            // defaultValue={[{id:0,category:'hello'},{id:1,category:'hello'}]}
          />
        </FormGroup>
        <div class="text-center">
          <button
            onClick={editModalHandler}
            // id="bttnSubmit"
            type="button"
            style={{
              backgroundColor: "#051B2F",
              textAlign: "center",
              color: "white",

              width: "296px",
              height: "64px",
              // padding: "22px 81px",
              borderRadius: "20px",
              color: "#FFFFFF",
              marginLeft: "1em",
              textTransform: "none",
              fontWeight: "bold",
              border: "0",
              marginTop: "2em",
              fontSize: "18px",
            }}
          >
            Guardar Cambios
          </button>
        </div>
      </Form>

      {/* LOGO PREVIEW MODAL */}
      {showLogoPreview && (
        <Modal isOpen={showLogoPreview} toggle={toggle2} size="lg">
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle2}
              >
                x
              </button>
            </div>
          </ModalHeader>
          <h3
            style={{ textAlign: "center", fontSize: "22px", fontWeight: "700" }}
          >
            Logo Preview
          </h3>
          <div
            className="text-center"
            style={{ height: "400px", border: "none" }}
          >
            {logo.includes("https") && (
              <iframe
                src={`${logo}#zoom=70`}
                title="Logo"
                height="400px"
                width="300px"
                scrolling="no"
              ></iframe>
            )}
            {!logo.includes("https") && (
              <img
                // src={`${URL.createObjectURL(preview)}#zoom=70`}
                src={URL.createObjectURL(preview)}
                alt="Logo"
                height="400px"
                width="300px"
              />
            )}
          </div>
          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              onClick={toggle2}
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "296px",
                height: "64px",
                padding: "22px 81px",
                borderRadius: "33px",
                color: "#FFFFFF",
                marginLeft: "1em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              Cerrar
            </button>
            <br />
          </div>
          <br />
        </Modal>
      )}
      {/* BANNER PREVIEW MODAL */}
      {showBannerPreview && (
        <Modal isOpen={showBannerPreview} toggle={toggle3} size="lg">
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle3}
              >
                x
              </button>
            </div>
          </ModalHeader>
          <h3
            style={{ textAlign: "center", fontSize: "22px", fontWeight: "700" }}
          >
            Banner Preview
          </h3>
          <div
            className="text-center"
            style={{ height: "400px", border: "none" }}
          >
            {banner.includes("https") && (
              <iframe
                src={`${banner}#zoom=70`}
                title="Logo"
                height="400px"
                width="300px"
                scrolling="no"
              ></iframe>
            )}
            {!banner.includes("https") && (
              <img
                // src={`${URL.createObjectURL(preview)}#zoom=70`}
                src={URL.createObjectURL(bannerpreview)}
                alt="Logo"
                height="400px"
                width="300px"
              />
            )}
          </div>
          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              onClick={toggle2}
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "296px",
                height: "64px",
                padding: "22px 81px",
                borderRadius: "33px",
                color: "#FFFFFF",
                marginLeft: "1em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              Cerrar
            </button>
            <br />
          </div>
          <br />
        </Modal>
      )}
      {/* EDIT STOREDATA */}
      {showEditStoreDataModal && (
        <Modal isOpen={showEditStoreDataModal} toggle={toggle4} size="lg">
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle4}
              >
                x
              </button>
            </div>
          </ModalHeader>

          <h3
            style={{ textAlign: "center", fontSize: "22px", fontWeight: "700" }}
          >
            ¿Está seguro que desea editar esta información?
          </h3>

          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              onClick={editStoreDataHandler}
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "296px",
                height: "64px",
                padding: "22px 81px",
                borderRadius: "33px",
                color: "#FFFFFF",
                marginLeft: "1em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              Si,Seguro
            </button>
            <br />
          </div>
          <br />
        </Modal>
      )}

      {/* EDIT SUCCESSFUL MODAL */}
      {showeditSuccessfulModal && (
        <Modal isOpen={showeditSuccessfulModal} toggle={toggle3} size="lg">
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle3}
              >
                x
              </button>
            </div>
          </ModalHeader>
          <h3
            style={{ textAlign: "center", fontSize: "22px", fontWeight: "700" }}
          >
            información editada correctamente
          </h3>
          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              onClick={() => {
                setshoweditSuccessfulModal(false);
              }}
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "296px",
                height: "64px",
                padding: "22px 81px",
                borderRadius: "33px",
                color: "#FFFFFF",
                marginLeft: "1em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              Entendido
            </button>
            <br />
          </div>
          <br />
        </Modal>
      )}
    </>
  );
};
export default PersonalizeClientStore;
