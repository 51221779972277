import React from 'react';
import CustomModal from '../../UI/CustomModal';
import classes from '../AlertsCenter.module.css';
import thirdModalStyles from '../../PublicationAlertModals/ThirdAlertModal.module.css';


const ThirdAlertModal = ({ 
    thirdModal, 
    toggleThirdModal,
    closeThirdModalCloseButton,
    loadingAlert,
    checkedEmails,
    isCheckedTerms,
    handleSaveChanges,
    handleAddEmailModalReturn,
    Plus,
    emails,
    EmailCheckbox,
    setCheckedEmails,
    TermAndConditions,
    setIsCheckedTerms,
    CircularProgress
  }) => {

    return (
        <CustomModal
            isOpen={thirdModal}
            toggle={toggleThirdModal}
            onClose={closeThirdModalCloseButton}
            title="Editar alerta"
            customStyles={{ box: thirdModalStyles.customModal }}
            customClassNames={{
                form: thirdModalStyles.modalForm,
                header: classes.crearAlertaParent,
                title: classes.crearAlerta,
                closeWrapper: classes.closeWrapper,
                closeIcon: classes.closeIcon,
                separatorContainer: thirdModalStyles.separatorContainer,
                separator: thirdModalStyles.separator,
                bodyContainer: thirdModalStyles.bodyContainer,
                footerContainer: thirdModalStyles.footerContainer,
                box: classes.afterElementNone
            }}
            footerActions={
                !loadingAlert && (
                    <div
                        className={`${thirdModalStyles.botonLogin} ${(checkedEmails?.length > 0 && isCheckedTerms) ? thirdModalStyles.enabled : ''}`}
                        onClick={(checkedEmails?.length > 0 && isCheckedTerms) ? handleSaveChanges : null}
                    >
                        <b className={thirdModalStyles.siguiente}>Guardar cambios</b>
                    </div>
                )
            }
        >
            <div className={thirdModalStyles.seleccionaDestinatarioContainer}>
                <div className={thirdModalStyles.seleccionaDestinatario}>Selecciona un destinatario</div>
            </div>
            <div className={thirdModalStyles.addEmailButtonContainer}>
                <div className={thirdModalStyles.addEmailButton}>
                    <div onClick={handleAddEmailModalReturn} className={thirdModalStyles.addEmailButtonElements}>
                        <img className={thirdModalStyles.plusIcon} src={Plus} />
                        <b className={thirdModalStyles.addEmail}>Agregar correo</b>
                    </div>
                </div>
            </div>
            <div className={thirdModalStyles.emailsContainer}>
                {emails && emails.map((email, index) => (
                    <EmailCheckbox 
                        key={index}
                        email={email}
                        checked={false}
                        checkedEmails={checkedEmails}
                        setCheckedEmails={setCheckedEmails}
                    />
                ))}
            </div>
            <div className={thirdModalStyles.terminosCondicionesContainer}>
                <div className={thirdModalStyles.terminosCondiciones}>
                    <TermAndConditions
                        isCheckedTerms={isCheckedTerms}
                        setIsCheckedTerms={setIsCheckedTerms}
                    />
                </div>
            </div>
            {loadingAlert && (
                <div className={classes.botonLoadingContainer}>
                    <div className={classes.botonLoading} disabled={true}>
                        <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                        />
                        <b >Cargando</b>
                    </div>
                </div>                  
            )}
        </CustomModal>
    );
};

export default ThirdAlertModal;
