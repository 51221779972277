import { useEffect,useState } from "react";
var configFile = require("../../config/url");
const GenerateEnviameLabel = ({ getEnviameLabel, purchaser, setisGeneratingEnviameLabel }) => {  

  useEffect(() => {
    putLabelEnviame()
  }, []);

  const putLabelEnviame = async () => {
      await getEnviameLabel (purchaser.store, purchaser.orderNo, purchaser.channel, purchaser.id_mtdi);
      setisGeneratingEnviameLabel(false)
  }

  return <></>;
};
export default GenerateEnviameLabel;
