import React, { useEffect, useState } from 'react'

import { Button, Box, Skeleton, CardContent, FormControlLabel, Switch, Card, Typography } from '@mui/material';
import Swal from 'sweetalert2'
import pendiente from '../../../../assets/img/onboarding/Pendiente.png'
import imgVerificacion from '../../../../assets/img/onboarding/Validacion.png'

//images
import felicidades from '../../../../assets/img/onboarding/felicidades.png'
import Error from '../../../../assets/img/onboarding/Error.png'
import Close from "../../../../assets/img/icons/close_black.png"
import { updateChannel } from 'services/channels';

//hay que editar el canales por onbordear
const ChannelSwitch = ({ 
    channelData,
    integrateNewChannel,
    storeData,
    handleOpenModalEditChannelIntegration,
    channelBeingModified
 }) => {

    const [checked, setChecked] = useState(!!(channelData?.channelConfig?.habilitado && !channelData.newChannel))

    useEffect(() => {
        setChecked(!!(channelData?.channelConfig?.habilitado && !channelData.newChannel))
    }, [channelData, storeData])


    const handleClickChannelCard = (e) => {
        if(!channelData?.channelParams?.config_mtd?.enabled){
            Swal.fire({
                title: 'Configuración del canal no disponible',
                text: "La configuración de integración no está disponible para el canal seleccionado",
                imageWidth: 200,
                imageHeight: 200,
                imageUrl: pendiente,
                closeButtonHtml: `<img src="${Close}" />`
            })
        }    
        else if (channelData.newChannel) {
            integrateNewChannel(channelData.channelParams)
        }
        else{
            handleOpenModalEditChannelIntegration(channelData, storeData)
        }
    }

    const handleClickChannelSwitch = async (e) => {
        e.stopPropagation()
        if(!channelData?.channelParams?.config_mtd?.enabled){
            Swal.fire({
                title: 'Configuración del canal no disponible',
                text: "La configuración de integración no está disponible para el canal seleccionado",
                imageWidth: 200,
                imageHeight: 200,
                imageUrl: pendiente,
                closeButtonHtml: `<img src="${Close}" />`
            })
        }    
        else if (channelData.newChannel) {
            integrateNewChannel(channelData.channelParams)
        }
        else if (channelData?.channelConfig?.habilitado) {
            await switchChannelEnabled("desactivar")
        }
        else {
            await switchChannelEnabled("activar")
        }

    }

    const switchChannelEnabled = async (operation) => {
        try {
            Swal.fire({
                title: '¿ Seguro que...',
                text: `Deseas ${operation} la integración`,
                imageWidth: 200,
                imageHeight: 200,
                imageUrl: imgVerificacion,
                showCloseButton:true,
                confirmButtonText: operation,
                closeButtonHtml: `<img src="${Close}" />`
              
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await updateChannel(storeData.id, [{ channel: channelData.channelParams.id, habilitado: !channelData.channelConfig.habilitado }])
                    if (response && response !== "Invalid parameters") {
                        channelData.channelConfig.habilitado = !channelData?.channelConfig?.habilitado
                        setChecked(channelData.channelConfig.habilitado)
                        Swal.fire({
                            title: 'Felicidades!',
                            text: `El canal fue ${operation === "activar" ? "activado" : "desactivado"} correctamente`,
                            imageWidth: 200,
                            imageHeight: 200,
                            imageUrl: felicidades,
                            closeButtonHtml: `<img src="${Close}" />`
                        })
                    } else {
                        setChecked(false)
                        Swal.fire({
                            title: 'Error..',
                            text: `Ocurrio un error al ${operation} el canal`,
                            imageWidth: 100,
                            imageHeight: 150,
                            imageUrl: Error,
                            closeButtonHtml: `<img src="${Close}" />`
                        })
                    }

                }
            })
        } catch (error) {
            Swal.fire({
                title: 'Error..',
                text: `Ocurrio un error inesperado`,
                imageWidth: 100,
                imageHeight: 150,
                imageUrl: Error,
                closeButtonHtml: `<img src="${Close}" />`
            }).then(async () => {
                setChecked((prevChecked) => !prevChecked);
            })
        }

    }




    return channelBeingModified != null && channelBeingModified.modifying && channelData?.channelParams?.id === channelBeingModified.channelId ?
        (<Skeleton
            width="90%"
            height="120px"
            sx={{
                borderRadius: "20px",
                border: "1px solid #dcdcdc"
            }}
        />)
    :
        (<Card onClick={(e) => handleClickChannelCard(e, "channelCard")} sx={{
            width: '90%',
            border: '1px solid #dcdcdc ',
            borderColor: channelData?.channelConfig?.habilitado ?'#06cbc1':'',
            '&:hover': {
                backgroundColor: '#F2F2F0',
                cursor: 'pointer'
            }
        }} >
            <CardContent id="cardContent" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {channelData ? <img id="cardContent" style={{ height: 40, width: 40, objectFit: 'contain', marginRight: "15px" }} src={channelData?.channelParams?.config_mtd.icon} /> : <></>}

                    {channelData?.newChannel ?
                        <>
                            <Typography fontFamily='Montserrat' id="cardContent" color="black" >{channelData?.channelParams?.canal}</Typography>

                        </>
                        :
                        <Typography fontFamily='Montserrat' id="cardContent" color="black" >{channelData?.channelParams?.canal}</Typography>
                    }
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt:1 }}>
                    <FormControlLabel
                        value="start"
                        control={
                            <Switch
                                name="channelSwitch"
                                color="primary"
                                onClick={(e) => handleClickChannelSwitch(e, "channelSwitch")}
                                checked={checked}
                            />}
                        labelPlacement="start"
                    />
                </Box>
            </CardContent>

        </Card>)
}

export default ChannelSwitch