//libraries
import React, { useState } from "react";

import { useMediaQuery, IconButton } from "@mui/material";

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Options from "../../../../assets/img/icons/Opciones.png";
import Swal from 'sweetalert2'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classes from "./displayEditClientStores.module.css";
import imgVerificacion from '../../../../assets/img/onboarding/Validacion.png'
import Collapse from '@mui/material/Collapse';
import Close from "../../../../assets/img/icons/close_black.png"
import { deleteStore } from "services/stores";
import { Box } from "@mui/material";
import { CircleFlag } from 'react-circle-flags'
//images
import felicidades from '../../../../assets/img/onboarding/felicidades.png'
import Error from '../../../../assets/img/onboarding/Error.png'


const CardStore = ({ client, getStoreDetailsHandler, toggleFirstModal, setCliente, setModalTittle, createAlert, windowWidth }) => {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const countrCodes = ["cl", "co", "pe", "mx"]
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handleCardClick = (event) => {
    if (event.target.id === "cardStore" || event.target?.textContent === client.name )
      getStoreDetailsHandler(client)

  };

  const handleMenuEdit = (event) => {
    handleClose()

    setCliente({
      idCliente: client.id,
      name: client?.name,
      namerlegal: client?.company_data?.representative_name,
      email: client?.company_data?.representative_email,
      phone: '',
      address: client?.company_data?.company_address,
      identity: client?.company_data?.company_rut,
      social_reason: client?.company_data?.company_name,
      type_of_selling: client?.company_data?.company_giro,
      model: '',
      kam_selected: client?.company_data?.kam_email || '',
      client_country: client?.paises[0],
      facturacion: client?.company_data?.company_services?.facturacion || false,
      bodega: client?.company_data?.company_services?.almacenamiento_bodega_instance || false,
      canales: client?.company_data?.company_services?.creacion_canales_de_venta || false,
      servicio: client?.company_data?.company_services?.servicio_al_cliente || false,
      channelsOnboarding: client?.canales_por_onbordear || [],
      contratFileName: client?.company_data?.company_contract?.location || null
    })
    setModalTittle("Editar Cliente")
    toggleFirstModal()

  }

  const handleMenuDelete = (event) => {
    try {
      handleClose()
      Swal.fire({
        title: '¿ Seguro que...',
        text: "Deseas desactivar este cliente",
        imageWidth: 200,
        imageHeight: 200,
        imageUrl: imgVerificacion,
        showCloseButton: true,
        confirmButtonText: 'Si, desactiva',
        closeButtonHtml: `<img src="${Close}" />`

      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteStore(client.id)
          if (response.status === 200) {
            createAlert("Adios, Bye, Tschüss, adieu, 再见 cliente eliminado.")
          } else {
            createAlert("Ocurrio un error al desactivar el cliente.")
          }


        }
      })
    } catch (error) {
      Swal.fire({
        title: 'Error..',
        text: `Ocurrio un error al desactivar el cliente`,
        imageWidth: 100,
        imageHeight: 150,
        imageUrl: Error,
      })
    }

  }

  const determineCardWidth = () => {
      try {
        if (isMobile){
          return "95%"
        }else{
          if (windowWidth < 1300)
           return "80%"
          else 
            return "93"
        }
      } catch (error) {
        console.log(error)
      }
  }

  return (
    <Card
      sx={{ margin: "10px", height: "88px", cursor:"pointer", width: determineCardWidth() }}
      id="cardStore" onClick={handleCardClick}
    >
      <CardContent  id="cardStore" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <Box id="cardName" sx={{ width: isMobile?"10%":"60%", alignItems: 'center' }}>
          <span style={{ fontSize: '15px' }} id="cardName" name="cardName" className={classes.cardTextName}><b>{client.name}</b></span>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', mr: isMobile?"-25px":"-10px" }}>
          <Box sx={{mt:"-5px"}}>
            <CircleFlag countryCode={countrCodes[client?.paises[0]-1]} width={"20px"} height={"20px"} />
          </Box>
        
          <IconButton aria-label="options" onClick={handleMenu} sx={{
           '&:hover': {
              backgroundColor: 'transparent',
            }, mt:"-12px"
          }}>
            <img src={Options} />
          </IconButton>

        </Box>

      </CardContent>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMenuEdit}>Editar Cliente</MenuItem>
        <MenuItem onClick={handleMenuDelete}>Desactivar Cliente</MenuItem>
      </Menu>

    </Card>
    


  )
}

export default CardStore