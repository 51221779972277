export const parsePostComboErrorMessage = (errorInternalCode) => {
    try {

        const internalCodeSplitted = errorInternalCode?.split(":") ?? ["server_error"]
        const errorCode = internalCodeSplitted[0]
        const errorFieldName = internalCodeSplitted[1]

        const errorMap = {
            server_error: "Existe un problema en el servidor. Inténtalo de nuevo más tarde.",
            data_array_not_empty: "Se debe crear al menos una promoción",
            products_empty: "La promoción debe tener por lo menos un producto",
            must_be_number: `Campo ${parseFieldNameComboError(errorFieldName)} debe ser numérico`,
            number_must_be_higher_than_zero: `Campo ${parseFieldNameComboError(errorFieldName)} debe ser mayor a cero`,
            promotion_exists: "Existe una promoción con el sku en la tienda señalada",
            ten_percent_rule: "El precio de descuento de un producto no puede ser menor al precio de compra del mismo menos 10%",
            invalid_discount_value: "El valor del descuento debe ser entre 0 y 100",
            invalid_product_distribution: "La cantidad de productos a pagar no puede ser menor a la cantidad de productos a obtener",
            single_product_promotion: "Este tipo de promoción solo acepta un producto",
            product_not_found: "No se encontró uno de los productos en la promoción",
            invalid_promotion_type: "Tipo de promoción inválido"
        }

        return errorMap[errorCode] || "Algo salió mal. Inténtalo de nuevo más tarde."
    } catch (error) {
        return err
    }
}

export const parseGetTextCompletionErrorMessage = (errorInternalCode) => {
    try {
        const ErrorDetail = errorInternalCode.includes("ai_service_error") ? errorInternalCode.split(":")[1] : errorInternalCode
        
        const errorMap = {
            server_error: "Existe un problema en el servidor. Inténtalo de nuevo más tarde.",
            prompt_id_empty: "Nombre del producto no puede estar vacío",
            prompt_params_not_found: "No se encontre el nombre del producto",
            user_input_required: "Nombre del producto no puede estar vacío",
            insufficient_quota: "Se ha superado la cuota mensual definido para la API de OpenAI",
            invalid_authenticacion: "Datos de autenticación incorrectos hacia la API de OpenAI",
            incorrect_api_key: "Credenciales invalidas",
            must_be_organization: "	La cuenta de OpenAI que está usando debe ser miembro de una organización",
            rate_limit: "Se ha superado el limite por solicitud",
            engine_overloaded: "Los servidores de OpenAI están con alto tráfico, intentar más tarde",
            unknown_error: "Error desconocido proveniente desde openai o no manejado"
        }

        return errorMap[ErrorDetail] || "Algo salió mal. Inténtalo de nuevo más tarde."
    } catch (error) {
        return error
    }
}

const parseFieldNameComboError = (fieldName) => {
    try {
        const fieldNameMap = {
            weight: "Peso",
            height: "Alto",
            length: "Largo",
            width: "Ancho",
            warranty: "Garantía",
        }

        return fieldNameMap[fieldName] || ""
    } catch (error) {
        return err
    }
}