
import errorIcon from "../../assets/img/errorPage.png";

function ErrorHandler(error) {

    return (
      <div style={{width:'95%',margin:'0'}}>
        <h3 style={{marginTop:'80px',textAlign:'center',fontWeight:'700',fontSize:'32px'}}>Lo sentimos, esta página no está disponible en este momento .</h3>
        {/* <p style={{marginBottom:'30px',marginTop:'0px',textAlign:'center',fontWeight:'600',fonttSize:'32px'}}>El link está roto, o tal vez la página fue removida. temporalmente</p> */}
        <p style={{marginBottom:'30px',marginTop:'0px',textAlign:'center',fontWeight:'600',fonttSize:'32px'}}>{error.error.message}</p>
        <img src={errorIcon} style={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
     <h5 style={{textAlign:'center',fontWeight:'700',fontSize:'15px',marginTop:'10px'}}>Intenta refrescar la página, o en su defecto intentarlo más tarde.</h5>
      </div>
    )
  }
  export default ErrorHandler;