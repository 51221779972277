//Libraries
import React, { useState } from 'react';
import Modal from 'reactstrap/es/Modal';

//MUI Libraries
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';

//Components
import CustomTooltip from '../../../components/UI/CustomTooltip';
import HeaderDetails from './HeaderDetails';
import ModalDetailsBody from './ModalDetailsBody';
import AlertSeller from '../../../components/UI/AlertSeller';

//Images
import NextModal from "../../../assets/img/icons/next-modal-white.svg";
import BackModal from "../../../assets/img/icons/back-modal-white.svg";

const ModalDetails = ({ displayModal, toggleModal, data, origin, toggleGeneral, createAlert }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [numPublication, setNumPublication] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertTime, setAlertTime] = useState(4500);

    const handleClose = () => {
        if (origin === "products") {
            toggleModal();
            toggleGeneral();
        } 

        else if (origin === "publications") {
            toggleModal();
        }
    }

    const handleEdit = () => {
        createAlert("Próximamente...")
    }

    const handleNext = () => {
        if (numPublication + 1 >= data.length) {
            setNumPublication(0)
        } else {
            setNumPublication(prevNum => prevNum + 1)
        }
    }

    const handleBack = () => {
        if (numPublication === 0) {
            setNumPublication(data.length - 1)
        } else {
            setNumPublication(prevNum => prevNum - 1)
        }
    }

    const handleShowAlert = (alertText, alertType) => { 
        setShowAlert(true);
        setAlertText(alertText);
        setAlertType(alertType);
    }

    return (
        <>
            {showAlert && <AlertSeller showAlert={showAlert} text={alertText} setShowAlert={setShowAlert} time={alertTime} type={alertType}/>}
            <Modal
                isOpen={displayModal}
                toggle={toggleModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                keyboard={false}
            >
                {/* Contenedor de grid para organizar los elementos */}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        gap: 6,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    {
                        data.length > 1 ?
                            <IconButton
                                onClick={handleBack}
                                sx={{
                                    gridColumn: 1,
                                    justifySelf: 'center',
                                    zIndex: 2000,
                                    borderRadius: '50%',
                                    padding: '8px',
                                    width: '40px',
                                    height: '100%',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <img src={BackModal} alt="Back" style={{ width: '100%', height: '100%' }} />
                            </IconButton> : <></>
                    }

                    {/* Contenido del modal */}
                    <Box
                        id="modal-content"
                        sx={{
                            gridColumn: 2,
                            position: 'relative',
                            maxWidth: isMobile ? '380px' : isTablet ? '600px' : '816px',
                            minWidth: isMobile ? '380px' : isTablet ? '600px' : '816px',
                            minHeight: '967px',
                            bgcolor: 'white',
                            boxShadow: 24,
                            p: 3,
                            zIndex: 1,
                            borderRadius: 4,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {
                            data.length > 1 ?
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '-60px',
                                        left: '-0.1%',
                                        bgcolor: 'white',
                                        pl: 2,
                                        borderRadius: "17px",
                                        minWidth: "262px",
                                        minHeight: "47px",
                                        display: "flex",
                                        alignItems: "center",
                                        zIndex: 2000,
                                    }}
                                >
                                    <Typography fontFamily='Montserrat' fontSize={"14px"} sx={{ whiteSpace: 'pre-line', fontWeight: 500 }}>
                                        <span>Viendo kit de SKU: <b>{data[0].sku}</b></span>
                                    </Typography>
                                </Box> : <></>
                        }

                        {data.length > 0 && data.map((unitData, index) => (
                            numPublication === index ? (
                                <React.Fragment key={unitData.sku}>
                                    <HeaderDetails
                                        channelIcon={unitData.iconChannel}
                                        url={unitData.url}
                                        warehouse={unitData.warehouse}
                                        stock={unitData.stock}
                                        alerts={unitData.alerts}
                                        suggestions={unitData.suggestions}
                                        idPublication={unitData.idPublication}  
                                        logistica={unitData.logistica}                                      
                                        translatedLogistica={unitData.translatedLogistica}   
                                        handleShowAlert={handleShowAlert}
                                        origin={origin}
                                        statusPublication={unitData.statusPublication}
                                        handleClose={handleClose}
                                        action={handleEdit}
                                        CustomTooltip={CustomTooltip}
                                    />
                                    <ModalDetailsBody
                                        publicationData={unitData}
                                        origin={origin}
                                        createAlert={createAlert}
                                        isKit={index === 0 && data.length > 1}
                                        handleShowAlert={handleShowAlert}
                                        CustomTooltip={CustomTooltip}
                                    />
                                </React.Fragment>
                            ) : null
                        ))}
                    </Box>

                    {
                        data.length > 1 ?
                            <IconButton
                                onClick={handleNext}
                                sx={{
                                    gridColumn: 3,
                                    justifySelf: 'center',
                                    zIndex: 2000,
                                    borderRadius: '50%',
                                    padding: '8px',
                                    width: '40px',
                                    height: '100%',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <img src={NextModal} alt="Next" style={{ width: '100%', height: '100%' }} />
                            </IconButton> : <></>
                    }
                </Box>
            </Modal>
        </>
    );
};

export default ModalDetails;
