import React from 'react'
import classes from './ColumnList.module.css'

import close from '../../assets/img/icons/close-white.svg'

function ColumnList({ configColumns , setConfigColumns, setShowColumnList}) {

    return (
        <div className={classes.wrapper} >
            <div className={classes.closeWrapper} onClick={() => setShowColumnList()}>
                <img src={close} className={classes.svg} />
            </div>
            <h4 className={classes.title}>Agregar o Eliminar</h4>
            <div className={classes.list}>
                { configColumns.map( (column, i) => (
                    <div key={column.title} className={classes.element} onClick={(e) => setConfigColumns(i)}>
                        <div className={classes.label}>
                            <div className={classes.checkboxWrapper}>
                                <label className={classes.formControl}>
                                    <input 
                                        onChange={e => {}}
                                        className={classes.checkbox} 
                                        type="checkbox"
                                        checked={!column.hidden}
                                    />
                                </label>
                            </div>
                            <div className={classes.detail}>
                                {column.title}
                            </div>
                        </div>
                    </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ColumnList