import React from 'react'
import ColumnList from './ColumnList'
import classes from './Toolbar.module.css'

const Toolbar = ({ configColumns, setConfigColumns, showColumnList, setShowColumnList }) => {

  return (
    <>
      {showColumnList && <ColumnList
        configColumns={configColumns}
        setConfigColumns={setConfigColumns}
        setShowColumnList={setShowColumnList}
      />
      }
      <div className={classes.wrapper} >
        <div className={classes.button} title="Mostrar opciones de columnas" onClick={() => setShowColumnList()}>
          <svg className={classes.svg} viewBox="0 0 24 24" focusable="false">
            <path d="M10 18h5V5h-5v13zm-6 0h5V5H4v13zM16 5v13h5V5h-5z"></path>
          </svg>
        </div>
      </div>
    </>
  )
}

export default Toolbar