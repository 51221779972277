import React from 'react'
import ProfileHeader from 'components/UI/ProfileHeader'
import classes from './AddBillings.module.css'
import CardItem from 'components/UI/CardItem'

import iconInstance from "../..//assets/img/icons/iconInstance.svg"
import iconBsale from "../..//assets/img/icons/iconBsale.svg"
import emptyImg from "../../assets/img/channelEmpty.png"


const AddBillings = ({ countries, myNotBillings, handleCurrent, handleRoute, parameters }) => {
  return (
    <div className={classes.wrapper}>
        <ProfileHeader backTo={() => handleRoute('current')}>Agregar facturación</ProfileHeader>
        <p className={classes.introductionText}>
        Acá encuentras la lista de los métodos de facturación que existen 
        actualmente. Elige el que se acomode más a tus necesidades y sigue 
        las instrucciones para llevar a cabo una buena conexión. 
        </p>
        <div className={classes.billings}>
            {myNotBillings.length === 0 && 
                <div className={classes.empty} >
                    <img src={emptyImg} className={classes.imgEmpty}/>
                    <span className={classes.textEmpty}>No hay métodos de facturación activos</span>
                </div>   
            }
            {myNotBillings.length > 0 &&
            <>
                <CardItem
                    name="Instance"
                    icon={iconInstance}
                    onClick={() => handleRoute('instance')}
                />
                <CardItem
                    name="Bsale"
                    icon={iconBsale}
                    onClick={() => handleRoute('bsale')}
                />
                {parameters.length > 0 && 
                    parameters.map( (billing, i) => (
                        billing.paises.some( c => countries.includes(c)) &&
                            <CardItem
                                key={i}
                                name={billing.name.charAt(0).toUpperCase() + billing.name.slice(1)}
                                icon={billing.config_mtd?.icon}
                                onClick={() => {
                                    handleRoute('addform')
                                    handleCurrent(billing.id)
                                }}
                            />
                    ))
                }
            </>}
        </div>
    </div>
  )
}

export default AddBillings