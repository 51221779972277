import React from 'react'
import classes from "./Login.module.css"

import configpc from "../../assets/img/configpc.png"

const NewPassword = ({ setView }) => {
  return (
    <div className={classes.loginWrapper}>
        <div className={classes.header}>
            <div></div>
            <h1></h1>
            <div></div>
        </div>
        <div className={classes.wrapperContrato}><img src={configpc} /></div>
        <h3>Alto ahi...</h3>
        <p className={classes.text}>Para más seguridad cambiar tu contraseña provisoria por una más cool</p>
        <button className={classes.loginButton} onClick={() => setView("new-password")}>
            Actualizar contraseña
        </button>
    </div>
  )
}

export default NewPassword