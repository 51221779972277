import React from 'react'
import classes from './ComboImagesThumbnails.module.css'


const ComboImagesThumbnails = ({ images, handleThumbnailImageChange }) => {

    return (
       
        <div className={classes.comboImagesThumbnails}>
            {images?.length > 0 
            ?
                images.map( (image, index) => (
                    <img 
                        src={image.url}
                        alt={`thumbnail_img_${index}`}
                        key={`image_${index}`}
                        onClick={() => handleThumbnailImageChange(image)}
                    />
                ))
            : 
                null
            }
        
        </div>
    )
}

export default ComboImagesThumbnails