import CreateCombo from 'components/Promotions/CreateCombo'
import WelcomePromotions from 'components/Promotions/WelcomePromotions'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import classes from './Promotions.module.css'

const Promotions = () => {

    const { path } = useRouteMatch()

    return (
        <div className={classes.wrapper}>
            <Switch>
                <Route path={`${path}/pack`} component={CreateCombo}/>
                <Route path={`${path}/kit`} component={CreateCombo}/>
                <Route path={`${path}/mxn`} component={CreateCombo}/>
                <Route path={`${path}/`} component={WelcomePromotions} />
            </Switch>
        </div>
    )
}

export default Promotions