import ProfileHeader from 'components/UI/ProfileHeader'
import React, { useRef, useState } from 'react'
import classes from "./InstanceFulfillment.module.css"
import logoInstance from "../../assets/img/logoInstanceazul.png"
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import { Modal, ModalHeader } from 'reactstrap'
import { connectBilling } from 'services/billings'
import { deleteBilling } from 'services/billings'
import { createWms } from 'services/wms'
import deleteImage from "../../assets/img/delete-image.png";
import { deleteWms } from 'services/wms'
const textSucces = "Conexión realizada con éxito."
const textModal = "Esta conexión se realizará de manera automática."
const textModalTwo = "¿Deseas realizar la conexión?"

const InstanceFulfillment = ({handleRoute, mode, myBillings, myNotBillings, storeData,updateStoreData,channelsWithoutWms,countries,allWmsConfiguration}) => {

  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [success,setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const currentChannel = useRef("")

  const handleModalConfirm = () =>  setShowModalConfirm(prevState => !prevState)
  const handleModalConfirmDelete = () =>  setShowModalConfirmDelete(prevState => !prevState)

  const handleChannel = (event) => {
    event.preventDefault()
    currentChannel.current = event.target.value
  }

  const handleSubmit = async (event) => {
    if ( currentChannel.current === "") { return }
    event.preventDefault()
    setLoading(true)

    let channelConfig = channelsWithoutWms.find( b => b.channelId === parseInt(currentChannel.current))

    const body = [{
        channel: currentChannel.current,
        paises: countries,
        wms:{
            wmsCompanyId: 0,
            wmsInstance: true
        }
    }]

    const response = await createWms( storeData.id, body);
 
    if (response.results[0]?.result === "Success") {
        setSuccess(true)
    }
    setLoading(false)
  }

  const handleSubmitDelete = async (event) =>{
    if ( currentChannel.current === "") { return }
    event.preventDefault()
    setLoading(true)
    const response = await deleteWms( storeData.id, currentChannel.current);
   
    if (response === "Store config wms delete") {
        setSuccess(true)
    }
    setLoading(false)
  }

  return (
    <>
      <div className={classes.wrapper}>
          <ProfileHeader backTo={() => handleRoute(mode === "delete" ? "current" : "add")}>Instance</ProfileHeader>
          <div className={classes.billings}>
              <div className={classes.inputLabel}>
                  <label className={classes.label}>
                      Elige un canal de venta
                  </label>
                  <select 
                      name="channel" 
                      onChange={(choice) =>  handleChannel(choice)}
                      className={classes.select}
                  >
                      <option value="">Canales disponibles</option>
                      {mode === "delete" &&
                        // channelsWithoutWms.filter((b)=> b.wmsCompanyName === "Instance").map((channel, i) => (
                        //     <option key={i} value={channel.channelId}>{channel.channelName}</option>
                        // ))
                    allWmsConfiguration
                  .filter((b) => b.wmsCompanyName === "Instance")
                  .map((channel, i) => (
                    <option key={i} value={channel.channelId}>
                      {channel.channelName}
                    </option>
                  ))}
                      
                      {mode === "connect" &&
                        channelsWithoutWms.map((channel, i) => (
                            <option key={i} value={channel.channelId}>{channel.channelName}</option>
                        ))
                      }
                      
                  </select>
              </div>
          </div>
          <br/>

          { mode === "connect" &&
            <button className={classes.button} onClick={handleModalConfirm}>
              <span className={classes.buttonText}>Conectar con Instance</span>
            </button>
          }
          { mode === "delete" &&
            <button className={classes.buttonDelete} onClick={handleModalConfirmDelete}>
                   <img src={deleteImage}/> &nbsp;
              <span className={classes.buttonTextDelete}> Eliminar este WMS de  manera definitiva</span>
            </button>
          }
      </div>
      { showModalConfirm && (
        <Modal isOpen={showModalConfirm} toggle={handleModalConfirm} size="lg">
            <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={handleModalConfirm}>
                    x
                    </button>
                </div>
            </ModalHeader>
            {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
            {!loading && 
                (<div className={classes.wrapperModal}>
                <h3 className={classes.textModal}>Conectar con</h3>
                <div className={classes.wrapperLogo}>
                  <img src={logoInstance} className={classes.imgLogo}/>
                </div>
                <p className={classes.textInstance}>{success ? textSucces : textModal }</p>
                <p className={classes.textInstanceTwo}>{success ? undefined : textModalTwo }</p>
                {success ? 
                    <button
                        className={classes.button}
                        onClick={() => {
                            handleModalConfirm()
                            handleRoute('current')
                            updateStoreData(storeData.id)
                        }}>
                        <span className={classes.buttonText}>Regresar a mi panel</span>
                    </button> 
                    : <button
                        type="submit"
                        className={classes.button}
                        onClick={handleSubmit}>
                        <span className={classes.buttonText}>Realizar conexión</span>
                    </button>
                }
                {!success && <span className={classes.textBack} onClick={handleModalConfirm}>No, quiero volver atrás</span>}                 
                </div>)
            }
        </Modal>
    )}
    { showModalConfirmDelete && (
        <Modal isOpen={showModalConfirmDelete} toggle={handleModalConfirmDelete} size="lg">
            <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={handleModalConfirmDelete}>
                    x
                    </button>
                </div>
            </ModalHeader>
            {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div> }
            {!loading && 
                (<div className={classes.wrapperModal}>
                <div className={classes.wrapperLogo}>
                  <img src={logoInstance} className={classes.imgLogo}/>
                </div>
                <p className={classes.textInstance}>{success ? "Se ha eliminado correctamente" : "¿Estas seguro que deseas eliminar esta WMS?" }</p>
                {success ? 
                    <button
                        className={classes.button}
                        onClick={() => {
                            handleModalConfirmDelete()
                            handleRoute('current')
                            updateStoreData(storeData.id)
                        }}>
                        <span className={classes.buttonText}>Regresar a mi panel</span>
                    </button> 
                    : <button
                        type="submit"
                        className={classes.button}
                        onClick={handleSubmitDelete}>
                        <span className={classes.buttonText}>Eliminar WMS</span>
                    </button>
                }
                {!success && <span className={classes.textBack} onClick={handleModalConfirmDelete}>No, quiero volver atrás</span>}                 
                </div>)
            }
        </Modal>
    )}
  </>
  )
}

export default InstanceFulfillment