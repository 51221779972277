import React from 'react';
import { Modal } from 'reactstrap';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import classes from './FifthAlertModal.module.css';


const FifthAlertModal = ({ 
    fifthModal, 
    toggleFifthModal,
    alertToUpdate,
    Close,
    closeFifthModal,
    activeDays,
    startDate,
    days,
    formatDate,
    endDate,
    alertTypeNames,
    emailString,
    loadingPublications,
    publications,
    Delete,
    handleDeletePublication,
    noPublicationsInAlertImg,
    isPaginationActive,
    unfoldToRigthIcon,
    paginationPage,
    handlePreviousPage,
    selectedAlert,
    finalPaginationPage,
    handleNextPage,
    handleNewPublications
  }) => {

    return (
        <Modal isOpen={fifthModal} toggle={toggleFifthModal} backdrop="static">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: "676px",
                    height: "663px",
                    bgcolor: 'white',
                    overflow: "hidden",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '17px',
                    zIndex: 1,
                    border: '1px solid #d3d3d3',
                    '@media (max-width:650px)': {
                        width: '90%', // width when screen size is less than 700px
                    },
                }}
            >
                <div className={classes.fifthModal}>
                    <div className={classes.fifthModalHeader}>
                        <div className={classes.fifthModalHeaderTitle}>
                            <div className={classes.fifthModalHeaderTitleText}>
                                {alertToUpdate?.alert_name}
                            </div>
                            <div className={classes.fifthModalHeaderClose}>
                                <img className={classes.fifthModalHeaderCloseIcon} src={Close} onClick={closeFifthModal} />
                            </div>
                        </div>
                        <div className={classes.fifthModalHeaderAlert}>
                            <div className={classes.fifthModalHeaderAlertDate}>
                                {activeDays && Object.values(activeDays).some(day => day) && startDate ? (
                                    <span>
                                        Envío los {days.filter(day => activeDays[day.key]).map(day => day.fullLabel.toLowerCase()).join(', ')} a partir del {formatDate(startDate)}
                                        {endDate ? ` hasta el ${formatDate(endDate)}` : ''}
                                    </span>
                                    ) : (
                                    ''
                                )}
                            </div>
                            <div className={classes.fifthModalHeaderAlertType}>
                                {`Tipo de alerta: ${alertTypeNames ?? ''}`}
                            </div>
                            <div className={classes.fifthModalHeaderAlertEmail}>
                                {`Se envía a: ${emailString ?? ''}`}
                            </div>
                        </div>
                    </div>
                    <div className={classes.fifthModalContent}>
                        {loadingPublications && (
                            <div className={classes.fifthModalContentPublications}>
                                <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                            </div>
                        )}
                        {!loadingPublications && (
                            <div className={classes.fifthModalContentPublications}>
                                {publications.length > 0 ? (
                                    publications.map((publication, index) => (
                                        <div key={index} className={classes.fifthModalPublicationsCard}>
                                            <div className={classes.publicationCardImage}>
                                                <img className={classes.publicationImage} src={publication.images ? (publication.images[0]?.link ?? '') : ''} alt={publication.name} />
                                            </div>
                                            <div className={classes.publicationCardName}>
                                                {publication.name}
                                            </div>
                                            <div className={classes.publicationCardCodes}>
                                                <span>ID: {publication.id_publication}</span>
                                                <span>SKU: {publication.sku}</span>                                                
                                            </div>
                                            <div className={classes.publicationCardDelete}>
                                                <img className={classes.publicationCardDeleteIcon} src={Delete} onClick={() => handleDeletePublication(publication.id_publication)} />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className={classes.noPublicationsMessage}>
                                        <div className={classes.noResultsImageContainer}>
                                            <img src={noPublicationsInAlertImg} className={classes.noResultsImage} />
                                        </div>  
                                        <div className={classes.noDataFoundTextContainer}>
                                            <span className={classes.noDataFoundText}>{"La alerta no tiene publicaciones"}</span>
                                        </div>                                                                                              
                                    </div>
                                )}
                            </div>
                        )}                               
                        <div className={isPaginationActive && publications?.length > 0 && !loadingPublications ? classes.fifthModalContentPagination : `${classes.fifthModalContentPagination} ${classes.paginationDisabled}`}>
                            <div className={classes.fifthModalPaginationInner}>
                                <img className={classes.paginationLeftArrow} alt="Prev" src={unfoldToRigthIcon} onClick={() => {
                                    if (paginationPage > 1 && isPaginationActive && publications?.length > 0 && !loadingPublications) {
                                        handlePreviousPage();
                                    }
                                }}/>
                                <span className={classes.paginationText} >{`${paginationPage} - ${selectedAlert?.alert_publications?.length < 100 ? 1 : finalPaginationPage}`}</span>
                                <img className={classes.paginationRightArrow} alt="Next" src={unfoldToRigthIcon} onClick={() => {
                                    if (paginationPage < finalPaginationPage && isPaginationActive && publications?.length > 0 && !loadingPublications) {
                                        handleNextPage();
                                    }
                                }} />
                            </div>                                    
                        </div>
                    </div>
                    <div className={classes.fifthModalFooter}>
                        <div className={loadingPublications ? classes.fifthModalFooterButtonDisabled : classes.fifthModalFooterButton} onClick={() => handleNewPublications()}>
                            Agregar
                        </div>
                    </div>                            
                </div>
            </Box>
        </Modal>
    );
};

export default FifthAlertModal;
