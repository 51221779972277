import React, { useState, useEffect } from 'react'
// import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useMediaQuery } from "@mui/material";
import Close from "../../../../assets/img/icons/close_black.png"
import ArrowSelect from "../../../../assets/img/icons/arrow-box.png"
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import CountryCircles from './CountryCircles';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { Modal } from 'reactstrap'
//styles
import classes from "./displayEditClientStores.module.css";
import { getUsersByProfile } from 'services/users';

import { emailValidation } from 'utils/utils';

function CreateStoreFirstModal({ openFirstModal, toggleFirstModal, setCountry, setCliente, toggleSecondModal, clientData, resetValues, modalTittle, allStores, welcomeStepKam = null, loadingClient, closeConfirmationModal }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [kam, setKam] = useState('');
    const [kamsOptions, setKamsOptions] = useState([])
    const [countryCode, setCountryCode] = useState(clientData.client_country)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [error, setError] = useState({
        nameError: false,
        namelegalError: false,
        emailError: false,
        countryError: false,
        kamError: false
    })
    const [clientDataFirst, setClientDataFirst] = useState({
        name: clientData.name,
        namerlegal: clientData.namerlegal,
        email: clientData.email,
        kam_selected: clientData.kam_selected,
    });


    const handleChangeFirstModal = (e) => {
        const { name, value } = e.target;
        setClientDataFirst({ ...clientDataFirst, [name]: value });
        if (e.target.name === "name" && error.nameError)
            setError({ ...error, nameError: false })
        if (e.target.name === "namerlegal" && error.namelegalError)
            setError({ ...error, namelegalError: false })
        if (e.target.name === "email" && error.emailError)
            setError({ ...error, emailError: false })
        if (clientDataFirst.name && clientDataFirst.namerlegal && clientDataFirst.email && countryCode && value) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    };

    useEffect(async () => {
        await getKams()
        if (clientData.name && clientData.namerlegal && clientData.email && clientData.client_country) {
            setButtonDisabled(false)
        }
    }, [])

    useEffect(() => {
        setCountry(countryCode)
        if (clientDataFirst.name && clientDataFirst.namerlegal && clientDataFirst.email && countryCode) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
        if (error.countryError)
            setError({ ...error, countryError: false })
    }, [countryCode])

    const getKams = async () => {
        try {
            let data = await getUsersByProfile(2)
            data = data.filter(user=>user?.enabled === true)
            if (!data)
                Swal.fire({
                    icon: 'error',
                    title: 'Error inesperado',
                    text: 'Por favor vuelve a intentar',
                    closeButtonHtml: `<img src="${Close}" />`
                })
            else {
                setKamsOptions(data)
                setKam(clientData.kam_selected)
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error inesperado',
                text: 'Por favor vuelve a intentar',
                closeButtonHtml: `<img src="${Close}" />`
            })
        }
    }


    const handleClose = async () => {
        try {
            const modalResult = await closeConfirmationModal()
            console.log(modalResult)
            if (modalResult) {
                resetValues()
                setKam("");
                toggleFirstModal();
            }
        } catch (error) {
            resetValues()
            setKam("");
            toggleFirstModal();
        }


    };

    const handleChangeKam = (event) => {
        handleChangeFirstModal(event)
        setKam(event.target.value);
        if (clientDataFirst.name && clientDataFirst.namerlegal && clientDataFirst.email && countryCode) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
        if (error.kamError)
            setError({ ...error, kamError: false })
    };

    const formValidation = () => {
        let errorForm = false
        let errors = {};
        if (allStores.find(e => e.name === clientDataFirst.name) && modalTittle !== "Editar Cliente") {
            errors = { ...errors, nameError: true }
            errorForm = true
        }
        if (!clientDataFirst.namerlegal) {
            errors = { ...errors, namelegalError: true }
            errorForm = true
        }
        if (!emailValidation(clientDataFirst.email)) {
            errors = { ...errors, emailError: true }
            errorForm = true
        }
        if (!clientData.client_country) {
            errors = { ...errors, countryError: true }
            errorForm = true
        }
        setError({ ...error, ...errors })
        return errorForm
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (welcomeStepKam) {
            welcomeStepKam(kam)
        }
        else {
            let errorForm = formValidation();

            if (!errorForm) {
                setCliente({ ...clientData, ...clientDataFirst })
                toggleFirstModal();
                toggleSecondModal();
            }
        }
    };

    return (
        <Modal
            isOpen={openFirstModal}
            toggle={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: "120%",
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 5,
                    zIndex: 1,
                    borderRadius: 4,

                    '@media (max-width: 600px)': {
                        width: '110%',

                    },
                    '&::after': !welcomeStepKam ? {
                        content: '""',
                        borderBottom: '10px solid #51CBCE', // Color ajustado
                        width: '33.33%', // Ajusta este valor según tus necesidades
                        position: 'absolute',
                        bottom: 0,
                        left: "0%",
                        borderRadius: 4,
                        borderBottomRightRadius: 4,
                        height: 24
                    } : ""
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div></div>
                    <Typography fontFamily='Montserrat' fontSize={isMobile ? "24px" : "33px"} sx={{ ml: isMobile ? "7%" : "8%" }} ><b>{modalTittle}</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <IconButton onClick={handleClose} sx={{
                            mr: isMobile ? "-20px" : "",
                            '&:hover': {
                                backgroundColor: 'transparent'

                            }
                        }}>
                            <img src={Close} width={isMobile && "70%"} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <div className={classes.lineWrapper} style={{ paddingRight: welcomeStepKam && "30px" }}>
                    <span className={classes.line}></span>
                </div>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { mt: 2, mb: 2 }
                    }}

                    noValidate
                    autoComplete="off"
                >
                    <Typography fontFamily='Montserrat' fontSize={isMobile ? "14px" : "20px"} align='center' ><b> {modalTittle === "Editar Cliente" ? "Edita la información del cliente" : "Completa la información con los datos del cliente"}</b></Typography>

                    <FormControl variant="standard" fullWidth error={error.nameError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Nombre del cliente
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="name" name="name" defaultValue={clientData.name}
                            disabled={welcomeStepKam ? true : false}
                            required
                            placeholder="Ingresa el nombre de cliente (ej: Softys, Unilever, etc.)"
                            onChange={handleChangeFirstModal} />
                        {error.nameError ? <FormHelperText>El nombre del cliente ya existe</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.namelegalError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Nombre del representante legal (contraparte)
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }}
                            id="namerlegal" name="namerlegal" defaultValue={clientData.namerlegal}
                            disabled={welcomeStepKam ? true : false}
                            placeholder="Ingresa el nombre del representante (contraparte)" onChange={handleChangeFirstModal} />
                        {error.namelegalError ? <FormHelperText>El nombre del cliente no es valido</FormHelperText> : <></>}
                    </FormControl>

                    <FormControl variant="standard" fullWidth error={error.emailError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Correo del representante (contraparte)
                        </InputLabel>
                        <InputBase sx={{ mt: 3.5, pl: "10px" }} id="email" defaultValue={clientData.email} disabled={welcomeStepKam ? true : false}
                            name="email" placeholder="Ingresa el nombre del representante (contraparte)" onChange={handleChangeFirstModal} />
                        {error.emailError ? <FormHelperText style={{ color: "black" }}>El email no es valido</FormHelperText> : <></>}
                    </FormControl>

                    {!welcomeStepKam &&
                        <FormControl variant="standard" fullWidth error={error.countryError}>
                            <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                                País de cliente
                            </InputLabel>
                            <div style={{ marginBottom: "24px", zIndex: 1 }}></div>
                            <CountryCircles setCountry={setCountryCode} country={clientData.client_country} />
                            {error.countryError ? <FormHelperText style={{ color: "black" }}>Debe seleccionar un país</FormHelperText> : <></>}
                        </FormControl>}


                    <FormControl variant="standard" fullWidth error={error.kamError}>
                        <InputLabel sx={{ color: 'black', fontSize: "20px" }} shrink >
                            Kam a cargo de cliente
                        </InputLabel>
                        <div style={{ marginBottom: "30px", zIndex: 1 }}></div>
                        <Select
                            sx={{ pt: 1, pb: 1, pl: 1 }}
                            name="kam_selected"
                            labelId="kam-label"
                            variant="standard"
                            id="kam-select"
                            value={kam}
                            label="Kam"
                            onChange={handleChangeKam}
                            displayEmpty
                            style={{ color: kam === '' ? 'gainsboro' : 'inherit' }}
                            IconComponent={() => (
                                <div style={{ position: "absolute", right: 15, pointerEvents: "none", cursor: 'pointer' }}>
                                    <img src={ArrowSelect} />
                                </div>

                            )}

                        >
                            <MenuItem sx={{ color: "#E8E8E8" }} value="" disabled>
                                {kamsOptions.length === 0 ? "Cargando lista de Kams....." : "Selecciona un Kam para este cliente"}
                            </MenuItem>
                            {kamsOptions.map((kam) => (
                                <MenuItem key={kam.email} value={kam.email}>{kam.first_name} {kam.last_name}</MenuItem>
                            ))}

                        </Select>
                        {error.kamError ? <FormHelperText>Debe seleccionar un/a kam</FormHelperText> : <></>}
                    </FormControl>


                    <Button disabled={loadingClient || buttonDisabled} type="submit" variant="contained" sx={{ fontSize: '26px', padding: welcomeStepKam ? '10px 16%' : '10px 27%' }} ><b>
                        {loadingClient && (
                            <CircularProgress
                                thickness={6}
                                size={33}
                                sx={{
                                    color: "#68cd96",
                                    position: 'absolute',
                                    top: '43%',
                                    right: '75%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                        {welcomeStepKam ? "Editar Cliente" : "Continuar"}  </b></Button>
                </Box>
            </Box>
        </Modal >
    )
}

export default CreateStoreFirstModal