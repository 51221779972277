import inPreparation from "../../assets/img/in-preparation.png";
import dispatchStep1 from "../../assets/img/dispatch-step-1.png";
import inDispatchStep1 from "../../assets/img/step-1-in-dispatch.png";
import arrivalStep1 from "../../assets/img/step1-arrival-ready.png";
import clientstep1 from "../../assets/img/step1-recievedby-client.png";
import step2Prep from "../../assets/img/step2-preparation.png";
import step2readytodispatch from "../../assets/img/step2-ready-to-dispatch.png";
import step3inDispatch from "../../assets/img/step3-in-dispatch.png";
import step3ReadyToDispatch from "../../assets/img/step3-ready-to-dispatch.png";
import step4InDispatch from "../../assets/img/step4-in-dispatch.png";
import step4ArrivalReady from "../../assets/img/step4-arrival-ready.png";
import step5Recieved from "../../assets/img/step5-receieved.png";
import step5ArrivalReady from "../../assets/img/step5-arrival-ready.png";
import currentStatus from "../../assets/img/current-status.png";
import checkmark from "../../assets/img/Checkmark.png";

import "./courier-status-module.css";

const CourierStatusModal = (props) => {
  return (
    <>


      <h4 style={{ fontWeight: "700", fontSize: "30px", textAlign: "center", marginBottom: "2em" }}>
        Estado del courier
      </h4>
      <p style={{ fontWeight: "600", fontSize: "14px", paddingLeft: "30px" }}>
        Número de orden:{" "}
        <span style={{ fontWeight: "400", fontSize: "14px" }}>
          &nbsp;&nbsp;{props.purchaser.orderNo}
        </span>
      </p>

      <p style={{ fontWeight: "600", fontSize: "14px", paddingLeft: "30px" }}>
        Codigo de barras:{" "}
        <span style={{ fontWeight: "400", fontSize: "14px" }}>
          &nbsp;&nbsp;{props.purchaser.barcodes}
        </span>
      </p>

      <p style={{ fontWeight: "600", fontSize: "14px", paddingLeft: "30px", marginBottom: "2em" }}>
        Courier:{" "}
        <span style={{ fontWeight: "400", fontSize: "14px" }}>
          &nbsp;&nbsp;{props.purchaser.courier}
        </span>
      </p>
      <br />

      {props.purchaser.statusCourier !== undefined && props.purchaser.statusCourier.reverse().map((item, index, array) => {
        if (index === 0) {
          let date = item.date;
          let dateArray = date.substring(0, 19);
          let newDate = dateArray.replace('T', ' ');

          return (
            <div class="center">
              <div class="container" style={{ display: "flex" }}>
                <div>
                  {" "}
                  <h6
                    style={{
                      fontWeight: "600",
                      // paddingLeft: "30px",
                      whiteSpace: "nowrap",
                    }}
                  >

                    {newDate}
                  </h6>
                </div>
                <div>
                  <img src={currentStatus} height="70%" style={{ marginTop: "-8px" }} />
                </div>
                <div>
                  <h6
                    style={{
                      fontWeight: "600",
                      // paddingLeft: "30px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.status}
                  </h6>
                </div>
              </div>
            </div>
          );
        } else {
          let date = item.date;
          let dateArray = date.substring(0, 19);

          let newDate = dateArray.replace('T', ' ');

          return (
            <div class="center">
              <div class="container" style={{ display: "flex" }}>
                <div>
                  {" "}
                  <p
                    style={{
                      fontSize: "12px",
                      // paddingLeft: "30px",
                      whiteSpace: "nowrap",
                      color: "#C4C4C4"
                    }}
                  >
                    {newDate}
                  </p>
                </div>
                <div>
                  <img src={checkmark} height="60%" style={{ marginTop: "-1em" }} />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "12px",
                      // paddingLeft: "30px",
                      whiteSpace: "nowrap",
                      color: "#C4C4C4"
                    }}
                  >
                    {item.status}
                  </p>
                </div>
              </div>
            </div>
          );
        }
      })}

      {/* STEP 1 */}
      {props.purchaser.statusCourier === "Creado" && (
        <div class="container">
          <figure>
            <img
              src={inPreparation}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
          <figure>
            <img
              src={dispatchStep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
          <figure>
            <img
              src={inDispatchStep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
          <figure>
            <img
              src={arrivalStep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
          <figure>
            <img
              src={clientstep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
        </div>
      )}
      {/* STEP 2 */}
      {props.purchaser.statusCourier === "Listo para despacho - Impreso" && (
        <div class="container">
          <figure>
            <img
              src={step2Prep}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={step2readytodispatch}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={inDispatchStep1}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={arrivalStep1}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={clientstep1}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
        </div>
      )}
      {/* STEP 3 */}
      {props.purchaser.statusCourier === "En Reparto" && (
        <div class="container">
          <figure>
            <img
              src={step2Prep}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={step3ReadyToDispatch}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={step3inDispatch}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={arrivalStep1}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={clientstep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
        </div>
      )}
      {/* STEP 4 */}
      {props.purchaser.statusCourier === "En planta de origen" && (
        <div class="container">
          <figure>
            <img
              src={step2Prep}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={step3ReadyToDispatch}
              style={{ float: "left", marginRight: "40px" }}
            />

          </figure>
          <figure>
            <img
              src={step4InDispatch}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
          <figure>
            <img
              src={step4ArrivalReady}
              style={{ float: "left", marginRight: "40px" }}
            />
            
          </figure>
          <figure>
            <img
              src={clientstep1}
              style={{ float: "left", marginRight: "40px" }}
            />
          </figure>
        </div>
      )}
      {/* STEP 5 */}
      {props.purchaser.statusCourier === "Entregado" && (
        <div class="container">
          <figure>
            <img
              src={step2Prep}
              style={{ float: "left", marginRight: "43px" }}
            />

          </figure>
          <figure>
            <img
              src={step3ReadyToDispatch}
              style={{ float: "left", marginRight: "43px" }}
            />

          </figure>
          <figure>
            <img
              src={step4InDispatch}
              style={{ float: "left", marginRight: "43px" }}
            />
          </figure>
          <figure>
            <img
              src={step5ArrivalReady}
              style={{ float: "left", marginRight: "43px" }}
            />
          </figure>
          <figure>
            <img
              src={step5Recieved}
              style={{ float: "left", marginRight: "43px" }}
            />
          </figure>
        </div>
      )}


    </>
  );
};
export default CourierStatusModal;
