import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable, useColumnOrder, useSortBy } from 'react-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toSpanishNumberFormat } from '../../../utils/utils';
import classes from './CompetitionTable.module.css';

// images
import EmptyPublicationsIcon from '../../../assets/icons/EmptyPublications.svg';
import SortingArrow from '../../../assets/img/icons/arrow-up-icon.svg';
import ActionIcon from '../../../assets/img/icons/disable-competition-icon.svg';
import CompetitionInfoCard from './CompetitionInfoCard';

const CompetitionTable = ({ tableData, selectedColumn, setSelectedColumn, selectedRowId, setSelectedRowId, setRivalId, setWantDelete, setSelectedRival }) => {
  const data = useMemo(() => tableData, [tableData]);  

  const initialLoad = useRef(true);  

  const lowestPriceRow = useMemo(() => {
    return data?.reduce((lowest, current) => {
      const lowestPrice = lowest && lowest.precio !== null && lowest.precio !== undefined 
        ? parseFloat(lowest.precio) 
        : Infinity;
  
      const currentPrice = current && current.precio !== null && current.precio !== undefined 
        ? parseFloat(current.precio) 
        : Infinity;
  
      if (currentPrice < lowestPrice) {
        return current;
      } else if (lowestPrice === Infinity && lowest === null) {
        return current;  // Return the first row if all prices are null/undefined
      } else {
        return lowest;
      }
    }, null);
  }, [data]);
  
  useEffect(() => {
    if (initialLoad.current && data.length > 0 && lowestPriceRow) {      
      setSelectedRival(lowestPriceRow);
      setSelectedRowId(lowestPriceRow.id);
      initialLoad.current = false;
    }
  }, [data, lowestPriceRow, setSelectedRowId]);  

  const handleActionClick = (rowId) => {
    setRivalId(rowId);
    setWantDelete(true);
  };  

  const customSort = (rows, columnId, desc) => {
    // Separate rows with null values in the selected column
    const nonNullRows = rows.filter(row => row.values[columnId] !== null && row.values[columnId] !== undefined);
    const nullRows = rows.filter(row => row.values[columnId] === null || row.values[columnId] === undefined);

    // Sort non-null rows
    nonNullRows.sort((rowA, rowB) => {
      const numA = parseFloat(rowA.values[columnId]);
      const numB = parseFloat(rowB.values[columnId]);

      if (desc) {
        return numB - numA;  // Descending order
      } else {
        return numA - numB;  // Ascending order
      }
    });

    // Append null rows at the end
    return [...nonNullRows, ...nullRows];
  };

  const columns = useMemo(() => [
    {
      Header: 'Información de competencia',
      accessor: 'competencia',
      disableSortBy: true,
      disableDrag: true,
      Cell: ({ value }) =>
        value ? (
          <CompetitionInfoCard
            imageUrl={value?.imageUrl || ''}
            category={value?.category || ''}
            name={value?.name || ''}
            code={value?.code || ''}
            iconUrl={value?.iconUrl || ''}
            publicationUrl={value?.publicationUrl || ''}
          />
        ) : (
          'Sin información'
        ),
    },
    { Header: 'Marca', accessor: 'marca', disableSortBy: true, disableDrag: true, Cell: ({ value }) => value ? value : 'Sin información' },
    { Header: 'Vendedor', accessor: 'vendedor', disableSortBy: true, disableDrag: true, Cell: ({ value }) => value ? value : 'Sin información' },
    { Header: 'Precio', accessor: 'precio', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? `$${toSpanishNumberFormat(value)}` : 'Sin información' },
    { Header: 'Stock', accessor: 'stock', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? toSpanishNumberFormat(value) : 'Sin información' },
    { Header: 'Palabras descripción', accessor: 'palabras', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? toSpanishNumberFormat(value) : 'Sin información' },
    { Header: 'Imágenes', accessor: 'imagenes', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? toSpanishNumberFormat(value) : 'Sin información' },
    { Header: 'Reviews', accessor: 'reviews', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? toSpanishNumberFormat(value) : 'Sin información' },
    { Header: 'Comentarios', accessor: 'comentarios', sortType: customSort, Cell: ({ value }) => (value || value === 0) ? toSpanishNumberFormat(value) : 'Sin información' },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <img
          className={classes.disableCompetitionIcon}
          src={ActionIcon}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click from triggering
            handleActionClick(row.original.id);
          }}
          alt="Action Icon"
        />
      ),
      disableDrag: true,
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: selectedColumn,
            desc: false,
          },
        ],
      },
      disableSortRemove: true,
      manualSortBy: true, // Enable manual sorting
      autoResetSortBy: false, // Keep the sorting state persistent
    },
    useColumnOrder,
    useSortBy
  );

  const sortedData = useMemo(() => {
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      return customSort(rows, id, desc);
    }
    return rows;
  }, [rows, sortBy]);

  useEffect(() => {
    if (sortBy.length > 0) {
      setSelectedColumn(sortBy[0].id);
    }
  }, [sortBy, setSelectedColumn]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const currentOrder = headerGroups[0].headers.map(col => col.id);
    const draggableColumns = currentOrder.slice(3, -1); // Exclude first 3 and last columns

    const [removed] = draggableColumns.splice(result.source.index, 1);
    draggableColumns.splice(result.destination.index, 0, removed);

    const newOrder = [
      ...currentOrder.slice(0, 3),
      ...draggableColumns,
      currentOrder[currentOrder.length - 1]
    ];

    setColumnOrder(newOrder);
  };

  const handleRowClick = (row) => {    
    setSelectedRival(row.original);
    setSelectedRowId(row.original.id);    
  };

  const sortedColumn = sortBy.length > 0 ? sortBy[0].id : null;

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table {...getTableProps()} className={classes.table}>
          <thead className={classes.header}>
            {headerGroups.map(headerGroup => (
              <Droppable droppableId="columns" direction="horizontal" key={headerGroup.id}>
                {(provided) => (
                  <tr {...headerGroup.getHeaderGroupProps()} ref={provided.innerRef}>
                    {headerGroup.headers.map((column, index) => {
                      const isSortedColumn = sortedColumn === column.id;
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={`${classes.sortable} ${isSortedColumn ? classes.boldColumn : classes.normalColumn}`}
                          key={column.id}
                        >
                          {index >= 3 && index < headerGroup.headers.length - 1 ? (
                            <Draggable draggableId={column.id} index={index - 3}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {column.render('Header')}
                                  {!column.disableSortBy && (
                                    <img
                                      src={SortingArrow}
                                      alt="Sort"
                                      className={
                                        column.isSorted
                                          ? column.isSortedDesc
                                            ? classes.sortedAsc
                                            : classes.sortedDesc
                                          : classes.sortIcon
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            <>
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <img
                                  src={SortingArrow}
                                  alt="Sort"
                                  className={
                                    column.isSorted
                                      ? column.isSortedDesc
                                        ? classes.sortedDesc
                                        : classes.sortedAsc
                                      : classes.sortIcon
                                  }
                                />
                              )}
                            </>
                          )}
                        </th>
                      );
                    })}
                    {provided.placeholder}
                  </tr>
                )}
              </Droppable>
            ))}
          </thead>
          {tableData?.length > 0 && 
            (
              <tbody {...getTableBodyProps()}>
                {sortedData.map(row => {
                  prepareRow(row);
                  const isSelectedRow = selectedRowId === row.original.id;
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row.original.id}
                      onClick={() => handleRowClick(row)}
                      style={{ backgroundColor: isSelectedRow ? '#D3D3D3' : 'transparent' }}
                    >
                      {row.cells.map(cell => {
                        const isSortedCell = sortedColumn === cell.column.id;
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ width: cell.column.width }}
                            key={cell.column.id}
                            className={isSortedCell ? classes.boldColumn : classes.normalColumn}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            ) 
          }        
        </table>
      </DragDropContext>
      <div>
        {tableData?.length < 1 && 
          (
            <div className={classes.dataNotFound}>
              <div className={classes.noPublicationsContainer}>
                <img
                    className={classes.publicationNotFoundIcon}
                    src={EmptyPublicationsIcon}
                    alt="empty-publications-icon"
                />
                <div className={classes.publicationNotFoundtextContainer}>
                    <span>Upsss... Por aquí no</span>
                    <span>encuentro información...</span>
                    <span className={classes.tryLaterText}>¡Intentalo más tarde!</span>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>    
  );
};

export default CompetitionTable;
