//Libraries
import React, { useState } from 'react';

//Components
import PublicationList from './Components/PublicationsList';
import classes from './Publications.module.css';

//Images
import unfoldToRigthIcon from "../../assets/img/icons/unfold-to-right.svg";

const Publications = () => {
    const [viewTitle, setViewTitle] = useState("Publicaciones");
    const [isEditView, setIsEditView] = useState(false);
    const [publicationsToSkip, setPublicationsToSkip] = useState("");

    const returnOriginalPage = () => {
        setViewTitle("Publicaciones");
        setIsEditView(false);
        setPublicationsToSkip("");
    }

    return (
        <React.Fragment>
            <div>
                <div className={classes.wrapperTitle}>
                    {isEditView && 
                        (
                            <img 
                                className={classes.arrowIcon} 
                                alt="Return" 
                                src={unfoldToRigthIcon} 
                                onClick={() => returnOriginalPage()}
                            />
                        )
                    }          
                    <span className={classes.title}>{viewTitle}</span>
                </div>
                <div className={classes.wrapper}>
                    <PublicationList
                        setViewTitle={setViewTitle}
                        setIsEditView={setIsEditView}
                        isEditView={isEditView}
                        publicationsToSkip={publicationsToSkip}
                        setPublicationsToSkip={setPublicationsToSkip}
                        returnOriginalPage={returnOriginalPage}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Publications