import React, { useState, useEffect } from 'react';
import classes from './SuggestionsModal.module.css';
import CustomModal from '../../../components/UI/CustomModal';
import { diffWords } from 'diff';
import { toSpanishNumberFormatWithNull } from '../../../utils/utils';

import SuggestionDownArrow from "../../../assets/img/icons/gray-down-arrow.svg";
import SuggestionsIcon from '../../../assets/img/icons/spark-icon.svg';
import CopyIcon from '../../../assets/img/icons/copy-vector.svg';

const SuggestionsModal = ({ suggestions, modalState, setModalState, setCopyResultText, setCopyCreationResult, handleShowAlert, origin }) => {
    const [currentIndex, setCurrentIndex] = useState(0);   

    const toggleModalState = () => setModalState(!modalState);

    const currentSuggestion = suggestions[currentIndex];
    const totalSuggestions = suggestions.length;

    const handleNext = () => {
        if (currentIndex < totalSuggestions - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            handleClose();
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleClose = () => {
        setModalState(false);
    };

    const getHighlightedDescription = () => {
        if (currentSuggestion.type !== 'description') return showValue(currentSuggestion.newValue);

        const oldDescription = currentSuggestion.oldValue || '';
        const newDescription = currentSuggestion.newValue || '';

        const diff = diffWords(oldDescription, newDescription);

        return diff.map((part, index) => {
            const className = part.added ? classes.highlightedText : '';
            return (
                <span key={index} className={className}>
                    {part.value}
                </span>
            );
        });
    };

    const getHighlightedKeywords = (value) => {
        const keywordData = value === 'old' ? currentSuggestion?.oldValue : currentSuggestion?.newValue;
    
        if (!Array.isArray(keywordData)) {
            return <span>No hay palabras claves.</span>;
        }
    
        return (
            <div>
                <span>
                    {value === 'old'
                        ? "Estas palabras son las que más utilizas..."
                        : "Basado en las palabras claves que usa la competencia te recomendamos:"}
                </span>
                <div className={classes.keywordsContainer}>
                    {keywordData.length > 0 
                        ? keywordData.map((keyword, index) => (
                            <span key={index}>
                                {value === 'old'
                                    ? (
                                        <>
                                            "{keyword[0]}" la utilizas {keyword[1] > 1 ? `${keyword[1]} veces` : `${keyword[1]} vez`}
                                        </>
                                    )
                                    : (
                                        <>
                                            Utiliza <span className={classes.highlightedText}>{keyword[1] > 1 ? `${keyword[1]} veces` : `${keyword[1]} vez`}</span> la palabra <span className={classes.highlightedText}>"{keyword[0]}"</span>
                                        </>
                                    )
                                }
                            </span>
                        ))
                        : <span>No hay palabras claves.</span>
                    }
                </div>
            </div>
        );
    };
    
     

    const getSummary = () => {
        switch (currentSuggestion.type) {
            case 'description':
                if(currentSuggestion.newWordCount > currentSuggestion.oldWordCount){
                    return `Para tener una correcta publicación debes agregar ${currentSuggestion.newWordCount - currentSuggestion.oldWordCount} palabras. ¡Pasarás de ${currentSuggestion.oldWordCount} palabras a ${currentSuggestion.newWordCount}!`;
                }else {
                    return `Para tener una correcta publicación debes quitar ${currentSuggestion.oldWordCount - currentSuggestion.newWordCount} palabras. ¡Pasarás de ${currentSuggestion.oldWordCount} palabras a ${currentSuggestion.newWordCount}!`;
                }                
            case 'price':
                if(currentSuggestion.change > 0){
                    return `¡Sube ${(currentSuggestion.change).toFixed(2).replace(/(\.00|,00)$/, '').replace('.', ',')}% tu precio ($${toSpanishNumberFormatWithNull(currentSuggestion.priceChange)}) y sigue ganando!`;
                }else {
                    return `¡Baja ${(currentSuggestion.change * -1).toFixed(2).replace(/(\.00|,00)$/, '').replace('.', ',')}% tu precio ($${toSpanishNumberFormatWithNull(currentSuggestion.priceChange)}) y sigue ganando!`;
                }
            case 'keywords':
                return `¡Agrega estas palabras a tu publicación para mejorar el posicionamiento!`;
            case 'images':
                return `El promedio de imágenes es ${currentSuggestion.info} y tu solo tienes ${currentSuggestion.oldValue}, ¡Agrega ${currentSuggestion.newValue > 1 ? `${currentSuggestion.newValue} imágenes` : `${currentSuggestion.newValue} imagen`}!`;
            default:
                return '';
        }
    };

    const getPrefix = () => {
        switch (currentSuggestion.type) {
            case 'description':
                return `Descripción original`;
            case 'price':
                return `Precio actual`;
            case 'keywords':
                return `Palabras claves`;
            case 'images':
                return `Imágenes actuales`;
            default:
                return '';
        }
    };

    const getTextColorType = () => {
        switch (currentSuggestion.type) {
            case 'price':
                return true;
            case 'images':
                return true;
            default:
                return false;
        }
    };

    const showValue = (value) => {
        switch (currentSuggestion.type) {
            case 'price':
                return `$${toSpanishNumberFormatWithNull(value)}`;
            case 'images':
                return value > 1 ? `${toSpanishNumberFormatWithNull(value)} imágenes` : `${toSpanishNumberFormatWithNull(value)} imagen`;
            default:
                return value;
        }
    };

    let progress;
    if (totalSuggestions === 1) {
        progress = 96; // Only one suggestion
    } else if (currentIndex === 0) {
        progress = 0; // First suggestion
    } else if (currentIndex === totalSuggestions - 1) {
        progress = 96; // Last suggestion
    } else {
        progress = ((currentIndex) / (totalSuggestions - 1)) * 100;
    }

    // Set CSS variable for progress bar width
    useEffect(() => {
        document.documentElement.style.setProperty('--progress-bar-width', `${progress}%`);
    }, [progress]);

    const handleCopySuggestion = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(currentSuggestion.newValue);
    };

    const handleCopyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        if(origin === 'modal'){
            handleShowAlert("1, 2, 3 ¡Código copiado!", "success");
        }else{
            setCopyResultText("1, 2, 3 ¡Código copiado!");
            setCopyCreationResult(true); 
        }        
    };

    return (
        <div>
            {suggestions.length > 0 && (
                <CustomModal
                isOpen={modalState}
                toggle={toggleModalState}
                onClose={handleClose}
                onBack={currentIndex > 0 ? handleBack : null}
                title="Sugerido por IA (Instance Analytics)"
                customStyles={{ box: classes.customModal }}
                customClassNames={{
                    form: classes.modalForm,
                    header: classes.modalHeader,
                    title: currentIndex > 0 ? classes.modalTitle : classes.modalTitleFirst,
                    closeWrapper: classes.closeWrapper,
                    closeIcon: classes.closeIcon,
                    separatorContainer: classes.separatorContainer,
                    separator: classes.separator,
                    bodyContainer: classes.bodyContainer,
                    footerContainer: classes.footerContainer,
                    box: classes.progressBar
                }}
                footerActions={
                    <div className={classes.footerActions}>
                        <div
                        className={`${classes.actionButton}`}
                        onClick={handleNext}
                        >
                            <span className={classes.buttonText}>{currentIndex < totalSuggestions - 1 ? 'Siguiente' : 'Cerrar'}</span>
                        </div>
                    </div>
                }
                >
                    <div className={classes.contentContainer}>
                        <div className={classes.innerContentContainer}>
                            <div className={classes.currentSuggestionContainer}>
                                <div className={classes.originalValueTitle}>
                                    <span>{getPrefix()} de tu publicación</span>
                                </div>
                                <div className={classes.originalValueContent}>
                                    {currentSuggestion.type === 'keywords' ? getHighlightedKeywords('old') : <span>{showValue(currentSuggestion.oldValue)}</span>}                                    
                                </div>                                
                            </div>
                            <div className={classes.imageSuggestionContainer}>
                                <img className={classes.downArrow} src={SuggestionDownArrow} alt="Down arrow" /> 
                                <span className={classes.innerContentText}>Te sugerimos estos cambios</span>
                            </div>                            
                            <div className={classes.suggestedSuggestionContainer}>
                                <div className={classes.suggestedValueTitle}>
                                    <div className={classes.innerSuggestedValueTitle}>
                                        <img className={classes.suggestionSpark} src={SuggestionsIcon} alt="Suggestion" /> 
                                        <span>Sugerencia de cambio</span>
                                    </div>
                                    <img className={classes.copyIcon} src={CopyIcon} alt="Copy" onClick={handleCopySuggestion} /> 
                                </div>
                                <div className={getTextColorType() ? classes.suggestedValueContentSpecial : classes.suggestedValueContent}>
                                    {currentSuggestion.type === 'keywords' ? getHighlightedKeywords('new') : <span>{getHighlightedDescription()}</span>}
                                </div> 
                            </div>
                        </div>
                        <div className={classes.suggestionSummary}>
                            <span>{getSummary()}</span>
                        </div>                        
                    </div>
                </CustomModal>
            )}
        </div>
    );
};

export default SuggestionsModal;
