import React, { useState } from 'react'
import Modal from 'reactstrap/es/Modal'
import { Box, Typography, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import classes from './AddSalesChannel.module.css'
import CloseIcon from '@mui/icons-material/CloseRounded';
import ChannelCircles from '../displayEditClientStores/ChannelsCircles';
import Swal from 'sweetalert2'
import CircularProgress from '@mui/material/CircularProgress';

import felicidades from '../../../../assets/img/onboarding/felicidades.png'
import Error from '../../../../assets/img/onboarding/Error.png'
import Close from "../../../../assets/img/icons/close_black.png"
import { putClientStore } from 'services/stores';

const ModalNewChannel = ({ displayNewChannel, toggleNewChannel, channelsCirclesImg, storeData, createAlert, handleCheckOnboarding = null }) => {

    const [channelsSelected, setChannelsSelected] = useState(storeData.canales_por_onbordear)
    const [loadingClient, setLoadingClient] = useState(false)

    const handleButtonAdd = async () => {
        try {
           
            setLoadingClient(true)
            const cliente = {
                idCliente: storeData.id,
                name: storeData?.tienda,
                namerlegal: storeData?.company_data?.representative_name || "",
                email: storeData?.company_data?.kam_email || "",
                phone: '',
                address: storeData?.company_data?.company_address || "",
                identity: storeData?.company_data?.company_rut || "",
                social_reason: storeData?.company_data?.company_name || "",
                type_of_selling: storeData?.company_data?.company_giro || "",
                model: '',
                kam_selected: storeData?.company_data?.kam_email || '',
                client_country: storeData?.paises[0],
                facturacion: storeData?.company_data?.company_services?.facturacion || false,
                bodega: storeData?.company_data?.company_services?.almacenamiento_bodega_instance || false,
                canales: storeData?.company_data?.company_services?.creacion_canales_de_venta || false,
                servicio: storeData?.company_data?.company_services?.servicio_al_storeDatae || false,
                channelsOnboarding: channelsSelected,
                contratFileName: storeData?.company_data?.company_contract?.location || null
            }
            cliente.contrat = null
            console.log(cliente)
            let dataResult = await putClientStore(cliente)
        
            if (dataResult.status === 200) {
                
                if (handleCheckOnboarding !== null){
                    handleCheckOnboarding({target:{name:"channel"}})
                    toggleNewChannel()
                }
                    

                if (createAlert){
                    toggleNewChannel()
                    createAlert("Woow, eres un editor/a nato", channelsSelected)
                }
                    
            } else {
                Swal.fire({
                    title: 'Error..',
                    text: `Ocurrio un error al modificar los canales`,
                    imageWidth: 100,
                    imageHeight: 150,
                    imageUrl: Error,
                    closeButtonHtml: `<img src="${Close}" />`
                }).then(() => {
                    setLoadingClient(false)
                })
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: 'Error..',
                text: `Ocurrio un error al modificar los canales`,
                imageWidth: 100,
                imageHeight: 150,
                imageUrl: Error,
                closeButtonHtml: `<img src="${Close}" />`
            }).then(() => {
                setLoadingClient(false)
            })
        }

    }

    return (
        <Modal
            isOpen={displayNewChannel}
            toggle={toggleNewChannel}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: '100%', 
                    maxWidth: '570px', 
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 3, 
                    zIndex: 1,
                    borderRadius: 4,
                    
                    '@media (max-width: 600px)': {
                        width: '90%', 
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div></div>
                    <Typography fontFamily='Montserrat' variant="h4" sx={{ ml: 5 }} ><b>Canal de venta</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <CloseIcon onClick={toggleNewChannel} sx={{
                            color: 'black', fontSize: 40, margin: '-2px 0 0', cursor: 'pointer',
                            pointerEvents: loadingClient ? 'none' : '',
                            opacity: loadingClient ? 0.5 : '',
                        }} />
                    </Tooltip>
                </Box>

                <div className={classes.lineWrapper} >
                    <span className={classes.line}></span>
                </div>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography fontFamily='Montserrat' fontSize="20px" align='center' ><b> Selecciona los canales de venta para integrar</b></Typography>
                    <ChannelCircles
                        setChannels={setChannelsSelected}
                        channelsSelected={channelsSelected}
                        listOfChannels={channelsCirclesImg}
                    />


                    <Button disabled={!(!loadingClient)} onClick={handleButtonAdd} variant="contained" sx={{ fontSize: '24px', width: '333px', height: '67px', borderRadius: "20px !important", mt: "50px" }}>
                        {loadingClient && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                        <b>Guardar Cambios</b>
                    </Button>
                </Box>

            </Box>
        </Modal>
    )
}

export default ModalNewChannel