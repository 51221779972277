import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { Skeleton } from "@mui/material";


const QuickSightVisualsLoader = () => {
  return (
    <Container>
        <Row>
            <Col xs={12} md={6} lg={3}>
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={12} md={6} lg={6}>
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={12} md={6} lg={6}>
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={0} md={6} lg={3} className="d-none d-md-block">
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={0} md={6} lg={3} className="d-none d-md-block">
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={0} md={6} lg={6} className="d-none d-md-block">
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
            <Col xs={0} md={6} lg={3} className="d-none d-md-block">
                <Skeleton width="100%" height="100px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
            </Col>
        </Row>
    </Container>
  )
}

export default QuickSightVisualsLoader