import React, { useState } from "react";
import SiIcon from "../../assets/img/si.png";
import noIcon from "../../assets/img/no.png";
import showPdf from "../../assets/img/showPdf.png";
import './OrderMobileCard.module.css';
import greyIcon from "../../assets/img/greyIcon.png";
import classes from "../../../src/views/Orders/mtdi-table.module.css";
import SendMail from "components/modalComponents/sendMail";
import CourierStatus from "../../assets/img/courierStatus.png";
import CorreosChile from "../../assets/img/correos-chile.png";
import Estado from "../../assets/img/Estado.png";
import Etiqueta from "../../assets/img/Etiqueta.png";
import downloadManifest from "../../assets/img/download.png";
import EstadoCourier from "../../assets/img/courier.png";
// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Input,Modal,ModalHeader} from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ClientModal from "components/ClientModal/client-modal";
import { post } from "jquery";
import SendLabel from "components/sendLabelToEmailModal/send-label";
import LabelModal from "components/labelModal/labelModal";
import ManifestoModal from "components/manifestoModal/manifesto-modal";
import CourierStatusModal from "components/courierStatusModal/courier-status-modal";
import BallotDetailModal from "components/BallotDetailModal/BallotDetailModal";
import BallotDetailMobile from "components/BallotDetailMobile/ballot-detail-mobile";
import ErrorHandler from "components/ErrorHandler/error-handler";
import {ErrorBoundary} from 'react-error-boundary'
function OrderMobileCard(props) {
  const { data } = props;
 
  const { isLoading } = props;
  const [showClientModal, setshowClientModal] = React.useState(false);
  const [buyer, setbuyer] = useState("");
  const [labelDetailsModal, setlabelDetailsModal] = useState(false);
  const [horizontalTabs, setHorizontalTabs] = React.useState("home");
  const [verticalTabs, setVerticalTabs] = React.useState("info");
  const [pageTabs, setPageTabs] = React.useState("homePages");
  const [showModal, setshowModal] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [cardData, setcardData] = React.useState(data);
  const [showLabel, setshowLabel] = useState(false);
  const [showManifest, setshowManifest] = useState(false);
  const [displayCourier, setdisplayCourier] = useState(false);
  const [clientDetailsModal, setclientDetailsModal] = useState(false);
  const [manifestDetailModal, setmanifestDetailModal] = useState(false);
  const [courierStatusDetailModal, setcourierStatusDetailModal] = useState(false);
  const [modalBallotDetails, setModalBallotDetails] = useState(false);
  const [openedCollapses, setOpenedCollapses] = React.useState([
    "collapseOne",
    "collapse1",
  ]);
  const toggle2 = (props) => {
setbuyer(props);
setModalBallotDetails(!modalBallotDetails);
  }
  const toggle4 = (props)=>{
    setbuyer(props);
    setlabelDetailsModal(!labelDetailsModal)
  }
  const toggle5 = (props)=>{
    setbuyer(props);
    setclientDetailsModal(!clientDetailsModal);
  }
  const toggle6 = (props)=>{
    setbuyer(props);
    setmanifestDetailModal(!manifestDetailModal);
  }
  const toggle7 = (props)=>{
    setbuyer(props);
    setcourierStatusDetailModal(!courierStatusDetailModal);
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };

  const showModalHandler = (props) => {
    console.log(props);
    setbuyer(props);
    setshowModal(true);
  };
  const showClientModalHandler = (props) => {
    setbuyer(props);
    setshowClientModal(true);
  };
  const hideClientModalHandler = (props) => {
    setshowClientModal(false);
  };
  const hideModalHandler = () => {
    setshowModal(false);
  };
  const showPdfHandler = () => {
    window.open(buyer.dte);
  };
  const showLabelModal = (props) => {
    setbuyer(props);
    setshowLabel(true);
  };
  const hideManifestModalHandler = () => {
    setshowManifest(false);
  };
  const displayCourierModalHandler =(props)=>{
    setdisplayCourier(true);
    setbuyer(props);
  }

  const searchFilter = (event) => {
    setQuery(event.target.value);
  };
  const hidelabelModalHandler = () => {
    setshowLabel(false);
  };
  const showManifestModalHandler = (props) => {
    setbuyer(props);
    setshowManifest(true);
  };
  const hideCourierModalHandler =()=>{
    setdisplayCourier(false);
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
    {displayCourier && (<CourierStatusModal purchaser={buyer} data={data}  onhideModal={hideCourierModalHandler}/>)}
      {showModal && (
        <SendMail
          onhideModal={hideModalHandler}
          data={data}
          purchaser={buyer}
        ></SendMail>
      )}
     
      {/* LABEL MODAL */}
      <Modal isOpen={labelDetailsModal} toggle={toggle4}>
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle4}
              >
                x
              </button>
            </div>
          </ModalHeader>    
          <LabelModal purchaser={buyer}  onhideModal={toggle4}/>
          <br />
        </Modal>
        {/* CLIENT MODAL */}

        <Modal isOpen={clientDetailsModal} toggle={toggle5}>
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle5}
              >
                x
              </button>
            </div>
          </ModalHeader>   
          <ClientModal
          onhideModal={hideClientModalHandler}
          purchaser={buyer}
        ></ClientModal> 
          <br />
        </Modal>
    {/* MANIFEST MODAL */}
        
    <Modal isOpen={manifestDetailModal} toggle={toggle6}>
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle6}
              >
                x
              </button>
            </div>
          </ModalHeader>   
          <ManifestoModal  onhideModal={toggle6}
       purchaser={buyer}/>
          <br />
        </Modal>
         {/* Ballot Detail Modal  */}

         <Modal isOpen={modalBallotDetails} toggle={toggle2} size="lg">
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle2}
              >
                x
              </button>
            </div>
          </ModalHeader>
        
          <BallotDetailMobile purchaser={buyer}/>
          {/* <BallotDetailModal purchaser={buyer} /> */}
          {/* <BallotDetailModal purchaser={buyer} /> */}

          <div className="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "296px",
                height: "64px",
                padding: "22px 81px",
                borderRadius: "33px",
                color: "#FFFFFF",
                marginLeft: "1em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop: "1em",
              }}
              onClick={toggle2}
            >
              Cerrar
            </button>
          </div>
          <br />
        </Modal>
       {/* COURIER STATUS MODAL */}
        
       <Modal isOpen={courierStatusDetailModal} toggle={toggle7}>
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle7}
              >
                x
              </button>
            </div>
          </ModalHeader>   
          <CourierStatusModal purchaser={buyer} onhideModal={toggle7}/>
          <br />
        </Modal>
      {!isLoading && (
        <div className="content">
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              borderRadius: "20px",
              marginBottom: "10px",
            }}
          >
            <Input
              placeholder="Buscar"
              onChange={searchFilter}
              style={{ width: "25%" }}
            />
          </div>
          <Row>
            <Col className="text-center" lg="6" md="12">
              {data
                .filter((post) => {
                  if (query === "") {
                    return post;
                  }
                })
                .map((post, index) => (
                  <Card key={index} className="card-tasks">
                    <CardHeader>
                      {/* <CardTitle tag="h4">Tabla Órdenes</CardTitle>
                <h5 className="card-category">Pedidos</h5> */}
                    </CardHeader>

                    <CardBody>
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              {/* <FormGroup check>
                            <Label check>
                              <Input defaultChecked type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup> */}
                            </td>

                            {/* OPS ID */}
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Ops ID:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {post.orderNo}
                            </td>
                            <td
                              className="td-actions text-right"
                              style={{ marginTop: "15px" }}
                            >
                              <br />
                            </td>
                          </tr>
   {/* DETAILS  */}
   <tr>
                            <td />
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Detalle:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                               <img src={showPdf} onClick={toggle2.bind(this,post)} />
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>

                          {/* CLIENTE   */}
                          <tr>
                            <td />
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {post.store}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>

                          {/* DTE */}
                          <tr>
                            <td />

                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              DTE:
                            </td>
                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {(() => {
                                switch (post.dte) {
                                  case "":
                                    return (
                                      <div>
                                        {" "}
                                        No{" "}
                                        <span className={classes.noIcon}>
                                          <img src={noIcon} />
                                        </span>
                                        <span className={classes.greyIcon}>
                                          <img src={greyIcon} />
                                        </span>
                                      </div>
                                    );

                                  case "-":
                                    return (
                                      <div>
                                        {" "}
                                        No{" "}
                                        <span className={classes.noIcon}>
                                          <img src={noIcon} />
                                        </span>
                                        <span className={classes.greyIcon}>
                                          <img src={greyIcon} />
                                        </span>
                                      </div>
                                    );

                                  default:
                                    return (
                                      <div>
                                        {" "}
                                        Si &nbsp;
                                        <span
                                          style={{
                                            marginLeft: "14px",
                                            cursor: "pointer",
                                          }}
                                          className={classes.si}
                                        >
                                          <img
                                            src={SiIcon}
                                            onClick={showModalHandler.bind(
                                              this,
                                              post
                                            )}
                                          />
                                        </span>
                                        &nbsp;
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className={classes.showPdf}
                                        >
                                          <a
                                            href={post.dte}
                                            target="_blank"
                                            title="Mostrar DTE"
                                          >
                                            <img src={showPdf} />
                                          </a>
                                        </span>
                                      </div>
                                    );
                                }
                              })()}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>

                          {/* RESPUESTA WMS */}
                          <tr>
                            <td />
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Estado WMS:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {(() => {
                                switch (post.statusWms) {
                                  case "Enviado":
                                    return (
                                      <div className={classes.enviado}>
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;Enviado
                                      </div>
                                    );
                                  case "Pendiente":
                                    return (
                                      <div className={classes.pendiente}>
                                        &nbsp;&nbsp;Pendiente
                                      </div>
                                    );
                                  case "No Aplica":
                                    return (
                                      <div className={classes.noAplica}>
                                        &nbsp;&nbsp;No Aplica
                                      </div>
                                    );
                                  default:
                                    return (
                                      <div className={classes.noAplica}>
                                        &nbsp;&nbsp;No Aplica
                                      </div>
                                    );
                                }
                              })()}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                        <br/>
                          <Accordion.Header>
                            <strong>Ver más</strong>
                          </Accordion.Header>
                          <Accordion.Body style={{padding:"0"}}>
                            {/* <div className="table-full-width table-responsive"> */}
                            <Table>
                              <tbody>
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Bodega:
                                  </td>
                                  <td
                                    className="text-left"
                                    id="text-card-mobile"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.warehouse}
                                    <br/>
                                  </td>
                                  {/* <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td> */}
                                </tr>
                                {/* FECHA DE ORDEN */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Fecha orden:
                                  </td>
                                  <td
                                    className="text-left"
                                    id="text-card-mobile"
                                    style={{ fontSize: "9px" }}
                                  >
                                    {post.creationDate}
                                  </td>
                                  {/* <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td> */}
                                </tr>

                                {/* TIENDA */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Tienda Oficial:
                                  </td>

                                  <td
                                    className="text-left"
                                    id="text-card-mobile"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.store}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* CANAL DE VENTA */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Canal de venta:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {post.channelName}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                

                                {/* ORDEN DE COMPRA */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orden de Compra:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "9px" }}
                                  >
                                    {post.orderNo}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* PAÍS */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    País:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.country}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* ESTADO DE PEDIDO */}
                                {/* <tr>
                        <td/>      
                        <td className="text-left" style={{fontSize: "12px", fontWeight:"bold"}}>
                          Estado de Pedido: 
                        </td>
                      
                        <td className="text-left" style={{fontSize: "12px"}}>
                          
                        </td>
                        <td className="td-actions text-right">
                            <br/>
                            <br/>
                        </td>
                       
                      </tr> */}

                                {/* RESPUESTA OMS */}
                                {/* <tr>
                        <td/>      
                        <td className="text-left" style={{fontSize: "12px", fontWeight:"bold"}}>
                          Respuesta OMS: 
                        </td>
                      
                        <td className="text-left" style={{fontSize: "12px"}}>
                          
                        </td>
                        <td className="td-actions text-right">
                            <br/>
                            <br/>
                        </td>
                       
                      </tr> */}

                                {/* HUB DE PAGO */}

                                <tr>
                                  <td></td>
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Hub de pago:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {post.hub}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                {/* LABELS */}

                                <tr>
                                  <td></td>
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Etiquetas/manifiesto:
                                  </td>
                                  {post.manifest === "" && (
                                    <td
                                      className="text-left"
                                      style={{ fontSize: "10px" }}
                                    >
                                      No disponible
                                    </td>
                                  )}
                                  {post.manifest !== "" && (
                                    <td
                                      className="text-left"
                                      style={{ fontSize: "10px" }}
                                    >
                                      <span>
                                        <img
                                          src={Etiqueta}
                                          // onClick={showLabelModal.bind(
                                          //   this,
                                          //   post
                                          // )}
                                          onClick={toggle4.bind(this,post)}
                                        />
                                      </span>{" "}
                                      &nbsp;
                                      <span>
                                        <img
                                          src={downloadManifest}
                                          onClick={toggle6.bind(
                                            this,
                                            post
                                          )}
                                        />
                                      </span>
                                    </td>
                                  )}

                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                {/* TOTAL */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    $ {post.totalWithoutShipping}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                {/* SHIPPING */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Shipping:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    $ {post.totalShipping}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                {/* RESPUESTA WMS */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Respuesta WMS:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {(() => {
                                      switch (post.responseWms) {
                                        case "Error":
                                          return (
                                            <div
                                              className={classes.stockError}
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {" "}
                                              &nbsp;&nbsp;&nbsp;&nbsp;Error de
                                              stock
                                            </div>
                                          );
                                        case "No enviado a WMS":
                                          return (
                                            <div
                                              className={
                                                classes.integratedMobile
                                              }
                                            >
                                              &nbsp;No enviado a WMS
                                            </div>
                                          );
                                        case "Enviado":
                                          return (
                                            <div
                                              className={classes.syncroError}
                                            >
                                              &nbsp;&nbsp;Error de Sincro
                                            </div>
                                          );
                                        default:
                                          return (
                                            <div className={classes.noAplica}>
                                              &nbsp;&nbsp;No Aplica
                                            </div>
                                          );
                                      }
                                    })()}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Hub Fulfillment:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "8px" }}
                                  >
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      {post.hubLogo !== "No aplica" && (
                                        <img
                                          style={{ paddingRight: "6px" }}
                                          src={post.hubLogo}
                                          width="30px"
                                          height="auto"
                                        />
                                      )}
                                      {post.hubFulfillment}
                                    </span>
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Courier:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      {post.courierLogo !== "No aplica" && (
                                        <img
                                          style={{ paddingRight: "8px" }}
                                          src={post.courierLogo}
                                          width="40px"
                                          height="32px"
                                        />
                                      )}
                                      {post.courier}
                                    </span>
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Shipping ID:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {post.shipping_id}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Estado Courier:
                                  </td>
                                  {(() => {
                                    if(post.statusCourier != undefined){
                                  if(post.statusCourier.length ===0){
                                      return (
                                        <td
                                          className="text-left"
                                          style={{ fontSize: "11px" }}
                                        >
                                          Orden creada
                                        </td>
                                      )
                                    }else{
                                      let a = post.statusCourier.map((items, index,arr) => {
                                        if(arr.length-1 === index)
                                        return (
                                          items.status)
                                        
                                      });
                                      let b = a.pop();
                                      return (
                                        <td
                                        className="text-left"
                                        style={{ fontSize: "10px" }}
                                      >
                                        {b} &nbsp;
                                        <span><img src={EstadoCourier} width="60%" onClick={toggle7.bind(this,post)} /></span>
                                      </td>
                                      )
                                    }
                                  }
                                  })()}
                                </tr>
                                <tr>
                                  <td></td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Comprador
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {post.buyerName}
                                    <br />
                                    {post.buyerIdNumber} &nbsp;{" "}
                                    <img
                                      src={Estado}
                                      width="19%"
                                      onClick={toggle5.bind(
                                        this,
                                        post
                                      )}
                                    />
                                  </td>
                                  <td className="td-actions text-right"></td>
                                </tr>

                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </CardBody>
                  </Card>
                ))}
              {data
                .filter((post) => {
                  if (
                    query !== "" &&
                    post.store !== undefined &&
                    post.country !== undefined &&
                    post.channelName !== undefined &&
                    post.orderNo !== undefined &&
                    post.officialStore != null &&
                    post.dte !== undefined &&
                    post.statusWms !== undefined &&
                    post.statusOrder !== undefined &&
                    post.shipping_id !== undefined &&
                    post.buyerName !== undefined &&
                    post.warehouse !== undefined
                  ) {
                    return (
                      post.store.toLowerCase().includes(query.toLowerCase()) ||
                      post.country.toLowerCase().includes(query.toLowerCase()) ||
                      post.channelName
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.orderNo.toString().includes(query) ||
                      post.officialStore
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.dte.toLowerCase().includes(query.toLowerCase()) ||
                      post.statusWms
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.statusOrder
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.shipping_id.toString().includes(query) ||
                      post.buyerName
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.warehouse.toLowerCase().includes(query.toLowerCase())
                    );
                  }
                })
                .map((post, index) => (
                  <Card key={index} className="card-tasks">
                    <CardHeader>
                    </CardHeader>

                    <CardBody>
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                            </td>
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Ops ID:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {post.orderNo}
                            </td>
                            <td
                              className="td-actions text-right"
                              style={{ marginTop: "15px" }}
                            >
                              <br />
                            </td>
                          </tr>

                          {/* CLIENTE   */}
                          <tr>
                            <td />
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {post.store}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>

                          {/* DTE */}
                          <tr>
                            <td />

                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              DTE:
                            </td>
                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {(() => {
                                switch (post.dte) {
                                  case "":
                                    return (
                                      <div>
                                        {" "}
                                        No{" "}
                                        <span className={classes.noIcon}>
                                          <img src={noIcon} />
                                        </span>
                                        <span className={classes.greyIcon}>
                                          <img src={greyIcon} />
                                        </span>
                                      </div>
                                    );

                                  case "-":
                                    return (
                                      <div>
                                        {" "}
                                        No{" "}
                                        <span className={classes.noIcon}>
                                          <img src={noIcon} />
                                        </span>
                                        <span className={classes.greyIcon}>
                                          <img src={greyIcon} />
                                        </span>
                                      </div>
                                    );

                                  default:
                                    return (
                                      <div>
                                        {" "}
                                        Si &nbsp;
                                        {/* <span
                                       style={{ marginLeft: "14px", cursor: "pointer" }}
                                       className={classes.si}
                                     > */}
                                        {/* <img src={SiIcon} onClick={showModalHandler.bind(this, props)}/>
                                     </span> */}
                                        &nbsp;
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className={classes.showPdf}
                                        >
                                          <a
                                            href={post.dte}
                                            target="_blank"
                                            title="Mostrar DTE"
                                          >
                                            <img src={showPdf} />
                                          </a>
                                        </span>
                                      </div>
                                    );
                                }
                              })()}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>

                          {/* RESPUESTA WMS */}
                          <tr>
                            <td />
                            <td
                              className="text-left"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Estado WMS:
                            </td>

                            <td
                              className="text-left"
                              style={{ fontSize: "12px" }}
                            >
                              {(() => {
                                switch (post.statusWms) {
                                  case "Enviado":
                                    return (
                                      <div className={classes.enviado}>
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;Enviado
                                      </div>
                                    );
                                  case "Pendiente":
                                    return (
                                      <div className={classes.pendiente}>
                                        &nbsp;&nbsp;Pendiente
                                      </div>
                                    );
                                  case "No Aplica":
                                    return (
                                      <div className={classes.noAplica}>
                                        &nbsp;&nbsp;No Aplica
                                      </div>
                                    );
                                  default:
                                    return (
                                      <div className={classes.noAplica}>
                                        &nbsp;&nbsp;No Aplica
                                      </div>
                                    );
                                }
                              })()}
                            </td>
                            <td className="td-actions text-right">
                              <br />
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                          <br/>
                            <strong>Ver más</strong>
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* <div className="table-full-width table-responsive"> */}
                            <Table>
                              <tbody>
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Bodega:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.warehouse}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                {/* FECHA DE ORDEN */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Fecha orden:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.creationDate}
                                  </td>
                                </tr>

                                {/* TIENDA */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Tienda:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {post.store}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* CANAL DE VENTA */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Canal de venta:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {post.channelName}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* TIENDA OFICIAL */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Tienda Oficial:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {post.officialStore}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* ORDEN DE COMPRA */}
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orden de Compra:
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {post.orderNo}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>

                                {/* PAÍS */}

                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    País:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {post.country}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    $ {post.totalWithoutShipping}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Estado Fulfillment:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {post.statusOrder}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td />
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Shipping ID:
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {post.shipping_id}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td
                                    className="text-left"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Comprador
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {post.buyerName}
                                  </td>
                                  <td className="td-actions text-right">
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </CardBody>
                  </Card>
                ))}
            </Col>
          </Row>
        </div>
      )}
      </ErrorBoundary>
    </>
  );
}

export default OrderMobileCard;
