import React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import classes from './CreateCombo.module.css'
import Input from './Input'
import ModalCombo from './ModalCombo'
import ModalProduct from './ModalProduct'
import ProductDetail from './ProductDetail'
import TagsInput from "./TagsInput"

import soon from '../../assets/img/SoonImages.png'
import ModalChatGPT from './ModalChatGPT'
import { textCompletion } from 'services/combos'
import { useRef } from 'react'

const CreateCombo = () => {
    const location = useLocation()
    const path = location.pathname
    const pathElements = path.split('/')
    const lastPathElement = pathElements[pathElements.length - 1]
    
    const typeDescription = useRef("")
    const [products, setProducts] = useState([])
    const [showModalCombo, setShowModalCombo] = useState(false)
    const [showModalProduct, setShowModalProduct] = useState(false)
    const [showModalChatGPT, setShowModalChatGPT] = useState(false)
    const [productsInCombo, setProductsInCombo] = useState([])
    const [detailCombo, setDetailCombo] = useState({"type": lastPathElement, "stock_to_reserve": null, "product_distribution": null})
    const [formData, setFormData] = useState(
        {
            "store_id": undefined,
            "ean": "",
            "sku": "",
            "name": "",
            "short_description": "",
            "long_description": "",
            "category_id": undefined,
            "brand_id": "",
            "weight": undefined,
            "height": undefined,
            "width": undefined,
            "length": undefined,
            "warranty": undefined,
            "keywords": [],
            "characteristics": [],
        }
        )
        
        const combo = {...formData, "products_in_promotion": productsInCombo, "suggested_products": [], "promotion_detail": detailCombo}

    const handleModalCombo = () => setShowModalCombo(prevState => !prevState)
    const handleModalProduct = () => setShowModalProduct(prevState => !prevState)
    const handleModalChatGPT = () => setShowModalChatGPT(prevState => !prevState)
    const handleAddProduct = (newProduct, stock, discount, clientPaid = null) => {
        if(products.length === 0) {
            setFormData({...formData, "store_id": newProduct.store_id, "brand_id": newProduct.brand_id, "category_id": newProduct.product_category.category_id})
        }
        if(lastPathElement === "mxn") {
            setDetailCombo({...detailCombo, "product_distribution": { "qty_products_to_pay": clientPaid , "qty_products_to_get": stock}})
        }
        const auxProduct = {...newProduct}
        auxProduct.comboStock = stock
        auxProduct.comboDiscount = discount
        setProducts(prevState => [...prevState, auxProduct])
        setProductsInCombo([...productsInCombo, {"discount_percentage": discount, "quantity": stock, "id_mtdi": newProduct.id_mtdi}])
    }
    const handleDeleteProduct = (id_mtdi) => {
        setProducts(prevState => prevState.filter(p => p.id_mtdi !== id_mtdi))
    }
    
    const handleEAN = (event) => setFormData({...formData, "ean": event.target.value })
    const handleSKU = (event) => setFormData({...formData, "sku": event.target.value })
    const handleName = (event) => setFormData({...formData, "name": event.target.value })
    const handleShortDescription = (event) => setFormData({...formData, "short_description": event.target.value })
    const handleLongDescription = (event) => setFormData({...formData, "long_description": event.target.value })
    const handleWeight = (event) => setFormData({...formData, "weight": event.target.value })
    const handleHeight = (event) => setFormData({...formData, "height": event.target.value })
    const handleWidth = (event) => setFormData({...formData, "width": event.target.value })
    const handleLength = (event) => setFormData({...formData, "length": event.target.value })
    const handleWarranty = (event) => setFormData({...formData, "warranty": event.target.value })
    const handleKeywords = (values) => {
        if(!values)
            return
        let valueAsArray = values
        if(!Array.isArray(values)){
            valueAsArray = [values]
        }
        setFormData({...formData, "keywords": [...formData.keywords, ...valueAsArray] })
    }
    const handleDeleteKeywords = (newKeywords) => {
        setFormData({...formData, "keywords": newKeywords })
    }
    const handleCharacteristics = (values) => {
        if(!values)
            return
        let valueAsArray = values
        if(!Array.isArray(values)){
            valueAsArray = [values]
        }
        setFormData({...formData, "characteristics": [...formData.characteristics, ...valueAsArray] })
    }
    const handleDeleteCharacteristics = (newKeywords) => {
        setFormData({...formData, "characteristics": newKeywords })
    }

    const getDescription = async (type) => {
        const nameInURL = encodeURI(formData.name)
        const typeOfDescription = type.split("-")[1]
        const currentType = `${typeOfDescription}_description`
        const response = await textCompletion(`prompt_id=${type}&prompt_user_input=${nameInURL}`)
        if ( response instanceof Error) {
            return response
        } else {
            setFormData({...formData, [currentType] : response.data.replace(/[\r\n]/gm, '')})
            return response
        }
    }

    const handleTypeDescription = (type) => {
            typeDescription.current = `product-${type}-description`
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.productsWrapper}>
                    <h1 className={classes.mainTitle}>
                        Crear {lastPathElement}
                    </h1>
                    <div className={classes.productsOptions}>
                        <h3 className={classes.mainSubtitle}>
                            Productos asociados a tu {lastPathElement}
                        </h3>
                        <button disabled={products.length === 1 && lastPathElement !== "kit"} className={classes.buttonAddProduct} onClick={handleModalProduct}>
                            <span className={classes.textButtonAddProduct}>+ Agregar producto</span>
                        </button>
                    </div>
                    {products.length === 0 &&
                        <p className={classes.emptyProducts}>
                            No hay productos asociados a tu {lastPathElement}. Haz click en el botón “Agregar producto” para comenzar a crear tu {lastPathElement}.
                        </p>
                    }
                    {products.length > 0 &&
                        <div className={classes.productsInCombo} >
                            {products.map((product) => (
                                <ProductDetail key={product.id_mtdi} product={product} combo={lastPathElement} handleDeleteProduct={handleDeleteProduct} />
                            ))}
                        </div>        
                    }
                </div>
                <div>
                    <h3 className={classes.mainSubtitle} style={{marginBottom: "16px"}}>
                        Información general del nuevo {lastPathElement}
                    </h3>
                    <div className={classes.wrapperInputs}>
                        <div className={classes.mainInputs}>
                            <div className={classes.firstRow}>
                                <Input 
                                    width="300px" 
                                    name="EAN" 
                                    type="text" 
                                    placeholder="Ejemplo: 1186608800" 
                                    handle={handleEAN} 
                                    value={formData.ean} 
                                    tooltip="Código único de identificación de tu producto. "
                                />
                                <Input 
                                    width="300px" 
                                    name="SKU" 
                                    type="text" 
                                    placeholder="Ejemplo: 96281-S" 
                                    handle={handleSKU} 
                                    value={formData.sku}
                                />
                                <Input 
                                    width="300px" 
                                    name="Marca" 
                                    type="text" 
                                    disabled 
                                    placeholder={products.length > 0 ? products[0].brand?.name : "No disponible"} 
                                />
                            </div>
                            <div className={classes.secondRow}>
                                <Input 
                                    width="600px" 
                                    name={`Nombre o título del ${lastPathElement}`} 
                                    type="text" 
                                    placeholder="Ejemplo: Combo de toallas húmedas Babymarc Super Premium" 
                                    handle={handleName} 
                                    value={formData.name} 
                                />
                                <Input 
                                    width="300px" 
                                    name="Categoría" 
                                    type="text" 
                                    disabled 
                                    placeholder={products.length > 0 ? products[0].product_category?.category_detail?.category : "No disponible"}
                                />
                            </div>
                            <div className={classes.thirdRow}>
                                <div onClick={() =>handleTypeDescription("short")}>
                                    <Input 
                                        width="450px" 
                                        name={`Descripción corta del ${lastPathElement}`} 
                                        type="textarea"
                                        handle={handleShortDescription}
                                        handleModalGPT={handleModalChatGPT}
                                        value={formData.short_description}
                                        chatGPT={true}
                                    />
                                </div>
                                <div onClick={()=>handleTypeDescription("long")}>
                                    <Input 
                                        width="450px" 
                                        name={`Descripción larga del ${lastPathElement}`} 
                                        type="textarea"
                                        handle={handleLongDescription}
                                        handleModalGPT={handleModalChatGPT}
                                        value={formData.long_description}
                                        chatGPT={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.soonWrapper}>
                            <img src={soon} />
                        </div>
                    </div>
                    <div className={classes.othersInputs}>
                        <Input width="300px" name="Garantía" type="number" handle={handleWarranty} value={formData.warranty} min="0" placeholder="Meses"/>
                        <Input width="100px" name="Largo" type="number" handle={handleLength} value={formData.length} min="0" placeholder="cms"/>
                        <Input width="80px" name="Ancho" type="number" handle={handleWidth} value={formData.width} min="0" placeholder="cms"/>
                        <Input width="80px" name="Alto" type="number" handle={handleHeight} value={formData.height} min="0" placeholder="cms"/>
                        <Input width="80px" name="Peso" type="number" handle={handleWeight} value={formData.weight} min="0" placeholder="kgs"/>
                        <TagsInput width="300px" label="Keywords" type="text" onKeyDown="Enter" handleKeyDown={handleKeywords} handleDeleteKeywords={handleDeleteKeywords} handle={() => null} tags={formData.keywords} placeholder="Escribe y presiona enter para agregar keywords"/>
                        <TagsInput width="300px" label="Características" type="text" onKeyDown="Enter" handleKeyDown={handleCharacteristics} handleDeleteKeywords={handleDeleteCharacteristics} handle={() => null} tags={formData.characteristics} placeholder="Escribe y presiona enter para agregar Características"/>
                    </div>
                </div>
                <div className={classes.buttonWrapper}>
                    <button 
                        className={classes.buttonGreen} 
                        onClick={handleModalCombo}
                        disabled={ !((lastPathElement === "kit" ? products.length > 1 : products.length > 0 ) && formData.warranty && formData.length && 
                                    formData.weight && formData.width && formData.height && formData.name 
                                    && formData.ean && formData.sku && formData.short_description && formData.long_description
                                    )}
                    >
                        <p className={classes.textButton}>
                            Crear {lastPathElement}
                        </p>
                    </button>
                </div>
            </div>
            {showModalProduct &&
                <ModalProduct 
                    handleModalProduct={handleModalProduct} 
                    handleAddProduct={handleAddProduct} 
                    showModalProduct={showModalProduct} 
                    comboType={lastPathElement}
                />
            }
            {showModalCombo &&
                <ModalCombo 
                    showModalCombo={showModalCombo}
                    handleModalCombo={handleModalCombo}
                    combo={combo}
                    products={products}
                    images={[]}
                />
            }
            {showModalChatGPT &&
                <ModalChatGPT 
                    showModalChatGPT={showModalChatGPT}
                    handleModalChatGPT={handleModalChatGPT}
                    getDescription={() => getDescription(typeDescription.current)}
                    name={formData["name"]}
                    handle={typeDescription.current}
                />
            }
        </>
    )
}

export default CreateCombo