import ProfileHeader from 'components/UI/ProfileHeader'
import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { getMaster, getUrl, postImage, saveImage } from 'services/catalog'
import { getStores } from 'services/stores'
import classes from './UploadImages.module.css'
import { useHistory } from "react-router-dom";

import atention from '../../assets/img/atention.png'
import successIcon from '../../assets/img/success.png'
import airpods from '../../assets/img/airpods.png'
import Trash from '../../assets/img/Trash.png'
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import GlobalError from './GlobalError'
import ModalURL from './ModalURL'

const UploadImages = () => {

    const [showModal, setShowModal] = useState(false)
    const [showModalURL, setShowModalURL] = useState(false)
    const [selectedStore, setSelectedStore] = useState(undefined)
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [errorFiles, setErrorFiles] = useState([])
    const [dragActive, setDragActive] = useState(false)
    const [countError, setCountError] = useState(0)
    const [successFiles, setSuccessFiles] = useState(false)
    const [globalError, setGlobalError] = useState(undefined)
    const [uploadErrors, setUploadErrors] = useState([])
    const [templateURL, setTemplateURL] = useState(undefined)

    const history = useHistory();

    const email = localStorage.getItem("name")

    useEffect(() => {
        getTemplateURL()
        myStores()
    }, [])

    useEffect(() => {
        setCountError(errorFiles.length)
    }, [errorFiles])

    const handleModal = () => setShowModal(prevState => !prevState)
    const handleModalURL = () => setShowModalURL(prevState => !prevState)


    const getTemplateURL = async () => {
        const response = await getMaster("url_products_upload")
        setTemplateURL(response.data[0]?.url)
    }

    const myStores = async () => {
        const response = await getStores(email)
        setStores(response.message)
    }

    const deleteErrorFiles = () => {
        for (let j = 0; j < errorFiles.length; j++) {
            setFiles(prevState => prevState.filter((f) => f.name !== errorFiles[j].nameFile))
        }
    }

    const handleStore = async (event) => {
        setLoading(true)
        setSelectedStore(event.target.value)
        setLoading(false)
    }

    const fileUpload = async (body) => {

        try {

            const response = await getUrl(body)
            if (response instanceof Error) throw response


            let filesForSave = { data: [] }
            for (let i = 0; i < response.data?.urls_data.length; i++) {
                const urlFile = response.data.urls_data[i].url_upload
                const currentFile = files.filter(f => f.name.split(".")[0] === `${response.data.urls_data[i].sku}_${response.data.urls_data[i].position}`)

                const responseS3 = await postImage(urlFile, currentFile[0])
                const [sku, posFormat] = currentFile[0].name.split("_")
                const [position, format] = posFormat.split(".")
                if (responseS3.status === 200) {
                    const urlInS3 = urlFile.split('?')
                    const bodySaveImage = {
                        url: urlInS3[0],
                        description: "",
                        position: position,
                        sku: sku,
                        store_id: parseInt(selectedStore)
                    }
                    filesForSave.data.push(bodySaveImage)
                }
            }
            deleteErrorFiles()
            for (let i = 0; i < response.data?.errors.length; i++) {

                if (uploadErrors.find(f => f.sku === response.data?.errors[i].sku)) continue
                setUploadErrors(prevState => [...prevState, { nameError: codeErrors(response.data?.errors[i].internal_code), sku: response.data?.errors[i].sku }])
            }
            setUploadErrors(prevState => prevState.filter((v, i, a) => a.findIndex(v2 => (v2.sku === v.sku)) === i))
            if (response.data?.urls_data.length === 0) throw new Error("No se pudo subir ninguna imagen. Revisa más abajo el detalle del error.")
            if (filesForSave.length === 0) throw new Error("No se pudo subir ninguna imagen. Revisa más abajo el detalle del error.")
            const save = await saveImage(filesForSave)
            setErrorFiles([])
            if (save instanceof Error) throw save
            for (let i = 0; i < save.data?.errors.length; i++) {
                if (uploadErrors.find(f => f.sku === save.data?.errors[i].sku)) continue
                setUploadErrors(prevState => [...prevState, { nameError: codeErrors(save.data?.errors[i].internal_code), sku: save.data?.errors[i].sku }])
            }
            setUploadErrors(prevState => prevState.filter((v, i, a) => a.findIndex(v2 => (v2.sku === v.sku)) === i))
            setUploadLoading(false)
            setSuccessFiles(true)

        } catch (error) {
            setUploadLoading(false)
            deleteErrorFiles()
            setErrorFiles([])
            setGlobalError(error.message)
        }
    }

    const ImagesUpload = () => {
        setUploadLoading(true)
        setGlobalError(undefined);
        let filesData = { data: [] }

        for (let j = 0; j < files.length; j++) {
            if (!checkError(files[j])) {
                continue
            }
            const [sku, posFormat] = files[j].name.split("_")
            const [position, format] = posFormat.split(".")
            const bodyFile = {
                type: "images",
                folder: "products",
                store_id: parseInt(selectedStore),
                sku: sku,
                position: position,
                file_format: format,
            }
            if (checkError(files[j])) {
                filesData.data.push(bodyFile)
            }
        }

        fileUpload(filesData)


    }

    const codeErrors = (code) => {
        switch (code) {
            case "not_found_sku":
                return "Producto no existe."
            case "product_doesnt_belong_store":
                return "Producto no existe en tienda."
            case "user_cant_modify_product":
                return "Este usuario no tiene los permisos para modificar el producto."
            case "store_not_found":
                return "Tienda no encontrada."
            case "field_required_empty":
                return "Uno o más atributos requeridos están vacíos."
            default:
                return "Error en el servidor. Intenta más tarde."
        }
    }

    const sortByAtt = (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
            return 1;
        return 0;
    }

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    const isNumber = (str) => {
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        if (typeof str === "string") {
            for (let i = 0; i < str.length; i++) {
                if (!numbers.includes(str[i])) {
                    return false
                }
            }
        }
        return true
    }

    const checkName = (str) => {
        if (str.includes("_")) {
            return true
        }
        else {
            return false
        }
    }

    const checkFormat = (str) => {
        const formats = ["jpg", "jpeg"]
        if (!formats.includes(str)) {
            return false
        }
        return true
    }

    const checkError = (file) => {

        if (!checkName(file.name)) {
            setErrorFiles(prevState => [...prevState, { nameError: "Nombre incorrecto", nameFile: file.name }])
            return false
        }
        const [sku, posFormat] = file.name.split("_")
        const [position, format] = posFormat.split(".")
        if (!isNumber(position)) {
            setErrorFiles(prevState => [...prevState, { nameError: "No incluye número de imagen", nameFile: file.name }])
            return false
        }
        if (!checkFormat(format)) {
            setErrorFiles(prevState => [...prevState, { nameError: "Imagen no es Jpeg", nameFile: file.name }])
            return false
        }
        return true
    }

    const handleDrop = (e) => {
        setUploadErrors([])
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setDragActive(false)
            const auxFiles = e.dataTransfer.files

            for (let i = 0; i < auxFiles.length; i++) {
                if (files.find(f => f.name === auxFiles[i].name)) continue
                setFiles(prevState => [...prevState, auxFiles[i]])
                checkError(auxFiles[i])
            }

        }
    }

    const handleFiles = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            if (files.find(f => f.name === e.target.files[i].name)) continue
            setFiles(prevState => [...prevState, e.target.files[i]])
            checkError(e.target.files[i])
        }
    }

    const deleteImage = (e, index) => {
        e.preventDefault()

        setErrorFiles(prevState => prevState.filter((e, i) => e.nameFile !== files[index].name))
        setFiles(prevState => prevState.filter((e, i) => i !== index))
        setUploadErrors(prevState => prevState.filter((e, i) => e.sku !== uploadErrors[index].sku));
        if(countError > 0) setCountError(countError - 1)
        setGlobalError(undefined);
    }

    const closedModal = () => {
        setSuccessFiles(false)
        setCountError(0)
        setFiles([])
        setGlobalError(undefined)
        setErrorFiles([])
        setUploadErrors([])
    }



    return (
        <>
            <div className={classes.wrapper}>
                <ProfileHeader>Cargar las fotos de tu producto</ProfileHeader>
                <p className={classes.text}>Acá puedes subir las fotografías de tus productos de manera masiva. Cumple con los requisitos de carga mínimo que aparecerán una vez que escojas tu tienda.</p>
                <p className={classes.text}>Para comenzar, selecciona la tienda a la que le quieres cargar fotografías:</p>
                <div className={classes.inputLabel}>
                    <label className={classes.label}>
                        Tienda
                    </label>
                    <select
                        name="store"
                        onChange={(choice) => handleStore(choice)}
                        className={classes.select}
                    >
                        <option value="">Seleccionar tienda</option>
                        {stores.length > 0 &&
                            stores.sort(sortByAtt).map((store, i) => (
                                <option key={i} value={store.id}>{store.name}</option>
                            ))
                        }
                    </select>
                </div>
                {selectedStore && !loading && (
                    <div className={classes.buttonsWrapper}>
                        <div className={classes.horizontalButtons}>
                            <button className={classes.button} onClick={handleModal}>
                                <span className={classes.buttonText}>Subir fotografías</span>
                            </button>
                            <button className={classes.buttonTwo} onClick={handleModalURL}>
                                <span className={classes.buttonText}>Cargar URLs</span>
                            </button>
                        </div>
                        <div className={classes.tipsWrapper}>
                            <h4 className={classes.tipsTitle}>Requisitos para subir tu fotografía</h4>
                            <div className={classes.contentWrapper}>
                                <div className={classes.imgTipsWrapper}>
                                    <img className={classes.imgTips} src={airpods} />
                                </div>
                                <div className={classes.textsWrapper}>
                                    <p>Nombre de fotografía debe ser <span className={classes.ean}>SKU_númerodefoto.jpeg</span></p>
                                    <p>Formato .JPG .JPEG</p>
                                    <p>Peso máximo permitido 2mb.</p>
                                    <p>Dimensiones permitidas 1000x1000px </p>
                                    <p>Fondo transparente o blanco.</p>
                                    <p>Intenta subir más de una fotografía por producto.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showModalURL &&
                <ModalURL
                    showModalURL={showModalURL}
                    handleModalURL={handleModalURL}
                    templateURL={templateURL}
                    currentStore={selectedStore}
                />
            }
            {showModal && (
                <Modal isOpen={showModal} toggle={handleModal} size="lg" onExit={closedModal}>
                    <ModalHeader>
                        <div className={classes.modalHeader}>
                            <button className={classes.modalExitButton} onClick={() => {
                                handleModal()
                                closedModal()
                            }}>
                                x
                            </button>
                        </div>
                    </ModalHeader>
                    {uploadLoading &&
                        <div className={classes.spinner}>
                            <img src={spinnerGif} className={classes.imgSpinner} />
                            <h2 className={classes.titleSpinner}>Subiendo imágenes...</h2>
                            <p className={classes.textSpinner}>Esta acción podría tardar un momento;<br />
                                por favor, no cierres esta pestaña mientras se efectúa la carga.</p>
                        </div>
                    }
                    {!uploadLoading && (
                        <div className={classes.wrapperModal}>
                            {globalError && <GlobalError message={globalError} />}
                            {!successFiles &&
                                <>
                                    <h3 className={classes.titleCountImages}>{files.length} imágenes</h3>
                                    <form className={classes.form} onDragEnter={handleDrag}>
                                        <input className={classes.inputHide} type="file" id="files" accept=".jpg, .jpeg" multiple={true} onChange={(e) => handleFiles(e)} />
                                        <label className={dragActive ? classes['dragActive'] : classes['labelForm']} htmlFor="files">
                                            {files.length > 0 && !dragActive &&
                                                <div className={classes.currentFiles}>
                                                    {files.map((file, i) => (
                                                        <div key={i} className={classes.viewFileWrapper}>
                                                            <span className={errorFiles.find(f => f.nameFile === file.name) ? classes['fileError'] : classes['file']}>
                                                                {file.name}
                                                            </span>
                                                            <div className={classes.detailErrorWrapper}>
                                                                <span className={classes.detailError}>
                                                                    {errorFiles.find(f => f.nameFile === file.name) ? errorFiles.find(f => f.nameFile === file.name).nameError : ""}
                                                                </span>
                                                                <div className={classes.imgTrashWrapper} onClick={(e) => deleteImage(e, i)}>
                                                                    <img className={classes.imgTrash} src={Trash} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {!dragActive && files.length === 0 && (
                                                <div>
                                                    <h5 className={classes.titleForm}>Arrastra tus archivos aquí</h5>
                                                    <p className={classes.textForm}>Las fotos deben ser de almenos 1000x1000</p>
                                                </div>
                                            )}
                                        </label>
                                        {dragActive && <div className={classes.events} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                                    </form>
                                </>
                            }
                            {successFiles &&
                                <div className={classes.successWrapper}>
                                    <div className={classes.iconSuccessWrapper}>
                                        <img src={successIcon} />
                                    </div>
                                    <h5 className={classes.titleSuccess}>Las imágenes se han cargado correctamente.</h5>
                                    <div className={classes.successButtons}>
                                        <button
                                            className={classes.button}
                                            onClick={() => {
                                                handleModal()
                                                closedModal()
                                            }}>
                                            <span className={classes.buttonText}>Volver a página de carga</span>
                                        </button>
                                        <button
                                            className={classes.buttonGreenSuccess}
                                            onClick={() => {
                                                handleModal()
                                                closedModal()
                                                history.push('products')
                                            }}>
                                            <span className={classes.buttonGreenText}>Ir a mi panel de productos</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            {successFiles && (uploadErrors.length > 0) &&
                                <div className={classes.successErrorWrapper}>
                                    <div className={classes.atentionWrapper}>
                                        <img className={classes.img} src={atention} />
                                    </div>
                                    <h3 className={classes.titleSuccessError}>
                                        Una o más de las imágenes presentan errores y no se cargaron correctamente. Revisa el listado a continuación y corrige
                                        el error para volver a intentarlo.
                                    </h3>
                                </div>
                            }
                            {((countError + uploadErrors.length) > 0) && (
                                <div className={classes.errorWrapper}>
                                    {countError > 0 &&
                                        <>
                                            <h5 className={classes.titleError}>Atención:</h5>
                                            <p className={classes.textError}>
                                                Hay {countError} archivos que no cumplen los requisitos mínimos y no se cargarán en la base de datos. Revisa la lista para ver los errores.
                                            </p>
                                        </>
                                    }
                                    {uploadErrors.length > 0 &&
                                        <>
                                            <h5 className={classes.titleError}>Listado de errores:</h5>
                                            {uploadErrors.map((error, i) => (
                                                <p key={i} className={classes.textError}>
                                                    {`Error en SKU:  ${error.sku}. Detalle del error: ${error.nameError}`}
                                                </p>
                                            ))}
                                        </>
                                    }
                                </div>)}


                            {!successFiles &&
                                <button className={classes.buttonModal} onClick={ImagesUpload}>
                                    <span className={classes.buttonModalText}>Subir fotos</span>
                                </button>
                            }
                        </div>
                    )}
                </Modal>
            )}
        </>
    )
}

export default UploadImages