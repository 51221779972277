import React, { useState } from 'react'
import classes from './Input.module.css'
import { Tooltip } from 'reactstrap'

import chatGPT from '../../assets/img/icons/chat-gpt.svg'
import info from '../../assets/img/Info.png'

const Input = ( props ) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltip = () => setTooltipOpen(!tooltipOpen);

    const styles ={
        width: props.width
    }

    const stylesInput ={
        width: props.widthInput
    }

    return (
        <div className={classes.wrapper} style={ styles }>
            <label className={classes.label} >
                <div className={classes.inputHeader}>
                    {props.name}
                    {props.tooltip &&
                        <div className={classes.chatWrapper} id={props.name}>
                            <img src={info} className={classes.chatImg} />
                        </div>
                    }
                    {props.tooltip &&
                        <Tooltip
                            isOpen={tooltipOpen}
                            target={props.name}
                            toggle={handleTooltip}
                            style= {{ backgroundColor: "white", color: "black", boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.17)"}}
                        >
                            {props.tooltip}
                        </Tooltip>
                    }
                    {props.chatGPT &&
                        <div className={classes.chatButtonWrapper} onClick={() => props.handleModalGPT()}>
                            <img src={chatGPT} className={classes.chatImg} />
                            <span className={classes.textGPT}>Generar</span>
                        </div>
                    }
                </div>
                {props.type === "textarea" ? 
                    <textarea className={classes.input}  rows="5" onChange={ event => props.handle(event)} value={props.value}/> 
                    :
                    <input 
                        className={classes.input}
                        style={ stylesInput }
                        placeholder={props.placeholder || ""}
                        type={props.type}
                        onChange={ event => props.handle(event)}
                        disabled={props.disabled}
                        onKeyDown={props.onKeyDown}
                        value={props.value}
                        min={props.min}
                        max={props.max}
                    />
                }
            </label>
        </div>
    )
}

export default Input