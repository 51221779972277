import React from 'react';
import CustomModal from '../UI/CustomModal';
import styles from '../UI/CustomModal.module.css';
import thirdModalStyles from './ThirdAlertModal.module.css';
import EmailCheckbox from '../UI/EmailCheckbox';
import TermAndConditions from '../UI/TermAndConditions';
import Plus from "../../assets/img/icons/plus-icon.svg";

const ThirdAlertModal = ({ thirdModal, toggleThirdModal, handleThirdModal, handleCloseThirdModal, handleAddEmailModalReturn, setCheckedEmails, setIsCheckedTerms, handleFourthModal, emails, checkedEmails, isCheckedTerms }) => {
  return (
    <CustomModal
      isOpen={thirdModal}
      toggle={toggleThirdModal}
      onBack={handleThirdModal}
      onClose={handleCloseThirdModal}
      title="Crear alerta"
      customStyles={{ box: thirdModalStyles.customModal }}
      customClassNames={{
        form: thirdModalStyles.modalForm,
        header: thirdModalStyles.crearAlertaParent,
        title: thirdModalStyles.crearAlerta,
        closeWrapper: thirdModalStyles.closeWrapper,
        backIcon: thirdModalStyles.backIcon,
        closeIcon: thirdModalStyles.closeIcon,
        separatorContainer: thirdModalStyles.separatorContainer,
        separator: thirdModalStyles.separator,
        bodyContainer: thirdModalStyles.bodyContainer,
        footerContainer: thirdModalStyles.footerContainer,
        box: (checkedEmails?.length > 0 && isCheckedTerms)  ? styles.afterElementThird : styles.afterElementSecond
      }}
      footerActions={
        <div
          className={`${thirdModalStyles.botonLogin} ${(checkedEmails?.length > 0 && isCheckedTerms) ? thirdModalStyles.enabled : ''}`}
          onClick={(checkedEmails?.length > 0 && isCheckedTerms) ? handleFourthModal : null}
        >
          <b className={thirdModalStyles.siguiente}>Siguiente</b>
        </div>
      }
    >
      <div className={thirdModalStyles.seleccionaDestinatarioContainer}>
        <div className={thirdModalStyles.seleccionaDestinatario}>Selecciona un destinatario</div>
      </div>
      <div className={thirdModalStyles.addEmailButtonContainer}>
        <div className={thirdModalStyles.addEmailButton}>
          <div onClick={handleAddEmailModalReturn} className={thirdModalStyles.addEmailButtonElements}>
            <img className={thirdModalStyles.plusIcon} src={Plus} />
            <b className={thirdModalStyles.addEmail}>Agregar correo</b>
          </div>
        </div>
      </div>
      <div className={thirdModalStyles.emailsContainer}>
        {emails && emails.map((email, index) => (
          <EmailCheckbox 
            key={index}
            email={email}
            checked={false}
            checkedEmails={checkedEmails}
            setCheckedEmails={setCheckedEmails}
          />
        ))}
      </div>
      <div className={thirdModalStyles.terminosCondicionesContainer}>
        <div className={thirdModalStyles.terminosCondiciones}>
          <TermAndConditions
            isCheckedTerms={isCheckedTerms}
            setIsCheckedTerms={setIsCheckedTerms}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default ThirdAlertModal;
