import React, { useEffect, useState } from "react";
import { getWmsParameters } from "services/wms";
import AddFulfillmentForm from "./add-fulfillment-form";
import AddWms from "./add-wms";
import CurrentFulfillment from "./current-fulfillment";


import InstanceFulfillment from "./InstanceFulfillment";

const AdminFulfillment = ({
  fetchStoreDetails,
  storeData,
  backToStoreDetail,
}) => {
  const [routeBackoffice, setRouteBackoffice] = useState("current");
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [instanceWms, setinstanceWms] = useState(false);
  const [OwnWms, setOwnWms] = useState(false);
  const [AllWmsConfiguration, setAllWmsConfiguration] = useState([]);
  const [countries, setCountries] = useState([]);
  const [channelsWithoutWms, setchannelsWithoutWms] = useState([]);
  const [currentWMS, setcurrentWMS] = useState(null);
  const [isAllWmsDisabled, setisAllWmsDisabled] = useState(false);
  const [isAllWmsIntegrated, setisAllWmsIntegrated] = useState(false);
  const [editInputData, seteditInputData] = useState([]);

  useEffect(() => {
    getinstanceWms(storeData);
    getParameters();
  }, []);

  const getinstanceWms = (data) => {
    seteditInputData(data);
  
    let channelsWithoutWms=[];
    let ownWmsArray = [];
    let instanceWmsArray = [];
    let countryArray = [];
    let auxCountries = [];
    let allWmsConfiguration = [];
 
    data.channels.map((channel) => {
 
    if(channel.channel_config?.habilitado !== false){
      if (
        channel.channel_config.wms[0].wmsInstance === true      
      ) {
        instanceWmsArray.push({
          channelId: channel.channel_config?.channelId,
          channelName: channel.channel_config?.channelName,
          wmsCompanyName: "Instance",
          wmsCompanyId: 0,
          wmsHabilitado: channel.channel_config?.wms[0].habilitado,
          wmsInstance:  channel.channel_config?.wms[0].wmsInstance
        });
      }else if( channel.channel_config.wms[0].name !== ''){
        ownWmsArray.push({
              channelId: channel.channel_config?.channelId,
              channelName: channel.channel_config?.channelName,
              wmsCompanyName: channel.channel_config?.wms[0].name, 
              wmsHabilitado: channel.channel_config?.wms[0].habilitado,
              wmsInstance:  channel.channel_config?.wms[0].wmsInstance
            });
           
      }
      if( channel.channel_config.wms[0].wmsInstance === undefined){
        channelsWithoutWms.push({
          channelId: channel.channel_config?.channelId,
          channelName: channel.channel_config?.channelName,
        })
      }
      
    }
   
     
      if (channel.channel_config?.habilitado) {
        if (channel.channel_config?.paises.length > 1) {
          auxCountries.concat(channel.channel_config?.paises);
        } else {
          auxCountries.push(channel.channel_config?.paises[0]);
        }
      
      }
    }); 
    allWmsConfiguration = [...instanceWmsArray, ...ownWmsArray]; 
   
    let disabledwms = allWmsConfiguration.filter(
      (item) => item.wmsCompanyName !== undefined
    );
  
setchannelsWithoutWms(channelsWithoutWms);
    setinstanceWms(instanceWmsArray);
    setOwnWms(ownWmsArray);
    setAllWmsConfiguration(allWmsConfiguration);
   
    
    if (disabledwms.length === 0) {
      setisAllWmsDisabled(true)
    }else setisAllWmsDisabled(false);
    const uniqueCountries = auxCountries.filter((x, i, a) => a.indexOf(x) == i);
    setCountries(uniqueCountries);
  };

  const updateStoreData = async (id) => {
    setLoading(true);
    const response = await fetchStoreDetails(id);
   
    getinstanceWms(response);
    setLoading(false);
  };

  const getParameters = async () => {
    const initialParameters = await getWmsParameters();
    const enabledParameters = await initialParameters.filter(
      (b) => b.config_mtd?.enabled === true
    );
  

    setParameters(enabledParameters);
    setLoading(false);
  };

  const handleRoute = (route) => { 
   
    setRouteBackoffice(route)
  }
 

  const handleCurrent = (id) => setcurrentWMS(id);

  return (
    <>
      {routeBackoffice === "current" && (
        <CurrentFulfillment
          loading={loading}
          handleCurrent={handleCurrent}
          handleRoute={handleRoute}
          backToStoreDetail={backToStoreDetail}
          instanceWms={instanceWms}
          parameters={parameters}
          OwnWms={OwnWms}
          allWmsConfiguration={AllWmsConfiguration}
          isAllWmsDisabled={isAllWmsDisabled}
        />
      )}
      {routeBackoffice === "add" && (
        <AddWms
          countries={countries}
          handleRoute={handleRoute}
          instanceWms={instanceWms}
          parameters={parameters}
          OwnWms={OwnWms}
          handleCurrent={handleCurrent}
          allWmsConfiguration={AllWmsConfiguration}
          isAllWmsDisabled={isAllWmsDisabled}
        />
      )}
         {routeBackoffice === 'addform' &&   
            <AddFulfillmentForm
              channelsWithoutWms={channelsWithoutWms}
              countries={countries}
                currentWMS={currentWMS}
                handleRoute={handleRoute}
                storeData={storeData}
                parameters={parameters}
                instanceWms={instanceWms}
                OwnWms={OwnWms}
                updateStoreData={updateStoreData}
                mode="connect"
                allWmsConfiguration={AllWmsConfiguration}
            />
        }
           {routeBackoffice === 'instance' &&   
            <InstanceFulfillment 
            countries={countries}
            channelsWithoutWms={channelsWithoutWms}
                handleRoute={handleRoute}
                instanceWms={instanceWms}
                OwnWms={OwnWms}
                storeData={storeData}
                updateStoreData={updateStoreData}
                allWmsConfiguration={AllWmsConfiguration}
                mode="connect"
            />
        }
         {routeBackoffice === 'editinstance' &&   
            <InstanceFulfillment
            channelsWithoutWms={channelsWithoutWms}
                handleRoute={handleRoute}
                OwnWms={OwnWms}
                instanceWms={instanceWms}
                storeData={storeData}
                updateStoreData={updateStoreData}
                mode="delete"
                allWmsConfiguration={AllWmsConfiguration}
              
            />
        }
         {routeBackoffice === 'editform' &&   
            <AddFulfillmentForm
            channelsWithoutWms={channelsWithoutWms}
            countries={countries}
              currentWMS={currentWMS}
              handleRoute={handleRoute}
              storeData={storeData}
              parameters={parameters}
              instanceWms={instanceWms}
              OwnWms={OwnWms}
              updateStoreData={updateStoreData}
              editInputData={editInputData}
              mode="edit"
            />
        }
     
    </>
  );
};

export default AdminFulfillment;
