import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { authUser } from "services/auth";
import AuthContext from "store/auth-context";
import classes from "./Login.module.css"

import close from "../../assets/img/close.svg";
import back from "../../assets/img/back.svg";
import { forgetUser } from "services/auth";
import ForgetSuccess from "./ForgetSuccess";
import NewPasswordSuccess from "./NewPasswordSuccess";
import NewPassword from "./NewPassword";
import { changePassword } from "services/auth";
import { Spinner } from "reactstrap";

var CryptoJS = require("crypto-js");

const Login = () => {

  const authCtx = useContext(AuthContext)
  const [error, setError] = useState(false)
  const [view, setView] = useState("sign-in")
  const [loading, setLoading] = useState(false)
  const userNameInput = useRef("")
  const userPasswordInput = useRef("")
  const userForgetEmail = useRef("")
  const userNewPassword = useRef("")
  const history = useHistory()
  

  const handleKeyPress = (event) => { if (event.key === 'Enter') { loginHandler() }}
  const handleKeyPressForget = (event) => { if (event.key === 'Enter') { changePass() }}

  const handleInput = () => setError(false)

  const forgetPass = async () => {
    setError(false);
    setLoading(true);
    try {
      const response = await forgetUser(userForgetEmail.current.value);
      console.log("forgetUser response: ", response);
      if (response === "Success") {
        setView("forget-success");
      } else {
        throw new Error("Error en el servidor");
      }
    } catch (error) {
      console.error("Error in forgetPass: ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  console.log("error: ", error);

  const changePass = async () => {
    setError(false)
    setLoading(true)
    const hash = CryptoJS.SHA256(userNewPassword.current.value);
    const encryptedPassword = hash.toString(CryptoJS.enc.Base64);
    try {
      const response = await changePassword(encryptedPassword)
      if (response === "Success") {
        setView("newpassword-success")
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }
   
  const loginHandler = async () => {
    setLoading(true)
    setError(false)
    const hash = CryptoJS.SHA256(userPasswordInput.current.value);
    const encryptedPassword = hash.toString(CryptoJS.enc.Base64);
    const response = await authUser(userNameInput.current.value, encryptedPassword)
    try {
      if (response === "Usuario o contraseña inválidos") {
        setError(true)
      } 
      else if (response.message === "Autorizado") {
        if (response.user.password_enabled === true && response.user.enabled === true) {
          setLocalStorageAuthorizedUser(response)
          authCtx.login(response.access_token)
          history.push("admin/orders")
        }
        if (response.user.password_enabled === false) {
          localStorage.setItem("TokenTemporary", response.access_token);
          localStorage.setItem("UserTemporary", response.user.email);
          setView("temporary-pass")
        }
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }



const setLocalStorageAuthorizedUser = (loginResponseData) => {
  if (loginResponseData.user.first_name !== undefined) {
    localStorage.setItem("first", loginResponseData.user.first_name)
  }
  if (loginResponseData.user.last_name !== undefined) {
    localStorage.setItem("last", loginResponseData.user.last_name)
  }
  if (loginResponseData.user.email !== undefined) {
    localStorage.setItem("dtm", loginResponseData.user.email);
    localStorage.setItem("name", loginResponseData.user.email);
  }
  if (loginResponseData.user.profile !== undefined) {
    localStorage.setItem("ut", loginResponseData.user.profile);
  }
  if (loginResponseData.user.countries !== undefined) {
    localStorage.setItem("ct", loginResponseData.user.countries);
  }
  if (loginResponseData.user.countries !== undefined) {
    localStorage.setItem("st", loginResponseData.user.stores);
  }
  localStorage.setItem("AccessToken", loginResponseData.access_token);
  localStorage.setItem("Token", loginResponseData.access_token);
}

  return (
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <Helmet>
              <title> Login </title>
              <meta name="title" content="Login" />
              <meta name="description" content="Login" />

              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={window.location.pathname + window.location.search}
              />
              <meta property="og:title" content="Login" />
              <meta
                property="og:description"
                content="Login"
              />
              <meta
                property="og:image"
                content="https://instancebucket.s3.amazonaws.com/imagenes/Instance_expertos_en_FullCommerce.png"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content={window.location.pathname + window.location.search}
              />
              <meta property="twitter:title" content="Login" />
              <meta
                property="twitter:image:alt"
                content="https://instancebucket.s3.amazonaws.com/imagenes/Instance_expertos_en_FullCommerce.png"
              />
            </Helmet>
            {view === "sign-in" &&
              <div className={classes.loginWrapper} >
                <div className={classes.header}>
                  <div className={classes.iconWrapper}></div>
                  <h1>Iniciar sesión</h1>
                  <a href="https://www.instancelatam.com" className={classes.iconWrapper}><img src={close} className={classes.iconHeader} /></a>
                </div>
                <div className={classes.divider}></div>
                <h2>Ingresa tu dirección de correo y contraseña</h2>
                <label className={error ? classes.labelError : classes.label} >
                  <div className={classes.inputHeader}>
                      Correo
                  </div>
                  <input
                    ref={userNameInput} 
                    className={error ? classes.inputError : classes.input}
                    placeholder="Ingresa aquí tu correo"
                    type="email"
                    onKeyPress={handleKeyPress}
                    onInput={handleInput}
                  />
                </label>
                <label className={error ? classes.labelError : classes.label}  >
                  <div className={classes.inputHeader}>
                    Contraseña
                  </div>
                  <input
                    ref={userPasswordInput}
                    className={error ? classes.inputError : classes.input}
                    placeholder="Ingresa aquí tu contraseña"
                    type="password"
                    onKeyPress={handleKeyPress}
                    onInput={handleInput}
                  />
                  {error && <span className={classes.error}>Vaya... el correo y/o contraseña son incorrectos.<br/>
                   ¡Intentalo de nuevo!</span>}
                </label>
                <div></div>
                <span className={classes.forget} 
                  onClick={() => { 
                    setError(false)
                    setView("recovery")                    
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </span>
                <button  disabled={loading} className={classes.loginButton} onClick={loginHandler}>
                  {loading &&
                  <>
                    <Spinner color="success" style={{ margin: "0", width: "30px", height: "30px" }}/>
                    Iniciando
                  </>
                  }
                  {!loading && <>Iniciar sesión</>}
                </button>
                <button disabled className={classes.newAccount}>Crear cuenta</button>
              </div>
            }
            {view === "recovery" && (
              <div className={classes.loginWrapper}>
                <div className={classes.header}>
                  <div className={classes.iconWrapper}>
                    <img
                      src={back}
                      onClick={() => setView("sign-in")}
                      style={{ cursor: "pointer" }}
                      className={classes.iconHeader}
                    />
                  </div>
                  <h1 className={classes.h1Recovery}>Recuperar contraseña</h1>
                  <a href="https://www.instancelatam.com" className={classes.iconWrapper}>
                    <img src={close} className={classes.iconHeader} />
                  </a>
                </div>
                <div className={classes.divider}></div>
                <h2>Ingresa tu dirección de correo</h2>
                <label className={error ? classes.labelError : classes.label}>
                  <div className={classes.inputHeader}>Correo</div>
                  <input
                    ref={userForgetEmail}
                    className={error ? classes.inputError : classes.input}
                    placeholder="Ingresa aquí tu correo electrónico"
                    type="email"
                  />
                  {error && (
                    <span className={classes.error}>
                      Vaya... el correo que ingresaste es incorrecto.<br />
                      ¡Intentalo de nuevo!
                    </span>
                  )}
                </label>
                <button disabled={loading} className={classes.loginButton} onClick={forgetPass}>
                  {loading && (
                    <>
                      <Spinner color="success" style={{ margin: "0", width: "30px", height: "30px" }} />
                      Enviando
                    </>
                  )}
                  {!loading && <>Enviar</>}
                </button>
              </div>
            )}
            {view === "new-password" &&
              <div className={classes.loginWrapper}>
                <div className={classes.header}>
                  <div className={classes.iconWrapper}></div>
                  <h1 className={classes.h1Recovery}>Crea una contraseña</h1>
                  <a href="https://www.instancelatam.com" className={classes.iconWrapper}><img src={close} className={classes.iconHeader} /></a>
                </div>
                <div className={classes.divider}></div>
                <h2>Ingresa una contraseña nueva</h2>
                <label className={error ? classes.labelError : classes.label}  >
                  <div className={classes.inputHeader}>
                    Contraseña
                  </div>
                  <input
                    ref={userNewPassword}
                    className={error ? classes.inputError : classes.input}
                    placeholder="Ingresa aquí tu contraseña nueva"
                    type="password"
                    onKeyPress={handleKeyPressForget}
                    onInput={handleInput}
                  />
                </label>
                <label className={error ? classes.labelError : classes.label}  >
                  <div className={classes.inputHeader}>
                  Repite la contraseña
                  </div>
                  <input
                    className={error ? classes.inputError : classes.input}
                    placeholder="Repite aquí tu contraseña nueva"
                    type="password"
                    onKeyPress={handleKeyPressForget}
                    onInput={handleInput}
                  />
                  {error && <span className={classes.error}>Recorcholis... algo salio mal, intentalo de nuevo más tarde.</span>}
                </label>
                <button disabled={loading} className={classes.loginButton} onClick={changePass}>
                  {loading &&
                    <>
                      <Spinner color="success" style={{ margin: "0", width: "30px", height: "30px" }}/>
                      Cambiando
                    </>
                  }
                  {!loading && <>Cambiar</>}
                </button>
              </div>
            }
            {view === "forget-success" &&<ForgetSuccess setView={setView} />}
            {view === "newpassword-success" &&<NewPasswordSuccess setView={setView} />}
            {view === "temporary-pass" &&<NewPassword setView={setView} />}
            </div>
        </div>
  );
};

export default Login;
