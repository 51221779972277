import React, { useEffect, useState } from 'react'

//libraries
import Modal from 'reactstrap/es/Modal'
import { Box, Typography } from '@mui/material'

//images
import closeImg from "../../../../../assets/img/icons/close_black.png"

//services
import { createChannel, updateChannel } from 'services/channels'

//components
import SuccessIntegrationChannel from "../ResultsIntegrationChannel/SuccessIntegrationChannel"
import FormCreateNewChannel from '../FormCreateNewChannel'
import ErrorIntegrationChannel from '../ResultsIntegrationChannel/ErrorIntegrationChannel'

const ChannelByCredentialsIntegrationModal = ({ 
    showModal,
    channel,
    storeData,
    handleToggle,
    setChannelsParameters,
    fetchStoreDetails,
    isEdit,
    setDisplayModalIntegrations,
    setIsEdit,
    handleSetUpdatingStoreDataForChannel 
}) => {

    const [errors, setErrors] = useState({})
    const [credentialsData, setCredentialsData] = useState({})
    const [showIntegrationSuccess, setShowIntegrationSuccess] = useState(false)
    const [showIntegrationError, setShowIntegrationError] = useState(false)
    const [creatingOrModifyingChannel, setCreatingOrModifyingChannel] = useState(false)


    useEffect(() => {
        setEmptyErrors()
        setEmptyCredentialsData()
    }, [])

    const setEmptyErrors = () => {
        const obj = {}

        for(const att of channel?.config_mtd?.data ||[]){
            obj[att.name] = false
        }

        setErrors(obj)

    }
    const setEmptyCredentialsData = () => {
        const obj = {}

        for(const att of channel?.config_mtd?.data ||[]){
            if(!isEdit){
                obj[att.name] = null
            }
            else{
                const channnelConfigFound = storeData?.channels?.find(e => e?.channel_config.channelId == channel.id)
                if(channnelConfigFound?.channel_config?.urls){
                    obj[att.name] = channnelConfigFound?.channel_config?.urls[att.name]
                }
            }
        }

        setCredentialsData(obj)

    }

    const handleCloseModal = () => {
        handleToggle(false)
        setDisplayModalIntegrations(false)
        setIsEdit(false)
    }

    const updateData = async (storeData) => {
        const storeDataUpdated = await fetchStoreDetails(storeData.id)
        setChannelsParameters(null, storeDataUpdated, true)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const hasErrors = checkErrors()

        if(!hasErrors){
            const body = [{
                channel: channel.id,
                paises: storeData.paises,
                urls: credentialsData
            }]

            setCreatingOrModifyingChannel(true)
            handleSetUpdatingStoreDataForChannel(channel.id, true)
            let response = null
            if(!isEdit)
                response = await createChannel( storeData.id, body )
            else{
                response = await updateChannel( storeData.id, body )
            }
            setCreatingOrModifyingChannel(false)
             
            const resultFound = response?.results?.find( resultNewChannel => resultNewChannel?.channelId != null && resultNewChannel?.channelId == channel.id)
            if(resultFound?.result === "Success"){
                setShowIntegrationError(false)
                setShowIntegrationSuccess(true)
                updateData(storeData).
                    then(() => {
                    }).catch(error => {
                        handleSetUpdatingStoreDataForChannel(channel.id, false)
                        console.log(error)
                    })
            }
            else{
                setShowIntegrationError(true)
                setShowIntegrationSuccess(false)
            }
        }
    }


    const checkErrors = () => {
        let hasErrors = false 
        for(const att of channel?.config_mtd?.data ||[]){
            if(att.required && ( !credentialsData[att.name] || !credentialsData[att.name]?.trim() )){
                hasErrors = true
                setErrors((prev) => {
                    return { ...prev, [att.name]: true }
                });
            }
        }

        return hasErrors
    }

    const handleOnChangeInput = (event) =>{
        const { name, value } = event.target;

        setCredentialsData((prev) => {
            return { ...prev, [name]: value }
        });
    }


    return (
        <Modal
            isOpen={showModal}
            toggle={handleToggle}
            aria-labelledby="modal-title"
        >
            <Box
                    id="modal-content"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: { xs: "400px", md: "570px"},
                        bgcolor: 'white',
                        boxShadow: 24,
                        p: 6,
                        zIndex: 1,
                        borderRadius: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: "relative"
                        }}
                    >
                        <img
                            src={ closeImg }
                            style={{ position: "absolute", top: "2px", right: "0px", paddingTop: 5, cursor: "pointer" }}
                            alt="close icon"
                            onClick={ handleCloseModal }
                        />
                        <Typography variant="h4" ><b>{ channel.canal }</b></Typography>
                        
                        <hr style={{ width: 59, color: "#57C2BD", borderTopWidth: "5px", opacity: 1 }}/>
                        {!showIntegrationSuccess && !showIntegrationError ?
                            <>
                                <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: "20px" }}>Completa la información con tus datos para {isEdit ? "modificar" : "conectar"} tu tienda.</Typography>
                                < FormCreateNewChannel 
                                    channel={channel}
                                    handleSubmit={handleSubmit}
                                    errors={errors}
                                    handleOnChangeInput={handleOnChangeInput}
                                    creatingOrModifyingChannel={creatingOrModifyingChannel}
                                    isEdit={isEdit}
                                    storeData={storeData}
                                    credentialsData={credentialsData}
                                />
                            </>
                        :
                            null
                        }
                        {showIntegrationSuccess ? 
                            <SuccessIntegrationChannel
                                handleToggleModalIntegration={handleToggle}
                                isEdit={isEdit}
                                setDisplayModalIntegrations={setDisplayModalIntegrations}
                            />
                        :
                            null
                        }
                        {showIntegrationError ? 
                            <ErrorIntegrationChannel
                                handleToggleModalIntegration={handleToggle}
                                setDisplayModalIntegrations={setDisplayModalIntegrations}
                            />
                        :
                            null
                        }
                    </Box>

                </Box>
        </Modal>
    )
}

export default ChannelByCredentialsIntegrationModal
