import React, { useEffect } from 'react'
import {
    Box, Typography, Card,
    CardContent, Grid, useMediaQuery
} from '@mui/material'
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Check from "../../../../../assets/img/icons/checkGreen.png"
import { styled } from "@mui/material/styles";
const CardTask = ({ name, text, canCheck, checkValue, buttonComplete, actionComplete, handleCheck }) => {

    const CardContentNoPadding = styled(CardContent)(`
        &:last-child {
            padding-bottom: 16px;
        }
    `);

    const isMobile = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        console.log(canCheck)
    }, [])


    return (
        <Card
            sx={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.4)', // Ajusta los valores según tus preferencias
                backgroundColor: checkValue ? '#E9E9E9' : ''
            }}>
            <CardContentNoPadding
                id="cardContent"
                sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: "50px" }}
            >
                <Grid container >
                    <Grid item xl={8.5} lg={8.5} md={8.5} xs={9}>
                        <FormControlLabel control={
                            <Checkbox
                                disabled={!canCheck}
                                onChange={handleCheck}
                                checked={checkValue}
                                style={{ color: "#D3D3D3" }}
                                size="small" name={name}
                                color="info"
                                checkedIcon={<CheckBoxOutlinedIcon sx={{ color: "#BCBCBC", backgroundColor: "#E9E9E9" }} />}
                            />} label={<Typography style={{ textDecoration: checkValue ? 'line-through' : '', fontSize: isMobile ? '14px': '' }} fontFamily='Montserrat' >{text}</Typography>}
                        />
                    </Grid>
                    <Grid item xl={3.5} lg={3.5} md={3.5} xs={3}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                        {checkValue ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >   {!isMobile && <Typography style={{ fontSize: "13px" }} fontFamily='Montserrat' >¡Completado!</Typography>}
                                
                                <div style={{ marginLeft: '8px' }}>
                                    <img src={Check} alt="check-card" width={'20px'} />
                                </div>

                            </Box>
                            :
                            buttonComplete &&
                            <Button onClick={actionComplete} variant="outlined" sx={{ fontSize: isMobile?'10px':'12px', borderRadius: "10px", padding: isMobile? "1px 35px 1px 35px":"1px 4px 1px 4px" }}  >
                                <b>¡Complétalo!</b>
                            </Button>}
                    </Grid>
                </Grid>
            </CardContentNoPadding>
        </Card>
    )
}

export default CardTask