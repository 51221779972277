//Libraries
import React, { useState, useEffect, useRef, useCallback } from 'react'
import moment from 'moment'

//MUI Libraries
import { Skeleton } from '@mui/material';
import AlertSeller from 'components/UI/AlertSeller';
import CloseIcon from '@mui/icons-material/Close';

//Components
import classes from './DownloadsCenter.module.css'
import CustomTooltip from '../UI/CustomTooltip';
import websocketHelper from "../../utils/websockets"
import { notificationTooltipContent } from '../../utils/tooltipContent'

//Images
import unfoldToRigthIcon from "../../assets/img/icons/unfold-to-right.svg"
import unfoldToLeftIcon from "../../assets/img/icons/unfold-to-left.svg"
import infoFortooltipIcon from '../../assets/img/icons/info-tooltip.svg'
import downloadEnabledIcon from "../..//assets/img/icons/download-enabled.svg"
import downloadDisabledIcon from "../..//assets/img/icons/download-disabled.svg"
import statusSuccess from '../../assets/img/check_verde.png'
import statusFailure from '../../assets/img/not-checked.png'
import statusProcessing from '../../assets/img/pendiente.png'

//Services
import { getDocumentDownloads } from 'services/catalog'
import { getNotifications, updateNotificationSeenStatus } from 'services/alerts'

const DownloadsCenter = ({ setActionsForDownloads, actionsForDownloads }) => {

    const [isHidden, setisHidden] = useState(true)
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true)
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(true)
    const [documentsDownloads, setDocumentDownloads] = useState([])
    const [notificationsList, setNotificationsList] = useState([])
    const [showAlert, setshowAlert] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertType, setAlertType] = useState("")
    const [defaultView, setDefaultView] = useState("descargas")
    const hasUnseenNotifications = notificationsList.some(item => item.has_seen === false);


    const handleClickUnfold = () =>{
        if(defaultView === 'notificaciones' && !isHidden && hasUnseenNotifications){            
            updateMyNotifications();
        }

        setDefaultView("descargas")
        setisHidden((prevValue) => !prevValue)
    }

    const getAllDocumentDownloads = async () => {
        setIsLoadingDocuments(true)
        const response = await getDocumentDownloads()
        setIsLoadingDocuments(false)
        if (!response.error) {
            setDocumentDownloads(response?.data?.data || [])            
        }
    }

    const getMyNotifications = async () => {
        setIsLoadingNotifications(true)
        const response = await getNotifications(`?user=${localStorage.getItem("name")}`)        
        setIsLoadingNotifications(false)

        if (!response.error) {
            setNotificationsList(response?.data || [])            
        }
    }

    const updateMyNotifications = async () => {                
        const ids = notificationsList.filter(notification => !notification.has_seen).map(notification => notification.id);
        const response = await updateNotificationSeenStatus({ ids }); 

        if (!response.error) {
            getMyNotifications()            
        }
    }

    const downloadExcelFile = (fileURL) => {
        if(fileURL)
          window.open(fileURL)
    }

    const parseDocumentType = (type) => {
        const typesMap = {
            "catalog": "Catálogo PIM",
            "publications": "Publicaciones"
        }

        return typesMap[type] || type
    }

     /* websocket */
     const socket = useRef(null)

     const onSocketMessage = useCallback((data) => {
 
         if(data){
             if(data.messageType === "reportIsReady"){                
                createAlert("¡Listo! Que comience la descarga...", "success")
                getAllDocumentDownloads()
                setisHidden(false)
             }

             if(data.messageType === "newNotification"){                
                getMyNotifications()
             }
         }
     }, [])

     const createAlert = (text, type) => {
        setAlertText(text)
        setAlertType(type)
        setshowAlert(true)
    }

    const connectWebsocket = useCallback(() => {
        websocketHelper.connect()
            .then(websocketInstance => {
                socket.current = websocketInstance
                socket.current?.onMessage?.addListener(onSocketMessage);
            })
            .catch(e => {
                console.error(e)
            })
    }, [])

    useEffect(() => {
        getMyNotifications()
        getAllDocumentDownloads()
        connectWebsocket()

        return () => {
            websocketHelper.disconnect(socket?.current)
            socket.current?.onMessage?.removeListener(onSocketMessage);
        }
    }, [])

    useEffect(() => {
        if(actionsForDownloads.refreshDownloads){
            getAllDocumentDownloads()
            setActionsForDownloads({
                ...actionsForDownloads,
                refreshDownloads: false
            })
        }

        if(actionsForDownloads.unfoldDownloadCenter){
            setisHidden(false)
        }
    }, [actionsForDownloads])

    const handleButtonViewClick = (event, name) => {
        if(defaultView === 'notificaciones' && name !== defaultView && hasUnseenNotifications){
            updateMyNotifications();
        }
        event.stopPropagation();
        setDefaultView(name);
    };

    const getNotificationTooltipParams = (details, createdAt, status) => {
        return {
            title: details[0]?.title || 'Sin información',
            titleValue: details[0]?.content || 'Sin información',
            date: moment(createdAt).format("DD-MM-YYYY HH:mm"),
            status: status === 'done' ? 'Éxito' : status === 'processing' ? 'Procesando' : 'Error',
            additionalDetail: details[1]?.title || 'Sin información',
            additionalDetailContent: details[1]?.content >= 0 ? details[1]?.content : 'Sin información'
        };
    };
    
    
    return (
        <>
        {showAlert && <AlertSeller showAlert={showAlert} text={alertText} setShowAlert={setshowAlert} time={5000} type={alertType} />}

        {!isHidden ? 
            (<div className={classes.DownloadCenterWrapper}>
                {defaultView === 'descargas' ? 
                    (
                        <div className={classes.DownloadRowsWrapper}>
                            {documentsDownloads?.length ?
                                documentsDownloads.map(item => (
                                    <div className={classes.DownloadRow}>                                        
                                        <div className={ [classes.RowTextWrapper, classes.DateTextWrapperContainer].join(' ')}>
                                            <span className={classes.DateTextWrapper}>{moment(item.created_at).format("DD-MM-YYYY HH:mm")}</span>
                                        </div>
                                        <div className={ [classes.RowTextWrapper, classes.DownloadTypeTextWrapper].join(' ') }>
                                            {parseDocumentType(item.type)}
                                        </div>
                                        <img
                                            className={classes.DownloadIconImg}
                                            alt="download-icon" 
                                            src={item?.status === "done" ? downloadEnabledIcon : downloadDisabledIcon}
                                            onClick={() => downloadExcelFile(item.url)}
                                            style={{ 
                                                cursor: item.status === "done" ? "pointer" : "not-allowed"
                                            }}
                                        />
                                    </div>
                                ))
                            :
                                <div className={classes.DownloadRow}>
                                    {isLoadingDocuments ?
                                        <div className={classes.LoaderWrapper}>
                                            <Skeleton variant="rectangular" height={30} width={"30%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5, marginLeft: 15}}/>
                                            <Skeleton variant="rectangular" height={30} width={"40%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5}}/>
                                            <Skeleton variant="rectangular" height={30} width={"20%"} sx={{ borderRadius: 2.5 }} />
                                        </div>
                                    :
                                        <div className={classes.NoDownloadsWrapper}>
                                            <span >No existen descargas generadas</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    ) 
                    : 
                    (
                        <div className={classes.DownloadRowsWrapper}>
                            {notificationsList?.length ?
                                notificationsList.map(item => (
                                    <div className={classes.DownloadRow}>
                                        <div className={[classes.RowTextWrapper, classes.DateTextWrapperContainer].join(' ')}>
                                            {item.has_seen === false && <span className={classes.NotificationIndicatorOnItem}></span>}
                                            <span className={item.has_seen === false ? classes.DateTextWrapper : classes.DateTextWrapperNoDot}>{moment(item.created_at).format("DD-MM-YYYY HH:mm")}</span>
                                        </div>
                                        <div className={[classes.RowTextWrapper, classes.DownloadTypeTextWrapper].join(' ')}>                                            
                                            {item.details && item.details.length > 0 ? (
                                                <span className={classes.DownloadTypeTextWrapper}>
                                                    {item.details[0].title || 'Sin información'}
                                                </span>
                                            ) : (
                                                <span className={classes.DownloadTypeTextWrapper}>Sin información</span>
                                            )}
                                        </div>
                                        <div className={[classes.RowTextWrapper, classes.NotificationStatusTextWrapper].join(' ')}>
                                            <CustomTooltip tooltipContent={notificationTooltipContent(...Object.values(getNotificationTooltipParams(item.details, item.created_at, item.status)))}>
                                                <img
                                                    className={classes.NotificationTooltip}
                                                    alt="tooltip-icon" 
                                                    src={infoFortooltipIcon}
                                                />
                                            </CustomTooltip>                                             
                                            <img
                                                className={classes.NotificationStatus}
                                                alt="status-icon" 
                                                src={item.status === 'done' ? statusSuccess : item.status === 'processing' ? statusProcessing : statusFailure}
                                            />
                                        </div>
                                    </div>
                                ))
                            :
                                <div className={classes.DownloadRow}>
                                    {isLoadingNotifications ?
                                        <div className={classes.LoaderWrapper}>
                                            <Skeleton variant="rectangular" height={30} width={"30%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5, marginLeft: 15}}/>
                                            <Skeleton variant="rectangular" height={30} width={"40%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5}}/>
                                            <Skeleton variant="rectangular" height={30} width={"20%"} sx={{ borderRadius: 2.5 }} />
                                        </div>
                                    :
                                        <div className={classes.NoDownloadsWrapper}>
                                            <span >No existen notificaciones</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                }
                <div className={classes.TittleWrapper}>
                    <div className={classes.TittleContainer} onClick={handleClickUnfold}>
                        <div className={defaultView === 'descargas' ? classes.TittleButton : classes.UnfocusTittleButton} onClick={(event) => handleButtonViewClick(event, 'descargas')}>
                            <div className={classes.TittleText} >Descargas</div>
                        </div>
                        <div className={defaultView === 'notificaciones' ? classes.TittleButton : classes.UnfocusTittleButton} onClick={(event) => handleButtonViewClick(event, 'notificaciones')}>
                            <div className={classes.TittleText} >Notificaciones</div>
                            {hasUnseenNotifications && <span className={classes.NotificationIndicator}></span>}
                        </div>                                                
                    </div>
                    <img className={classes.DownloadFileIcon} alt="Frame" src={unfoldToRigthIcon} onClick={handleClickUnfold}/>
                </div>
            </div>)
        : 
            (
                <div className={classes.unfoldDownloadCenterBtn} onClick={handleClickUnfold}>
                    <img className={classes.unfoldLeftIcon} alt="Frame" src={unfoldToRigthIcon} />
                    {hasUnseenNotifications && <span className={classes.NotificationIndicatorClose}></span>}
                </div>
            )
        }   
        </>
    )


}

export default DownloadsCenter