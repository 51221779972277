//Libraries
import React from 'react';

//Components
import CustomTooltip from '../UI/CustomTooltip';
import classes from './AlertList.module.css';
import { tooltipEachAlertContent } from '../../utils/tooltipContent';

const AlertList = ({
    alerts,
    allMyAlerts,
    alertTypes,
    days,
    handleSelectAlert,
    handleEditAlertType,
    handleEditAlertFrequency,
    handleEditAlertEmails,
    handleEditAlertName,
    handleAddPublications,
    handelDeleteAlert,
    showOptions,
    selectedAlert,
    optionsMenuRef,
    inforFortooltipIcon,
}) => {
    const renderAlerts = () => {
        return alerts.map((alert) => {
            const currentAlert = allMyAlerts.find((item) => item.id === alert.alert_id);

            if (!currentAlert) {
                return null;
            }

            const alertTypeNames = Array.isArray(currentAlert.alert_type_ids)
                ? currentAlert.alert_type_ids.map((id) => {
                    const alertType = alertTypes.find((type) => type.typeId === id);
                    return alertType ? alertType.name : '(Tipo no disponible)';
                }).join(', ')
                : '(No hay tipos de alertas)';

            const alertDays = Array.isArray(currentAlert.alert_frequency)
                ? currentAlert.alert_frequency.map((freq) => {
                    const day = days.find((day) => day.key === freq);
                    return day ? day.fullLabel : '(Día no encontrado)';
                }).join(', ')
                : '(No hay frecuencia)';

            const alertRecipients = Array.isArray(currentAlert.alert_recipients)
                ? (() => {
                    const maxRecipientsToShow = 3;
                    const extraCount = currentAlert.alert_recipients.length - maxRecipientsToShow;
                    const displayedRecipients = currentAlert.alert_recipients.slice(0, maxRecipientsToShow).join(', ');
                    return extraCount > 0
                        ? `${displayedRecipients}... +${extraCount}`
                        : displayedRecipients;
                })()
                : '(No hay destinatario)';

            const publicationsCount = currentAlert.alert_publications?.length || 0;

            return (
                <div key={alert.alert_id} className={classes.alertCard}>
                    <div className={classes.alertCardTitleContainer}>
                        <span className={classes.alertCardTitle}>{alert.alert_name}</span>
                        <CustomTooltip tooltipContent={tooltipEachAlertContent(alert, alertTypeNames, alertDays, alertRecipients, publicationsCount)}>
                            <img className={classes.infoIconForTootltip} alt="info" src={inforFortooltipIcon} />
                        </CustomTooltip>
                    </div>
                    <div
                        className={classes.alertCardButtonContainer}
                        onClick={() => handleSelectAlert(alert)}
                    >
                        <div className={classes.alertCardButton}>
                            <span className={classes.alertCardButtonText}>Editar</span>
                        </div>
                    </div>
                    {showOptions && selectedAlert && selectedAlert.alert_id === alert.alert_id && (
                        <div ref={optionsMenuRef} className={classes.optionsMenu}>
                            <div className={classes.optionItemFirst} onClick={() => handleEditAlertType(alert)}>Editar tipo de alerta</div>
                            <div className={classes.separatorHorizontal}></div>
                            <div className={classes.optionItem} onClick={() => handleEditAlertFrequency(alert)}>Editar frecuencia de envío</div>
                            <div className={classes.separatorHorizontal}></div>
                            <div className={classes.optionItem} onClick={() => handleEditAlertEmails(alert)}>Editar destinatario</div>
                            <div className={classes.separatorHorizontal}></div>
                            <div className={classes.optionItem} onClick={() => handleEditAlertName(alert)}>Renombrar alerta</div>
                            <div className={classes.separatorHorizontal}></div>
                            <div className={classes.optionItem} onClick={() => handleAddPublications(alert)}>Gestionar publicaciones</div>
                            <div className={classes.separatorHorizontal}></div>
                            <div className={classes.optionItemLast} onClick={() => handelDeleteAlert(alert)}>Eliminar alerta</div>
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <div>
            {renderAlerts()}
        </div>
    );
};

export default AlertList;