import React, { useCallback, useState } from "react";

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
})

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('Token')
    return {
      token: storedToken,
    }
}

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();
    
    let initialToken
    if (tokenData) {
      initialToken = tokenData.token
    }
  
    const [token, setToken] = useState(initialToken)
    const userIsLoggedIn = !!token
  
    const logoutHandler = useCallback(() => {
      setToken(null);
      localStorage.removeItem('Token')
    }, []);
  
    const loginHandler = (token) => {
      setToken(token)
      localStorage.setItem('Token', token)
    }

    const contextValue = {
      token: token,
      isLoggedIn: userIsLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
    }
  
    return (
      <AuthContext.Provider value={contextValue}>
        {props.children}
      </AuthContext.Provider>
    )
}
  
export default AuthContext;