import React, { useEffect, useState, forwardRef } from 'react';
import classes from './Select2.module.css';
import Input from 'components/Promotions/Input';

// Images
import arrowSelect from '../../assets/img/Despliegue.png';
import arrowSelectGrey from '../../assets/img/arrow-bottom-grey.png';
import SortingArrow from '../../assets/img/icons/sort-arrow.svg';

const Select = forwardRef(({ filterName, handle, name, options, handleOptions, selectedOption, value = 'value', search = false, sortByName = true, showSortingArrow = false }, ref) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [openOptions, setOpenOptions] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setFilteredOptions(options || []);
    }, [options]);

    const closeSelectInput = (e) => {
        e.stopPropagation();
        const inputElementSelect = e.target.querySelector('input');

        if ((e.currentTarget.type !== 'text' && !e.relatedTarget) || 
            (e.currentTarget?.className == e.target?.className && e.currentTarget?.className === e.relatedTarget?.className) ||
            (e.currentTarget?.className == e.target?.className && !e.relatedTarget)
        ) {
            setOpenOptions(false);
        }

        if (inputElementSelect != null || e.target.type === 'text') return;

        setOpenOptions(false);
    };

    const handleOpenOptions = (e) => {
        const inputElementSelect = e.target.querySelector('input');
        if (e.target === e.currentTarget || e.target.type === 'text' || inputElementSelect != null) return;
        setOpenOptions((prevState) => !prevState);
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        const filtered = options.filter((option) => option[name].toLowerCase().includes(query.toLowerCase()));
        setFilteredOptions(filtered);
    };

    const sortByAtt = (a, b) => {
        if (!sortByName) return 1;
        if (a[name].toLowerCase() < b[name].toLowerCase()) return -1;
        if (a[name].toLowerCase() > b[name].toLowerCase()) return 1;
        return 0;
    };

    return (
        <div onClick={handleOpenOptions} tabIndex={0} className={classes.wrapper} onBlur={closeSelectInput}>
            <div className={classes.inputSelect}>
                {selectedOption && <div className={classes.mainTextSelected}>{selectedOption}</div>}
                {!selectedOption && <div className={options?.length > 0 ? classes.mainText : classes.mainTextDisabled}>{filterName}</div>}

                <div className={classes.iconWrapper}>
                    <img src={options?.length > 0 ? arrowSelect : arrowSelectGrey} className={classes.icon} />
                </div>
            </div>

            {openOptions && (
                <div className={classes.options}>
                    {search && (
                        <div className={classes.searchWrapper}>
                            <Input width="100%" type="text" value={searchQuery} handle={handleSearch} />
                        </div>
                    )}
                    {filteredOptions.sort(sortByAtt).map((option, index) => (
                        <div
                            key={option[value] + index.toString()}
                            className={`${classes.option} ${selectedOption === option[name] ? classes.selectedOption : ''}`}
                            onClick={() => {
                                handle(option[value] ?? option.value, option?.direction);
                                handleOptions(option[name], option?.direction);
                            }}
                        >
                            {showSortingArrow && option.showArrow && (
                                <img
                                    src={SortingArrow}
                                    alt="Sorting Arrow"
                                    className={`${classes.sortingArrow} ${selectedOption === option[name] ? classes.selectedArrow : ''} ${option.direction === 'asc' ? classes.arrowAsc : classes.arrowDesc}`}
                                />
                            )}
                            {option[name]}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default Select;
