import React from 'react'

//images
import successImg from "../../../../../assets/img/integration-success-centered.svg"

//libraries
import { Typography, Button } from '@mui/material'

const SuccessIntegrationChannel = ({ handleToggleModalIntegration, isEdit, setDisplayModalIntegrations }) => {

    const handleClickClose = () => {
        setDisplayModalIntegrations(false)
        handleToggleModalIntegration(false)
    }

  return (
    <div style={{ textAlign: "center" }}>
        <img
            style={{ marginTop: 60 }}
            src={successImg}
            height={250}
            alt="success integration"

        />
        <Typography
            fontSize={40}
        >
            <b>¡Hurra!</b>
        </Typography>
        <span style={{ fontSize: 18 }}>La {isEdit ? "Modificación de la " : ""}integración fué exitosa</span>
        <Button
            variant="outlined"
            sx={{ fontSize: '22px', padding: '10px 25%', borderRadius: "14px", mt: '60px' }}
            onClick={handleClickClose}
        >
            <b>Cerrar</b>
        </Button>
    </div>
  )
}

export default SuccessIntegrationChannel 