import React from 'react'
import { useState } from 'react'
import classes from './ModalChatGPT.module.css'
import { Modal, ModalHeader } from 'reactstrap'

import spinnerGif from "../../assets/img/spinnerLogos.gif"
import successIcon from '../../assets/img/success.png'
import errorImage from '../../assets/img/icons/errorIlustration.png'
import confirm from '../../assets/img/icons/confirmIlustration.png'

function ModalChatGPT({showModalChatGPT, handleModalChatGPT, getDescription, name }) {
    
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState( name.length >= 10? undefined : "Agrega un nombre o título al kit con más de 10 caracteres.")

    const handleDescription = async () => {
        setLoading(true)
        try {
            const response = await getDescription()
            if (response instanceof Error) {
                throw response
            }
            setSuccess(true)
        } catch (error) {
            setError(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={showModalChatGPT} toggle={handleModalChatGPT} size="lg">
                <ModalHeader>
                    <div className={classes.modalHeader}>
                        <button className={classes.modalExitButton} onClick={() => {
                            handleModalChatGPT()
                        }}>
                            x
                        </button>
                    </div>
                </ModalHeader>
                {loading && 
                    <div className={classes.spinner}>
                        <img src={spinnerGif} className={classes.imgSpinner} />
                        <p className={classes.textSpinner}>Estamos creando tu descripción,<br/>
                        por favor espera un momento.</p>
                    </div> 
                }
                {!loading && success && !error &&
                    <div className={classes.successWrapper}>
                        <div className={classes.iconSuccessWrapper}>
                            <img src={successIcon} />
                        </div>
                        <h2 className={classes.title}>Descripción creada exitosamente</h2>
                        <button className={classes.buttonGreen} onClick={() => handleModalChatGPT()}>
                            <p className={classes.buttonGreenText}>
                                Entendido
                            </p>
                        </button>
                    </div>
                }
                {!loading && error &&
                    <div className={classes.successWrapper}>
                        <div className={classes.iconSuccessWrapper}>
                            <img src={errorImage} />
                        </div>
                        <h2 className={classes.title}>Ups... Hubo un error</h2>
                        <p className={classes.detailError} >Error: {error}</p>
                        <button className={classes.buttonGreen} onClick={() => handleModalChatGPT()}>
                            <p className={classes.buttonGreenText}>
                                Cerrar
                            </p>
                        </button>
                    </div>
                }
                {!loading && !success && !error &&
                    <div className={classes.successWrapper}>
                        <div className={classes.iconSuccessWrapper}>
                            <img src={confirm} />
                        </div>
                    <h2 className={classes.title}>¿Estás seguro de que quieres generar con inteligencia artificial tu descripción?</h2>
                    <button disabled={name.length < 10} className={classes.buttonGreen} onClick={() => handleDescription()}>
                        <p className={classes.buttonGreenText}>
                            Confirmar
                        </p>
                    </button>
                </div>
                }
            </Modal>
    )
}

export default ModalChatGPT