import React, { useEffect, useRef, useState } from 'react'
import classes from './Label.module.css'
import ErrorAlert from 'components/UI/ErrorAlerts/error-alert'
import { getLabel, getOrderId, putEnviameLabel } from "services/orders"
import BallotDetailModal from "../BallotDetailModal/BallotDetailModal";
import { Modal, Tooltip } from 'reactstrap';
import { ModalHeader } from 'react-bootstrap';

import Etiqueta from "../../assets/img/Etiqueta.png"
import generateLabel from "../../assets/img/gen-label.png"
import downloadManifest from "../../assets/img/download.png"
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import question from '../../assets/img/question.png'

const Label = ({ rowData, toggle6 }) => {

  const [order, setOrder] = useState({ ...rowData })
  const availableStatusEnviame = ["Listo para despacho", "Listo para despacho - Impreso"]
  const [enviameQuantity, setEnviameQuantity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const sectionRef = useRef(null)
  const [modalBallotDetailsLabel, setModalBallotDetailsLabel] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltip = () => setTooltipOpen(!tooltipOpen)
  const toggle3 = () => setModalBallotDetailsLabel(!modalBallotDetailsLabel)
  const increment = () => setEnviameQuantity(prev => prev + 1)
  const decrement = () => setEnviameQuantity(prev => prev - 1)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setError(undefined)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    };
  }, [sectionRef])

  const generateCurrentLabel = async () => {
    setLoading(true)
    try {
      const response = await getLabel(order.channel, order.orderNo, order.store)
      if (response instanceof Error) { throw response}
      if ( response.message.label === "in progress") {
        setOrder( {...order, statusLabel: "in progress"} )
        const interval = setInterval(async () => {
          const response = await getOrderId(order.orderNo)
          const updateOrder = await response.message.filter(or => or.id_mtdi === order.id_mtdi)[0]
          if (updateOrder.label !== "") {
            clearInterval(interval)
            setOrder({ ...updateOrder, label: updateOrder.label })
          }  
        }, 10000)
        return () => clearInterval(interval)
      } else if (response.message.label !== "" || response.message.manifest !== "") {
        setOrder( {
          ...order,
          label: response.message.label || order.label,
          manifest: response.message.manifest || order.manifest,
          statusCourier: response.message.statusCourier || order.statusCourier
        } )
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getEnviameLabel = async () => {
    setLoading(true)
    const EnviameLabelBody = {
      orderNo: order.orderNo,
      idChannel: order.channel,
      idStore: order.store,
      nPackage: enviameQuantity,
    }
    try {
      const response = await putEnviameLabel(EnviameLabelBody)
      if (response instanceof Error) {
        throw response
      }
      const updateOrder = { ...order };
      updateOrder.label = response.message.label !== "" ? response.message.label : order.label ?? "";
      updateOrder.manifest = response.message.manifest !== "" ? response.message.manifest : order.manifest ?? "";
      setOrder({ ...updateOrder });
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getOrderById = async () => {
    setLoading(true)
    try {
      const response = await getOrderId(order.orderNo)
      const updateOrder = await response.message.filter(or => or.id_mtdi === order.id_mtdi)[0]
      setOrder({ ...updateOrder })
    } catch (error) {
      setError(error.message)
    } finally{
      setLoading(false)
    }
  }

  if (
    order !== null ||
    order?.label !== undefined ||
    order?.manifest !== undefined
  ) {
    return (
      <>
        {!loading &&
          <>
            {((order.label == "") ||
              (order.label !== ""
                && order.hubFulfillment == "Enviame"
                && (availableStatusEnviame.includes(order.statusCourier[order.statusCourier.length - 1].status)
                  || order.statusCourier[order.statusCourier.length - 1].status === "Creado")))
              && !order.orderValidated && order.validatorActived
              && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    title="Validar productos"
                    className={classes.showValidar}
                  >
                    <button style={{ paddingInline: "20px", paddingBlock: "5px", borderRadius: "5px", borderWidth: "0px", fontSize: "13px" }} onClick={toggle3}>Validar</button>
                  </span>
                </div>
              )}
            {order.hubFulfillment === "Enviame" &&
              order.label !== "" &&
              order.shippingType !== "fulfillment" && ((!order.validatorActived || (order.orderValidated && order.validatorActived)) || !availableStatusEnviame.includes(order.statusCourier[order.statusCourier.length - 1].status)) && (
                <div className={classes.enviameWrapper}>
                  {availableStatusEnviame.includes(order.statusCourier[order.statusCourier.length - 1].status) && (!order.validatorActived || (order.orderValidated && order.validatorActived)) && (
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px" }}>
                      <span>
                        <a href={order.label} target="blank" className={classes.showLabel} title="Mostrar Etiqueta">
                          <img src={Etiqueta} style={{ width: "100%" }} />
                        </a>
                      </span>
                      {order.manifest !== "" &&
                        <span style={{ cursor: "pointer" }} title="Mostrar Manifiesto">
                          <img src={downloadManifest} onClick={() => toggle6()} style={{ width: "100%" }} />
                        </span>
                      }
                    </div>
                  )}

                  {!availableStatusEnviame.includes(order.statusCourier[order.statusCourier.length - 1].status) && (
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "5px" }}>
                      <span>
                        <a href={order.label} target="blank" className={classes.showLabel} title="Mostrar Etiqueta">
                          <img src={Etiqueta} style={{ width: "100%" }} />
                        </a>
                      </span>
                      {order.manifest !== "" &&
                        <span style={{ cursor: "pointer" }} title="Mostrar Manifiesto">
                          <img src={downloadManifest} onClick={() => toggle6()} style={{ width: "100%" }} />
                        </span>
                      }
                    </div>
                  )}

                  {availableStatusEnviame.includes(order.statusCourier[order.statusCourier.length - 1].status) && (!order.validatorActived || (order.orderValidated && order.validatorActived)) && (
                    <span title="generar etiquetas" style={{ flex: "1", margin: "5px", marginRight: "10px" }}>
                      <div className={classes.actions}>
                        <button
                          disabled={enviameQuantity === 0}
                          className={classes.button}
                          style={{
                            borderRight: "none",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                          }}
                          onClick={decrement}
                        >
                          -
                        </button>

                        <input
                          value={enviameQuantity}
                          style={{
                            border: "2px solid #06CBC1",
                            width: "30px",
                            paddingLeft: "10px",
                          }}
                        />
                        <button
                          className={classes.button}
                          style={{
                            borderLeft: "none",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                          }}
                          onClick={increment}
                        >
                          +
                        </button>
                      </div>

                      <button disabled={enviameQuantity === 0} className={classes.createLabel} onClick={getEnviameLabel}>
                        Crear nueva Etiqueta
                      </button>
                    </span>
                  )}
                </div>
              )
            }
            {order.statusLabel === "available" &&
              order.manifest === "" &&
              order.label === "" && (!order.validatorActived || (order.orderValidated && order.validatorActived)) &&
              (
                <div className={classes.contentLabel}>
                  <span title="generar etiqueta">
                    <img
                      src={generateLabel}
                      onClick={generateCurrentLabel}
                    />
                  </span>
                </div>
              )
            }
            {order.statusLabel === "in progress" && order.label === ""&&
              (
                <div className={classes.contentLabel}>
                  <p>Procesando</p>
                    <div className={classes.tooltipWrapper} id="tooltip">
                        <img src={question} className={classes.tooltipIMG} />
                    </div>
                    <Tooltip
                        isOpen={tooltipOpen}
                        target="tooltip"
                        toggle={handleTooltip}
                        style= {{ backgroundColor: "white", color: "black", boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.17)"}}
                    >
                        Tu etiqueta se esta procesando, esto puede demorar alrededor de un minuto.
                    </Tooltip>
                  
                </div>
              )
            }
            {/* No posee etiqueta/manifiesto creada, ni la posibilidad de crearla */}
            {order.statusLabel === 'unavailable' &&
              order.statusManifest === 'unavailable' &&
              order.manifest === '' &&
              order.label === '' && (order.orderValidated || !order.validatorActived) && (
                <div style={{ textAlign: "center" }}>
                  <span>No disponible</span>
                </div>
              )}
            {/* Solo posee etiqueta disponible */}
            {order.label !== "" &&
              order.manifest === "" &&
              order.hubFulfillment !== "Enviame" && (
                <div className={classes.contentLabel}>
                  <a href={order.label} target="blank" className={classes.showLabel} title="Mostrar Etiqueta">
                    <img src={Etiqueta} />
                  </a>
                </div>
              )}
            {/* Solo posee manifiesto disponible */}
            {order.manifest !== "" && order.label === "" && (
              <div>
                <span className={classes.showLabel} title="Mostrar Manifiesto" >
                  &nbsp;
                  <img src={downloadManifest} onClick={() => toggle6()} />
                </span>
              </div>
            )}
            {/* Posee etiqueta y manifiesto */}
            {order.manifest !== "" && order.label !== "" && order.hubFulfillment !== "Enviame" && (
              <div>
                <a href={order.label} target="blank" className={classes.showLabel} title="Mostrar Etiqueta" >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {order.hubFulfillment !== "Enviame" && (
                    <img src={Etiqueta} />
                  )}
                </a>
                <span className={classes.showLabel} title="Mostrar Manifiesto" >
                  &nbsp;
                  <img src={downloadManifest} onClick={() => toggle6()} />
                </span>
              </div>
            )
            }
          </>
        }
        {error && (
          <ErrorAlert
            ref={sectionRef}
            errorAlertMessage={error}
            onClose={() => setError(undefined)}
          />
        )}
        {loading &&
          <div className={classes.wrapperSpinner}>
            <img src={spinnerGif} className={classes.imgSpinner} />
          </div>
        }

        <Modal isOpen={modalBallotDetailsLabel} toggle={toggle3} size="lg" className={classes.modalValidator}>
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                style={{
                  background: "none",
                  position: "relative",
                  marginLeft: "14em",
                  color: "black",
                  border: "none",
                }}
                onClick={toggle3}
              >
                x
              </button>
            </div>
          </ModalHeader>
          <BallotDetailModal rowOrder={order} label={true} getOrderById={getOrderById} toggle={toggle3} hasToGroupByParentPromotion={false} />
        </Modal>
      </>
    );
  }
}



export default Label