import React from 'react'
import classes from './GlobalError.module.css'

import Icon from '../../assets/img/global-error-icon.png'

function GlobalError({ message }) {
  return (
    <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
            <img className={classes.img} src={Icon} />
        </div>
        <div className={classes.contentWrapper} >
            <h3 className={classes.title}>¡Lo sentimos! tu carga no se realizó.</h3>
            <p className={classes.text}>{message}</p>
        </div>
    </div>
  )
}

export default GlobalError