import React from 'react'
import Modal from 'reactstrap/es/Modal'
import { Box, Typography, Button } from '@mui/material'

const AmazonModal = ({ channel, storeData, displayModal, handleToggleModal, isEdit }) => {

    const handleClickIntegrationButton = () => {
        const URIConfigForCurrentStore = channel?.config_mtd?.auth_urls?.find(e => e.countryId == storeData.paises[0])
        
        if(URIConfigForCurrentStore != undefined){
            window.location.href = encodeURI(`${URIConfigForCurrentStore.url}&state=${storeData.id}-${storeData.paises[0]}`)
        }
    }

    const handleClose = () => {
        handleToggleModal(false)
    }

  return (
    <Modal
            isOpen={displayModal}
            toggle={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: { xs: "400px", md: "570px"},
                        bgcolor: 'white',
                        boxShadow: 24,
                        p: 6,
                        zIndex: 1,
                        borderRadius: 4,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                   
                    <img style={{ height: 250, width: 250, objectFit: 'contain' }} src={channel.config_mtd.logo} />
                    <Typography variant="h4" sx={{ textAlign: 'center' }}><b>{isEdit ? "Reconectar" : "Conectar"} con...</b></Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>Para una integracion exitosa, <br />debes iniciar sesión en Amazon</Typography>
                    <Button onClick={handleClickIntegrationButton} variant="contained" sx={{ fontSize: '24px', padding: '10px 100px', borderRadius: "14px", mt:"50px"}}><b>Integrar cuenta</b></Button>
                </Box>

            </Box>
        </Modal>
  )
}

export default AmazonModal