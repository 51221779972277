import BallotDetailTable from "components/BallotDetailTable/BallotDetailTable";
import React, { useState, useEffect } from 'react';
import { ModalBody } from "react-bootstrap";
import { Container, Form, FormGroup, Label, Row, Col } from "reactstrap";
import Button from '@material-ui/core/Button';
import { putOrder } from "services/orders";
import Loading from "../../assets/img/spinnerLogos.gif"
import classes from './ballot-detail-modal.module.css'

const BallotDetailModal = ({ rowOrder, label, getOrderById, toggle, hasToGroupByParentPromotion }) => {
  const [validates, checkValidates] = useState('');
  const [validateButton, startValidate] = useState(!!((localStorage.getItem("ut") == 5 || localStorage.getItem("ut") == 1) && label));
  const [lengthCol1, updateLength1] = useState((localStorage.getItem("ut") == 5 || localStorage.getItem("ut") == 1) && label ? "10" : "12");
  const [lengthCol2, updateLength2] = useState((localStorage.getItem("ut") == 5 || localStorage.getItem("ut") == 1) && label ? "2" : "0");
  const [errorPop, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderWithGroupedItems, setOrderWithGroupedItems] = useState({ ...rowOrder })

  const handleInputChange = (event) => {
    for (const item of orderWithGroupedItems.products) {

      const count_sku = event.target.value.split("\n").filter((e) => (e && e !== "" && e === item.sku)).length;
      const count_ean = event.target.value.split("\n").filter((e) => (e && e !== "" && e === item.ean)).length;
      
      item.countValidated = count_sku > count_ean ? count_sku : count_ean

      if (count_sku === item.qty || count_ean === item.qty) {
        item.check = true;
        item.errorQtyOver = false
        item.errorQtyUnder = false
      } else if(count_sku > item.qty || count_ean > item.qty){
        item.check = false;
        item.errorQtyOver = true
        item.errorQtyUnder = false
      }
      else if(count_sku < item.qty || count_ean < item.qty){
        item.check = false;
        item.errorQtyUnder = true
        item.errorQtyOver = false
      }
    }


    const productsSku = orderWithGroupedItems.products.map(e => e.sku);
    const productsEan = orderWithGroupedItems.products.map(e => e.ean);
    const temp = event.target.value.split("\n")[event.target.value.split("\n").length - 1];
    const sku = temp === "" ? event.target.value.split("\n")[event.target.value.split("\n").length - 2] : temp
    const ean = temp === "" ? event.target.value.split("\n")[event.target.value.split("\n").length - 2] : temp

    if (!productsSku.includes(sku) && !productsEan.includes(ean)) setError(true)
    else setError(false)

    checkValidates(event.target.value);
  };

  const ManageError = () => {
    let productos = validates.split("\n");
    productos.pop();
    for (let i = productos.length - 1; i >= 0; i--) {
      if (!orderWithGroupedItems.products.includes(productos[i])) {
        productos.pop(i);
        break;
      }
    }
    checkValidates(productos.join("\n"));
    setError(false)
  }

  const handleValidateButton = () => {
    startValidate(!validateButton);
    updateLength1(lengthCol1 == "12" ? "8" : "12")
    updateLength2(lengthCol2 == "4" ? "0" : "4")
  };

  const sendOrderValidated = async (order) => {
    for (const product of order.products.filter(product => !product.isPromo)) {
      product.check = true
    }
    order.validated = true;
    const response = await putOrder(order);
    if (response.status === 200) {
      setIsLoading(false)
      getOrderById();
      toggle();
    }

  }

  const groupItemsByEan = (rowOrder) => {
    const productsGrouped = []
    const productsWithEanTranslated = JSON.parse(JSON.stringify(rowOrder?.products || []))?.map(e => ({...e,  ean: (e.ean_translated || e.ean) })) || []


    for(const product of productsWithEanTranslated.filter(e => !e.shouldIgnoreInValidator)){
      const productFound = productsGrouped.find(e => e.ean && e.ean === product.ean)
      if(productFound){
        productFound.qty = productFound.qty + product.qty
      }else{
        productsGrouped.push(product)
      }
    }

    setOrderWithGroupedItems({ ...rowOrder, products: productsGrouped })
  }

  useEffect(() => {
    if(!hasToGroupByParentPromotion)
      groupItemsByEan(rowOrder)
  }, [rowOrder])
  

  return (
    <div className="ballotDetailModalWrapper">
      <ModalBody>
        <div>
          <span className={classes.pDetalle}>
            {(!label && "Detalle Boleta") || (label && "Validar Productos")}
          </span>
          <span className={classes.pDetalleSubTitle}>
            Orden n° {orderWithGroupedItems.orderNo} - {orderWithGroupedItems.client} - {orderWithGroupedItems.channelName}
          </span>
        </div>
        <div className="Column" style={{ margin: 0, padding: 0 }}>
          {
            !validateButton && label && (
              <Row >
                <div style={{ textAlign: "right", marginBottom: "3%" }} >
                  <Button variant="contained" style={{ backgroundColor: "#051B2F" }} onClick={handleValidateButton}>Validar</Button>
                </div>
              </Row>
            )
          }
          <Row>
            <Col md={lengthCol1}>
              <BallotDetailTable productDetails={orderWithGroupedItems} label={label} hasToGroupByParentPromotion={hasToGroupByParentPromotion} />
            </Col>

            {validateButton && (
              <Col md={lengthCol2}>
                <div>
                  <Form>
                    <FormGroup>
                      <Label>Escanea tus productos:</Label>
                      <textarea
                        value={validates}
                        onChange={handleInputChange}
                        className={classes.textAreaScan}
                        style={{fontWeight: 600}}
                      />
                    </FormGroup>
                  </Form>
                  {
                    errorPop && (
                      <div>
                        <p>El producto agregado no se encuentra en la lista, ¿desea mantenerlo?</p>
                        <div className={classes.divStart}>
                          <button className={classes.buttonRechazar} onClick={ManageError}>Eliminar</button>
                          <button className={classes.buttonAceptar} onClick={() => { setError(false) }}>Mantener</button>
                        </div>
                      </div>
                    )
                  }
                  {!isLoading && !orderWithGroupedItems.orderValidated && orderWithGroupedItems.products.filter(e => e.check && !e.shouldIgnoreInValidator).length === orderWithGroupedItems.products.filter(e => !e.shouldIgnoreInValidator).length && !errorPop &&
                    (
                      <div className="text-center">
                        <button
                          onClick={() => {
                            sendOrderValidated(orderWithGroupedItems)
                            setIsLoading(true)
                          }}
                          className={classes.buttonValidation}
                        >
                          Enviar Validacíon
                          <span className="btn-label">
                            <i className="nc-icon nc-send" style={{ marginLeft: "10px" }} />
                          </span>
                        </button>
                      </div>
                    )
                  }
                  {
                    isLoading && (
                      <div className={classes.wrapperSpinner}>
                        <img className={classes.imgSpinner} src={Loading}></img>
                      </div>
                    )
                  }
                </div>
              </Col>
            )}
          </Row>
        </div>
        <br />
      </ModalBody>

    </div>
  );
};

export default BallotDetailModal;
