import React from 'react'

// MUI components
import { Box, Typography, useMediaQuery } from "@mui/material";

//Components
import { tooltipHealthcheckContent, tooltipHealthcheckAdvice } from '../../../utils/tooltipContent';
import ProgressBar from '../../../views/publications/Components/ProgressBar';

// images
import noCheck from "../../../assets/img/no-check.png";
import Question from "../../../assets/img/icons/question.png";

const PublicationHealthcheck = ({ healthcheckData, CustomTooltip }) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    return (
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            mt:"10px",
            maxWidth: isTablet ? '300px' : 'none'
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "15px",
                    border: "1px solid #D3D3D3",
                    borderRadius: "17px",
                    minWidth: isTablet ? '160px' : '260px',
                    minHeight: "85px",
                    mt: "10px"
                }}
            >
                <CustomTooltip tooltipContent={tooltipHealthcheckContent}> 
                    <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: "#D3D3D3" }}>
                        {'Healthcheck'}
                        <img
                            src={Question}
                            alt="tooltip"
                            style={{ marginLeft: '5px', minWidth: "15px", minHeight: "15px", objectFit: 'contain' }}
                        />
                    </Typography>
                </CustomTooltip>                 

                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', height: '100px', mt: '10px' }}>
                    <ProgressBar percentage={(healthcheckData?.health ?? 0) * 100} strokeWidth={10} isDefaultColor={true} defaultFontSize={'24px'} defaultFontWeight={'700'}/>
                </Box>                
            </Box>

            {healthcheckData?.actions?.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: "15px",
                        border: "1px solid #D3D3D3",
                        borderRadius: "17px",
                        minWidth: isTablet ? '160px' : '260px',
                        minHeight: "85px",
                        mt: "10px"
                    }}
                >
                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'row' }}>
                        <CustomTooltip tooltipContent={tooltipHealthcheckAdvice}> 
                            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                                <Typography 
                                    fontFamily="Montserrat" 
                                    fontSize="15px" 
                                    sx={{ 
                                        fontWeight: 600, 
                                        color: "#D3D3D3", 
                                        overflow: 'hidden', 
                                        textOverflow: 'ellipsis', 
                                        whiteSpace: 'nowrap', 
                                        display: 'inline-block', 
                                        maxWidth: '200px' 
                                    }}
                                >
                                    {'Elementos por cumplir'}
                                </Typography>
                                <img
                                    src={Question}
                                    alt="tooltip"
                                    style={{ marginLeft: '5px', minWidth: '15px', minHeight: '15px', objectFit: 'contain' }}
                                />
                            </Box>
                        </CustomTooltip> 
                    </Box>                                     

                    {healthcheckData.actions.map((e, i) => (
                            <Box key={i} sx={{ display: 'flex', alignItems: 'flex-start', margin: '7px 0px' }}>
                                <Box sx={{ width: '10%' }}>
                                    <img src={noCheck} alt="no-check" />
                                </Box>

                                <Box sx={{ width: '90%', padding: '0px 10px' }}>
                                    <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ whiteSpace: 'pre-line', fontWeight: 400 }}>
                                        {e.value ?? ""}
                                    </Typography>                                    
                                </Box>
                            </Box>
                        ))
                    }               
                </Box>
            )}
        </Box >
    )
}

export default PublicationHealthcheck;
