import React from 'react';
import CustomModal from '../UI/CustomModal';
import addEmailModalStyles from './AddEmailModal.module.css';
import styles from "../../views/publications/Components/CreateAlertModal.module.css";

const AddEmailModal = ({
  addEmailModal,
  toggleAddEmailModal,
  handleAddEmailModalReturn,
  handleCloseAddEmailModal,
  inputEmail,
  handleInputEmailChange,
  handleAddEmail
}) => {

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <CustomModal
      isOpen={addEmailModal}
      toggle={toggleAddEmailModal}
      onBack={handleAddEmailModalReturn}
      onClose={handleCloseAddEmailModal}
      title="Crear alerta"
      customStyles={{ box: addEmailModalStyles.customModal }}
      customClassNames={{
        form: addEmailModalStyles.modalForm,
        header: addEmailModalStyles.crearAlertaParent,
        title: addEmailModalStyles.crearAlerta,
        closeWrapper: addEmailModalStyles.closeWrapper,
        backIcon: addEmailModalStyles.backIcon,
        closeIcon: addEmailModalStyles.closeIcon,
        separatorContainer: addEmailModalStyles.separatorContainer,
        separator: addEmailModalStyles.separator,
        bodyContainer: addEmailModalStyles.bodyContainer,
        footerContainer: addEmailModalStyles.footerContainer,
        box: styles.afterElementSecond
      }}
      footerActions={
        <div
          className={`${addEmailModalStyles.botonLogin} ${isValidEmail(inputEmail) ? addEmailModalStyles.enabled : ''}`}
          onClick={() => handleAddEmail(inputEmail)}
          disabled={!isValidEmail(inputEmail)}
        >
          <b className={addEmailModalStyles.siguiente}>Agregar</b>
        </div>
      }
    >
        <div className={addEmailModalStyles.seleccionaDestinatarioContainer}>
            <div className={addEmailModalStyles.seleccionaDestinatario}>Ingresa una dirección de correo</div>
        </div>         
        <div className={addEmailModalStyles.emailInputContainer}>
          <span className={addEmailModalStyles.inputCorreo}>Correo</span>
          <div className={addEmailModalStyles.inputBorderContainer}>
            <input
              type="email"
              className={addEmailModalStyles.emailInput}
              value={inputEmail}
              onChange={handleInputEmailChange}
              placeholder="Ingresa aquí tu correo electrónico"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent form submission
                    if (isValidEmail(inputEmail)) {
                        handleAddEmail(inputEmail);
                    }
                }
              }}
            />
          </div>
        </div>
    </CustomModal>
  );
};

export default AddEmailModal;
