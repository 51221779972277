import Modal from "../UI/Modal";
import whatsApp from "../../assets/img/whatsapp.png";
import mailButton from "../../assets/img/mailButton.png";
import textMessage from "../../assets/img/textMessage.png";
import { checkRut, prettifyRut, formatRut } from "react-rut-formatter";
import React, { useState } from "react";
import SendManifest from "components/sendManifestToEmailModal/send-manifest";
const ManifestoModal = (props) => {
  console.log(props.purchaser);
  const [displayEmailModal, setdisplayEmailModal] = useState(false);
  
  return (
    <React.Fragment>
      {displayEmailModal && (
        <>
          <SendManifest   onhideModal={props.onhideModal}
            purchaser={props.purchaser}/>
        </>
      )}
      {!displayEmailModal && (
        <>
        {/* <Modal onhideModal={props.onhideModal}> */}
          <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
          
            Manifiesto
          </h5>
          <div>
            <div class="text-center" style={{ height: "400px" }}>
              {/* <iframe src='https://storage.googleapis.com/carrier-deliveries/202207/99531674/232143-label.pdf' width="500"
               height="300"/> */}
              <iframe
                src={`${props.purchaser.manifest}#zoom=fitH`}
                title="Manifiesto"
                height="400px"
                width="300px"
                scrolling="no"
              
              ></iframe>
            </div>
          </div>
          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "300px",
                height: "63px",
                padding: "22px 50px",
                borderRadius: "17px",
                color: "#FFFFFF",
                marginLeft: "0.5em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop:"2em"
              }}
              onClick={() => {
                setdisplayEmailModal(true);
              }}
            >
              Enviar por correo &nbsp;
              <span className="btn-label">
                <i className="nc-icon nc-send" />
              </span>
            </button>
          </div>
          <br />
        </>

      )}
    </React.Fragment>
  );
};
export default ManifestoModal;
