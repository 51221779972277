import React from 'react';
import { Modal } from 'reactstrap';
import Box from '@mui/material/Box';
import Interrogacion from "../../assets/img/interrogacion-31.svg";
import styles from "../../views/publications/Components/CreateAlertModal.module.css";

const WarningModal = ({ warningModal, toggleWarningModal, handleFirstModal }) => {


    return (
        <Modal isOpen={warningModal} toggle={toggleWarningModal}>
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: "540px",
                    height: "623px",
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 5,
                    borderRadius: '30px',
                    zIndex: 1,
                    border: '1px solid #d3d3d3',
                    '@media (max-width:575px)': {
                        width: '90%',
                    },
                }}
            >
                <div className={styles.modalProduct}>
                    <section className={styles.frameParent}>
                        <div className={styles.interrogacionWrapper}>
                            <img className={styles.interrogacion} src={Interrogacion} />
                        </div>
                        <div className={styles.frameGroup}>
                            <div className={styles.cuidadoWrapper}>
                                <h1 className={styles.cuidado}>¡Cuidaaado!</h1>
                            </div>
                            <div className={styles.deseasDescargarLosContainer}>
                                <p className={styles.agregastePublicacionesDe}>
                                    Agregaste publicaciones de tiendas
                                </p>
                                <p className={styles.distintas}>distintas...</p>
                                <p className={styles.estasSeguroQue}>
                                    ¿Estas seguro que quieres continuar?
                                </p>
                            </div>
                        </div>
                    </section>
                    <div className={styles.modalProductInner}>
                        <div className={styles.botonLoginParent}>
                            <button className={styles.botonLogin} onClick={toggleWarningModal}>
                                <b className={styles.volver}>Volver</b>
                            </button>
                            <div className={styles.continueButton} onClick={handleFirstModal}>
                                <b className={styles.continuar}>Continuar</b>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>                    
        </Modal>
    );
};

export default WarningModal;
