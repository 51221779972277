import React, { useState } from 'react'
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { CircleFlag } from 'react-circle-flags'

function CountryCircles({setCountry, country}) {

    const [countrySelected, setcountrySelected] = useState(country || null)

    const handleOnClickFlag = (e) => {
        try {
            let countrysIds = { "mx": 4, "cl": 1, "pe": 3, "co": 2 }
            let countryId = countrysIds[e.target.attributes.title.value]
     
            if (countrySelected === countryId){
                setcountrySelected(null)
                countryId=0
            }
            else{
                setcountrySelected(countryId)
            }
            setCountry(countryId)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box sx={{ '& > :not(style)': { m: 1 }, zIndex: 1 }}>
            <Fab
                id = "mx"
              
                aria-label="add"
                style={{ width: '34px', height: '34px', boxShadow: countrySelected === 4 ? '0 0 0 5px #51CBCE' : 'none', }}
                onClick={handleOnClickFlag}>
                <CircleFlag countryCode="mx" />
            </Fab>
            <Fab
                
                aria-label="add"
                style={{ width: '34px', height: '34px', boxShadow: countrySelected === 2 ? '0 0 0 5px #51CBCE' : 'none', }}
                onClick={handleOnClickFlag}>
                <CircleFlag countryCode="co" />
            </Fab>
            <Fab
               
                aria-label="add"
                style={{ width: '34px', height: '34px', boxShadow: countrySelected === 3 ? '0 0 0 5px #51CBCE' : 'none', }}
                onClick={handleOnClickFlag}>
                <CircleFlag countryCode="pe" />
            </Fab>
            <Fab
                
                aria-label="add"
                style={{ width: '34px', height: '34px', boxShadow: countrySelected === 1 ? '0 0 0 5px #51CBCE' : 'none', }}
                onClick={handleOnClickFlag}>
                <CircleFlag countryCode="cl" />
            </Fab>
        </Box>
    )
}

export default CountryCircles