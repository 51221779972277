import React from 'react'
import classes from './PublicationCardLoader.module.css'

import { Skeleton } from '@mui/material';


const PublicationCardLoader = () => {

    return (
        <>
            <div className={classes.cardWrapper}>
                <div className={classes.cardImgWrapper}>
                    <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                </div>
                <div className={classes.cardTextWrapper}>
                    <div className={classes.textCategoryWrapper}>
                        <Skeleton width="100%" height={25} style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                    <Skeleton width="100%" height={80} style={{transform: "scale(1, 1)", marginBottom: 15, marginTop: 10, opacity: 0.35}} />
                    <div className={classes.textidsWrapper}>
                        <Skeleton width="100%" height={25} style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                </div>
                <div className={classes.cardPercentageWrapper}>
                    <>
                        <Skeleton width={60} height={25} style={{transform: "scale(1, 1)", opacity: 0.35}} />
                        <Skeleton width={75} height="70%" style={{ marginTop: 20, opacity: 0.35 }} />
                    </>
                </div>
                <div className={classes.cardInfoWrapper}>
                    <div>
                        <Skeleton width={70} height={25} style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                    <div>
                        <Skeleton width={70} height={100} style={{opacity: 0.35}} />
                    </div>
                    <div className={classes.unitWrapper}>
                        <img  />
                        <Skeleton width={70} height={20} style={{transform: "scale(1, 1)", opacity: 0.35}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicationCardLoader;