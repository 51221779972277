import React from 'react'

// MUI components
import { Box, Typography, useMediaQuery } from "@mui/material";

//Components
import { tooltipBuyboxContent, tooltipBuyboxAdvice } from '../../../utils/tooltipContent';
import { toSpanishNumberFormat } from '../../../utils/utils';

// Images
import Ganando from "../../../assets/img/ganando 2.png";
import Perdiendo from "../../../assets/img/Perdiendo 2.png";
import Empatando from "../../../assets/img/Empatando 2.png";
import GanandoIlu from "../../../assets/img/ganando.png";
import PerdiendoIlu from "../../../assets/img/perdiendo.png";
import EmpatandoIlu from "../../../assets/img/Empatando.png";
import Question from "../../../assets/img/icons/question.png";

const PublicationBuybox = ({ buyboxData, publicationChannel, CustomTooltip }) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    
    const getImage = (status) => {
        if (["winning"].includes(status)) {
            return Ganando;
        } else if (["sharing_first_place"].includes(status)) {
            return Empatando;
        } else {
            return Perdiendo;
        }
    };

    const getSecondImage = (status) => {
        if (["winning"].includes(status)) {
            return GanandoIlu;
        } else if (["sharing_first_place"].includes(status)) {
            return EmpatandoIlu;
        } else {
            return PerdiendoIlu;
        }
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            mt:"10px",
            maxWidth: isTablet ? '300px' : 'none'
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "10px",
                    border: "1px solid #D3D3D3",
                    borderRadius: "17px",
                    minWidth: isTablet ? '160px' : '260px',
                    minHeight: "85px",
                    mt: "10px"
                }}
            >
                <CustomTooltip tooltipContent={tooltipBuyboxContent}> 
                    <Typography fontFamily='Montserrat' fontSize={"14px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: "#D3D3D3" }}>
                        {'Buybox'}
                        <img
                            src={Question}
                            alt="tooltip"
                            style={{ marginLeft: '5px', minWidth: "15px", minHeight: "15px", objectFit: 'contain' }}
                        />
                    </Typography>  
                </CustomTooltip>              

                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={getImage(buyboxData.status)}
                        alt="buybox"
                        style={{ width: "120px", height: "90px", objectFit: 'contain' }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "10px",
                    border: "1px solid #D3D3D3",
                    borderRadius: "17px",
                    minWidth: isTablet ? '160px' : '260px',
                    minHeight: "85px",
                    mt: "10px"
                }}
            >
                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'row' }}>
                    <CustomTooltip tooltipContent={tooltipBuyboxAdvice}> 
                        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography 
                                fontFamily="Montserrat" 
                                fontSize="14px" 
                                sx={{ 
                                    fontWeight: 600, 
                                    color: "#D3D3D3", 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    whiteSpace: 'nowrap', 
                                    display: 'inline-block', 
                                    maxWidth: '200px' 
                                }}
                            >
                                {'Análisis de la competencia'}
                            </Typography>
                            <img
                                src={Question}
                                alt="tooltip"
                                style={{ marginLeft: '5px', minWidth: '15px', minHeight: '15px', objectFit: 'contain' }}
                            />
                        </Box>
                    </CustomTooltip> 
                </Box>

                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', mt: '10px' }}>
                    <Typography fontFamily='Montserrat' fontSize={"14px"} sx={{ whiteSpace: 'pre-line' }}>
                        {publicationChannel ?? "Sin Información"}
                    </Typography>

                    <Typography fontFamily='Montserrat' fontSize={"10px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600 }}>
                        {'Precio ganador:'}
                    </Typography>

                    <Typography fontFamily='Montserrat' fontSize={"10px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600 }}>
                        {buyboxData.winner.price ? `$${toSpanishNumberFormat(buyboxData.winner.price)}` : 'Sin información'}
                    </Typography>
                </Box>

                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', mt: '10px' }}>
                    <Typography fontFamily='Montserrat' fontSize={"10px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600 }}>
                        {'Precio sugerido para ganar:'}
                    </Typography>

                    <Typography fontFamily='Montserrat' fontSize={"25px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600 }}>
                        {buyboxData.price_to_win ? `$${toSpanishNumberFormat(buyboxData.price_to_win)}` : 'Sin información'}
                    </Typography>
                </Box>

                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={getSecondImage(buyboxData.status)}
                        alt="buybox-status"
                        style={{ width: "150px", height: "150px", objectFit: 'contain' }}
                    />
                </Box>
            </Box>
        </Box >
    )
}

export default PublicationBuybox;
