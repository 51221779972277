import configFile from '../config/url'
import { parseGetTextCompletionErrorMessage, parsePostComboErrorMessage } from "../utils/errorMessageParser" 
import {performanceMetric} from "../utils/utils";

export const postCombo = async (requestBody) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()

    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append('Authorization',`${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: myHeaders,
    }

    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}products/promotions`, requestOptions)
        performanceMetric("/products/promotions", "POST", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()

            const errorMessageParsed = parsePostComboErrorMessage(data.internal_code)
            throw new Error(errorMessageParsed)
        }
        if (response.ok) return await response.json()
    }
    catch(err) {
       return err
    }
}

export const textCompletion = async (query) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()

    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append('Authorization',`${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}ai/text-completion?${query}`, requestOptions)
        performanceMetric("/ai/text-completion", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        
        if (!response.ok){ 
            const data = await response.json()
            const errorMessageParsed = parseGetTextCompletionErrorMessage(data.internal_code)
            throw new Error(errorMessageParsed)
        }
        if (response.ok) return await response.json()
    } 
    catch (err) {
        return err
    }

}