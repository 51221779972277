import React from 'react'
import { CircularProgressbar } from "react-circular-progressbar";
import classes from './ProductsList.module.css'

const ProgressBar = ({ percentage }) => {

    const setColor = (number) => {
        if (number < 50) {
          return {path: '#EA271F', trail: 'rgba(255, 96, 89, 0.1)' , text: '#DD362F' }
        } else if ( 49 < number && number < 80) {
          return {path: '#FFD88C', trail: 'rgba(255, 237, 144, 0.5)' , text: '#E2A023' }
        } else if (79 < number && number  < 100) {
          return {path: '#B7DD8E', trail: 'rgba(151, 212, 86, 0.3)' , text: '#97D456' }
        } else if (number === 100) {
          return {path: '#22BB3D', trail: '#22BB3D' , text: '#22BB3D' }
        }

        return {path: '#EA271F', trail: 'rgba(255, 96, 89, 0.1)' , text: '#DD362F' }
      }

    return (
      <>
        <h4 className={classes.scoreTitle} style={{ color: `${setColor(percentage)?.text || "white"}` }}>Puntaje de publicación perfecta</h4>
        <CircularProgressbar
            value={Math.trunc(percentage || 0)}
            text={`${Math.trunc(percentage || 0)}%`}
            strokeWidth={15}
            styles={{
                root: {
                width: "100%"
                },
                path: {
                stroke: setColor(percentage)?.path || "",
                strokeLinecap: 'round',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
                },
                trail: {
                stroke: setColor(percentage)?.trail || "",
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
                },
                text: {
                fill: setColor(percentage)?.text || "",
                fontSize: '16px',
                textAnchor: 'middle',
                dominantBaseline: 'middle',
                },
            }}
        />
      </>
    )
}

export default ProgressBar