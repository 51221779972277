// Libraries
import * as React from 'react';
import DOMPurify from 'dompurify';

// MUI Libraries
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';

const HtmlTooltip = styled(({ className, isMobile, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isMobile }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: isMobile ? 500 : 380,
        fontSize: 15,
        textAlign: 'left',
        display: 'inline-block',
        color: '#000',
        fontFamily: 'Montserrat',
        border: '1px solid #dadde9',
        borderRadius: '10px',
        whiteSpace: 'normal',
        padding: theme.spacing(1),
    },
}));

const CustomTooltip = ({
    children,
    tooltipContent,
    skipStringSanitization = false,
    wrapperStyle = {},
    wrapperClassName = '',
    showTooltip = true,
    offset = [0, 0]
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    let content = tooltipContent;
    if (!skipStringSanitization) {
        content = DOMPurify.sanitize(tooltipContent, {
            ALLOWED_TAGS: ['div', 'p', 'span', 'br'],
            ALLOWED_ATTR: ['style', 'className'],
        });
    }

    const handleClick = (event) => {
        if (isMobile) {
            event.stopPropagation();
        }
    };

    const [open, setOpen] = React.useState(false);

    const handleTouchStart = (event) => {
        event.stopPropagation();
        setOpen(true);
    };

    const handleTouchEnd = (event) => {
        event.stopPropagation();
        setOpen(false);
    };

    if (!showTooltip) {
        return <>{children}</>;
    }

    return (
        <HtmlTooltip
            isMobile={isMobile}
            title={
                skipStringSanitization ? (
                    tooltipContent
                ) : (
                    <div style={{ whiteSpace: 'normal' }}>
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                )
            }
            interactive="true"
            disableHoverListener={isMobile}
            disableFocusListener={isMobile}
            disableTouchListener={!isMobile}
            enterTouchDelay={0}
            leaveTouchDelay={6000}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: offset,
                        },
                    },
                ],
            }}
        >
            <div style={{ ...wrapperStyle }} className={wrapperClassName} onClick={handleClick} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                {children}
            </div>
        </HtmlTooltip>
    );
};

export default CustomTooltip;
