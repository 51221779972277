//libraries
import React, { useEffect, useState, useRef } from 'react'

import { Row, Col, Container } from 'react-bootstrap';

//styles
import classes from "./css/MetricsDashboards.module.css";

//services
import { 
    getFilters as getFiltersFromAPI,
    getUserDashboards as getUserDashboardsFromAPI,
    generateEmbedURLs  as generateEmbedURLsFromAPI
} from 'services/quicksight';

//components
import FiltersMetricsDashboards from "./components/FiltersMetricsDashboards"
import QuickSightVisuals from './components/QuickSightVisuals';
import QuickSightVisualsLoader from "./components/QuickSightVisualsLoader"

const MetricsDashboardsView = () => {
    
    const [filtersData, setFiltersData] = useState({})
    const [originalFiltersData, setOriginalFiltersData] = useState({})
    const [userDashboardsData, setUserDashboardsData] = useState([])
    const [dashboardGroupSelected, setDashboardGroupSelected] = useState(null)
    const [isLoadingFilters, setIsLoadingFilters] = useState(false)
    const [isLoadingUserDashboards, setIsLoadingUserDashboards] = useState(false)
    const [filtersValuesForDashboards, setFiltersValuesForDashboards] = useState([])


    useEffect(() => {
      getInitialDataforFilters()
    }, [])

    useEffect(() => {
        if(filtersValuesForDashboards?.length){
            console.log(filtersValuesForDashboards)
        }
    }, [filtersValuesForDashboards])
    

    const getInitialDataforFilters = () => {
        try {
            getFiltersData()
            getUserDashboards()
        } catch (error) {
            console.log(error)
        }
    }
    
    const getFiltersData = () => {
        try {
            setIsLoadingFilters(true)
            getFiltersFromAPI().
                then(response => {
                    setIsLoadingFilters(false)
                    if(response?.response){
                        const formattedfiltersData = formattedDataForFilters(response.response.data?.data)

                        if(formattedfiltersData != null){
                            setFiltersData({...formattedfiltersData})
                            setOriginalFiltersData(JSON.parse(JSON.stringify(formattedfiltersData)))
                        }
                        
                    }
                })
                .catch( err => {
                    setIsLoadingFilters(false)
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const formattedDataForFilters = (dataFromResponse) => {
        try {
            return {
                countries: dataFromResponse?.countries?.filter(e => e.hasStoresInAnyChannel) || [],
                channels: dataFromResponse?.channels?.filter(e => e.hasStores) || [],
                stores: dataFromResponse.stores || []
            }
        } catch (error) {
            console.log(error)
            return dataFromResponse
        }
    }

    const handleDashboardGroupChange = async (dashboardGroupId) => {
        try {
            const dashboardGroupFound = userDashboardsData?.find(e => e?.group.id === dashboardGroupId)
            //LEAVE COMMENT BECAUSE WE WILL TEST WITH USERS IF THE CHANGE OF DASHBOARD IS QUICK ENOUGH
            if(dashboardGroupFound){
                const dashboardGroupWithUrlsUpdated = await updateDashboardUrls(dashboardGroupFound)
                setDashboardGroupSelected(dashboardGroupWithUrlsUpdated)
            }
            // if(dashboardGroupFound){
            //      setDashboardGroupSelected(dashboardGroupFound)
            // }
        } catch (error) {
            console.log(error)
        }
    }

    const updateDashboardUrls = async (dashboardGroup) => {
        try {
            
            const dashboardsRefetched = await refetchDashboardsURL(dashboardGroup?.dashboards)
            addUrlsUpdatedToDashboardGroup(dashboardGroup, dashboardsRefetched)
            return dashboardGroup
        } catch (error) {
            console.log(error)
            return dashboardGroup
        }
    }

    const addUrlsUpdatedToDashboardGroup = (dashboardGroup, dashboardsRefetched= [], compareDashboardIdOnly=true) => {
        try {
            for(const dashboard of dashboardGroup?.dashboards || []){
                const  dashboardRefetchedFound = dashboardsRefetched.find(e => {

                    return compareDashboardIdOnly ?
                        e?.dashboardParametersItem?.quick_sight_dashboard_id === dashboard?.quickSightDashboardId 
                    :
                        e?.dashboardParametersItem?.quick_sight_dashboard_id === dashboard?.quickSightDashboardId && e?.dashboardParametersItem?.quick_sight_sheet_id == dashboard?.quickSightSheetId && e?.dashboardParametersItem?.quick_sight_visual_id == dashboard?.quickSightVisualId 
                })
                
                if(dashboardRefetchedFound != undefined){
                    dashboard.embedUrl = dashboardRefetchedFound.result?.url
                }

            }

            return { ...dashboardGroup }
        } catch (error) {
            console.log(error)
        }
    }

    const refetchDashboardsURL = async (dashboards) => {
        try {
            let dashboardsParams = dashboards.map(e => {
                return {
                    dashboard_type: e.type === "sheet" ? "dashboard" : e.type,
                    quick_sight_dashboard_id: e.quickSightDashboardId,
                    quick_sight_sheet_id: e.quickSightSheetId,
                    quick_sight_visual_id: e.quickSightVisualId,
                    filters: []
                }
            })

            if(dashboards.find(e => e.type === "sheet")){
                dashboardsParams = [dashboardsParams?.[0]]
            }
            const response = await generateEmbedURLsFromAPI({ dashboard_parameters: dashboardsParams })
            return response?.response?.data?.data?.success
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const getUserDashboards = () => {
        try {
            setIsLoadingUserDashboards(true)
            getUserDashboardsFromAPI("?generate_only_one_url=true").
                then(response => {
                    setIsLoadingUserDashboards(false)
                    if(response?.response){
                        
                        setUserDashboardsData(response.response.data?.data || [])
                        
                        setDashboardGroupSelected(response.response.data?.data?.[0])
                    }
                })
                .catch( err => {
                    setIsLoadingUserDashboards(false)
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }

    //end pagination methods

    return (
        <>
            <div className={classes.wrapperTitle}>
                <span className={classes.title}>Indicadores</span>
            </div>
            <Container style={{ marginTop: 30, padding: "0px 40px" }} fluid>
                <Row>
                    <Col xs={12}>
                        <FiltersMetricsDashboards 
                            filtersData={filtersData}
                            userDashboardsData={userDashboardsData}
                            dashboardGroup={dashboardGroupSelected}
                            originalFiltersData={originalFiltersData}
                            dashboardGroupSelected={dashboardGroupSelected}
                            loaders={{
                                isLoadingFilters,
                                isLoadingUserDashboards
                            }}
                            setters={{
                                setFiltersData,
                                setUserDashboardsData,
                                setIsLoadingFilters,
                                setIsLoadingUserDashboards,
                                setFiltersValuesForDashboards
                            }}
                            handlers= {{
                                handleDashboardGroupChange
                            }}
                        />
                    </Col>
                    {isLoadingUserDashboards || !filtersValuesForDashboards?.length?
                        <QuickSightVisualsLoader />   
                    :
                        <Col xs={12}>
                            <QuickSightVisuals 
                                dashboardGroup={dashboardGroupSelected}
                                filtersValuesForDashboards={filtersValuesForDashboards}
                                loaders={{
                                    isLoadingUserDashboards
                                }}
                            />
                        </Col>
                    }
                </Row>
            </Container>
        </>
    );
};


export default MetricsDashboardsView