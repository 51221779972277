import React, { useState, useEffect } from 'react'
import Modal from 'reactstrap/es/Modal'
import { Box, Typography, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TableProducts = ({ openProductsModal, toggleProductsModal, product, origin }) => {

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        try {
            if (origin === "ProductList") {
                let rowsAux = []
                for (const unit of product.promotion_detail.products_in_promotion) {
                    rowsAux.push(createDataUnitProducts(unit.product.name, unit.product.store.tienda ,unit.product.item_ids.find(e => e.type === 'ean').value, unit.product.item_ids.find(e => e.type === 'sku').value, unit.product.base_price, unit.quantity))
                }
                setRows(rowsAux)
                setColumns([
                    'Nombre',
                    'Tienda',
                    'Ean',
                    'Sku',
                    'Precio',
                    'Cantidad'

                ])
            }
        } catch (error) {
            console.log(error)
        }

    }, [])

    const createDataUnitProducts = (name, tienda,ean, sku, precio, stock) => {
        return { name, tienda, ean, sku, precio, stock };
    }

    return (
        <Modal
            isOpen={openProductsModal}
            toggle={toggleProductsModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: '100%',
                    maxWidth: '1000px',
                    bgcolor: 'white',
                    minWidth: '1000px',
                    boxShadow: 24,
                    p: 3,
                    zIndex: 1,
                    borderRadius: 4,

                    '@media (max-width: 1000px)': {
                        width: '100%',
                        minWidth: 'unset', // Eliminar el minWidth de 200%
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div></div>
                    <Typography fontFamily='Montserrat' variant="h4" sx={{ ml: 5, mb: 3 }} ><b>Detalle productos</b></Typography>
                    <Tooltip title="Salir" arrow>
                        <CloseIcon onClick={toggleProductsModal} sx={{
                            color: 'black', fontSize: 40, margin: '-2px 0 0', cursor: 'pointer'
                        }} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography fontFamily='Montserrat' fontSize="15px" align='center'  > Lista de productos que componen al {product.promotion_detail.type.charAt(0).toUpperCase() + 
                    product.promotion_detail.type.slice(1)} <b>{product.item_ids[0].value}</b> </Typography>

                    <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
                        <Table sx={{ minWidth: 800 }} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: "#51CBCE" }}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column} align="center">{column}</TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.ean}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="center">{row.tienda}</TableCell>
                                        <TableCell align="center">{row.ean}</TableCell>
                                        <TableCell align="center">{row.sku}</TableCell>
                                        <TableCell align="center">{row.precio}</TableCell>
                                        <TableCell align="center">{row.stock}</TableCell>
                                      
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>

            </Box>
        </Modal>
    )
}

export default TableProducts