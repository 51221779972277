import {performanceMetric} from "../utils/utils";

const configFile = require('../config/url');

export const getBillingsParameters = async () => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}config/parameters/billings`, requestOptions)
        performanceMetric("/config/parameters/billings", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = await response.json()
        return data.message
    }
    catch(err) {
        console.log(err)
    }     
}

export const connectBilling = async (storeId, body) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append('Authorization', bearerToken);
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    }

    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}store/config/billing?id=${storeId}`, requestOptions)
        performanceMetric("/store/config/billing", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = response.json()
        return data
    } catch {
        console.log(err)
    }
}

export const editBilling = async (storeId, body) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append('Authorization', bearerToken);
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    }

    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}store/config/billing?id=${storeId}`, requestOptions)
        performanceMetric("/store/config/billing", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = response.json()
        return data
    } catch {
        console.log(err)
    }
}

export const deleteBilling = async (storeId, channelId) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}store/config/billing?id=${storeId}&channel=${channelId}`, requestOptions)
        performanceMetric("/store/config/billing", "DELETE", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        const data = await response.json()
        return data.message
    }
    catch(err) {
        console.log(err)
    }
}