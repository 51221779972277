

export const publicationsFiltersLoaderItemData = {
    propsComponent: {
        width: 100,
        height: 25
    },
    style: {
        transform: "scale(1, 1)",
        margin: "7px 0px"
    }
}

export const loaderSquaredFilterButtonData = {
    propsComponent: {
        width: 40,
        height: 40
    },
    style: {
        transform: "scale(1, 1)",
    }
}
export const publicationsFiltersLoaderData = [
    {...publicationsFiltersLoaderItemData},
    {...publicationsFiltersLoaderItemData},
    {...publicationsFiltersLoaderItemData, },
    {...publicationsFiltersLoaderItemData},
    {...publicationsFiltersLoaderItemData}
]


export const publicationsButtonsLoaderData = [
    {
        propsComponent: {
            width: 100,
            height: 40
        },
        style: {
            transform: "scale(1, 1)",
        }
    },
    {
        ...loaderSquaredFilterButtonData
    }
    
]


export const publicationsSearchBarLoader =  {
    propsComponent: {
        width: "100%",
        height: 48
    },
    style: {
        transform: "scale(1, 1)",
        borderRadius: 15
    }
}