const configFile = require('../config/url');
import axios from "axios"
import {performanceMetric} from "../utils/utils";

export const getChannelsParameters = async () => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders
    }
    try {
        const start = Date.now();  
        const response = await fetch(`${configFile.config.ambiente}config/parameters/channels`, requestOptions)
        performanceMetric("/config/parameters/channels", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        const data = await response.json()
        return data.message
    }
    catch(err) {
        console.log(err)
    }     
}

export const createChannel = async (storeId, body) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    }
    try {
        const start = Date.now();   
        const response = await fetch(`${configFile.config.ambiente}store/config/channels?id=${storeId}`, requestOptions)
        performanceMetric("/store/config/channels", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        
        const data = await response.json()
        return data.message
    }
    catch(err) {
        return err
    }
}

export const updateChannel = async (storeId, body) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    }

    try {
        const start = Date.now();  
        const response = await fetch(`${configFile.config.ambiente}store/config/channels?id=${storeId}`, requestOptions)
        performanceMetric("/store/config/channels", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        
        const data = await response.json()
        return data.message
    }
    catch(err) {
        return err
    }
}

export const deleteChannel = async (storeId, channelId) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    }
    try {
        const start = Date.now();  
        const response = await fetch(`${configFile.config.ambiente}store/config/channels?id=${storeId}&channel=${channelId}`, requestOptions)
        performanceMetric("/store/config/channels", "DELETE", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        
        const data = await response.json()
        return data.message
    }
    catch(err) {
        return err
    }
}

export const generateTokensByOauth = async (requestBody) => {
    try {
        const accessToken = localStorage.getItem('Token')

        const axiosRequestConfig = {
            method: 'POST',
            url: `${configFile.config.ambiente}store/config/channels/oauth-credentials`,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: requestBody
        }; 

        const response = await axios(axiosRequestConfig)
        return {
            response : {...response},
            error: null
        }
    }
    catch(err) {
        console.log(err)
        return {
            response: null,
            error: err
        }
    }
}