
import React, { useState } from "react";
import SendLabel from "components/sendLabelToEmailModal/send-label";

const LabelModal = (props) => {
 
  console.log(props);
  const [displayEmailModal, setdisplayEmailModal] = useState(false);
  return (
    <React.Fragment>
     
      {displayEmailModal && (
        <>
          <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
           
           
          </h5>

          <SendLabel
            onhideModal={props.onhideModal}
            purchaser={props.purchaser}
          />
        </>
      )}
      {!displayEmailModal && (
        <>
          <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
          
            Etiqueta
          </h5>
          <div>
            <div className="text-center" style={{  height:"400px", border: "none" }} >
            
              <iframe
                src={`${props.purchaser.label}#zoom=70`}
                title="Etiqueta"
                height="400px"
                width="300px"
                scrolling="no"
              
              ></iframe>
            </div>
          </div>
          <div class="text-center">
            <button
              id="bttnSubmit"
              type="submit"
              style={{
                backgroundColor: "#051B2F",
                textAlign: "center",
                color: "white",
                width: "300px",
                height: "63px",
                padding: "22px 50px",
                borderRadius: "17px",
                color: "#FFFFFF",
                marginLeft: "0.5em",
                textTransform: "none",
                fontWeight: "bold",
                border: "0",
                marginTop:"2em"
              }}
              onClick={() => {
                setdisplayEmailModal(true);
              }}
            >
              Enviar por correo &nbsp;
              <span className="btn-label">
                <i className="nc-icon nc-send" />
              </span>
            </button>
          </div>
          <br />
        </>

      )}
    </React.Fragment>
  );
};
export default LabelModal;
