import React, { useState } from 'react';
import Checkbox from './CheckboxAlert';
import termAndConditions from './TermAndConditions.module.css';

const TermAndConditions = ({ isCheckedTerms, setIsCheckedTerms }) => {
    const [isChecked, setIsChecked] = useState(isCheckedTerms);

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        setIsCheckedTerms(newCheckedState);
    };

    return (
        <div className={termAndConditions.container} onClick={handleCheckboxChange}>
            <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <span>{`Revisa los \0`}</span>
            <span className={termAndConditions.terminosYCondiciones}> Términos y Condiciones</span>
        </div>
    );
};

export default TermAndConditions;
