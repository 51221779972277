import axios from 'axios';
import { performanceMetric } from "../utils/utils";
const configFile = require('../config/url');


export const getAlertTypes = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}alerts_analytics/alerts_types${query}`, requestOptions)
        performanceMetric("/alerts_analytics/alerts_types", "GET", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}


export const getAlerts = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}alerts_analytics${query}`, requestOptions)
        performanceMetric("/alerts_analytics", "GET", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}


export const postAlert = async (alert) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambienteAnalytics}alerts_analytics`,
            headers: {
                'x-api-key': configFile.config.apiKeyAnalytics,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify(alert)
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error;
    }
}


export const updateAlert = async (alert) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambienteAnalytics}alerts_analytics`,
            headers: {
                'x-api-key': configFile.config.apiKeyAnalytics,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify(alert)
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error;
    }
}


export const getNotifications = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}alerts_analytics/alerts_notifications${query}`, requestOptions)
        performanceMetric("/alerts_analytics/alerts_notifications", "GET", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}


export const updateNotificationSeenStatus = async (notifications) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambienteAnalytics}alerts_analytics`,
            headers: {
                'x-api-key': configFile.config.apiKeyAnalytics,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify(notifications)
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error;
    }
}