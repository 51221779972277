import React, { useRef, useState } from 'react'
import classes from './Filters.module.css'
import Select from '../../../components/UI/Select2'
import { Skeleton } from '@mui/material';

//images
import TrashIcon from "../../../assets/img/trash-icon.png"
import DowloadIcon from "../../../assets/img/icons/dowloadIcon.png"

//data
import { 
    publicationsFiltersLoaderData,
    publicationsButtonsLoaderData,
    loaderSquaredFilterButtonData
} from "../../../utils/loaders/loadersData"

const Filters = ({ countries, setFilters, dowload, clearSort, setStoreExcel, setCountryExcel, loadingFilters, loadingPublications }) => {
    const currentCountry = useRef("")
    const currentStore = useRef("")
    const currentCategory = useRef("")
    const currentBrand = useRef("")
    const currentChannel = useRef("")
    const currentState = useRef("")
    const currentPublicationType = useRef("")


    const [stores, setStores] = useState(countries?.find(e => e.country === "Todos")?.stores ?? [])
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const channels = [{ "id": 2, "channel": "Mercado Libre" }, { "id": 18, "channel": "Amazon" }]
    const [states] = useState([
        { "id": "Pendiente", "state": "Pendiente" },
        { "id": "Publicado", "state": "Publicado" },
        { "id": "Rechazado", "state": "Rechazado" }
    ]);
    const [publicationTypes] = useState([
        { "id": "healthcheck=true", "type": "Tradicionales" },
        { "id": "buybox=true", "type": "BuyBox" },
        { "id": "healthcheck=false&buybox=false", "type": "Sin Clasificar" }
    ]);
    const [selectedOptionCountry, setSelectedOptionCountry] = useState("")
    const [selectedOptionStore, setSelectedOptionStore] = useState("")
    const [selectedOptionCategory, setSelectedOptionCategory] = useState("")
    const [selectedOptionBrand, setSelectedOptionBrand] = useState("")
    const [selectedOptionChannel, setSelectedOptionChannel] = useState("")
    const [selectedOptionState, setSelectedOptionState] = useState("");
    const [selectedOptionPublicationType, setSelectedOptionPublicationType] = useState("");

    const handleOptionsCountry = (value) => {
        setSelectedOptionCountry(value)
        setSelectedOptionCategory("")
        setSelectedOptionBrand("")
        setSelectedOptionStore("")
    }

    const handleOptionsStore = (value) => {
        setSelectedOptionStore(value)
        setSelectedOptionCategory("")
        setSelectedOptionBrand("")
    }

    const handleOptionsCategory = (value) => {
        setSelectedOptionCategory(value)
    }

    const handleOptionsBrand = (value) => {
        setSelectedOptionBrand(value)
    }

    const handleOptionsChannel = (value) => {
        setSelectedOptionChannel(value)
    }

    const handleOptionsState = (value) => {
        setSelectedOptionState(value);
    };

    const handleOptionsPublicationType = (value) => {
        setSelectedOptionPublicationType(value);
    };

    const handleCountry = (value) => {
        setCountryExcel(value);
        currentCountry.current = value;
        handleOptionsCountry(countries.find(e => e.value === value)?.name ?? "");
        setStores(countries.find(c => c.value === value)?.stores ?? [])
    }

    const handleStore = (value) => {
        setStoreExcel(value);
        currentStore.current = value
        handleOptionsStore(stores.find(e => e.value === value)?.name ?? "");
        setCategories(stores.find(s => s.value === value)?.categories ?? [])
        setBrands(stores.find(s => s.value === value)?.brands ?? [])
    }

    const handleCategory = (value) => {
        currentCategory.current = value;
        handleOptionsCategory(categories.find(e => e.value === value)?.name ?? "");
    }

    const handleBrand = (value) => {
        currentBrand.current = value
        handleOptionsBrand(brands.find(e => e.value === value)?.name ?? "");
    }

    const handleChannel = (value) => {
        currentChannel.current = value
        handleOptionsChannel(channels.find(e => e.value === value)?.name ?? "");
    }

    const handleState = (value) => {
        currentState.current = value;
        handleOptionsState(states.find(e => e.id === value)?.state ?? "");
    };

    const handlePublicationType = (value) => {
        currentPublicationType.current = value;
        handleOptionsPublicationType(publicationTypes.find(e => e.id === value)?.type ?? "");
    };


    const clearFilters = () => {
        clearSort()
        currentCountry.current = ""
        handleOptionsCountry("");
        currentStore.current = ""
        handleOptionsStore("");
        currentCategory.current = ""
        handleOptionsCategory("");
        currentBrand.current = ""
        handleOptionsBrand("");
        currentChannel.current = ""
        handleOptionsChannel("");
        currentState.current = "";
        handleOptionsState("");
        currentPublicationType.current = "";
        handleOptionsPublicationType("");
        setFilters(currentStore.current, currentCountry.current, currentCategory.current, currentBrand.current, currentChannel.current, currentState.current, currentPublicationType.current);
    }

    return (
        <div className={classes.filtersWrapper} >
            <div className={classes.filters}>
                {loadingFilters ?
                    <>
                        {publicationsFiltersLoaderData.map( (loaderData, index) => (
                            <Skeleton {...loaderData.propsComponent} style={loaderData.style} key={"pub-filter-select-loader-" + index}/>
                        ))}
                    </>
                :
                    <>
                         <div className={classes.inputLabel}>
                            <Select filterName="País" options={countries} handleOptions={handleOptionsCountry} selectedOption={selectedOptionCountry} handle={handleCountry} name="country" ref={currentCountry} />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select search filterName="Tienda" options={stores} handleOptions={handleOptionsStore} selectedOption={selectedOptionStore} handle={handleStore} name="store" />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select filterName="Categoría" options={categories} handleOptions={handleOptionsCategory} selectedOption={selectedOptionCategory} handle={handleCategory} name="category" />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select filterName="Marca" options={brands} handleOptions={handleOptionsBrand} selectedOption={selectedOptionBrand} handle={handleBrand} value="id" name="name" />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select filterName="Canal de venta" options={channels} handleOptions={handleOptionsChannel} selectedOption={selectedOptionChannel} handle={handleChannel} value="id" name="channel" />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select filterName="Estado" options={states} handleOptions={handleOptionsState} selectedOption={selectedOptionState} handle={handleState} value="id" name="state" />
                        </div>
                        <div className={classes.inputLabel}>
                            <Select filterName="Tipo de publicación" options={publicationTypes} handleOptions={handleOptionsPublicationType} selectedOption={selectedOptionPublicationType} handle={handlePublicationType} sortByName={false} value="id" name="type" />
                        </div>
                    </>
                }
            </div>
            <div className={classes.filtersButtons}>
                {loadingFilters ?
                    <>
                         {publicationsButtonsLoaderData.map( (loaderData, index) => (
                            <Skeleton {...loaderData.propsComponent} style={loaderData.style} key={"pub-filter-button-loader-" + index}/>
                        ))}
                    </>
                :
                    <>
                        <button className={classes.buttonFilter} onClick={() => setFilters(currentStore.current, currentCountry.current, currentCategory.current, currentBrand.current, currentChannel.current, currentState.current, currentPublicationType.current)}>
                            <span className={classes.buttonFilterText}>Aplicar</span>
                        </button>
                        <button className={classes.buttonClear} onClick={clearFilters}>
                            <img className={classes.buttonFilterText} src={TrashIcon} />
                        </button>
                    </>
                }
                {
                    loadingPublications ?
                        <Skeleton {...loaderSquaredFilterButtonData.propsComponent} style={loaderSquaredFilterButtonData.style}/>
                    :
                        <button className={classes.buttonDowload}
                            onClick={() => {
                                dowload(currentStore.current, currentCountry.current)
                            }}
                        >
                            <img className={classes.buttonFilterText} src={DowloadIcon} />
                        </button>
                }
                    
            </div>
        </div>
    )
}

export default Filters;