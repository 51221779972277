const configFile = require('../config/url');
import axios from 'axios';
import {performanceMetric} from "../utils/utils";

export const getUser = async (email) => {
    const accessToken = localStorage.getItem("AccessToken");
    const updateEmail = email.replace("+", "%2b")
    const myHeaders = new Headers()
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `Bearer ${accessToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    }
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambiente}user?user=${updateEmail}`, requestOptions)
        performanceMetric("/user", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        const data = await response.json();
        return data.message
    }
    catch (err) {
        console.log(err)
    }
}

export const getUsersByProfile = async (profile) => {
    try {
        const accessToken = localStorage.getItem("AccessToken")

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}users?profile=${profile}`,
            headers: { 
              'x-api-key': configFile.config.apiKey, 
              'Authorization': 'Bearer ' + accessToken
            }
          };
        return await axios.request(config)
            .then((response) => {
                return response.data.message
            })
            .catch((error) => {
                return error
            });

    } catch (error) {
        return error;
    }
}
