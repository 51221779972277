import WebSocketAsPromised from 'websocket-as-promised';

import config from '../config/url'

const connect = async () => {
    try {

        const accessToken = localStorage.getItem("Token")

        const wsp = new WebSocketAsPromised(config.config.webSocketConnectURL, {
            createWebSocket: url => new WebSocket(url, [accessToken]),
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data)
        });

        await wsp.open()
        return wsp
        
    } catch (error) {
        console.log(error)
        return null
    }
}

const disconnect = async (webSocketInstance) => {
    try {
        return await webSocketInstance?.close()
    } catch (error) {
       console.error(error)
    }
}

export default {
    connect,
    disconnect
}