
import { prettifyRut } from "react-rut-formatter";

const ClientModal = (props) => {
  let FormattedRut = prettifyRut(props.purchaser.buyerIdNumber);
  return (
    <>
      <p style={{ textAlign: "center" }}>Orden {props.purchaser.orderNo}</p>
      <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
        {props.purchaser.buyerName}
      </h5>
      <p
        style={{ color: "#858F99", textAlign: "center", paddingBottom: "5px" }}
      >
        {FormattedRut}
        <br />
      </p>
      <hr
        style={{
          width: "30px",
          textAlign: "center",
          justifyContent: "center",
          height: "2px",
          backgroundColor: "rgb(0 42 252)",
          color: "rgb(0 42 252)",
          marginLeft: "48%",
        }}
      ></hr>
    </>
  );
};
export default ClientModal;
