import React, { useState, useEffect } from 'react'

//librarys
import {
    Box,
} from "@mui/material";

//img
import withoutImage from "../../../assets/img/errorImage.png"

const BoxImages = ({ images = [], origin }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [imgSelected, setImgSelected] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <Box sx={{ display: "flex", width: "100%" }}>

            {/* Column of small images */}
            <Box sx={{
                display: "flex",
                flexDirection: 'column',
            }}>
                {[0, 1, 2, 3].map((position) => (
                    <LittleImage
                        key={position}
                        position={position}
                        images={images}
                        setImgSelected={setImgSelected}
                        imgSelected={imgSelected}
                        last={position === 3}
                        origin={origin} 
                        windowWidth={windowWidth}
                    />
                ))}
            </Box>

            {/* Principal image */}
            <PrincipalImage
                image={images?.length > 0 ? images[imgSelected]?.url : withoutImage}
                origin={origin} 
                windowWidth={windowWidth}
            />

        </Box>
    )
}

export default BoxImages

const LittleImage = ({ position, images, setImgSelected, last = false, imgSelected, origin, windowWidth }) => {
    const imageExists = images[position]?.url;
    const objectFitStyle = origin === 'publications' ? 'contain' : 'cover';
    return (
        <Box
            onClick={() => { if (imageExists) setImgSelected(position) }}
            sx={{
                aspectRatio: '1/1',
                border: position === imgSelected ? '3px solid #57C2BD' : '1px solid #D3D3D3',
                borderRadius: '17px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: windowWidth < 300 ? '70%' : '85px',
                height: windowWidth < 300 ? '70%' : '85px',
                mb: last ? "0px" : "8px",
                cursor: imageExists ? "pointer" : "default",
                opacity: imageExists ? 1 : 0.5
            }}>
            {imageExists ? (
                <img src={images[position].url} alt="little-img" style={{ borderRadius: '17px', width: "100%", height: "100%", objectFit: objectFitStyle }} />
            ) : (
                <></>
            )}
        </Box>
    )
}

const PrincipalImage = ({ image, origin, windowWidth }) => {
    const objectFitStyle = origin === 'publications' ? 'contain' : 'cover';

    return (
        <Box sx={{
            aspectRatio: '1/1',
            border: '1px solid #D3D3D3',
            borderRadius: '17px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '20px',
            width: windowWidth < 1300 ? '70%' : '365px',
            height: windowWidth < 1300 ? '70%' : '365px'
        }}>
            <img src={image} alt="principal-img" style={{ borderRadius: '17px', width: "100%", height: "100%", objectFit: objectFitStyle }} />
        </Box>
    )
}
