const axios = require('axios');
const qs = require('qs');
const configFile = require('../config/url');

//esto tiene que estar en el back
export const getRefreshTokenMeli = async (code) => {

    let data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': configFile.config.clientIdMeli,
        'client_secret': configFile.config.clientSecretMeli,
        'code': code,
        'redirect_uri': window.location.origin + '/admin/clientProfile'
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.mercadolibre.com/oauth/token',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded'
        },
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error
        });
}



