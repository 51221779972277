//react components
import React, { useState, useEffect } from 'react'

//librarys
import {
    Box,
    IconButton, Typography, Button, useMediaQuery
} from "@mui/material";

//Components
import SuggestionsModal from '../../../views/Competitiveness/Components/SuggestionsModal';
import { toSpanishNumberFormat } from '../../../utils/utils';
import { 
    tooltipPublicationRedirect, 
    tooltipPublicationLogistica, 
    tooltipPublicationStockModal, 
    suggestionsTooltip, 
    tooltipContentBell 
} from '../../../utils/tooltipContent';

//imgs
import CloseIcon from '@mui/icons-material/CloseRounded';
import Canasta from "../../../assets/img/canasta.png";
import Location from "../../../assets/img/icons/location.png";
import redirect from "../../../assets/img/icons/redirect.png";
import Bell from '../../../assets/img/icons/black-bell.svg';
import SuggestionsButton from '../../../assets/img/icons/spark-button-icon.svg';

// Services
import { getPublicationsSuggestions } from 'services/publications';

const HeaderDetails = ({ channelIcon, url, warehouse, stock, origin, statusPublication, handleClose, action = null, alerts = [], suggestions = 0, idPublication = null, handleShowAlert, logistica, translatedLogistica, CustomTooltip }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [suggestionsData, setSuggestionsData] = useState(null);
    const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);

    const handleOpenSuggestions = () => {
         if(suggestions > 0){
            setShowSuggestionsModal(true);
         }
    };

    useEffect(() => {
        if (suggestions > 0 && !suggestionsData) {
            handleSuggestionsData();          
        }
    }, [suggestions]);

    const handleSuggestionsData = async () => {
        try {
            const response = await getPublicationsSuggestions(`?id=${idPublication}`);    
            
            if (response?.data) {
                const suggestions = formatSuggestionsData(response?.data);                  
                setSuggestionsData(suggestions);               
            }else{
                setSuggestionsData([]); 
            }

        } catch (error) {
            setSuggestionsData([]); 
        } 
    };

    const countWords = (text) => {
        return text ? text.split(/\s+/).filter(word => word.length > 0).length : 0;
    };
    
    const formatSuggestionsData = (data) => {
        return data.map((suggestion) => {
            switch (suggestion.type) {
                case 'description': {
                    const oldValue = suggestion.original_value[0]?.description || '';
                    const newValue = suggestion.suggestion_result[0]?.description || '';
                    
                    const oldWordCount = countWords(oldValue);
                    const newWordCount = countWords(newValue);
    
                    return {
                        type: 'description',
                        oldValue,
                        newValue,
                        oldWordCount,
                        newWordCount,
                    };
                }
    
                case 'price': {
                    const currentPrice = suggestion.original_value[0]?.buybox?.current_price || 0;
                    const actionPrice = suggestion.suggestion_result[0]?.action_price || '';
                    
                    const priceChange = parseFloat(actionPrice.replace(/[^\d.-]/g, '')) || 0;
                    const isAdd = actionPrice.includes('Add');
                    
                    const newValue = isAdd ? currentPrice + priceChange : currentPrice - priceChange;                    
                    const changePercentage = (priceChange / currentPrice) * 100;
                
                    return {
                        type: 'price',
                        oldValue: currentPrice,
                        newValue,
                        priceChange,
                        change: isAdd ? changePercentage : -changePercentage,
                        info: suggestion.suggestion_result[0]?.info_price || ''
                    };
                }
    
                case 'keywords': {
                    const originalKeywords = suggestion.suggestion_result[0]?.publication_keywords || [];
                    const suggestedKeywords = suggestion.suggestion_result[0]?.info_keywords || [];
                
                    return {
                        type: 'keywords',                        
                        oldValue: originalKeywords,
                        newValue: suggestedKeywords
                    };
                }

                case 'images': {
                    const originalImagesCount = suggestion.original_value[0]?.images?.length || 0;
                    const suggestedImagesCount = parseInt(suggestion.suggestion_result[0]?.images?.match(/\d+/)[0], 10) || 0;
                    const infoImages = parseInt(suggestion.suggestion_result[0]?.info_images?.match(/\d+/)[0], 10) || 0;
                
                    return {
                        type: 'images',
                        oldValue: originalImagesCount,
                        newValue: suggestedImagesCount,
                        info: infoImages
                    };
                }
    
                default:
                    return null;
            }
        }).filter(Boolean); // Remove any null values from the array
    };
    
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: origin === 'publications' ? '10px' : '5px'
            }}>
                <CustomTooltip tooltipContent={tooltipPublicationRedirect} showTooltip={origin === 'publications'}>                                            
                    <img src={channelIcon} alt="channel-icon" />                                

                    <IconButton
                        onClick={() => window.open(url)}
                        sx={{
                            ml: "10px",
                            visibility: !url || url === "" ? 'hidden' : 'visible'
                        }}
                    >
                        <img src={redirect} alt="redirect" style={{ width: '150%', objectFit: 'cover' }} />
                    </IconButton>
                </CustomTooltip>
                {!isMobile &&
                    <Box sx={{ display: "flex", flexDirection: "row", alignContent: "center", gap: origin === 'publications' ? '25px' : '10px' }}>
                        <CustomTooltip tooltipContent={tooltipPublicationLogistica} showTooltip={origin === 'publications'}>                                            
                            <Box sx={{ display: "flex", ml: "30px" }}>
                                <img src={Location} alt="location" style={{ width: "20px", height: "20px" }} />
                                <Typography fontFamily='Montserrat' fontSize="14px" sx={{ fontWeight: 500, ml: "10px" }}>
                                    {origin === 'products' ? warehouse : (translatedLogistica ? translatedLogistica : (logistica ? logistica : "Sin información"))}
                                </Typography>
                            </Box>
                        </CustomTooltip>                         
                    
                        <CustomTooltip tooltipContent={tooltipPublicationStockModal} showTooltip={origin === 'publications'}>
                            <Box sx={{ display: "flex", ml: "30px" }}>
                                <img src={Canasta} alt="canasta" style={{ width: "20px", height: "20px" }} />
                                <Typography fontFamily='Montserrat' fontSize="14px" sx={{ fontWeight: 500, ml: "10px" }}>
                                    {toSpanishNumberFormat(stock)} {(origin === "products" || origin === "publications") ? (stock === 1 ? 'Unidad' : "Unidades") : ""}
                                </Typography>
                            </Box>
                        </CustomTooltip> 
                    
                        {origin === "publications" && (
                            <>
                                <CustomTooltip tooltipContent={tooltipContentBell(alerts)} showTooltip={origin === 'publications' && alerts.length > 0}>
                                    <Box sx={{ display: "flex", ml: "30px" }}>
                                        <img src={Bell} alt="campana" style={{ width: "20px", height: "20px" }} />
                                        <Typography fontFamily='Montserrat' fontSize="14px" sx={{ fontWeight: 500, ml: "10px" }}>
                                            {alerts.length === 1 ? `${toSpanishNumberFormat(alerts.length)} alerta` : `${toSpanishNumberFormat(alerts.length)} alertas`}
                                        </Typography>
                                    </Box>
                                </CustomTooltip>
                    
                                {suggestions !== 0 && (
                                    
                                    <Box sx={{ display: "flex", position: "absolute", right: "80px", top: "25px" }}>
                                        <CustomTooltip tooltipContent={suggestionsTooltip} showTooltip={origin === 'publications'}>
                                            <img
                                                src={SuggestionsButton}
                                                alt="sugerencias"
                                                style={{ cursor: 'pointer', width: "35px", height: "35px" }}
                                                onClick={handleOpenSuggestions}
                                            />
                                        
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '-10px',
                                                    left: '-10px',
                                                    backgroundColor: '#EB3223',
                                                    borderRadius: '50%',
                                                    width: '25px',
                                                    height: '25px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography sx={{ fontFamily: 'Montserrat', color: 'white', fontSize: '15px', fontWeight: '600' }}>
                                                    {suggestions}
                                                </Typography>
                                            </Box>
                                        </CustomTooltip>
                                    </Box>
                                )}
                    
                                {showSuggestionsModal && (
                                    <SuggestionsModal
                                        suggestions={suggestionsData}
                                        modalState={showSuggestionsModal} 
                                        setModalState={setShowSuggestionsModal}
                                        handleShowAlert={handleShowAlert}
                                        origin={'modal'}
                                    />
                                )}
                            </>
                        )}
                    </Box>                
                }
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }} >
                {
                    origin === "products" &&
                    <Button
                        onClick={action}
                        variant="outlined"
                        sx={{
                            fontSize: '14px',
                            borderRadius: "10px",
                            minWidth: "112px",
                            maxWidth: "112px",
                            maxHeight: "35px",
                            minHeight: "35px"
                        }}
                    >
                        <b>Editar</b>
                    </Button>

                }

                <CloseIcon
                    onClick={handleClose}
                    sx={{
                        ml: "30px",
                        color: 'black',
                        fontSize: 35,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: 'transparent' },
                    }}
                />
            </Box>

        </Box>
    )
}

export default HeaderDetails