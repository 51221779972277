import React, { useEffect, useState } from "react";
import ProfileHeader from "components/UI/ProfileHeader";
import classes from "./addWms.module.css";
import CardItem from "components/UI/CardItem";

import iconInstance from "../..//assets/img/icons/iconInstance.svg";

import emptyImg from "../../assets/img/channelEmpty.png";

const AddWms = ({
  countries,

  allWmsConfiguration,
  handleCurrent,
  handleRoute,
  parameters,
  isAllWmsDisabled,
}) => {
  const [isWmsNotAvailable, setisWmsNotAvailable] = useState(false);

  useEffect(() => {
    let x = parameters.filter((wms) => wms.paises[0] === countries[0]);
    let resultfilter = [];
    resultfilter = x.filter(
      (firstArrayItem) =>
        !allWmsConfiguration.some(
          (secondArrayItem) =>
            firstArrayItem.name === secondArrayItem.wmsCompanyName
        )
    );

    if (resultfilter.length === 0) setisWmsNotAvailable(true);
  }, []);

  return (
    <div className={classes.wrapper}>
      <ProfileHeader backTo={() => handleRoute("current")}>
        Agregar nuevo fulfillment
      </ProfileHeader>
      {isAllWmsDisabled && (
        <p className={classes.introductionText}>
          Acá encuentras la lista de los WMS que existen actualmente
          disponibles. Elige el que se acomode más a tus necesidades y sigue las
          instrucciones para llevar a cabo una buena conexión.
        </p>
      )}
      {!isAllWmsDisabled && (
        <p className={classes.introductionText}>
          En esta sección puedes agregar otro WMS que se encuentre disponible.
        </p>
      )}
      <div className={classes.billings}>
        {allWmsConfiguration.length === 0 && (
          <>
            <CardItem
              name="Instance"
              icon={iconInstance}
              onClick={() => handleRoute("instance")}
            />

            {parameters.length > 0 &&
              parameters.map(
                (billing, i) =>
                  billing.paises.some((c) => countries.includes(c)) && (
                    <CardItem
                      key={i}
                      name={
                        billing.name.charAt(0).toUpperCase() +
                        billing.name.slice(1)
                      }
                      icon={billing.config_mtd?.icon}
                      onClick={() => {
                        handleRoute("addform");
                        handleCurrent(billing.name);
                      }}
                    />
                  )
              )}
          </>
        )}
        {allWmsConfiguration.length !== 0 &&
          allWmsConfiguration.filter((b) => b.wmsCompanyName === "Instance")
            .length === 0 && (
            <CardItem
              name="Instance"
              icon={iconInstance}
              onClick={() => handleRoute("instance")}
            />
          )}
        {allWmsConfiguration.length !== 0 && (
          <>
            {parameters.length > 0 &&
              allWmsConfiguration.length !== 0 &&
              parameters.map(
                (wms, i) =>
                  allWmsConfiguration.filter(
                    (b) => b.wmsCompanyName === wms.name
                  ).length === 0 &&
                  wms.paises.some((c) => countries.includes(c)) && (
                    <CardItem
                      key={i}
                      name={
                        wms.name.charAt(0).toUpperCase() + wms.name.slice(1)
                      }
                      icon={wms.config_mtd?.icon}
                      onClick={() => {
                        handleRoute("addform");
                        handleCurrent(wms.name);
                      }}
                    />
                  )
              )}
          </>
        )}
      </div>
      {isWmsNotAvailable && (
        <div className={classes.empty}>
          <img src={emptyImg} className={classes.imgEmpty} />
          <span className={classes.textEmpty}>
            No hay más WMS disponibles para agregar.
          </span>
          <p className={classes.explainEmpty}>
            Por el momento, no existen más WMS disponibles para realizar una
            nueva conexión.
          </p>
          <button
            className={classes.button}
            onClick={() => handleRoute("current")}
          >
            <span className={classes.buttonText}>
              Ver mis WMS ya integrados
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AddWms;
