import MaterialTable from "material-table";
import React, { forwardRef } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Search from "@material-ui/icons/Search";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import RoomIcon from "@material-ui/icons/Room";
import SaveAlt from "@material-ui/icons/SaveAlt";
import noDataImage from "../../assets/img/noDataImageBlue.png";
import checkedImg from "../../assets/img/check_verde.png"
import pendingImg from "../../assets/img/pendiente.png"
import notCheckedImg from "../../assets/img/not-checked.png"
import "../../assets/css/global.css";

const tableIcons = {
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <RoomIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
};

const BallotDetailTable = (props) => {
  let columns = [];

  if (props.label) {
    columns = [
      {
        title: "Validado",
        field: "check",
        render: (rowData) => {
            const attributesForValidationColumn = getAttributesForValidationColumn(rowData)

            return (
            <>
              <img src={attributesForValidationColumn.sourceImg} />
              <span style={attributesForValidationColumn.stylesLabel}>{rowData.countValidated || 0} / {rowData.qty}</span>
            </>)
        },
        hidden: !props.label
      },
      {
        title: "Ean",
        field: "ean",
        width: "13%",
        hidden: props.productDetails.products.filter(e => e.ean != "").length === 0,
      },
      {
        title: "Cantidad",
        field: "qty",
        width: "13%",
      },
      {
        title: "Nombre",
        field: "name",
        width: "13%",
      },
      {
        title: "SKU",
        field: "sku",
        width: "13%",
        hidden: props.productDetails.products.filter(e => e.sku != "").length === 0,
      }
    ];
  }else{
    columns = [
      {
        title: "Nombre",
        field: "name",
        width: "13%",
      },
      {
        title: "Ean",
        field: "ean",
        width: "13%",
        hidden: props.productDetails.products.filter(e => e.ean != "").length === 0,
      },
      {
        title: "SKU",
        field: "sku",
        width: "13%",
        hidden: props.productDetails.products.filter(e => e.sku != "").length === 0,
      },
      {
        title: "Cantidad",
        field: "qty",
        width: "13%",
      }
    ];
  }

  const getAttributesForValidationColumn = (rowDataProduct) => {
    const attributesForValidationColumn = {
      sourceImg: pendingImg,
      stylesLabel: { fontWeight: 600, paddingLeft: 10 }
    }

    if(rowDataProduct.check){
      attributesForValidationColumn.sourceImg = checkedImg
      attributesForValidationColumn.stylesLabel = { ...attributesForValidationColumn.stylesLabel, fontWeight: 600, color: "green" }
    }
    else if(rowDataProduct.errorQtyOver){
      attributesForValidationColumn.sourceImg = notCheckedImg
      attributesForValidationColumn.stylesLabel = {  ...attributesForValidationColumn.stylesLabel, fontWeight: 600, color: "red" }
    }
    else if(rowDataProduct.errorQtyUnder){
      attributesForValidationColumn.sourceImg = pendingImg
      attributesForValidationColumn.stylesLabel = {  ...attributesForValidationColumn.stylesLabel, color: "black", fontWeight: 600 }
    }

    return attributesForValidationColumn
  }

  const getParentChildData = (row, rows) => {
    if(!props.hasToGroupByParentPromotion)
      return false

    return rows.find(a => {
      return (
        row?.tableData != null &&
        row?.tableData.id != a?.tableData.id &&
        (a.ean && a.ean === row.ean_origin) || (a.sku && a.sku === row.sku_origin)
      )
    })
  }

  return (
    <MaterialTable
      options={{
        rowStyle: rowData => {
          if(!props.hasToGroupByParentPromotion)
            return { backgroundColor: "white", fontWeight: 600 }

          const parentFound = props.productDetails?.products?.find(product => {
            return (rowData.sku && rowData.sku_origin === product.sku) || (rowData.ean && rowData.ean_origin === product.ean) 
          })

          return { backgroundColor: parentFound != null ? "#F5F0F0" : "white", fontWeight: 600  }
        },
        search: false,
        exportButton: false,
        tableLayout: "fixed",
        toolbar: false,
        maxBodyHeight: "400px",
        minBodyHeight: "400px",
        pageSize: props.hasToGroupByParentPromotion ? (props.productDetails?.products?.length || 0) :  (props.productDetails?.products?.filter(e => !e.shouldIgnoreInValidator)?.length || 0),
        headerStyle: {
          backgroundColor: "#051B2F",
          color: "#FFF",
          fontSize: "12px",
          textAlign: "center",
          fontWeight: "bold",
          overflow: "hidden"
        },
      }}
      icons={tableIcons}
      columns={columns}
      data={props.hasToGroupByParentPromotion ? (props.productDetails?.products || []) :  (props.productDetails?.products?.filter(e => !e.shouldIgnoreInValidator) || []) }
      parentChildData={(row, rows) => getParentChildData(row, rows)}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "20rem",
              }}
            >
              <img
                src={noDataImage}
                style={{ marginTop: "2em" }}
                width="160"
                alt="noData"
              />
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#051B2F",
                }}
              >
                {" "}
                &nbsp;
                <span> No hay información disponible.</span>
              </p>
            </div>
          ),
        },
        pagination: {
          labelRowsSelect: "líneas",
          labelDisplayedRows: "{from}-{to} productos de {count}",
          firstTooltip: "Ir a la primera página",
          previousTooltip: "Página anterior",
          nextTooltip: "Próxima página",
          lastTooltip: "Ir a la última página",
        },
      }}
    />
  );
};

export default BallotDetailTable;
