import React from 'react'
import classes from "./Login.module.css"

import contrato from "../../assets/img/contrato.png"
import close from "../../assets/img/close.svg";

const ForgetSuccess = ({ setView }) => {
  return (
    <div className={classes.loginWrapper}>
        <div className={classes.header}>
            <div className={classes.iconWrapper}></div>
            <h1>Recuperar contraseña</h1>
            <a href="https://www.instancelatam.com" className={classes.iconWrapper}><img src={close} className={classes.iconHeader} /></a>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.wrapperContrato}><img src={contrato} /></div>
        <p className={classes.text}>Hemos enviado
            <b> un mensaje a tu correo electrónico </b>
            con las <b>indicaciones</b> de como reestablecer
            tu contraseña.
        </p>
        <button className={classes.loginButton} onClick={() => setView("sign-in")}>
            Cerrar
        </button>
        <button className={classes.newAccount} onClick={() => setView("recovery")}
        >
            Enviar nuevamente
        </button>
    </div>
  )
}

export default ForgetSuccess