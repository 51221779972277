//Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Components
import { formatLastUpdateOfPublication, transformTranslatedStatus, toSpanishNumberFormatWithNull } from '../../../utils/utils';
import classes from './PublicationCard.module.css';
import ProgressBar from './ProgressBar';
import EmptyPublications from './EmptyPublications';
import Checkbox from '../../../components/UI/Checkbox';
import CustomTooltip from '../../../components/UI/CustomTooltip';
import PublicationStatus from './PublicationStatus';
import PublicationModalFormat from './PublicationModalFormat';
import { 
    tooltipHealthcheckContent, 
    tooltipBuyboxContent, 
    suggestionsTooltip, 
    tooltipPublicationStateContent, 
    tooltipPublicationUpdate, 
    tooltipPublicationRedirect, 
    tooltipPublicationStock,
    tooltipContentBell 
} from '../../../utils/tooltipContent';

//images
import Canasta from '../../../assets/img/canasta.png';
import Ganando from '../../../assets/img/ganando 2.png';
import Perdiendo from '../../../assets/img/Perdiendo 2.png';
import Empatando from '../../../assets/img/Empatando 2.png';
import Bell from '../../../assets/img/icons/alert-bell.svg';
import Redirect from '../../../assets/img/icons/redirect-vector.svg';
import infoFortooltipIcon from '../../../assets/img/icons/info-tooltip.svg';
import Copy from '../../../assets/img/icons/copy-vector.svg';
import SuggestionsIcon from '../../../assets/img/icons/spark-icon.svg';

const PublicationCard = ({
    product,
    checked,
    onCheckboxChange,
    deselectedIds = [],
    setDeselectedIds,
    selectAllPagesChecked = false,
    alertSByPublication = [],
    innercardWrapper,
    leftSideContainer,
    rightSideContainer,
    cardWrapper,
    cardImgWrapperContainer,
    checkedCard,
    firstcardCheckContainer,
    cardCheckContainer,
    cardImgWrapper,
    cardImg,
    cardTextWrapper,
    cardPercentageWrapper,
    percentageWrapper,
    cardInfoWrapper,
    textWrapperHeader,
    textNameWrapper,
    textName,
    textidsWrapper,
    titleInfoWrapperHealthcheck,
    titleInfoWrapperBuybox,
    titleInfo,
    titleInfoDate,
    publicationDate,
    flexWrapper,
    statusContainer,
    titleInfoWrapperInformation,
    cardInfoLogoWrapper,
    unitWrapper,
    renderCardCheckContainer,
    renderCardImgWrapper,
    renderCardTextWrapper,
    renderCardPercentageWrapper,
    renderCardInfoWrapper,
    renderTextWrapperHeader,
    renderTextNameWrapper,
    renderTextidsWrapper,
    renderTitleInfoWrapperHealthcheck,
    renderTitleInfoWrapperBuybox,
    renderFlexWrapper,
    renderStatusContainer,
    renderTitleInfoWrapperInformation,
    renderCardInfoLogoWrapper,
    renderUnitWrapper,
    setCopyResultText,
    setCopyCreationResult,
    callerComponent = 'PublicationList',
    setSearchView,
    setSelectedPublication,
    setLoadingCompetition
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isChecked, setIsChecked] = useState(checked);
    const [displayModalDetails, setDisplayModalDetails] = useState(false);
    const toggleDisplayModal = () => setDisplayModalDetails(!displayModalDetails);

    const handleToggleModal = () => {
        if(callerComponent === 'SearchPublications'){
            setSelectedPublication(product);
            setSearchView(false);
            setLoadingCompetition(true);
        }else{
            toggleDisplayModal();
        }        
    };

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (deselectedIds.includes(product.id_publication)) {
            setIsChecked(false);
        } else {
            setIsChecked(checked);
        }
    }, [checked, deselectedIds, product.id_publication]);

    const handleCheckboxChange = (isChecked) => {
        setIsChecked(isChecked);

        if (!isChecked && selectAllPagesChecked) {
            setDeselectedIds((prevIds) => [...prevIds, product.id_publication]);
        } else if (isChecked) {
            setDeselectedIds((prevIds) =>
                prevIds.filter((id) => id !== product.id_publication)
            );
        }

        onCheckboxChange(product.id_publication, product.client_id, isChecked);
    };

    const handleBellClick = (event) => {
        event.stopPropagation();
    };

    const handleRedirectClick = (event) => {        
        event.stopPropagation();        
        const url = product?.publication_url;
        if (url) {
            window.open(url, '_blank');
        }
    };

    const statusData = {
        status: product?.status,
        translatedStatus: transformTranslatedStatus(product?.translated_status),
        caller: 'publications',
    };

    const alertsForProduct = alertSByPublication?.filter(
        (alert) => alert.id_publication === product.id_publication
    );

    const handleCopyPublicationId = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(product.id_publication);
    };

    const handleCopyPublicationEan = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(product.ean);
    };

    const handleCopyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        setCopyResultText("1, 2, 3 ¡Código copiado!");
        setCopyCreationResult(true); 
    };

    const createAlert = (text) => {
        setCopyCreationResult(true)
        setCopyResultText(text)
    }

    const getCardCheckContainerClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? cardCheckContainer || classes.cardCheckContainer
            : firstcardCheckContainer || classes.firstcardCheckContainer;
    }; 
    
    const getCardTooltipContainerParentClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.cardTooltipContainerParent
            : classes.cardNoTooltipContainerParent;
    }; 

    const getCardSuggestionsContainerParentClass = () => {
        return product?.suggestion_quantity > 0
            ? classes.suggestionsTooltipContainerParent
            : classes.suggestionsNoTooltipContainerParent;
    }; 

    const getCardCheckboxClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.cardCheckbox
            : classes.cardCheckboxMiddle;
    }; 

    const getBellIconClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.bellIcon
            : classes.bellIconInvisible;
    }; 

    const hasAlertsClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? true
            : false;
    };
    
    return (
        <>
            <div
                className={`${cardWrapper || classes.cardWrapper} ${isChecked ? checkedCard || classes.checkedCard : ''}`}
                onClick={handleToggleModal}
            >
                <div className={getCardCheckContainerClass()}>
                    {renderCardCheckContainer ? (
                        renderCardCheckContainer(product)
                    ) : (
                        <div className={classes.checkAndTooltipsContainer}>
                            <div className={classes.topTooltipContainer}>
                                <div className={getCardTooltipContainerParentClass()}>
                                    <CustomTooltip tooltipContent={tooltipContentBell(alertsForProduct)} wrapperStyle={{ width: '100%', height: '100%'}} showTooltip={hasAlertsClass()}>
                                        <div className={classes.suggestionTooltipArea}>
                                            <div className={classes.cardTooltipContainer}>                                            
                                                <img
                                                    className={getBellIconClass()}
                                                    src={Bell}
                                                    alt="Alert Bell"
                                                    onClick={handleBellClick}
                                                />                                            
                                            </div>
                                        </div> 
                                    </CustomTooltip>                                   
                                </div>
                            </div>
                            <div className={getCardCheckboxClass()}>
                                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                            </div>
                            <div className={classes.bottomTooltipContainer}>
                                <div className={getCardSuggestionsContainerParentClass()}>
                                    <CustomTooltip tooltipContent={suggestionsTooltip} wrapperStyle={{ width: '100%', height: '100%'}}>
                                        <div className={classes.suggestionTooltipArea}>                                    
                                            <div className={classes.suggestionsTooltipContainer}>
                                                <img
                                                    className={classes.sparkIcon}
                                                    src={SuggestionsIcon}
                                                    alt="Suggestions"
                                                    onClick={handleBellClick}
                                                />
                                            </div>                                                                    
                                        </div>
                                    </CustomTooltip>
                                </div>                                
                            </div>
                        </div>
                    )}
                </div>
                <div className={innercardWrapper || classes.innercardWrapper}>
                    <div className={leftSideContainer || classes.leftSideContainer}>
                        <div className={cardImgWrapperContainer || classes.cardImgWrapperContainer}>
                            <div className={cardImgWrapper || classes.cardImgWrapper}>
                                {renderCardImgWrapper ? (
                                    renderCardImgWrapper(product)
                                ) : (
                                    <img
                                        width={windowWidth < 1300 ? '80%' : '100%'}
                                        className={cardImg || classes.cardImg}
                                        src={product.images ? product.images[0]?.link ?? '' : ''}
                                        alt={product.name}
                                    />
                                )}
                            </div>
                            <div className={textidsWrapper || classes.textidsWrapper}>
                                {renderTextidsWrapper ? (
                                    renderTextidsWrapper(product)
                                ) : (
                                    <>
                                        <div className={classes.textIdMLC}>
                                            <span className={classes.textId}>ID: {product.id_publication}</span>
                                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationId} />
                                        </div>
                                        <div className={classes.textIdEAN}>
                                            <span className={classes.textId}>EAN: {product.ean}</span>
                                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationEan} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={cardTextWrapper || classes.cardTextWrapper}>
                            {renderCardTextWrapper ? (
                                renderCardTextWrapper(product)
                            ) : (
                                <>
                                    <div className={textWrapperHeader || classes.textWrapperHeader}>
                                        {renderTextWrapperHeader ? (
                                            renderTextWrapperHeader(product)
                                        ) : (
                                            <>
                                                <div className={classes.textClientWrapper}>
                                                    <span className={classes.textClient}>{product.client}</span>
                                                </div>
                                                <div className={classes.textCategoryWrapper}>
                                                    <span className={classes.textTitle}>{product.category}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className={textNameWrapper || classes.textNameWrapper}>
                                        {renderTextNameWrapper ? (
                                            renderTextNameWrapper(product)
                                        ) : (
                                            <span className={textName || classes.textName}>{product.name}</span>
                                        )}
                                    </div>   
                                    { product?.sale_price ? 
                                        (
                                            <div className={classes.publicationPrice}>
                                                <div className={classes.publicationOriginalPriceContainer}>  
                                                    { product?.sale_price?.regular_price ? 
                                                        (
                                                            <span className={product?.sale_price?.price != product?.sale_price?.regular_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(product?.sale_price?.regular_price, '0')}</span>                           
                                                        ) 
                                                        : 
                                                        (
                                                            product?.original_price ? 
                                                            (
                                                                <span className={product?.sale_price?.price != product?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(product?.original_price, '0')}</span>                           
                                                            ) 
                                                            : 
                                                            (
                                                                <span className={product?.sale_price?.price != product?.price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(product?.price, '0')}</span>                           
                                                            )
                                                        ) 
                                                    }                                                              
                                                </div>
                                                <div className={classes.publicationCurrentPriceContainer}>
                                                    <span className={classes.currentPrice}>${product?.sale_price?.price ? toSpanishNumberFormatWithNull(product?.sale_price?.price, '0') : toSpanishNumberFormatWithNull(product?.price, '0')}</span>                           
                                                </div>
                                            </div>
                                        ) 
                                        : 
                                        (
                                            <div className={classes.publicationPrice}>
                                                <div className={classes.publicationOriginalPriceContainer}>   
                                                    { product?.original_price ? 
                                                        (
                                                            <span className={product?.price != product?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(product?.original_price, '0')}</span>                           
                                                        ) 
                                                        : 
                                                        (
                                                            product?.base_price ? 
                                                            (
                                                                <span className={product?.price < product?.base_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(product?.base_price, '0')}</span>                           
                                                            ) 
                                                            : 
                                                            (
                                                                <span className={classes.noBasePrice}>$</span>                           
                                                            )
                                                        )
                                                    }                                                              
                                                </div>
                                                <div className={classes.publicationCurrentPriceContainer}>
                                                    <span className={classes.currentPrice}>${toSpanishNumberFormatWithNull(product?.price, '0')}</span>                           
                                                </div>
                                            </div>
                                        )
                                    }                                  
                                </>
                            )}
                        </div>
                    </div>
                    <div className={rightSideContainer || classes.rightSideContainer}>
                        <div className={cardPercentageWrapper || classes.cardPercentageWrapper}>
                            {renderCardPercentageWrapper ? (
                                renderCardPercentageWrapper(product)
                            ) : (
                                <>
                                    {product.has_healthcheck && !product.has_buybox && (
                                        <>
                                            <CustomTooltip tooltipContent={tooltipHealthcheckContent} wrapperStyle={{ width: '100%', height: '100%'}} offset={[0, -120]}>                                                                                                                        
                                                <div className={titleInfoWrapperHealthcheck || classes.titleInfoWrapperHealthcheck}>
                                                    {renderTitleInfoWrapperHealthcheck ? (
                                                        renderTitleInfoWrapperHealthcheck(product)
                                                    ) : (
                                                        <>
                                                            <span className={titleInfo || classes.titleInfo}>Healthcheck</span>
                                                            <img
                                                                className={classes.infoIconForTootltipInfo}
                                                                alt="info"
                                                                src={infoFortooltipIcon}
                                                            />                                                      
                                                        </>
                                                    )}
                                                </div>
                                                <div className={flexWrapper || classes.flexWrapper}>
                                                    {renderFlexWrapper ? (
                                                        renderFlexWrapper(product)
                                                    ) : (
                                                        <div className={percentageWrapper || classes.percentageWrapper}>
                                                            <ProgressBar percentage={(product.healthcheck?.health || 0) * 100} strokeWidth={10} isDefaultColor={true} defaultFontSize={'24px'} defaultFontWeight={'700'} />
                                                        </div>
                                                    )}
                                                </div>
                                            </CustomTooltip>
                                        </>
                                    )}
                                    {product.has_buybox && (
                                        <>
                                            <CustomTooltip tooltipContent={tooltipBuyboxContent} wrapperStyle={{ width: '100%', height: '100%'}} offset={[0, -120]}>
                                                <div className={titleInfoWrapperBuybox || classes.titleInfoWrapperBuybox}>
                                                    {renderTitleInfoWrapperBuybox ? (
                                                        renderTitleInfoWrapperBuybox(product)
                                                    ) : (
                                                        <>
                                                            <span className={titleInfo || classes.titleInfo}>Buybox</span>
                                                            <img
                                                                className={classes.infoIconForTootltipInfo}
                                                                alt="info"
                                                                src={infoFortooltipIcon}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                <div className={flexWrapper || classes.flexWrapper}>
                                                    {renderFlexWrapper ? (
                                                        renderFlexWrapper(product)
                                                    ) : (
                                                        <div className={classes.buyboxWrapper}>
                                                            <div>
                                                                {['winning'].includes(product.buybox.status) && (
                                                                    <img className={classes.imgBuyBox} src={Ganando} alt="Winning" />
                                                                )}
                                                                {!['winning', 'sharing_first_place'].includes(product.buybox.status) && (
                                                                    <img className={classes.imgBuyBox} src={Perdiendo} alt="Losing" />
                                                                )}
                                                                {['sharing_first_place'].includes(product.buybox.status) && (
                                                                    <img className={classes.imgBuyBox} src={Empatando} alt="Tying" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </CustomTooltip>
                                        </>
                                    )}
                                    {!product.has_buybox && !product.has_healthcheck && (
                                        <>
                                            <div className={flexWrapper || classes.flexWrapper}>
                                                {renderFlexWrapper ? (
                                                    renderFlexWrapper(product)
                                                ) : (
                                                    <EmptyPublications calledFrom="publicationsList" />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className={statusContainer || classes.statusContainer}>
                                        {renderStatusContainer ? (
                                            renderStatusContainer(product)
                                        ) : (
                                            <CustomTooltip tooltipContent={tooltipPublicationStateContent}>
                                                <PublicationStatus statusData={statusData} />
                                            </CustomTooltip>                                            
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={cardInfoWrapper || classes.cardInfoWrapper}>
                            {renderCardInfoWrapper ? (
                                renderCardInfoWrapper(product)
                            ) : (
                                <>
                                    <div className={titleInfoWrapperInformation || classes.titleInfoWrapperInformation}>
                                        {renderTitleInfoWrapperInformation ? (
                                            renderTitleInfoWrapperInformation(product)
                                        ) : (
                                            <CustomTooltip tooltipContent={tooltipPublicationUpdate}>
                                                <>
                                                    <span className={titleInfoDate || classes.titleInfoDate}>Última modificación:</span>                                                    
                                                    <span className={publicationDate || classes.publicationDate}>{formatLastUpdateOfPublication(product?.last_update_channel)}</span>
                                                </>
                                            </CustomTooltip>
                                        )}
                                    </div>
                                    <div className={cardInfoLogoWrapper || classes.cardInfoLogoWrapper}>
                                        {renderCardInfoLogoWrapper ? (
                                            renderCardInfoLogoWrapper(product)
                                        ) : (
                                            <>                                            
                                                <CustomTooltip tooltipContent={tooltipPublicationRedirect} wrapperClassName={classes.cardLogoInnerWrapper} >
                                                    <div>
                                                        <img
                                                            className={classes.channelIcon}
                                                            src={product.channel_icon}
                                                            alt="Channel Icon"
                                                        />
                                                    </div>
                                                    <div className={classes.cardInfoRedirectIcon}>
                                                        <img src={Redirect} alt="Redirect Icon" onClick={handleRedirectClick} />
                                                    </div>
                                                </CustomTooltip>
                                            </>
                                        )}
                                    </div>
                                    <div className={unitWrapper || classes.unitWrapper}>
                                        {renderUnitWrapper ? (
                                            renderUnitWrapper(product)
                                        ) : (
                                            <>
                                                <CustomTooltip tooltipContent={tooltipPublicationStock} wrapperStyle={{ width: '100%', height: '100%'}}>
                                                    <div className={classes.unitWrapperIcon}>
                                                        <img className={classes.unitBasketIcon} src={Canasta} alt="Canasta" />
                                                    </div>
                                                    <div className={classes.unitWrapperText}>
                                                        <span className={classes.unitStockText}>
                                                            {toSpanishNumberFormatWithNull(product.stock) ?? 0} Unidades
                                                        </span>
                                                    </div>
                                                </CustomTooltip>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {displayModalDetails && (
                <PublicationModalFormat
                    displayModalDetails={displayModalDetails}
                    toggleDisplayModal={toggleDisplayModal}
                    publication={product}
                    createAlert={createAlert}
                    alertsForProduct={alertsForProduct}
                />
            )}
        </>
    );
};

PublicationCard.propTypes = {
    product: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    deselectedIds: PropTypes.array,
    setDeselectedIds: PropTypes.func,
    selectAllPagesChecked: PropTypes.bool,
    alertSByPublication: PropTypes.array,
    innercardWrapper: PropTypes.string,
    leftSideContainer: PropTypes.string,
    rightSideContainer: PropTypes.string,
    cardWrapper: PropTypes.string,
    checkedCard: PropTypes.string,
    cardCheckContainer: PropTypes.string,
    cardImgWrapper: PropTypes.string,
    cardImgWrapperContainer: PropTypes.string,
    cardImg: PropTypes.string,
    cardTextWrapper: PropTypes.string,
    cardPercentageWrapper: PropTypes.string,
    percentageWrapper: PropTypes.string,
    cardInfoWrapper: PropTypes.string,
    textWrapperHeader: PropTypes.string,
    textNameWrapper: PropTypes.string,
    textName: PropTypes.string,
    textidsWrapper: PropTypes.string,
    titleInfoWrapperHealthcheck: PropTypes.string,
    titleInfoWrapperBuybox: PropTypes.string,
    titleInfo: PropTypes.string,
    titleInfoDate: PropTypes.string,
    publicationDate: PropTypes.string,
    flexWrapper: PropTypes.string,
    buyboxWrapper: PropTypes.string,
    statusContainer: PropTypes.string,
    titleInfoWrapperInformation: PropTypes.string,
    cardInfoLogoWrapper: PropTypes.string,
    unitWrapper: PropTypes.string,
    firstcardCheckContainer: PropTypes.string,
    renderCardCheckContainer: PropTypes.func,
    renderCardImgWrapper: PropTypes.func,
    renderCardTextWrapper: PropTypes.func,
    renderCardPercentageWrapper: PropTypes.func,
    renderCardInfoWrapper: PropTypes.func,
    renderTextWrapperHeader: PropTypes.func,
    renderTextNameWrapper: PropTypes.func,
    renderTextidsWrapper: PropTypes.func,
    renderTitleInfoWrapperHealthcheck: PropTypes.func,
    renderTitleInfoWrapperBuybox: PropTypes.func,
    renderFlexWrapper: PropTypes.func,
    renderBuyboxWrapper: PropTypes.func,
    renderStatusContainer: PropTypes.func,
    renderTitleInfoWrapperInformation: PropTypes.func,
    renderCardInfoLogoWrapper: PropTypes.func,
    renderUnitWrapper: PropTypes.func,
};

export default PublicationCard;
