import React from 'react';
import CustomModal from '../UI/CustomModal';
import firstModalStyles from './FirstAlertModal.module.css';
import FirstAlertModalLoader from '../../views/publications/Components/FirstAlertModalLoader';
import AlertTypeCard from '../../views/publications/Components/AlertTypeCard';
import styles from '../../views/publications/Components/CreateAlertModal.module.css';
import { useMediaQuery } from '@mui/material';

const FirstAlertModal = ({
  firstModal,
  toggleFirstModal,
  checkedAlertTypes,
  loadingAlertTypes,
  handleCloseFirstModal,
  alertData,
  setCheckedAlertTypes,
  handleSecondModal
}) => {
   //Mobile configuration
   const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <CustomModal
      isOpen={firstModal}
      toggle={toggleFirstModal}
      onClose={handleCloseFirstModal}
      title="Crear alerta"
      customStyles={{ box: firstModalStyles.customModal }}
      customClassNames={{
        form: firstModalStyles.modalForm,
        header: firstModalStyles.crearAlertaParent,
        title: firstModalStyles.crearAlerta,
        closeWrapper: firstModalStyles.closeWrapper,
        closeIcon: firstModalStyles.closeIcon,
        separatorContainer: firstModalStyles.separatorContainer,
        separator: firstModalStyles.separator,
        bodyContainer: firstModalStyles.modal1CreacinDeAlertaInner,
        footerContainer: firstModalStyles.botonLoginWrapper,
        box: checkedAlertTypes.length > 0 ? styles.afterElement : styles.afterElementNone
      }}
      footerActions={
        !loadingAlertTypes && (
          <button
            className={firstModalStyles.submitButton}
            disabled={checkedAlertTypes.length < 1}
            onClick={handleSecondModal}
          >
            <b className={firstModalStyles.siguiente}>Siguiente</b>
          </button>
        )
      }
    >
      {loadingAlertTypes ? (
        <FirstAlertModalLoader />
      ) : (
        <section className={firstModalStyles.modal1CreacinDeAlertaInner}>
          <div className={firstModalStyles.frameParent}>
            <div className={firstModalStyles.seleccionaAlMenosUnTipoDeWrapper}>
              <div style={{paddingTop:isMobile ? "55px": ""}} className={firstModalStyles.seleccionaAlMenos}>
                Selecciona al menos un tipo de alerta
              </div>
            </div>
            <div style={{paddingTop:isMobile ? "55px": ""}} className={firstModalStyles.frameGroup}>
              {alertData && alertData.map((alert, index) => (
                <AlertTypeCard
                  key={index}
                  alertType={alert}
                  isChecked={false}
                  checkedAlertTypes={checkedAlertTypes}
                  setCheckedAlertTypes={setCheckedAlertTypes}
                />
              ))}
            </div>
          </div>
        </section>
      )}
    </CustomModal>
  );
};

export default FirstAlertModal;
