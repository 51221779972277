import { useState } from 'react'
import React from 'react'
import classes from './WelcomePromotions.module.css'

import addIcon from '../../assets/img/add.png'
import discountIcon from '../../assets/img/discount.png'
import pencilIcon from '../../assets/img/pencil.png'
import NewButton from './NewButton'
import { Link, useRouteMatch } from 'react-router-dom'


const WelcomePromotions = () => {

    const [showMenu, setShowMenu] = useState(false)
    const { url } = useRouteMatch()

    const handleShowMenu = () => setShowMenu(prevState => !prevState)

    return (
        <div className={classes.wrapper}>
            <div className={classes.card} style={{ cursor: 'default'}}>
                <div className={classes.iconWrapper}>
                    <img src={discountIcon} className={classes.icon}/>
                </div>
                <h3 className={classes.title}>Promociones activas</h3>
            </div>
            <div className={classes.card} style={{ cursor: 'default'}}>
                <div className={classes.iconWrapper}>
                    <img src={pencilIcon} className={classes.icon}/>
                </div>
                <h3 className={classes.title}>Editar promociones</h3>
            </div>
            <div className={classes.card} onClick={handleShowMenu} style={{ borderRadius: showMenu ? '20px 20px 0 0' : '20px'}}>
                <div className={classes.iconWrapper}>
                    <img src={addIcon} className={classes.icon}/>
                </div>
                <h3 className={classes.title}>Crear nuevas promociones</h3>
                {showMenu && 
                    <div className={classes.menu}>
                        <Link to={`${url}/mxn`}>
                            <NewButton>CREAR MXN (3x2)</NewButton>
                        </Link>
                        <Link  to={`${url}/pack`}>
                            <NewButton>CREAR PACK</NewButton>
                        </Link>
                        <Link  to={`${url}/kit`}>
                            <NewButton>CREAR KIT</NewButton>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default WelcomePromotions