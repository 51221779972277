import ProfileTableHandler from 'components/ProfileTableHandler/profile-table-handler';
import React from 'react';
import { Container } from 'reactstrap';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorHandler from 'components/ErrorHandler/error-handler';


const CustomerMaintainer = () => {

  const getProfileType = () => {
    switch (localStorage.getItem("ut")) {
      case "1":
        return "Administrador"
      case "2":
        return "Kam"
      case "3":
        return "Cliente"
      case "4":
        return "Externo"
      case "5":
        return "Operador"
      default:
        return ""
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
    <div>

          <br/>
          <br/>
          <br/>
            <h5
            className="titleTable"
            style={{
              color: "#C4C4C4",
              width: "450px",
              fontSize: "10px",
              fontWeight: "800",
              marginLeft: "4em",
              marginBottom: "0px",
            }}
          >
            Administrar perfiles: Vista {getProfileType()}
          </h5>
          <p
            id="textNameTable"
            style={{
              color: "black",
              width: "450px",
              fontSize: "30px",
              fontWeight: "800",
              marginLeft: "2em",
              marginTop:"1em"
            }}
          >
            Administración de usuarios
          </p>

          <Container>

        
      <ProfileTableHandler/>

          </Container>




    </div>
    </ErrorBoundary>
  )
}

export default CustomerMaintainer;