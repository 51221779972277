import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import classes from './Pagination.module.css'

const Pagination = ({ props, total, incrementPageHandler, isLoading, setPage, setRowsHandle, name, toUp }) => {
    const { page, rowsPerPage, onChangePage, rowsPerPageOptions, onChangeRowsPerPage, count } = props;
    const { NextPage, PreviousPage } = props.icons;
    const [rows, setRows] = useState(rowsPerPage);
    let totalPages = (Math.round(total / rowsPerPage)) === 0 ? 1 : (Math.round(total / rowsPerPage));

    const handleChange = (event) => {
        setRows(event.target.value);
        setRowsHandle(event.target.value);
        onChangeRowsPerPage(event, rows);
        window.scrollTo(0, 0);
    };

    const handleMoreOrders = async () => {
        if ((rowsPerPage * (page + 1)) >= count) {
            await incrementPageHandler()
        }
    }

    return (
        <div className={classes.pagination}>
            <div className={classes.selector}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ fontSize: "0.8rem" }}>
                    <Select
                        value={rows}
                        label="rows"
                        onChange={handleChange}
                        style={{ boxShadow: "none" }}
                    >
                        {rowsPerPageOptions.map((option) => (
                            <MenuItem key={option} value={option} style={{ fontSize: "0.8rem" }}> {option} {name} </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <PreviousPage className={classes.pageSelector} onClick={
                (e) => {
                    if (page !== 0){
                        setPage(page - 1)
                        onChangePage(e, page - 1)
                        toUp()
                    } 
                }
            } />
            <span className={classes.text}> Pagina {page + 1} de {totalPages}</span>
            <NextPage className={classes.pageSelector} onClick={
                (e) => {
                    if (!isLoading) handleMoreOrders();
                    setPage(page + 1)
                    onChangePage(e, page + 1)
                    toUp()
                }
            } />
        </div>
    );
}

export default Pagination