import React, { useEffect, useState } from 'react'
import {
    Box, Typography, useMediaQuery
} from '@mui/material'

import Close from "../../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardTask from './CardTask';
import { channelImports } from "utils/channelsImagesImport";
import ModalNewChannel from '../../AdminSalesChannels/ModalNewChannel';
import { Modal } from 'reactstrap'

const WelcomeStep = ({ displayBienvenidaModal, toggleBienvenidaModal, clientDetails, editOnboardingSteps }) => {

    const [welcomeChecks, setWelcomeChecks] = useState(
        {
            contrat: clientDetails.onboarding_steps[0].tasks?.contrat,
            email: clientDetails.onboarding_steps[0].tasks?.email,
            channel: clientDetails.onboarding_steps[0].tasks?.channel
        }
    )
    const isMobile = useMediaQuery('(max-width:600px)');
    const [channelsCirclesImg, setChannelsCirclesImg] = useState(null)
    const [displayNewChannel, setDisplayNewChannel] = useState(null)
    const toggleNewChannel = () => setDisplayNewChannel(!displayNewChannel);

    useEffect(async () => {
        const importCircles = await channelImports()
        setChannelsCirclesImg(importCircles)
    }, [])

    const handleCheck = (e) => {
        const newCheck = { ...welcomeChecks, [e.target.name]: !welcomeChecks[e.target.name] }
        setWelcomeChecks(newCheck);
        editOnboardingSteps(clientDetails.onboarding_steps[0].id, newCheck)
    }

    const handleCompleteChannels = () => {
        toggleNewChannel()
    }

    return (
        <>
            <Modal
                isOpen={displayBienvenidaModal}
                toggle={toggleBienvenidaModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                keyboard={false}
                backdrop="static"
            >
                <Box
                    id="modal-content"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: isMobile ? '100%' : '120%',
                        maxWidth: '570px',
                        bgcolor: 'white',
                        boxShadow: 24,
                        pl: 6,
                        pr: 6,
                        pb: 10,
                        pt: 2,
                        zIndex: 1,
                        borderRadius: 4,


                    }}
                >

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: "30px" }}>
                        <div></div>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Typography style={{ color: "#D3D3D3", fontSize: isMobile ? '17px' : '25px', fontFamily: 'Montserrat' }} ><b>Etapa</b></Typography>
                            <Typography fontFamily='Montserrat' variant={isMobile ? "h5" : "h4"} ><b>Bienvenida</b></Typography>
                        </Box>
                        <Box sx={{ mr: -4 }}>
                            <Tooltip title="Salir" arrow>
                                <IconButton onClick={toggleBienvenidaModal} sx={{

                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}>
                                    <img src={Close} width={'80%'} alt="cerrar" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': { mt: 2, mb: 2 },
                            justifyContent: 'center',
                        }}
                    >
                        {welcomeChecks.contrat !== undefined &&
                            <CardTask
                                name={"contrat"}
                                text={"Carga de contrato"}
                                canCheck={false} //este prop se usa para saber si el check se puede marcar y desmarcar
                                checkValue={welcomeChecks.contrat}
                                buttonComplete={false}
                                handleCheck={handleCheck}
                            />
                        }

                        {welcomeChecks.channel !== undefined &&
                            <CardTask
                                name={"channel"}
                                text={"Definición de canales a aperturar"}
                                canCheck={false} //este prop se usa para saber si el check se puede marcar y desmarcar
                                checkValue={welcomeChecks.channel}
                                buttonComplete={true}
                                actionComplete={handleCompleteChannels}
                                handleCheck={handleCheck}
                            />
                        }


                        {welcomeChecks.email !== undefined &&
                            <CardTask
                                name={"email"}
                                text={"Envio de correo de bienvenida"}
                                canCheck={false} //este prop se usa para saber si el check se puede marcar y desmarcar
                                checkValue={welcomeChecks.email}
                                buttonComplete={false}
                                handleCheck={handleCheck}
                            />
                        }


                    </Box>

                </Box>

            </Modal>
            {
                displayNewChannel &&
                <ModalNewChannel
                    displayNewChannel={displayNewChannel}
                    toggleNewChannel={toggleNewChannel}
                    channelsCirclesImg={channelsCirclesImg}
                    storeData={clientDetails}
                    handleCheckOnboarding={handleCheck}
                />
            }
        </>

    )
}

export default WelcomeStep

