import ProfileHeader from 'components/UI/ProfileHeader'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, ModalHeader } from 'reactstrap'

import DownloadCatalogModal from 'components/AdminCatalog/DownloadCatalogModal';
import GlobalError from './GlobalError'

import { getStores } from 'services/stores'
import { getDocument, getMaster, postFile } from 'services/catalog'

import websocketHelper from "../../utils/websockets"

import spinnerGif from "../../assets/img/spinnerLogos.gif"
import iconDownload from "../../assets/img/icons/iconDownload.svg"
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertSeller from 'components/UI/AlertSeller';
import CloseIcon from '@mui/icons-material/Close';

import classes from './UploadCatalog.module.css'


const UploadCatalog = () => {

    const [showModal, setShowModal] = useState(false)
    const [success, setSucces] = useState(false)
    const [selectedStore, setSelectedStore] = useState(undefined)
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [template, setTemplate] = useState(undefined)
    const [historyStore, setHistoryStore] = useState([])
    const [file, setFile] = useState(undefined)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [globalError, setGlobalError] = useState(undefined)
    const [error, setError] = useState(undefined)
    const sectionRef = useRef(null)
    const [loadingDownloadCatalog, setLoadingCatalog] = React.useState(false);
    const [showModalDownloadCatalog, setShowModalDownloadCatalog] = useState(false)
    const [refreshCatalogDownload, setRefreshCatalogDownload] = useState(false)
    const [showAlert, setshowAlert] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertType, setAlertType] = useState("")
    
    const email = localStorage.getItem("name")


    /* websocket */
    const socket = useRef(null)

    const onSocketMessage = useCallback((data) => {

        if(data){
            if(data.messageType === "reportIsReady"){
                setRefreshCatalogDownload(true)
                setshowAlert(true)
            }
        }
    }, [])

    const createAlert = (text, type) => {
        setAlertText(text)
        setAlertType(type)
        setshowAlert(true)
    }

    const connectWebsocket = useCallback(() => {

        websocketHelper.connect()
            .then(websocketInstance => {
                socket.current = websocketInstance

                socket.current?.onMessage?.addListener(onSocketMessage);

            })
            .catch(e => {
                console.error(e)
            })
    }, [])

    const handleToggleModalCatalogDownload = () => {
        setShowModalDownloadCatalog( prevState => !prevState)
    }

    const handleModal = () => setShowModal(prevState => !prevState)
    const handleButtonCatalogo = async () => {
        handleToggleModalCatalogDownload()
    }

    useEffect(() => {
        myStores()
        getTemplate()

        connectWebsocket()

        return () => {
            websocketHelper.disconnect(socket?.current)
            socket.current?.onMessage?.removeListener(onSocketMessage);
        }
    }, [])

    const myStores = async () => {
        const response = await getStores(email)
        setStores(response.message)
    }

    const getTemplate = async () => {
        const response = await getMaster("products_master_upload")
        setTemplate(response.data[0]?.url)
    }

    const sortByAtt = (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
            return 1;
        return 0;
    }

    const handleStore = async (event) => {
        setLoading(true)
        const id = event.target.value
        setSelectedStore(event.target.value)
        const response = await getDocument(id)
        setHistoryStore(response.data)
        setLoading(false)
    }

    const updatedDocuments = async () => {
        setLoading(true)
        const response = await getDocument(selectedStore)
        setHistoryStore(response.data)
        setLoading(false)
    }

    const handleFile = (event) => {
        setFile(event.target.files[0])
    }

    const handleUpload = async (e) => {
        setUploadLoading(true)
        setGlobalError(undefined)
        let formData = new FormData()

        formData.append('file', file)
        formData.append('store_id', selectedStore)

        try {
            const response = await postFile(formData)

            if (response instanceof Error) throw response

            if (response?.data) {
                setSucces(true)
            }
            setUploadLoading(false)
            updatedDocuments()
        } catch (error) {
            setGlobalError(error.message)
            setUploadLoading(false)
        }

    }

    const nameOfStore = (idStore) => {
        let array = stores.filter((s) => s.id === idStore)
        return array[0].name
    }

    const closedModal = () => {
        setSucces(false)
        setGlobalError(undefined)
    }

    useEffect(() => {
        if (showAlert) {
          const timeoutId = setTimeout(() => {
            setshowAlert(false);
          }, 5000);
    
          return () => clearTimeout(timeoutId);
        }
      }, [showAlert]);

    return (
        <>
            {showAlert ?
                <Alert
                icon={false}
                sx={{
                    zIndex: 10000,
                    fontFamily: 'Montserrat',
                    mt: "-100px",
                    ml: {
                        xs: "-10%",
                        xl: "-5%"
                    },
                    borderRadius: "10px !important",
                    color: "#22BB3D",
                    backgroundColor: "#E9F9EC",
                    position: 'absolute',
                    minHeight: "65px",
                    paddingTop: "10px",
                    minWidth: "540px",
                    fontSize: "18px"
                }}
                action={
                    <CloseIcon
                    sx={{
                        fontSize: "27px",
                        color: "#24BB3E",
                        cursor: "pointer",
                        marginTop: "4px"
                    }}
                    onClick={() => {
                        setshowAlert(false);
                    }} />
                }
                >
                <b>Se ha terminado de generar uno a más catálogos</b>
                <div
                    style={{
                        clipPath: 'polygon(17px 0px, 100% 0px, 98% 100%, 30% 100%, 50% 80%, 90% 100%, 23px 100%)',
                        position: 'absolute',
                        bottom: '0px',
                        left: -17,
                        right: 0,
                        height: '12%',
                        backgroundColor: '#24BB3E',
                    }}
                />
                </Alert>
            :
                null
            }
            
            <div className={classes.wrapper}>
                <ProfileHeader>Cargar catálogo</ProfileHeader>
                <p className={classes.text}>Genera un archivo con los parámetros de tus productos y sube a la plataforma actualizaciones masivas. Selecciona tu tienda, descarga el formato personalizado y  conoce cuáles son los campos obligatorios y el formato correcto de tus archivos antes de subir el archivo y realizar tu carga.</p>
                <p className={classes.text}>Para comenzar, selecciona la tienda a la que le quieres cargar un nuevo catálogo:</p>
                <div className={classes.inputLabel}>
                    <label className={classes.label}>
                        Tienda
                    </label>
                    <select
                        name="store"
                        onChange={(choice) => handleStore(choice)}
                        className={classes.select}
                    >
                        <option value="">Seleccionar tienda</option>
                        {stores.length > 0 &&
                            stores.sort(sortByAtt).map((store, i) => (
                                <option key={i} value={store.id}>{store.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {template !== "" && (
                <>
                    <div className={classes.buttonsWrapper}>
                        <a href={template} className={classes.buttonGreen} target="_blank" >
                            <span className={classes.buttonText}>Descargar formato plantilla</span>
                        </a>
                        <button disabled={!(!loading && selectedStore)} className={classes.button} onClick={handleModal}>
                            <span className={classes.buttonText}>Subir catálogo</span>
                        </button>

                        <button className={classes.button} onClick={handleButtonCatalogo} style={{ position: 'relative' }}>
                            {loadingDownloadCatalog && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            <span className={classes.buttonText}>Descargar catalogo</span>
                        </button>
                     



                    </div>
                    <div className={classes.tableHeader}>
                        <span className={classes.date}>Fecha</span>
                        <span className={classes.storeName}>Tienda</span>
                        <span className={classes.storeName}>Status</span>
                        <span className={classes.storeName}>Acciones</span>
                    </div>
                </>
            )}
            {historyStore.length > 0 && !loading &&
                historyStore.map((upload, i) => (
                    <div className={classes.tableRow} key={i}>
                        <span className={classes.date}>{upload.updated_at.substring(0, 10)}</span>
                        <span className={classes.storeName}>{nameOfStore(upload.store_id)}</span>
                        <div className={classes.status}>
                            <span>Completado</span>
                        </div>
                        <a href={upload.url} target='_blank' className={classes.download}>
                            <img src={iconDownload} className={classes.img} />
                        </a>
                    </div>
                ))
            }
            {loading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div>}
            {showModal && (
                <Modal isOpen={showModal} toggle={handleModal} onExit={closedModal} size="lg">
                    <ModalHeader>
                        <div className={classes.modalHeader}>
                            <button className={classes.modalExitButton}
                                onClick={() => {
                                    handleModal()
                                    setSucces(false)
                                    setGlobalError(undefined)
                                }}>
                                x
                            </button>
                        </div>
                    </ModalHeader>
                    {uploadLoading && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div>}
                    {!uploadLoading &&
                        (<div className={classes.wrapperModal}>
                            {!success &&
                                <>
                                    {globalError && <GlobalError message={globalError} />}
                                    <h3 className={classes.titleModal}>Subir catálogo</h3>
                                    <p className={classes.textModal}>Recuerda que las columnas EAN y SKU son requerimiento obligatorio en el archivo de carga masiva.</p>
                                    <div className={classes.formFile}>
                                        <label className={classes.label}>Archivo carga masiva</label>
                                        <input
                                            type="file"
                                            name="file"
                                            onChange={(e) => handleFile(e)}
                                            className={classes.inputFile}
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        />
                                    </div>
                                </>
                            }
                            {success &&
                                <h3 className={classes.successText}>Catálogo cargado exitosamente.</h3>
                            }
                            {success ?
                                <button
                                    className={classes.button}
                                    onClick={() => {
                                        handleModal()
                                        setSucces(false)
                                    }}>
                                    <span className={classes.buttonText}>Volver a página de catálogo</span>
                                </button>
                                :
                                <div className={classes.buttonsModal}>
                                    <button
                                        type="submit"
                                        className={classes.buttonModal}
                                        onClick={(e) => handleUpload(e)}>
                                        <span className={classes.buttonModalText}>Subir catálogo</span>
                                    </button>
                                    <button
                                        className={classes.buttonRed}
                                        onClick={handleModal}
                                    >
                                        <span className={classes.buttonRedText}>Cancelar</span>
                                    </button>
                                </div>
                            }
                        </div>)
                    }
                </Modal>
            )}
            <DownloadCatalogModal
                showModal={showModalDownloadCatalog}
                handleToggle={handleToggleModalCatalogDownload}
                stores={stores}
                refreshCatalogDownload={refreshCatalogDownload}
                setRefreshCatalogDownload={setRefreshCatalogDownload}
            />
        </>
    )
}

export default UploadCatalog