import React from 'react'
import Modal from 'reactstrap/es/Modal'
import { Box, Typography, Button } from '@mui/material'
const configFile = require('../../../../../config/url');

const MeliModal = ({ displayModalMeli, toggleMeliModal, channel, storeData }) => {

    const handleButtonIntegration = ( ) => {
        localStorage.setItem("storeIntegration", storeData.id);
        localStorage.setItem("countryIntegration", storeData.paises?.length ? storeData.paises[0] : null);
        window.location.href = `https://auth.mercadolibre.cl/authorization?response_type=code&client_id=${configFile.config.clientIdMeli}&redirect_uri=${window.location.origin}/admin/clientProfile`;
    }

    const handleClose = () => {
        toggleMeliModal()
        window.location.href = window.location.origin + '/admin/clientProfile?storeId=' + storeData.id;
       
    }

    return (
        <Modal
            isOpen={displayModalMeli}
            toggle={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',

                    transform: 'translate(-50%)',
                    width: { xs: "400px", md: "570px"},

                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 6,
                    zIndex: 1,
                    borderRadius: 4,


                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                   
                    <img style={{ height: 250, width: 250, objectFit: 'contain' }} src={channel.config_mtd.logo} />
                    <Typography variant="h4" ><b>Conectar con...</b></Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>Para una integracion exitosa, <br />debes iniciar sesión en Mercado libre</Typography>
                    <Button onClick={handleButtonIntegration} variant="contained" sx={{ fontSize: '24px', padding: '10px 100px', borderRadius: "14px", mt:"50px"}}><b>Integrar cuenta</b></Button>
                </Box>

            </Box>
        </Modal>
    )
}

export default MeliModal