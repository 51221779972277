//Libraries
import React, { useState, useEffect } from 'react';

//Components
import { transformTranslatedStatus, toSpanishNumberFormatWithNull, formatConversionRate, translateLogisticType } from '../../../utils/utils';
import { tooltipHealthcheckContent, tooltipBuyboxContent } from '../../../utils/tooltipContent';
import classes from './PublicationInformation.module.css';
import PublicationStatus from '../../publications/Components/PublicationStatus';
import CustomTooltip from '../../../components/UI/CustomTooltip';
import ProgressBar from '../../publications/Components/ProgressBar';
import EmptyPublications from '../../publications/Components/EmptyPublications';

//Images
import Canasta from '../../../assets/img/canasta.png';
import Location from '../../../assets/img/icons/location.png';
import Redirect from '../../../assets/img/icons/redirect-vector.svg';
import Copy from '../../../assets/img/icons/copy-vector.svg';
import infoFortooltipIcon from '../../../assets/img/icons/info-tooltip.svg';
import Ganando from '../../../assets/img/ganando 2.png';
import Perdiendo from '../../../assets/img/Perdiendo 2.png';
import Empatando from '../../../assets/img/Empatando 2.png';

const PublicationInformation = ({ selectedPublication, setCopyResultText, setCopyCreationResult }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const handleRedirectClick = (event) => {
        event.stopPropagation();
        const url = selectedPublication?.publication_url;
        if (url) {
            window.open(url, '_blank');
        }
    };    

    const statusData = {
        status: selectedPublication?.status,
        translatedStatus: transformTranslatedStatus(selectedPublication?.translated_status),
        caller: 'publications',
    };

    const handleCopyPublicationId = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(selectedPublication.sku);
    };

    const handleCopyPublicationEan = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(selectedPublication.ean);
    };

    const handleCopyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        setCopyResultText("1, 2, 3 ¡Código copiado!");
        setCopyCreationResult(true); 
    };

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
                <div className={classes.channelIconContainer}>
                    <img className={classes.channelIcon} src={selectedPublication.channel_icon} />
                    <img className={classes.cardInfoRedirectIcon} src={Redirect} alt="Redirect Icon" onClick={handleRedirectClick} />
                </div>  
                <div className={classes.locationContainer}>
                    <img className={classes.locationIcon} src={Location} alt="Delivery type" />  
                    <span className={classes.locationText}>{selectedPublication?.logistica ? translateLogisticType(selectedPublication?.logistica) : 'Sin info'}</span>                  
                </div>   
                <div className={classes.stockContainer}>
                    <img className={classes.basketIcon} src={Canasta} alt="Basket Icon" />  
                    <span className={classes.stockText}>{selectedPublication?.stock ? toSpanishNumberFormatWithNull(selectedPublication?.stock) : 0}</span>                  
                </div>                        
            </div>  
            <div className={classes.publicationContainer}>
                <div className={classes.publicationLeftContainer}>
                    <div className={classes.publicationImageContainer}>
                        <img className={classes.publicationImage} width={windowWidth < 1300 ? '80%' : '100%'} src={selectedPublication?.images[0]?.link} alt="Publication image" /> 
                    </div>
                    <div className={classes.publicationStatus}>
                        <PublicationStatus statusData={statusData} />
                    </div>
                </div>
                <div className={classes.publicationRightContainer}>
                    <div className={classes.publicationIds}>
                        <div className={classes.textIdSKU}>
                            <span className={classes.textId}>SKU: {selectedPublication.sku}</span>
                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationId} />
                        </div>
                        <div className={classes.textIdEAN}>
                            <span className={classes.textId}>EAN: {selectedPublication.ean}</span>
                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationEan} />
                        </div>
                    </div>
                    <div className={classes.publicationNames}>
                        <div className={classes.textClientWrapper}>
                            <span className={classes.textClient}>{selectedPublication.client}</span>
                        </div>
                        <div className={classes.textCategoryWrapper}>
                            <span className={classes.textCategory}>{selectedPublication.category}</span>
                        </div>
                        <div className={classes.textNameWrapper}>
                            <span className={classes.textTitle}>{selectedPublication.name}</span>
                        </div>
                    </div>
                    { selectedPublication?.sale_price ? 
                        (
                            <div className={classes.publicationPrice}>
                                <div className={classes.publicationOriginalPriceContainer}>  
                                    { selectedPublication?.sale_price?.regular_price ? 
                                        (
                                            <span className={selectedPublication?.sale_price?.price != selectedPublication?.sale_price?.regular_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(selectedPublication?.sale_price?.regular_price)}</span>                           
                                        ) 
                                        : 
                                        (
                                            selectedPublication?.original_price ? 
                                            (
                                                <span className={selectedPublication?.sale_price?.price != selectedPublication?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(selectedPublication?.original_price)}</span>                           
                                            ) 
                                            : 
                                            (
                                                <span className={selectedPublication?.sale_price?.price != selectedPublication?.price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(selectedPublication?.price)}</span>                           
                                            )
                                        ) 
                                    }                                                              
                                </div>
                                <div className={classes.publicationCurrentPriceContainer}>
                                    <span className={classes.currentPrice}>${selectedPublication?.sale_price?.price ? toSpanishNumberFormatWithNull(selectedPublication?.sale_price?.price) : toSpanishNumberFormatWithNull(selectedPublication?.price)}</span>                           
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            <div className={classes.publicationPrice}>
                                <div className={classes.publicationOriginalPriceContainer}>   
                                    { selectedPublication?.original_price ? 
                                        (
                                            <span className={selectedPublication?.price != selectedPublication?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(selectedPublication?.original_price)}</span>                           
                                        ) 
                                        : 
                                        (
                                            selectedPublication?.base_price ? 
                                            (
                                                <span className={selectedPublication?.price < selectedPublication?.base_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormatWithNull(selectedPublication?.base_price)}</span>                           
                                            ) 
                                            : 
                                            (
                                                <span className={classes.noBasePrice}>$</span>                           
                                            )
                                        )
                                    }                                                              
                                </div>
                                <div className={classes.publicationCurrentPriceContainer}>
                                    <span className={classes.currentPrice}>${toSpanishNumberFormatWithNull(selectedPublication?.price)}</span>                           
                                </div>
                            </div>
                        )
                    }                    
                </div>
            </div>  
            <div className={classes.dataContainer}>
                <div className={classes.dataLeftContainer}>
                    <div className={classes.healthBuyboxContainer}>
                        {selectedPublication?.has_healthcheck && !selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Healthcheck</span>
                                        <CustomTooltip tooltipContent={tooltipHealthcheckContent}>
                                            <img
                                                className={classes.infoIconForTootltipInfo}
                                                alt="info"
                                                src={infoFortooltipIcon}
                                            />
                                        </CustomTooltip>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.healthcheck}>
                                            <ProgressBar percentage={(selectedPublication.healthcheck?.health || 0) * 100} strokeWidth={10} isDefaultColor={true} defaultFontSize={'24px'} defaultFontWeight={'700'} />
                                        </div>                                        
                                    </div>
                                </>
                            )
                        }
                        {selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Buybox</span>
                                        <CustomTooltip tooltipContent={tooltipBuyboxContent}>
                                            <img
                                                className={classes.infoIconForTootltipInfo}
                                                alt="info"
                                                src={infoFortooltipIcon}
                                            />
                                        </CustomTooltip>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.buybox}>
                                            {['winning'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Ganando} alt="Winning" />
                                            )}
                                            {!['winning', 'sharing_first_place'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Perdiendo} alt="Losing" />
                                            )}
                                            {['sharing_first_place'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Empatando} alt="Tying" />
                                            )}
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                        {!selectedPublication?.has_healthcheck && !selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Healthcheck y Buybox</span>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.noBuyboxHealthcheck}>
                                            <EmptyPublications calledFrom="publicationsList" />
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                    </div> 
                    <div className={classes.wordsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Palabras descripción</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormatWithNull(selectedPublication?.statistics?.long_description_word_count)}</span>  
                        </div>
                    </div> 
                    <div className={classes.reviewsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Reviews totales</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.reviews_quantity  >= 0 ? toSpanishNumberFormatWithNull(selectedPublication?.reviews_quantity) : 'Sin información'}</span>  
                        </div>
                    </div> 
                    <div className={classes.clicksContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Visitas últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.statistics?.last_7_days_visits ? toSpanishNumberFormatWithNull(selectedPublication?.statistics?.last_7_days_visits) : 'Sin información'}</span>  
                        </div>
                    </div> 
                </div> 
                <div className={classes.dataRightContainer}>
                    <div className={classes.salesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Ventas últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>${toSpanishNumberFormatWithNull(selectedPublication?.statistics?.total_sales_in_money_last_7_days)}</span>  
                        </div>
                    </div> 
                    <div className={classes.lastSalesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Pedidos últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormatWithNull(selectedPublication?.statistics?.total_sales_last_7_days)}</span>  
                        </div>
                    </div> 
                    <div className={classes.imagesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Imágenes</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormatWithNull(selectedPublication?.images?.length)}</span>  
                        </div>
                    </div> 
                    <div className={classes.comentsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Comentarios totales</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.statistics?.comments_quantity >= 0 ? toSpanishNumberFormatWithNull(selectedPublication?.statistics?.comments_quantity) : 'Sin información'}</span>  
                        </div>
                    </div> 
                    <div className={classes.conversionContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Conversión últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>
                                {formatConversionRate(selectedPublication?.statistics?.last_7_days_conversion)}
                            </span>
                        </div>
                    </div> 
                </div> 
            </div>         
        </div>       
    );
}    

export default PublicationInformation;
