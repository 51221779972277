//Libraries
import React, { useRef, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

//MUI Libraries
import { Skeleton } from '@mui/material';

//Components
import { suggestionsTooltip } from '../../../utils/tooltipContent';
import classes from './SearchPublications.module.css';
import SearchPublicationCardLoader from './SearchPublicationCardLoader'
import PublicationCard from '../../publications/Components/PublicationCard';
import PublicationComparison from './PublicationComparison';
import CustomTooltip from '../../../components/UI/CustomTooltip';
import PublicationComparisonLoader from './PublicationComparisonLoader';

// Services
import { getPublications } from 'services/publications';

// Images
import IconSearch from '../../../assets/img/icons/search.png';
import EmptyPublicationsIcon from '../../../assets/icons/EmptyPublications.svg';
import SuggestionsIcon from '../../../assets/img/icons/spark-icon.svg';
import Ballons from '../../../assets/img/ballons-image.png';


const SearchPublications = ({ searchView, setSearchView, setCopyResultText, setCopyCreationResult, selectedPublication, setSelectedPublication, loadingCompetition, setLoadingCompetition, setRivalId, setWantDelete, setSelectedRival }) => {
    const [searchInput, setSearchInput] = useState("");
    const [urlSearch, setUrlSearch] = useState(false);
    const [isLoadingPublications, setIsLoadingPublications] = useState(false);
    const [isLoadingPublicationsWithSuggestions, setIsLoadingPublicationsWithSuggestions] = useState(true);
    const [publications, setPublications] = useState([]);
    const [publicationsWithSuggestions, setPublicationsWithSuggestions] = useState([]);
    const [displaySuggestions, setDisplaySuggestions] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const requestIdRef = useRef(0);
    const searchInputRef = useRef("");
    const numberOfPublications = 10;

    useEffect(() => {

        setUrlSearch(false);
        const pathParts = window.location.pathname.split('/');
        const pathId = pathParts.length > 3 ? pathParts[3] : null; // structure "/admin/competitiveness/{MLC}"
        const publicationId = pathId;
    
        if (publicationId && !selectedPublication) { 
            setUrlSearch(true);           
            handlePublicationByUrl(publicationId);                        
        }
    }, []);

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {        
        if (isLoadingPublicationsWithSuggestions) {           
            handleGetPublicationsWithSuggestions();
        }
    }, [isLoadingPublicationsWithSuggestions]);

    const handlePublicationByUrl = async (query) => {
        try {           
            const response = await getPublications(`?publications_to_include=${query}`);

            if (response?.data?.publications[0]) {
                setSelectedPublication(response?.data?.publications[0]);  
                setLoadingCompetition(true); 
                setSearchView(false); 
                setUrlSearch(false);                                            
            }
        } catch (error) {
            setSearchView(true);
            console.error("Error fetching publications:", error);
        }
    };

    const debouncedSearch = useCallback(
        _.debounce((query) => {
            const newRequestId = Date.now();
            requestIdRef.current = newRequestId;
            searchHandle(query, newRequestId);
        }, 1000),
        []
    );

    const handleSearchInput = (event) => {
        const newValue = event.target.value;
        setSearchInput(newValue);
        searchInputRef.current = newValue;

        if (newValue.length >= 3) {
            setDisplaySuggestions(false);
            setIsLoadingPublications(true);
            debouncedSearch(newValue);
        } else {
            setIsLoadingPublications(false);
            setDisplaySuggestions(true);
            setPublications([]);
        }
    };

    const searchHandle = async (query, id) => {
        let newQuery = `?general_search_fields=product_name,brand_name,ean,sku,category_name,id_publication&general_search_value=${query}&has_ws_competition=true`;
        newQuery = newQuery.concat(`&page_size=${numberOfPublications}&page=1&sort_by=suggestion_quantity&sort_direction=desc`);

        try {
            const response = await getPublications(newQuery);
            // Check if the response corresponds to the latest request
            if (id === requestIdRef.current && searchInputRef.current.length >= 3) {
                setIsLoadingPublications(false);
                setPublications(response?.data?.publications || []);
            }
        } catch (error) {
            console.error("Error fetching publications:", error);
            setIsLoadingPublications(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const renderCardCheckContainer = (product) => {
        if(product?.suggestion_quantity > 0){
            return <div className={classes.cardCheckContainer}>
                        <div className={classes.sparkContainer}>
                            <CustomTooltip tooltipContent={suggestionsTooltip}>
                                <img
                                    className={classes.sparkIcon}
                                    alt="sugerencias"
                                    src={SuggestionsIcon}
                                />
                            </CustomTooltip>
                        </div>
                    </div>;
        }else{
            return <div className={classes.cardCheckContainer}></div>;
        }        
    };

    const renderCardImgWrapper = (product) => {
        return <img
            width={windowWidth < 1300 ? '80%' : '100%'}
            className={classes.cardImg}
            src={product.images ? product.images[0]?.link ?? '' : ''}
            alt={product.name}
        />      
    };

    const renderCardPercentageWrapper = (product) => {
        return <div></div>;       
    };

    const renderCardInfoWrapper = (product) => {
        return <div></div>;       
    };

    const renderTextNameWrapper = (product) => {
        return <span className={classes.textNameSuggestions}>{product.name}</span>      
    };

    const handleGetPublicationsWithSuggestions = async () => {
        try {
            let newQuery = `?has_ws_competition=true&has_suggestions=true`;
            newQuery = newQuery.concat(`&page_size=${numberOfPublications}&page=1&sort_by=suggestion_quantity&sort_direction=desc`);

            const response = await getPublications(newQuery);             
            setPublicationsWithSuggestions(response?.data?.publications || []);
            
        } catch (error) {
            console.log("Error trying to get publications with suggestions!");
        } finally {
            setIsLoadingPublicationsWithSuggestions(false);
        }
    };

    return (
        <div className={classes.mainContainer}>
            {urlSearch ? 
                (
                    <PublicationComparisonLoader />
                ) 
                : 
                (
                    <>
                        {searchView ? 
                            (                        
                                <div className={searchInputRef.current.length > 2 ? classes.secondContainerNoSpace : classes.secondContainer}>
                                    <div className={classes.searchHeaderContainer}>
                                        <div className={classes.titleHeaderContainer}>
                                            <span>Esto <span className={classes.notGoogleText}>no</span> es Google</span>
                                        </div>
                                        <div className={classes.subtitleHeaderContainer}>
                                            <div className={classes.subtitleText1Container}>
                                                <span>Elige una de tus publicaciones</span>
                                            </div>
                                            <div className={classes.subtitleText2Container}>
                                                <span>{`y te mostramos información de la competencia :)`}</span>
                                            </div>
                                        </div>
                                        <div className={classes.searchBarHeaderContainer}>
                                            <div className={classes.searchBarContainer}>
                                                <div className={classes.searchBar}>
                                                    <div className={classes.iconSearchWrapper}>
                                                        <img className={classes.searchIcon} src={IconSearch} alt="Search Icon" />
                                                    </div>
                                                    <div className={classes.searchInputWrapper}>
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            value={searchInput}
                                                            onChange={handleSearchInput}
                                                            onKeyDown={handleKeyDown}
                                                            className={classes.searchInput}
                                                            placeholder="Busca por nombre de producto, marca, tienda, SKU, EAN"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                            
                                    </div>
                                    <div className={searchInputRef.current.length > 2 ? classes.publicationsContainer : classes.nopublicationsSuggestionsContainer}>
                                        <div className={classes.innerPublicationsContainer}>
                                            { isLoadingPublications ? 
                                                (
                                                    Array.from(Array(numberOfPublications).keys()).map((item) => (
                                                        <SearchPublicationCardLoader key={item} />
                                                    ))
                                                ) : 
                                                (                                           
                                                    publications?.length > 0 ? (
                                                        publications.map(element => (
                                                            <PublicationCard 
                                                                product={element} 
                                                                key={element?.id_publication}   
                                                                cardWrapper={classes.cardWrapper}                                                                            
                                                                renderCardCheckContainer={renderCardCheckContainer}
                                                                innercardWrapper={classes.innercardWrapper}
                                                                leftSideContainer={classes.leftSideSearchContainer}
                                                                rightSideContainer={classes.rightSideSearchContainer}
                                                                titleInfo={classes.titleInfo}
                                                                cardTextWrapper={classes.cardTextWrapper}
                                                                textWrapperHeader={classes.textWrapperHeader}
                                                                textNameWrapper={classes.textNameWrapper}
                                                                textName={classes.publicationName}
                                                                cardImgWrapperContainer={classes.cardImgWrapperContainer}
                                                                cardImgWrapper={classes.cardImgWrapper} 
                                                                cardImg={classes.cardImgSearch}
                                                                percentageWrapper={classes.percentageWrapper}
                                                                flexWrapper={classes.flexWrapper}
                                                                callerComponent='SearchPublications'
                                                                setSearchView={setSearchView}
                                                                setCopyResultText={setCopyResultText}
                                                                setCopyCreationResult={setCopyCreationResult}
                                                                setSelectedPublication={setSelectedPublication}
                                                                setLoadingCompetition={setLoadingCompetition}
                                                            ></PublicationCard>
                                                        )) 
                                                    ) : 
                                                    (
                                                        searchInput?.length > 2 && (
                                                            <div className={classes.noPublicationsContainer}>
                                                                <img
                                                                    className={classes.publicationNotFoundIcon}
                                                                    src={EmptyPublicationsIcon}
                                                                    alt="empty-publications-icon"
                                                                />
                                                                <div className={classes.publicationNotFoundtextContainer}>
                                                                    <span>Vaya... busco y busco pero no</span>
                                                                    <span>encuentro publicaciones con ese nombre</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )                                       
                                                )
                                            }
                                        </div>                                                               
                                    </div> 
                                    { displaySuggestions && 
                                        (
                                            isLoadingPublicationsWithSuggestions ? 
                                            (
                                                <div className={classes.suggestionsContainer}>
                                                    <div className={classes.suggestionsTitleContainer}>
                                                        <Skeleton width="15%" height="50%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                                                    </div>
                                                    <div className={classes.suggestionsContentContainer}>
                                                        <div className={classes.suggestionsInnerContainer}>
                                                            {
                                                                Array.from(Array(numberOfPublications).keys()).map((item) => (
                                                                    <SearchPublicationCardLoader 
                                                                        key={item} 
                                                                        cardWrapper={classes.cardWrapperSkeletonSuggestions} 
                                                                        cardIconWrapper={classes.cardIconWrapperSkeletonSuggestions}
                                                                        cardPercentageWrapper={classes.cardPercentageWrapperSkeletonSuggestions} 
                                                                        cardInfoWrapper={classes.cardInfoWrapperSkeletonSuggestions} 
                                                                    />
                                                                ))
                                                            }
                                                        </div>                                                
                                                    </div>
                                                </div>
                                            ) 
                                            : 
                                            (
                                                publicationsWithSuggestions?.length > 0 ? 
                                                (
                                                    <div className={classes.suggestionsContainer}>
                                                        <div className={classes.suggestionsTitleContainer}>
                                                            <span>Te sugerimos que revises...</span>
                                                        </div>
                                                        <div className={classes.suggestionsContentContainer}>
                                                            <div className={classes.suggestionsInnerContainer}>
                                                                {
                                                                    publicationsWithSuggestions.map(element => (
                                                                        <PublicationCard 
                                                                            product={element} 
                                                                            key={element?.id_publication}   
                                                                            cardWrapper={classes.cardWrapperSuggestions} 
                                                                            renderCardImgWrapper={renderCardImgWrapper}                                                                           
                                                                            renderCardCheckContainer={renderCardCheckContainer}
                                                                            renderCardInfoWrapper={renderCardInfoWrapper}
                                                                            renderCardPercentageWrapper={renderCardPercentageWrapper}
                                                                            renderTextNameWrapper={renderTextNameWrapper}                                                                    
                                                                            innercardWrapper={classes.innercardWrapper}
                                                                            leftSideContainer={classes.leftSideContainer}
                                                                            rightSideContainer={classes.rightSideContainer}
                                                                            textNameWrapper={classes.textNameWrapperSuggestions}
                                                                            textWrapperHeader={classes.textWrapperHeaderSuggestions}
                                                                            cardImgWrapperContainer={classes.cardImgWrapperContainerSuggestions}
                                                                            cardTextWrapper={classes.cardTextWrapperSuggestions}
                                                                            cardCheckContainer={classes.cardCheckContainerSuggestions}                                                                    
                                                                            cardImgWrapper={classes.cardImgWrapperSuggestions}
                                                                            cardImg={classes.cardImg}
                                                                            cardPercentageWrapper={classes.cardPercentageWrapperSuggestions}
                                                                            cardInfoWrapper={classes.cardInfoWrapperSuggestions}
                                                                            callerComponent='SearchPublications'
                                                                            setSearchView={setSearchView}
                                                                            setCopyResultText={setCopyResultText}
                                                                            setCopyCreationResult={setCopyCreationResult}
                                                                            setSelectedPublication={setSelectedPublication}
                                                                            setLoadingCompetition={setLoadingCompetition}
                                                                        ></PublicationCard>
                                                                    )) 
                                                                }
                                                            </div>                                                    
                                                        </div>
                                                    </div>
                                                ) 
                                                : 
                                                (
                                                    <div className={classes.suggestionsContainer}>
                                                        <div className={classes.suggestionsNoTitleContainer}>
                                                            <img
                                                                className={classes.ballonsImg}
                                                                alt="Globos"
                                                                src={Ballons}
                                                            />
                                                        </div>
                                                        <div className={classes.suggestionsNoContentContainer}>
                                                            <div className={classes.suggestionsNoContentText}>
                                                                <span>¡Magnífico!</span>
                                                                <span>No tienes publicaciones con sugerencias...</span>
                                                                <span>Peeeeero, puedes buscar publicaciones y</span>
                                                                <span>revisar la competencia (con el buscador)</span>
                                                            </div>                                                    
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )
                                    }                            
                                </div>
                            ) 
                            :
                            (
                                <div className={searchInputRef.current.length > 2 ? classes.secondContainerNoSpace : classes.secondContainer}>
                                    <PublicationComparison
                                        selectedPublication={selectedPublication}
                                        loadingCompetition={loadingCompetition}
                                        setLoadingCompetition={setLoadingCompetition}
                                        setCopyResultText={setCopyResultText} 
                                        setCopyCreationResult={setCopyCreationResult}
                                        setRivalId={setRivalId}
                                        setWantDelete={setWantDelete}
                                        setSelectedRival={setSelectedRival}
                                    />
                                </div>                        
                            )
                        }
                    </>
                )
            }                        
        </div>
    );
}

export default SearchPublications;
