import React, { useState, useEffect } from 'react'
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';


function ChannelCircles({ setChannels, channelsSelected, listOfChannels }) {

    const [channelSelected, setChannelSelected] = useState(channelsSelected || [])
   

    const handleOnClickFlag = (e) => {
        try {
            let filteredChannels = [];
            if (channelSelected.includes(parseInt(e.target.alt))) {
                filteredChannels = channelSelected.filter(channel => channel !== parseInt(e.target.alt))
                setChannelSelected(filteredChannels)
            }
            else {
                filteredChannels = [...channelSelected, parseInt(e.target.alt)]
                setChannelSelected(filteredChannels)
            }
            setChannels(filteredChannels)
            

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            {listOfChannels?.length > 0 ? <Box sx={{ '& > :not(style)': { m: 1 }, zIndex: 1 }}>
                {listOfChannels?.map((channel) => (
                    <Tooltip key={channel.channelData.id.toString()} title={channel.channelData.canal} arrow>
                        <Fab
                            id={channel.channelData.id.toString()}
                            key={channel.channelData.id.toString()}
                            style={{ boxShadow: channelSelected.includes(channel.channelData.id.toString()) || channelSelected.includes(channel.channelData.id) ? '0 0 0 5px #51CBCE' : 'none', }}
                            aria-label="add"
                            size='small'
                            onClick={handleOnClickFlag}>
                            <img src={channel.channelImg} alt={channel.channelData.id.toString()} />
                        </Fab>
                    </Tooltip>
                ))}
            </Box>
                :
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress /> <Typography sx={{ ml: 2 }} >Cargando lista de canales...</Typography>
                </Box>}
        </React.Fragment>
    )
}

export default ChannelCircles