import {performanceMetric} from "../utils/utils";

const configFile = require('../config/url');
const axios = require('axios');


export const getStores = async (userEmail) => {
    const accessToken = localStorage.getItem('Token');
    const updateEmail = userEmail.replace("+", "%2b")
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}stores?user=${updateEmail}`, requestOptions)
        performanceMetric("/stores", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = await response.json()
        return data
    }
    catch (err) {
        console.log(err)
    }
}

export const getStore = async (idStore) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}store?id=${idStore}`, requestOptions)
        performanceMetric("/store", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = await response.json()
        return data
    }
    catch (err) {
        console.log(err)
    }
}
export const getStoreByName = async (name) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `${bearerToken}`);
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append('Content-Type', 'text/plain');
    const requestOptions = {
        method: "GET",
        headers: myHeaders
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}store?name=${name}`, requestOptions);
        performanceMetric("/store", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        const data = await response.json();
        return data.message;
    }
    catch (err) {
        console.log(err)
    }
}

export const getStoreDetails = async (id) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append("Authorization", bearerToken);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    let url = `${configFile.config.ambiente}store?id=${id}`;

    try {
        const response = await fetch(url, requestOptions)
        return await response.json()
    }
    catch (err) {
        console.log("error", err);
    }
};

export const postClientStore = async (clientData) => {
    try {
        const accessToken = localStorage.getItem('Token')
        let data = {
            "tienda": clientData.name,
            "company_data": {
                "company_name": clientData.social_reason,
                "company_rut": clientData.identity,
                "company_phone": "",
                "company_giro": clientData.type_of_selling,
                "kam_name": "",
                "kam_email": clientData.kam_selected,
                "kam_phone": "",
                "company_address": clientData.address,
                "representative_name": clientData.namerlegal,
                "representative_email": clientData.email,
                "representative_phone": "",
                "company_logo": "",
                "company_banner": "",
                "company_contract": clientData.contrat,
                "company_services": {
                    "facturacion": clientData.facturacion,
                    "almacenamiento_bodega_instance": clientData.bodega,
                    "creacion_canales_de_venta": clientData.canales,
                    "servicio_al_cliente": clientData.servicio
                }
            },
            "store_data": {
                "email_color": "#1c549e",
                "email_text": "",
                "categories": []
            },
            "paises": [clientData.client_country],
            "canales_por_onbordear": clientData.channelsOnboarding
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}store`,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify({ data })
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error;
    }
}


export const putClientStore = async (clientData) => {
    try {
        const accessToken = localStorage.getItem('Token')
        let data = {
            "tienda": clientData.name,
            "company_data": {
                "company_name": clientData.social_reason,
                "company_rut": clientData.identity,
                "company_phone": "",
                "company_giro": clientData.type_of_selling,
                "kam_name": "",
                "kam_email": clientData.kam_selected,
                "kam_phone": "",
                "company_address": clientData.address,
                "representative_name": clientData.namerlegal,
                "representative_email": clientData.email,
                "representative_phone": "",
                "company_logo": "",
                "company_banner": "",
                "company_contract": clientData.contrat,
                "company_services": {
                    "facturacion": clientData.facturacion,
                    "almacenamiento_bodega_instance": clientData.bodega,
                    "creacion_canales_de_venta": clientData.canales,
                    "servicio_al_cliente": clientData.servicio
                }
            },
            "store_data": {
                "email_color": "#1c549e",
                "email_text": "",
                "categories": []
            },
            "paises": [clientData.client_country],
            "canales_por_onbordear": clientData.channelsOnboarding
        }

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}store?id=` + clientData.idCliente,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify({ data })
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error;
    }
}

export const deleteStore = async (clientId) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}store?id=` + clientId,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error
    }

}

export const patchOnboardingStep = async (clientId, onboarding_steps) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}store/config/onboarding-steps?id=` + clientId,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: JSON.stringify({ onboarding_steps })
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error
    }
}

export const sendOnboardingEmail = async (storeId, emailId) => {
    try {
        const accessToken = localStorage.getItem('Token')

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${configFile.config.ambiente}store/onboarding-email?storeId=${storeId}&emailId=${emailId}`,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return await axios.request(config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            });
    } catch (error) {
        return error
    }
}

export const getBusinessModels = async () => {
    try {
        const accessToken = localStorage.getItem('Token')

        const axiosRequestConfig = {
            method: 'get',
            url: `${configFile.config.ambiente}store/onboarding/business-models`,
            headers: {
                'x-api-key': configFile.config.apiKey,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }; 

        return await axios(axiosRequestConfig)

    } catch (error) {
        return null
    }
}