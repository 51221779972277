//Libraries
import React, { useState, useEffect, useRef, forwardRef } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Search from "@material-ui/icons/Search";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import RoomIcon from "@material-ui/icons/Room";
import { Select, MenuItem, Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { prettifyRut } from "react-rut-formatter";
import { Button, Col, Modal, ModalHeader } from "reactstrap";
import SendMail from "components/modalComponents/sendMail";
import OrderMobileCard from "components/OrderMobileCard/OrderMobileCard";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import SplashScreen from "components/UI/splash-screen";
import SaveAlt from "@material-ui/icons/SaveAlt";
import WmsModal from "components/modalComponents/wms-modal";
import ClientModal from "components/ClientModal/client-modal";
import CourierStatusModal from "components/courierStatusModal/courier-status-modal";
import BallotDetailModal from "components/BallotDetailModal/BallotDetailModal";
import LabelModal from "components/labelModal/labelModal";
import ManifestoModal from "components/manifestoModal/manifesto-modal";
import ErrorHandler from "components/ErrorHandler/error-handler";
import { ErrorBoundary } from "react-error-boundary";
import GenerateEnviameLabel from "components/GenerateEnviameLabel/generate-enviame-label";
import { Rating } from "@mui/material";
import ErrorAlert from "components/UI/ErrorAlerts/error-alert";
import ReviewModal from "components/ReviewModal/review-modal";
import Toolbar from "../../components/OrdersTable/Toolbar";
import Label from "components/OrdersTable/Label";
import StepByStep from "components/StepByStep/StepByStep";
import copy from "copy-to-clipboard";

//Images
import noDataImage from "../../assets/img/noDataImageBlue.png";
import greyIcon from "../../assets/img/greyIcon.png";
import EstadoCourier from "../../assets/img/courier.png";
import ReviewPreview from "../../assets/img/image-preview.png";
import SiIcon from "../../assets/img/si.png";
import noIcon from "../../assets/img/no.png";
import showPdf from "../../assets/img/showPdf.png";
import copyIcon from "../../assets/img/copy.png"
import Estado from "../../assets/img/Estado.png";
import iconFilterButton from "../../assets/img/icons/Reports/iconFilters.png";
import noOrdersDataImage from "../../assets/img/searches/no-orders-found.png"

//Services
import { getOrderId, putEnviameLabel, getOrders, getFilters } from "services/orders";

//CSS
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/global.css";
import classes from "./mtdi-table.module.css";
import Pagination from "components/OrdersTable/Pagination";

//Constants
const limit =50;
const XLSX = require("xlsx");
const configFile = require("../../config/url");
const tableIcons = {
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <RoomIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (<ChevronLeft {...props} ref={ref} />)),
};

const defaultConfigColumns = [
  { title: "Orden de Compra", hidden: false },
  { title: "Detalle", hidden: false },
  { title: "Etiquetas/Manifiesto", hidden: false },
  { title: "Fecha de Orden", hidden: false },
  { title: "Canal de Venta", hidden: false },
  { title: "Cliente", hidden: false },
  { title: "Tienda Oficial", hidden: false },
  { title: "País", hidden: false },
  { title: "DTE", hidden: false },
  { title: "Bodega", hidden: false },
  { title: "Respuesta WMS", hidden: false },
  { title: "Estado WMS", hidden: false },
  { title: "Hub de pago", hidden: false },
  { title: "Total", hidden: false },
  { title: "Shipping", hidden: false },
  { title: "Hub fulfillment", hidden: false },
  { title: "Estado Courier", hidden: false },
  { title: "Shipping ID", hidden: false },
  { title: "Comprador", hidden: false },
  { title: "Reviews", hidden: false },
  { title: "Nro DTE", hidden: false }
]

registerLocale("es", es);

const MtdiTable = () => {
  const [EnviameLabelQuantity, setEnviameLabelQuantity] = useState([]);
  const [showErrorAlert, setshowErrorAlert] = useState(false);
  const [errorAlertMessage, seterrorAlertMessage] = useState("");
  const [isMobileSizes, setIsMobileSized] = useState(false);
  const [filtersClass, setfiltersClass] = useState("FiltersInDesktop");
  const [showFilter, setshowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [paginationOrders, setPagination] = useState({});
  const [pageCount, setpageCount] = useState(1);
  const [country, setcountry] = useState("");
  const [countryId, setcountryId] = useState(0);
  const [store, setstore] = useState("");
  const [storeId, setstoreId] = useState(0);
  const [salesChannel, setsalesChannel] = useState("");
  const [channelId, setchannelId] = useState(0);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [modalBallotDetails, setModalBallotDetails] = useState(false);
  const [labelDetailsModal, setlabelDetailsModal] = useState(false);
  const [clientDetailsModal, setclientDetailsModal] = useState(false);
  const [manifestDetailModal, setmanifestDetailModal] = useState(false);
  const [courierStatusDetailModal, setcourierStatusDetailModal] = useState(false);
  const [isGeneratingEnviameLabel, setisGeneratingEnviameLabel] = useState(false);
  const [ReviewsModal, setReviewsModal] = useState(false);
  const dateToday = new Date();
  dateToday.setMonth(dateToday.getMonth() - 1);
  const [selectedDateFrom, setselectedDateFrom] = useState(dateToday.toISOString().slice(0, 10));
  const [selectedDateTo, setselectedDateTo] = useState(new Date().toISOString().slice(0, 10));
  const [genEnviameLabel, setgenEnviameLabel] = useState(false);
  const [buyer, setbuyer] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [showWMSModal, setshowWMSModal] = useState(false);
  const [showCourierModal, setshowCourierModal] = useState(false);
  const [clientModal, setclientModal] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingIncrementPage, setisLoadingIncrementPage] = useState(false);
  const [filteredCountryData, setfilteredCountryData] = useState([]);
  const [filteredStoreData, setfilteredStoreData] = useState([]);
  const [filteredChannelArray, setfilteredChannelArray] = useState([]);
  const [manifestModal, setmanifestModal] = useState(false);
  const [searchOrderId, setsearchOrderId] = useState("");
  const [hideLabelManifest, sethideLabelManifest] = useState(false);
  const [configColumns, setConfigColumns] = useState(defaultConfigColumns)
  const [showColumnList, setShowColumnList] = useState(false)
  const [noMore, setNoMore] = useState(false)
  const [pageToSet, setPage] = useState(0);
  const [rowsToSet, setRows] = useState(10);
  const [foundResultsInFirstLoad, setFoundResultsInFirstLoad] = useState(false)
  const [showCopySuccessMsg, setShowCopySuccessMsg] = useState(false)
  const toggle = () => setIsTourOpen(!isTourOpen);
  const toggle2 = () => setModalBallotDetails(!modalBallotDetails);
  const toggle4 = () => setlabelDetailsModal(!labelDetailsModal);
  const toggle5 = () => setclientDetailsModal(!clientDetailsModal);
  const toggle6 = () => setmanifestDetailModal(!manifestDetailModal);
  const toggle7 = () => setcourierStatusDetailModal(!courierStatusDetailModal);
  const ReviewsModalHandler = () => setReviewsModal(!ReviewsModal);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const sectionRef = useRef(null)
  const maxBodyHeight = `${window.innerHeight - 480}px`;
  const minBodyHeight = "430px"

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setshowErrorAlert(false)
        seterrorAlertMessage("")
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    };
  }, [sectionRef])

  const handleShowColumn = () => setShowColumnList(prev => !prev)

  const handleCheckbox = (i) => {
    let auxArr = [...configColumns]
    auxArr[i].hidden = !configColumns[i].hidden
    setConfigColumns(auxArr)
    localStorage.setItem("configColumn", JSON.stringify(auxArr.map(e => e.hidden)));
  }

  const getConfigColumn = () => {
    const localConfig = JSON.parse(localStorage.getItem("configColumn"))
    if (localConfig !== null) {
      let auxGetconfig = configColumns.map((e, i) => ({ title: e.title, hidden: localConfig[i] }))
      setConfigColumns(auxGetconfig)
    }
  }

  const officialStore = "";
  const client = "";
  
  const getProfileType = () => {
    switch (localStorage.getItem("ut")) {
      case "1":
        return "Administrador"
      case "2":
        return "Kam"
      case "3":
        return "Cliente"
      case "4":
        return "Externo"
      case "5":
        return "Operador"
      default:
        return ""
    }
  }

  useEffect(() => {
    if (localStorage.getItem("ut") === "1") {
      setstoreId(0);
    }
    if (localStorage.getItem("ut") === "2") {
      setstoreId(0);
    }
    fetchOrderData();
    fetchFilterData();
  }, []);

  const fetchFilterData = async () => {
    try {
      const response = await getFilters();
      setfilteredCountryData(response.message || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // set initial value
    const mediaWatcher = window.matchMedia("(max-width: 767px)");
    setIsMobileSized(mediaWatcher.matches);

    //watch for updates
    function updateIsNarrowScreen(e) {
      return
    }
    mediaWatcher.addEventListener("change", updateIsNarrowScreen);

    // clean up
    return function cleanup() {
      mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
    };
  });

  useEffect(() => {
    if (isMobileSizes) {
      setfiltersClass("FiltersInMobile");
      setshowFilter(false);
    }
    if (!isMobileSizes) {
      setfiltersClass("FiltersInDesktop");
      setshowFilter(true);
    }
  }, [isMobileSizes]);

  useEffect(() => {
    if (client !== "") {
      const x = data.filter((item) => item.client.includes(client));
      setData(x);
    }
  }, [client]);

  useEffect(() => {
    if (officialStore !== "") {
      const x = data.filter((item) =>
        item.officialStore.includes(officialStore)
      );
      setData(x);
    }
  }, [officialStore, storeId]);

  useEffect(() => {
    if (startDate !== null) {
      setselectedDateFrom(startDate.toISOString().slice(0, 10));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== null) {
      setselectedDateTo(endDate.toISOString().slice(0, 10));
    }
  }, [endDate]);

  const copyToClipboard = (textToCopy) => {
    copy(textToCopy)
    setShowCopySuccessMsg(true)
  }

  const fetchOrderData = async () => {
    setisLoading(true);
    getConfigColumn()
    if (localStorage.getItem("ut") === "2") sethideLabelManifest(true);

    try {
      const response = await getOrders(pageCount, limit, channelId, storeId, countryId, selectedDateFrom, selectedDateTo, searchOrderId);

      if(!response?.message?.orders?.length)
        setFoundResultsInFirstLoad(false)
      else
        setFoundResultsInFirstLoad(true)
      
      setData(response.message.orders);
      setPagination(response.message.pagination);
      const enviameArray = response.message.orders.filter((item) => item.hubFulfillment === "Enviame").map(e => { return { orderNo: e.orderNo, Quantity: 0, isLoading: false } })

      setEnviameLabelQuantity(enviameArray);
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetNewPage = (pageToStart) => {
    setPage(pageToStart)
  }

  const handleSetRows = (rows) => {
    setRows(rows);
  }

  const showFiltersHandler = () => {
    setshowFilter(!showFilter);
  };
  const hideWMSModalHandler = () => {
    setshowWMSModal(false);
  };
  const hideManifestModalHandler = () => {
    setmanifestModal(false);
  };
  const hideClientModalHandler = () => {
    setclientModal(false);
  };
  const showModalHandler = () => {
    setshowModal(true);
  };
  const hideModalHandler = () => {
    setshowModal(false);
  };
  const hideCourierModalHandler = () => {
    setshowCourierModal(false);
  };

  const applyFiltersButtonhandler = async () => {
    setisLoading(true);
    try {
      const response = await getOrders(pageCount, limit, channelId, storeId, countryId, selectedDateFrom, selectedDateTo, searchOrderId);

      if (response.message.orders !== undefined) {
        setData(response.message.orders);
        setPagination(response.message.pagination);

        if(!selectedDateFrom && !selectedDateTo && !searchOrderId)
          setFoundResultsInFirstLoad(true)
        else
          setFoundResultsInFirstLoad(false)


        const enviameArray = response.message.orders.filter((item) => item.hubFulfillment === "Enviame").map(e => { return { orderNo: e.orderNo, Quantity: 0, isLoading: false } })
        setEnviameLabelQuantity(enviameArray);
        setisLoading(false);
      }

      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  }


  const getOrderById = async (orderNumber, id_mtdi) => {
    setisLoading(true)
    const response = await getOrderId(orderNumber)
    const currentOrder = response.message.filter(o => o.id_mtdi === id_mtdi)[0]
    const updatedData = data.map((obj) =>
      obj.id_mtdi === id_mtdi ? {
        ...currentOrder
      } : obj
    )
    setData(updatedData)
    setisLoading(false)
  }

  const getEnviameLabel = async (storeId, orderNumber, channelId, id_mtdi) => {
    const newState = EnviameLabelQuantity.map((obj) => {
      if (obj.orderNo === orderNumber) {
        return { ...obj, isLoading: true };
      }
      return obj;
    })
    setEnviameLabelQuantity(newState);
    let quantityOfLabels = EnviameLabelQuantity.filter(
      (item) => item.orderNo === orderNumber
    ).map((item) => item.Quantity);

    let EnviameLabelBody = {
      orderNo: orderNumber,
      idChannel: channelId,
      idStore: storeId,
      nPackage: quantityOfLabels[0],
    };

    setgenEnviameLabel(false)

    try {
      const response = await putEnviameLabel(EnviameLabelBody)
      if (response instanceof Error) {
        throw response
      }
      setsearchOrderId(buyer.orderNo);
      setisGeneratingEnviameLabel(false)
      getOrderById(buyer.orderNo, id_mtdi)
    } catch (error) {
      setshowErrorAlert(true)
      seterrorAlertMessage(error.message)
    } finally {
      const newState = EnviameLabelQuantity.map((obj) => {
        return { ...obj, isLoading: false }
      })
      setEnviameLabelQuantity(newState)
    }
  }

  const incrementPageHandler = async () => {
    setisLoadingIncrementPage(true);
    setpageCount((prevValue) => prevValue + 1);
    try {
      if (!noMore) {
        const response = await getOrders(pageCount + 1, limit, channelId, storeId, countryId, selectedDateFrom, selectedDateTo, searchOrderId);
        if (response.message.pagination.pagination !== limit) {
          setNoMore(true)
        }

        let newOrdersData = response.message.orders;
        setData([...data, ...newOrdersData]);
        setPagination(response.message.pagination);
      }
      setisLoadingIncrementPage(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0 (0 = enero, 11 = diciembre)
    const year = dateObject.getFullYear();

    const hour = String(dateObject.getHours());
    const minutes = String(dateObject.getMinutes());

    return `${day}-${month}-${year} ${hour}:${minutes}`;
  };

  const columns = [
    {
      title: "Orden de Compra",
      field: "orderNo",
      width: "13%",
      hidden: configColumns[0].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px"
      },
      render: (rowData) => {
        if (rowData.orderNo !== undefined) {
          return (
            <div>
              <span title="Nro de orden">
                { rowData.orderNo }
              </span>
              <img 
                title="copiar nro de orden" 
                heigth="17" width="17"
                className={classes.imgCopy}
                src={copyIcon} 
                onClick={() => copyToClipboard(rowData.orderNo)}
              />
            </div>
          );
        }
        else {
          return null
        }
      }
    },
    {
      title: "Detalle",
      field: "orderNo",
      width: "5%",
      hidden: configColumns[1].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px"
      },
      render: (rowData) => {
        if (rowData.orderNo !== undefined) {
          return (
            <div>
              <span
                style={{ cursor: "pointer" }}
                title="Mostrar Detalle compra"
                className={classes.showPdf}
              >
                <img src={showPdf} onClick={toggle2} />
              </span>
            </div>
          );
        }
      },
    },
    {
      title: "Etiquetas/Manifiesto",
      field: "manifiest,label,statusCourier,hubFulfillment",
      hidden: hideLabelManifest || configColumns[2].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
      render: (rowData) => <Label rowData={rowData} toggle6={toggle6} />,
    },
    {
      title: "Fecha de Orden",
      field: "creationDate",
      width: "13%",
      align: "center",
      hidden: configColumns[3].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
      render: (rowData) => {
        return <p>{formatDate(rowData.creationDate)}</p>
      }
    },
    {
      title: "Canal de Venta",
      field: "channelName",
      width: "13%",
      align: "center",
      hidden: configColumns[4].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
      render: (rowData) => {
        if (rowData.channelIcon !== "") {
          return <div>
            <span
              title={rowData.channelName}
            >
              <img style={{ width: "30%" }} src={rowData.channelIcon}></img>
            </span>
          </div>
        } else {
          return <p>{rowData.channelName}</p>
        }
      }
    },
    {
      title: "Cliente",
      field: "client",
      width: "13%",
      align: "center",
      hidden: configColumns[5].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Tienda Oficial",
      field: "officialStore",
      width: "15%",
      hidden: configColumns[6].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "País",
      field: "country",
      width: "13%",
      hidden: configColumns[7].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px"
      },
    },
    {
      title: "DTE",
      field: "dte",
      width: "13%",
      hidden: configColumns[8].hidden,
      render: (rowData) => {
        if (rowData.dte !== undefined) {
          if (rowData.dte === "") {
            return (
              <div>
                {" "}
                No &nbsp;{" "}
                <span style={{ marginLeft: "4px" }} className={classes.noIcon}>
                  <img src={noIcon} />
                </span>
                &nbsp;
                <span className={classes.greyIcon}>
                  <img src={greyIcon} />
                </span>
              </div>
            );
          }
          if (rowData.dte === "-") {
            return (
              <div>
                No &nbsp;
                <span style={{ marginLeft: "4px" }} className={classes.noIcon}>
                  <img title="No existe DTE" src={noIcon} />
                </span>
                &nbsp;
                <span
                  className={classes.greyIcon}
                  style={{ cursor: "pointer" }}
                >
                  <img src={greyIcon} title="No existe DTE" />
                </span>
              </div>
            );
          }
          if (rowData.dte.substring(0, 4) === "http") {
            return (
              <div>
                Si &nbsp;
                <span
                  style={{ marginLeft: "14px", cursor: "pointer" }}
                  className={classes.si}
                >
                  <img
                    src={SiIcon}
                    title="Enviar DTE"
                    onClick={showModalHandler.bind(this, data)}
                  />
                </span>
                &nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  title="Mostrar DTE"
                  className={classes.showPdf}
                >
                  <a href={rowData.dte} target="_blank">
                    <img src={showPdf} />
                  </a>
                </span>
              </div>
            );
          }
        }
      },

      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Bodega",
      field: "warehouse",
      width: "13%",
      hidden: configColumns[9].hidden,
      render: (rowData) => {
        if (rowData.warehouse === "") {
          return <div>No disponible</div>;
        } else {
          return <div>{rowData.warehouse}</div>;
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Respuesta WMS",
      field: "responseWms",
      width: "15%",
      hidden: configColumns[10].hidden,
      render: (rowData) => {
        if (rowData.responseWms != undefined) {
          if (rowData.responseWms === "") {
            return <div>No disponible</div>;
          }
          if (rowData.responseWms === "Error") {
            return (
              <div>
                <span className={classes.stockError}>Error De Stock </span>
                <span style={{ marginLeft: "14px", cursor: "pointer" }}></span>
              </div>
            );
          }
          if (rowData.responseWms === "Error") {
            return (
              <div>
                <span className={classes.stockError}>Error De Stock </span>
                <span style={{ marginLeft: "14px", cursor: "pointer" }}></span>
              </div>
            );
          }
          if (rowData.responseWms === "No enviado a WMS") {
            return (
              <div>
                <span className={classes.integrated}>No enviado a WMS </span>
                <span style={{ marginLeft: "14px", cursor: "pointer" }}></span>
              </div>
            );
          }
          if (rowData.responseWms === "Enviado") {
            return (
              <div>
                <span className={classes.confirmado}>Enviado</span>
                <span style={{ marginLeft: "14px", cursor: "pointer" }}></span>
              </div>
            );
          }
          if (rowData.responseWms.includes("XML cargado correctamente")) {
            return (
              <div>
                <span className={classes.confirmado}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviado&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span style={{ marginLeft: "14px", cursor: "pointer" }}></span>
              </div>
            );
          }
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px"
      },
    },
    {
      title: "Estado WMS",
      field: "statusWms",
      width: "13%",
      hidden: configColumns[11].hidden,
      render: (rowData) => {
        if (rowData.statusWms === "") {
          return <div>No disponible</div>;
        }
        if (rowData.statusWms === "Enviado") {
          return (
            <div className={classes.enviado}>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;Enviado
            </div>
          );
        }
        if (rowData.statusWms === "Pendiente") {
          return <div className={classes.pendiente}>&nbsp;&nbsp;Pendiente</div>;
        }
        if (rowData.statusWms === "No Aplica") {
          return <div className={classes.cancelado}>&nbsp;&nbsp;No Aplica</div>;
        }
      },

      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Hub de pago",
      field: "hub",
      width: "15%",
      hidden: configColumns[12].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Total",
      field: "totalWithoutShipping",
      width: "13%",
      hidden: configColumns[13].hidden,
      render: (rowData) => {
        if (rowData.totalWithoutShipping !== undefined) {
          let formatted = new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
          }).format(rowData.totalWithoutShipping);
          return (
            <div>
              {formatted}
              &nbsp;
            </div>
          );
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Shipping",
      field: "totalShipping",
      width: "13%",
      hidden: configColumns[14].hidden,
      render: (rowData) => {
        if (rowData.totalShipping !== undefined) {
          let formatted = new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
          }).format(rowData.totalShipping);
          return (
            <div>
              {formatted}
              &nbsp;
            </div>
          );
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Hub fulfillment",
      field: "hubFulfillment,hubLogo",
      width: "15%",
      hidden: configColumns[15].hidden,
      render: (rowData) => {
        if (rowData.hubFulfillment != undefined) {
          return (
            <div>
              {rowData.hubFulfillment !== "Por definir" && (
                <span style={{ whiteSpace: "nowrap" }}>
                  {rowData.hubLogo !== "No aplica" && (
                    <img
                      style={{ paddingRight: "8px" }}
                      src={rowData.hubLogo}
                      width="40px"
                      height="32px"
                    />
                  )}
                  {rowData.hubFulfillment}
                </span>
              )}
              {rowData.hubFulfillment === "Por definir" && (
                <span style={{ whiteSpace: "nowrap" }}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;<span> </span>
                  {rowData.hubFulfillment}
                </span>
              )}
            </div>
          );
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Estado Courier",
      field: "statusCourier",
      width: "16%",
      hidden: configColumns[16].hidden,
      render: (rowData) => {
        if (rowData.statusCourier) {

          let lastStatus = rowData.statusCourier[rowData.statusCourier.length - 1];
          lastStatus = lastStatus?.status ?? "";
          if (lastStatus === "ready_to_ship") lastStatus = "Listo para despacho";
          return (
            <div style={{ display: "flex" }}>
              <span style={{ width: "85%", paddingInline: "5%" }}>
                {lastStatus}
              </span>
              <span
                style={{
                  width: "15%",
                  float: "right",
                  whiteSpace: "nowrap",
                  left: "80px",
                }}
              >
                <img
                  src={EstadoCourier}
                  width="40px"
                  title="Ver historial de estados"
                  onClick={toggle7}
                />
              </span>
            </div>
          );

        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Shipping ID",
      field: "shipping_id",
      width: "13%",
      hidden: configColumns[17].hidden,
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Comprador",
      field: "buyerName",
      width: "18%",
      hidden: configColumns[18].hidden,
      render: (rowData) => {
        if (rowData.buyerName != undefined) {
          let FormattedRut = prettifyRut(rowData.buyerIdNumber);
          return (
            <div style={{ display: "flex" }}>
              <div
                style={{ display: "flex", alignItems: "center", width: "85%", }}
              >
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                    display: "inline-block",
                  }}
                >
                  {rowData.buyerName}
                </span>
                {FormattedRut !== "" && ( // Aquí se verifica si FormattedRut no está vacío
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "11px",
                      color: "#858F99",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    <br />
                    {FormattedRut}
                  </span>
                )}

              </div>
              <span
                id="span1"
                style={{
                  width: "15%",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &nbsp;
                <img
                  style={{ float: "left", maxWidth: "80%" }}
                  src={Estado}
                  title="Cliente Info"
                  onClick={toggle5}
                />
              </span>
            </div>
          );
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Reviews",
      field: "products,channel",
      width: "20%",
      hidden: configColumns[19].hidden,
      render: (rowData) => {
        if (rowData.channel !== 2) {
          return (
            <div>
              <span
                style={{
                  width: "0%",

                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                <Rating name="read-only" value={null} readOnly />
              </span>
              <span>
                <img
                  src={ReviewPreview}
                  width="20px"
                  style={{ marginBottom: "10px", marginLeft: "3px" }}
                  onClick={ReviewsModalHandler}
                />
              </span>
            </div>
          );
        }
        if (
          (rowData.channel === 2 &&
            rowData.products[0].length === 1 &&
            rowData.products[0].review !== "") ||
          rowData.products[0].review !== undefined
        ) {
          return (
            <div>
              <span
                style={{
                  width: "0%",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                <Rating
                  name="read-only"
                  value={rowData.products[0].review.rate}
                  readOnly
                />
              </span>
              <span>
                <img
                  src={ReviewPreview}
                  width="20px"
                  style={{ marginBottom: "10px", marginLeft: "3px" }}
                  onClick={ReviewsModalHandler}
                />
              </span>
            </div>
          );
        }
        if (
          (rowData.channel === 2 &&
            rowData.products[0].review === "") ||
          rowData.products[0].review === undefined
        ) {
          return (
            <div>
              <span
                style={{
                  width: "0%",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                <Rating name="read-only" value={null} readOnly />
              </span>
              <span>
                <img
                  src={ReviewPreview}
                  width="20px"
                  style={{ marginBottom: "10px", marginLeft: "3px" }}
                  onClick={ReviewsModalHandler}
                />
              </span>
            </div>
          );
        }
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
    {
      title: "Nro DTE",
      field: "invoiceNumber",
      width: "20%",
      hidden: configColumns[20].hidden,
      render: (rowData) => {
        return (
          <span className={classes.invoiceNumber}>
            {rowData?.invoiceNumber || "No disponible"}
          </span>
        )
      },
      headerStyle: {
        backgroundColor: "#051B2F",
        color: "#FFF",
        fontSize: "12px",
      },
    },
  ]

  const handleCountryChange = (event) => {
    setcountry(event.target.value);
    //Get countryId from filteredCountryData
    const country = filteredCountryData?.find((e) => e.country === event.target.value);
    setcountryId(country?.value ?? 0);
    setfilteredStoreData(country?.stores ?? []);
  };

  const handleStoreChange = (event) => {
    setstore(event.target.value);
    //Get storeId from filteredStoreData
    const val = filteredStoreData.filter(function (item) {
      if (item.store === event.target.value) {
        return item;
      }
    });
    setstoreId(val[0].value);
    const selectedStoreData = filteredStoreData.filter((selectedStore) => {
      return selectedStore.store === event.target.value;
    });
    const selectedChannelsArray = selectedStoreData[0].channels;
    const selectedChannels = selectedChannelsArray.map((item) => {
      return item;
    });
    setfilteredChannelArray(selectedChannels);
  };

  const handleSalesChannelChange = (event) => {
    setsalesChannel(event.target.value);
    //Get ChannelId from filteredChannelArray
    const val = filteredChannelArray.filter(function (item) {
      if (item.channel === event.target.value) {
        return item;
      }
    });
    setchannelId(val[0].value);
  };

  const reloadTableHandler = () => {
    fetchOrderData();

    location.reload();
  };

  const searchOrderIdHandler = (event) => {
    setsearchOrderId(event.target.value);
  };

  const DownloadFileHandler = () => {
    let binary_univers = data;
    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");
    XLSX.writeFile(wb, "instance_orders.xlsx");
  };

  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        {isLoading && <SplashScreen message="órdenes" />}
        {showModal && (
          <SendMail
            onhideModal={hideModalHandler}
            data={data}
            purchaser={buyer}
          ></SendMail>
        )}

        {manifestModal && (
          <ManifestoModal
            onhideModal={hideManifestModalHandler}
            purchaser={buyer}
          />
        )}
        {showWMSModal && (
          <WmsModal onhideModal={hideWMSModalHandler}></WmsModal>
        )}

        {showCourierModal && (
          <CourierStatusModal
            onhideModal={hideCourierModalHandler}
            purchaser={buyer}
          ></CourierStatusModal>
        )}
        <div className=".tenthStepTour"
          style={{
            padding: "0 30px 30px",
            minHeight: "calc(100vh - 123px)",
            marginTop: "66px",
          }}>

          <h5
            className="titleTable"
            style={{
              color: "#C4C4C4",
              width: "450px",
              fontSize: "10px",
              fontWeight: "800",
              marginLeft: 10,
              marginBottom: "0px",
            }}
          >
            Órdenes de compra : Vista {getProfileType()}
          </h5>
          {isMobileSizes && (
            <button
              style={{
                backgroundColor: "transparent",
                color: "black",
                width: "100%",
                padding: "20px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={showFiltersHandler}
            >
              <img src={iconFilterButton} width="15" />
              &nbsp;{showFilter ? "Ocultar Filtros" : "Mostrar Filtros"}
            </button>
          )}
          {showFilter && (
            <div id={filtersClass}>
              <Col md="12">
                <div className="secondStepTour">
                  <label htmlFor="select-country">
                    <h5
                      style={{
                        color: "black",
                        width: "30px",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "0px",
                      }}
                    >
                      País
                    </h5>
                    <Select
                      labelId="select-country"
                      id="select-country"
                      style={{
                        width: "193px",
                        height: "46px",
                        marginLeft: 10,
                        backgroundColor: "white",
                        borderRadius: "17px",
                        marginBottom: "1em",
                        marginTop: "1em"
                      }}
                      value={country}
                      label="Country"
                      placeholder="Seleccione un país"
                      onChange={handleCountryChange}
                    >
                      {Array.from(
                        new Set(filteredCountryData?.map((obj) => obj) || [])
                      ).map((period, i) => {
                        return (
                          <MenuItem
                            style={{
                              width: "193px",
                              height: "46px",
                              backgroundColor: "white",
                            }}
                            value={period.country}
                            key={i}
                          >
                            {period.country}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </label>
                  <label htmlFor="select-tienda">
                    <h5
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "0px",
                        marginTop: "1em",
                      }}
                    >
                      Tienda
                    </h5>
                    <Select
                      labelId="select-tienda"
                      id="select-tienda"
                      style={{
                        width: "193px",
                        height: "46px",
                        backgroundColor: "white",
                        borderRadius: "17px",
                        marginLeft: 10,
                        marginTop: "1em",
                      }}
                      value={store}
                      label="select-canal"
                      placeholder="Seleccione una tienda"
                      onChange={handleStoreChange}
                    >
                      {Array.from(
                        new Set(filteredStoreData.map((obj) => obj.store))
                      ).sort().map((period) => {
                        return <MenuItem value={period}>{period}</MenuItem>;
                      })}
                    </Select>
                  </label>
                  <label htmlFor="select-canal">
                    <h5
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "0px",
                        marginTop: "1em",
                      }}
                    >
                      Canal De Venta
                    </h5>

                    <Select
                      labelId="select-canal"
                      id="select-canal"
                      placeholder="Seleccione un canal"
                      style={{
                        width: "193px",
                        height: "46px",
                        backgroundColor: "white",
                        borderRadius: "17px",
                        marginLeft: 10,
                        marginTop: "1em",
                      }}
                      value={salesChannel}
                      label="select-canal"
                      onChange={handleSalesChannelChange}
                    >
                      {Array.from(
                        new Set(filteredChannelArray.map((obj) => obj.channel))
                      ).map((period) => {
                        return <MenuItem value={period}>{period}</MenuItem>;
                      })}
                    </Select>
                  </label>

                  <label className="seventhStepTour">
                    <h5
                      id="fechaDesde"
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "6px",
                        marginTop: "0px",
                      }}
                    >
                      Fecha Desde
                    </h5>

                    <DatePicker
                      id="datepickerCalendar"
                      type="number"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      style={{ width: 200, marginLeft: 10 }}
                      placeholderText="dd/mm/yy"
                      locale="es"
                    />
                  </label>

                  <label className="seventhStepTour">
                    <h5
                      id="fechaHasta"
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "6px",
                        marginTop: "0px",
                      }}
                    >
                      Fecha Hasta
                    </h5>

                    <DatePicker
                      id="datepickerCalendar"
                      type="number"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      style={{ width: 200, marginLeft: 10 }}
                      placeholderText="dd/mm/yy"
                      locale="es"
                    />
                  </label>

                  <label>
                    <h5
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "800",
                        marginLeft: 10,
                        marginBottom: "6px",
                        marginTop: "0px",
                      }}
                    >
                      Número de orden
                    </h5>

                    <input
                      type="text"
                      id="quantity"
                      name="quantity"
                      min="1"
                      style={{
                        width: "193px",
                        height: "46px",
                        backgroundColor: "white",
                        borderRadius: "17px",
                        marginLeft: 10,
                        border: "none",
                        outline: "none",
                      }}
                      placeholder="Digite el número de orden"
                      onChange={searchOrderIdHandler}
                    />
                  </label>

                  <Button
                    color="primary"
                    id="tableButtons"
                    style={{
                      borderRadius: "22px",
                      color: "#FFFFFF",
                      marginLeft: "3em",
                      textTransform: "none",
                      letterSpacing: "1px",
                      width: "120px",
                      height: "46px",
                      fontWeight: "600",
                    }}
                    className="thirdStepTour"
                    onClick={applyFiltersButtonhandler}
                  >
                    Aplicar
                  </Button>
                  {showErrorAlert && (
                    <ErrorAlert
                      ref={sectionRef}
                      errorAlertMessage={errorAlertMessage}
                      onClose={() => setshowErrorAlert(false)}
                    />
                  )}

                  <Button
                    id="tableButtons"
                    color="primary"
                    style={{
                      borderRadius: "22px",
                      color: "#FFFFFF",
                      marginLeft: 10,
                      textTransform: "none",
                      letterSpacing: "1px",
                      height: "46px",
                      fontWeight: "600",
                    }}
                    className="thirdStepTour"
                    onClick={DownloadFileHandler}
                  >
                    Descargar Excel
                  </Button>
                  <Button
                    id="tableButtons"
                    className="btn-round btn-icon fourthStepTour"
                    color="primary"
                    onClick={reloadTableHandler}
                  >
                    <i
                      className="nc-icon nc-refresh-69"
                      style={{ color: "#ffffff" }}
                    />
                  </Button>
                  <Button
                    id="tableButtons"
                    color="primary"
                    className="btn-round btn-icon ninthStepTour"
                    onClick={() => setIsTourOpen(true)}
                  >
                    <i
                      className="nc-icon nc-alert-circle-i"
                      style={{ color: "#ffffff" }}
                    />
                  </Button>
                </div>
              </Col>
            </div>
          )}

          <div className="firstStepTour">
            {/* MOBILE VERSION */}
            <div id="OrderMobileCard" className="first-step">
              <br />
              {!isLoading && (
                <div>
                  <OrderMobileCard
                    data={data}
                    isLoading={isLoading}
                    purchaser={buyer}
                  ></OrderMobileCard>
                </div>
              )}

              <br />
            </div>

            {/* DESKTOP VERSION */}
            
            <div id="OrderDesktopTable">
              {isLoading && (
                <MaterialTable
                  options={{
                    search: false,
                    exportButton: true,
                    tableLayout: "fixed",
                  }}
                  title=""
                  icons={tableIcons}
                  columns={columns}
                  data={[]}
                  components={{
                    Body: () => (
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "50%"
                        }}
                      >
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp; &nbsp; <br />
                        <div>
                          <br />
                        </div>
                        <br />
                      </div>
                    ),
                    emptyDataSourceMessage: (
                      <h1>No se encuentra la información.</h1>
                    ),
                  }}
                ></MaterialTable>
              )}
              {data.length === 0 && !isLoading && (
                <div className="no-data-found-container">
                  <div>
                    <img src={noOrdersDataImage} className={"no-results-image"} />
                  </div>
                  <span className="no-data-found-text">¡Narices...!.</span>
                  <span className="no-data-found-text">{!foundResultsInFirstLoad ? "Aún no tienes órdenes de compra" : "No se encontraron resultados"}</span>
                </div>
              )}
              {data?.length > 0 && isLoadingIncrementPage && (
                <SplashScreen message="nuevas órdenes" />
              )}
              {data?.length > 0 && !isLoading && !isLoadingIncrementPage && (
                <MaterialTable
                  onRowClick={(evt, selectedRow) => {
                    setbuyer(selectedRow);
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-start",
                            marginLeft: "20rem",
                            width: "100%",
                          }}
                        >
                          <img
                            src={noDataImage}
                            style={{ marginTop: "2em" }}
                            width="160"
                            alt="noData"
                          />
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "#051B2F",
                            }}
                          >
                            {" "}
                            &nbsp;
                            <span> No hay información disponible.</span>
                          </p>
                        </div>
                      ),
                    },
                  }}
                  key={data.id_mtdi}
                  icons={tableIcons}
                  title=""
                  data={data}
                  columns={columns}
                  options={{
                    columnsButton: true,
                    sorting: true,
                    search: false,
                    exportButton: true,
                    tableLayout: "fixed",
                    pageSize: rowsToSet,
                    pageSizeOptions: [5, 10, 20, 50],
                    maxBodyHeight,
                    minBodyHeight,
                    initialPage: pageToSet,
                    rowStyle: {
                      backgroundColor: 'white',
                      color: "black"
                    }
                  }}
                  style={{ marginLeft: 10, marginTop: "2em", color: "black !important" }}
                  components={{
                    Toolbar: props => (
                      <Toolbar
                        {...props}
                        configColumns={configColumns}
                        setConfigColumns={handleCheckbox}
                        showColumnList={showColumnList}
                        setShowColumnList={handleShowColumn}
                      />
                    ),
                    Pagination: (props) => (
                      <Pagination
                        props={props}
                        total={paginationOrders.total}
                        incrementPageHandler={() => incrementPageHandler()}
                        isLoading={isLoadingIncrementPage}
                        setPage={handleSetNewPage}
                        setRowsHandle={handleSetRows}
                        name="órdenes"
                        toUp={()=>{window.scrollTo(0, 0);}}
                      />
                    )
                  }}
                />
              )}
            </div>
          </div>
          <StepByStep
            navigator={navigator}
            setIsTourOpen={setIsTourOpen}
            toggle={toggle}
            isTourOpen={isTourOpen}
            disableBody={disableBody}
            enableBody={enableBody}
          ></StepByStep>
          <Modal isOpen={modalBallotDetails} toggle={toggle2} size="lg">
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={toggle2}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <BallotDetailModal rowOrder={buyer} label={false} hasToGroupByParentPromotion={true} />
          </Modal>

          {genEnviameLabel && (
            <GenerateEnviameLabel
              purchaser={buyer}
              getEnviameLabel={getEnviameLabel}
              setisGeneratingEnviameLabel={setisGeneratingEnviameLabel}
              EnviameLabelQuantity={EnviameLabelQuantity}
              isGeneratingEnviameLabel={isGeneratingEnviameLabel}
            />
          )}

          <Modal isOpen={labelDetailsModal} toggle={toggle4}>
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={toggle4}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <LabelModal purchaser={buyer} onhideModal={toggle4} />
            <br />
          </Modal>
          {/* CLIENT MODAL */}

          <Modal isOpen={clientDetailsModal} toggle={toggle5}>
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={toggle5}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <ClientModal
              onhideModal={hideClientModalHandler}
              purchaser={buyer}
              clientModal={clientModal}
            ></ClientModal>
            <br />
          </Modal>
          {/* REVIEWS MODAL */}

          <Modal isOpen={ReviewsModal} toggle={ReviewsModalHandler}>
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={ReviewsModalHandler}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <ReviewModal purchaser={buyer} />
            <div className="text-center">
              <button
                id="bttnSubmit"
                type="submit"
                style={{
                  backgroundColor: "#051B2F",
                  textAlign: "center",
                  width: "296px",
                  height: "64px",
                  padding: "22px 81px",
                  borderRadius: "33px",
                  color: "#FFFFFF",
                  marginLeft: 10,
                  textTransform: "none",
                  fontWeight: "bold",
                  border: "0",
                  marginTop: "1em",
                }}
                onClick={ReviewsModalHandler}
              >
                Cerrar
              </button>
            </div>
            <br />
          </Modal>
          {/* MANIFEST MODAL */}

          <Modal isOpen={manifestDetailModal} toggle={toggle6}>
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={toggle6}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <ManifestoModal onhideModal={toggle6} purchaser={buyer} />
            <br />
          </Modal>
          {/* COURIER STATUS MODAL */}

          <Modal isOpen={courierStatusDetailModal} toggle={toggle7}>
            <ModalHeader>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{
                    background: "none",
                    position: "relative",
                    marginLeft: "14em",
                    color: "black",
                    border: "none",
                  }}
                  onClick={toggle7}
                >
                  x
                </button>
              </div>
            </ModalHeader>
            <CourierStatusModal purchaser={buyer} onhideModal={toggle7} />
            <br />
          </Modal>
        </div>
        {showCopySuccessMsg ?
          <Snackbar  open={showCopySuccessMsg} onClose={() => setShowCopySuccessMsg(false)} autoHideDuration={3500} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert style={{ color: "#156118", fontFamily: "Montserrat", background: "white" }} severity="success" variant="outlined" >
              Nro de orden copiado al portapeles
            </Alert>
          </Snackbar>
        :
          null
        }
      </ErrorBoundary >
    </React.Fragment >
  );
};

export default MtdiTable;
