import ProfileHeader from "components/UI/ProfileHeader";
import React, { useEffect, useRef, useState } from "react";
import { Alert } from "reactstrap";
import { Modal, ModalHeader } from "reactstrap";
import { connectBilling } from "services/billings";
import classes from "./addFulfillmentForm.module.css";
import spinnerGif from "../../assets/img/spinnerLogos.gif";
import deleteImage from "../../assets/img/delete-image.png";
import { editBilling } from "services/billings";
import { deleteBilling } from "services/billings";
import { createWms } from "services/wms";
import { deleteWms } from "services/wms";
import { editWms } from "services/wms";

const AddFulfillmentForm = ({
  handleRoute,
  currentWMS,
  parameters,
  mode,
  myBillings,
  channelsWithoutWms,
  storeData,
  updateStoreData,
  OwnWms,
  countries,
  editInputData,
  allWmsConfiguration
}) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState({});
  const [inputs, setInputs] = useState([]);
  const [selectedWmsId, setselectedWmsId] = useState("");
  const [wmsLogo, setwmsLogo] = useState("");
  const [countriesBilling, setCountriesBilling] = useState([]);
  const currentChannel = useRef("");
  const currentEditChannel = useRef("");
  const [editData, seteditData] = useState([]);
  useEffect(() => {
  createInputs();
  }, []);


  const createInputs = async () => {
    
    const data = await parameters.find(
      (parameter) => parameter.name === currentWMS
    );
    setselectedWmsId(data.id);
    setCountriesBilling(data.paises);
    setwmsLogo(data.config_mtd.logo);
    setBilling(data);
      let config = data.config_mtd?.data;
      for (let i = 0; i < config.length; i++) {
        config[i].value = "";
     
      } 
      setInputs(config);
    
  };

  const handleInputsChange = (i, event) => {
    event.preventDefault();
    let auxArray = [...inputs];
    auxArray[i].value = event.target.value;
    setInputs(auxArray);
  };

  const handleChannel = (event) => {
    event.preventDefault();
    currentChannel.current = event.target.value;
    
  };
  const handleEditChannel = (event) => {
    event.preventDefault();
    currentEditChannel.current = event.target.value;
  
    let filteredWmsData = [];
    let wmsDataInputs = [];
   
    if (mode === "edit" && currentEditChannel.current !== "") {
    
      filteredWmsData = editInputData.channels.filter(
        (item) => item.channel_config.channelId === +currentEditChannel.current
      );
    
      let { _id, ...mics } = filteredWmsData[0].wms_data;
    
      wmsDataInputs = mics;

     
    }

    let auxInput = inputs;
    let arr3 = auxInput.map((item, i) => Object.assign({}, item, wmsDataInputs[i]));
 
    let resultArray = [];
    for (let i = 0; i <= auxInput.length - 1; i++) {
      for(let key in wmsDataInputs){
      
        if(key  === auxInput[i].name){
          resultArray.push([{
            name: auxInput[i].name,
            value: wmsDataInputs[key],
            required: auxInput[i].required,
          
          }
          
          ]);
        }
    }
  
    }
    var flattened = [].concat.apply([], resultArray);
    setInputs(flattened);
  };

  const handleSubmit = async (event) => {
    if (currentChannel.current === "") {
      return;
    }
    event.preventDefault();
    setLoading(true);
    const body = inputs.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
      {}
    );
   

    body.channel = parseInt(currentChannel.current);
   
    let WmsConnectionData = [];
    WmsConnectionData = [
      {
        channel: body.channel,
        paises: countries,
        ...body,
        wms: {
          wmsCompanyId: selectedWmsId,
          wmsInstance: false,
        },
      },
    ];
  
    const response = await createWms(storeData.id, WmsConnectionData);
    if (response.results[0]?.result === "Success") {
      setSuccess(true);
    }
    setLoading(false);
  };

  const handleSubmitEdit = async (event) => {
    const body = inputs.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
      {}
    );
    let bodyValues = Object.values(body);

    if (
      currentEditChannel.current === "" 
    ) {
     
      return;
    }
    event.preventDefault();
    setLoading(true);
    body.channel = parseInt(currentEditChannel.current);

    let WmsEditData = [
      {
        channel: body.channel,
        paises: countries,
        ...body,
        wms: {
          wmsCompanyId: selectedWmsId,
          wmsInstance: false,
        },
      },
    ];
   

    const response = await editWms(storeData.id, WmsEditData);
   
    if (response.message.results[0]?.result === "Success") {
      setSuccess(true);
    }
    setLoading(false);
  };

  const handleSubmitDelete = async (event) => {
    if (currentEditChannel.current === "") {
      return;
    }
    event.preventDefault();
    setLoading(true);

    const response = await deleteWms(storeData.id, currentEditChannel.current);
  
    if (response === "Store config wms delete") {
      setSuccess(true);
    }
    setLoading(false);
  };

  const handleModalConfirm = () =>
    setShowModalConfirm((prevState) => !prevState);
  const handleModalConfirmDelete = () =>
    setShowModalConfirmDelete((prevState) => !prevState);

  return (
    <>
      {inputs.length > 0 && (
        <div className={classes.wrapper}>
          <ProfileHeader
            backTo={() => handleRoute(mode === "edit" ? "current" : "add")}
          >
            {billing.name?.charAt(0).toUpperCase() + billing.name?.slice(1)}
          </ProfileHeader>
          {/* {mode === "edit" && (
            <Alert
              color="danger"
              style={{
                backgroundColor: "rgba(255, 96, 89, 0.1)",
                color: "rgba(205, 56, 56, 0.97)",
              }}
            >
              <h6>Ten en cuenta: </h6>
              Por seguridad, no te mostraremos los datos que actualmente tienes
              en esta conexión. Recuerda que si editas estos campos se cambiará
              tu configuración.
            </Alert>
          )} */}
          <div className={classes.inputLabel}>
            <label className={classes.label}>Elige un canal de venta</label>
          {mode === 'connect' &&  <select
              name="channel"
             
              onChange={(choice) => handleChannel(choice)}
              className={classes.select}
            >
              <option value="">Canales disponibles</option>
             
              {mode === "connect" &&
            
            channelsWithoutWms.map((channel, i) => (
               
                  <option key={i} value={channel.channelId}>
                    {channel.channelName}
                  </option>
                ))}
            </select>}
            {mode === 'edit' &&  <select
              name="channel"
             
              onChange={(choice) => handleEditChannel(choice)}
              className={classes.select}
            >
              <option value="">Canales disponibles</option>
              {mode === "edit" &&
                OwnWms.filter((b) => b.wmsCompanyName === currentWMS).map(
                  (channel, i) => (
                    <option key={i} value={channel.channelId}>
                      {channel.channelName}
                    </option>
                  )
                )}
              {mode === "connect" &&
            
            channelsWithoutWms.map((channel, i) => (
               
                  <option key={i} value={channel.channelId}>
                    {channel.channelName}
                  </option>
                ))}
            </select>}
          </div>
          <div className={classes.inputs}>
            {inputs.map((item, i) => (
              <div className={classes.inputLabel} key={i}>
                <label className={classes.label}>{item.name}</label>
               {mode ==='connect' && <input
                  type="text"
                  name={item.name}
                  value={inputs[i].value}
                  className={classes.input}
                  onChange={(event) => handleInputsChange(i, event)}
                />} 
                {mode === 'edit' &&
                 <input
                 type="text"
                 name={item.name}
                 value={inputs[i].value}
                 className={classes.input}
                 onChange={(event) => handleInputsChange(i, event)}
               />
                }
              </div>
            ))}
          </div>
          {mode === "connect" && (
            <button className={classes.button} onClick={handleModalConfirm}>
              <span className={classes.buttonText}>
                Conectar con{" "}
                {billing.name?.charAt(0).toUpperCase() + billing.name?.slice(1)}
              </span>
            </button>
          )}
          {mode === "edit" && (
            <>
              <button className={classes.button} onClick={handleModalConfirm}>
                <span className={classes.buttonText}>Guardar Cambios</span>
              </button>
              <button
                className={classes.buttonDelete}
                onClick={handleModalConfirmDelete}
              >
                <img src={deleteImage} /> &nbsp;
                <span className={classes.buttonTextDelete}>
                  Eliminar este WMS de manera definitiva
                </span>
              </button>
            </>
          )}
        </div>
      )}
      {showModalConfirm && (
        <Modal isOpen={showModalConfirm} toggle={handleModalConfirm} size="lg">
          <ModalHeader>
            <div className={classes.modalHeader}>
              <button
                className={classes.modalExitButton}
                onClick={handleModalConfirm}
              >
                x
              </button>
            </div>
          </ModalHeader>
          {loading && (
            <div className={classes.spinner}>
              <img src={spinnerGif} className={classes.imgSpinner} />
            </div>
          )}
          {!loading && (
            <div className={classes.wrapperModal}>
              {mode === "edit" && (
                <h3 className={classes.textModal}>
                  {success
                    ? "Wms editada correctamente"
                    : "¿Está seguro que desea editar esta Wms?"}
                </h3>
              )}
              {mode === "connect" && (
                <div>
                  <img src={wmsLogo} style={{ width: "150px" }} />
                  <h3 className={classes.textModal}>
                    {success
                      ? "WMS Integrado correctamente."
                      : `Vas a realizar una integración  con ${currentWMS}
¿Deseas continuar?`}
                  </h3>
                </div>
              )}
              {success ? (
                <button
                  className={classes.button}
                  onClick={() => {
                    handleModalConfirm();
                    handleRoute("current");
                    updateStoreData(storeData.id);
                  }}
                >
                  <span className={classes.buttonText}>Entendido</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className={classes.button}
                  onClick={mode === "edit" ? handleSubmitEdit : handleSubmit}
                >
                  <span className={classes.buttonText}>Si, seguro</span>
                </button>
              )}
              {!success && (
                <span className={classes.textBack} onClick={handleModalConfirm}>
                  No, quiero volver atrás
                </span>
              )}
            </div>
          )}
        </Modal>
      )}
      {showModalConfirmDelete && (
        <Modal
          isOpen={showModalConfirmDelete}
          toggle={handleModalConfirmDelete}
          size="lg"
        >
          <ModalHeader>
            <div className={classes.modalHeader}>
              <button
                className={classes.modalExitButton}
                onClick={handleModalConfirmDelete}
              >
                x
              </button>
            </div>
          </ModalHeader>
          {loading && (
            <div className={classes.spinner}>
              <img src={spinnerGif} className={classes.imgSpinner} />
            </div>
          )}
          {!loading && (
            <div className={classes.wrapperModal}>
              <p className={classes.textConfirm}>
                {success
                  ? "Se ha eliminado correctamente"
                  : "¿Estas seguro que deseas eliminar esta Wms?"}
              </p>
              {success ? (
                <button
                  className={classes.button}
                  onClick={() => {
                    handleModalConfirmDelete();
                    handleRoute("current");
                    updateStoreData(storeData.id);
                  }}
                >
                  <span className={classes.buttonText}>
                    Regresar a mi panel
                  </span>
                </button>
              ) : (
                <button
                  type="submit"
                  className={classes.button}
                  onClick={handleSubmitDelete}
                >
                  <span className={classes.buttonText}>Eliminar WMS</span>
                </button>
              )}
              {!success && (
                <span
                  className={classes.textBack}
                  onClick={handleModalConfirmDelete}
                >
                  No, quiero volver atrás
                </span>
              )}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddFulfillmentForm;
