//libraries
import React, { useState, useEffect, useRef } from "react";

//components
import SplashScreen from "components/UI/splash-screen";
import CreateStoreFirstModal from "./CreateStoreFirstModal";
import CreateStoreSecondModal from "./CreateStoreSecondModal";
import CreateStoreThirdModal from "./CreateStoreThirdModal";
import CardStore from "./CardStore";
import CountryCircles from "./CountryCircles";
import Swal from 'sweetalert2'
import Grid from '@mui/material/Grid'
import { Button, Skeleton, Card, CardContent, useMediaQuery, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import arrowSelect from '../../../../assets/img/Despliegue.png'
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import atras from "../../../../assets/img/icons/despliegueAtras.png"
import CreateStoreMobile from "./CreateStoreMobile";

//images
import addStore from "../../../../assets/img/icons/addStore.png";
import Error from '../../../../assets/img/icons/error.png'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import pendiente from '../../../../assets/img/onboarding/Pendiente.png'
import Close from "../../../../assets/img/icons/close_black.png"
import lupa from "../../../../assets/img/icons/lupa.png"
import { channelImports } from '../../../../utils/channelsImagesImport';
import confirmation from '../../../../assets/img/onboarding/Validacion.png'
//styles
import classes from "./displayEditClientStores.module.css";

//services
import { getStores, postClientStore, putClientStore, postWelcomeEmail } from "services/stores";

const DisplayClientStores = (props) => {

  const [mainContainerHeight, setMainContainerHeight] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mainContainerRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const totalCards = 12;
  const userStoreAux = [1, 2, 3, 4]

  const [UserStoreList, setUserStoreList] = useState([]);
  const [allStores, setAllStores] = useState(null)
  const [StoreArrays, setStoreArrays] = useState([]);
  const [StoreArraysOriginal, setStoreArraysOriginal] = useState([]);
  const [StoreArraysFiltered, setStoreArraysFiltered] = useState([]);

  const [page, setPage] = useState(1);

  const [openFirstModal, setOpenFirstModal] = useState(false);
  const toggleFirstModal = () => setOpenFirstModal(!openFirstModal);

  const [openMobileModal, setOpenMobileModal] = useState(false);
  const toggleMobilModal = () => setOpenMobileModal(!openMobileModal);

  const [displayModalSecond, setDisplayModalSecond] = useState(false);
  const toggleSecondModal = () => setDisplayModalSecond(!displayModalSecond);

  const [displayModalThird, setDisplayModalThird] = useState(false);
  const toggleThirdModal = () => setDisplayModalThird(!displayModalThird);

  const [countryFilter, setcountryFilter] = useState(null)
  const [countrySelected, setCountrySelected] = useState(null)
  const [isLoading, setisLoading] = useState(false);
  const [listOfChannels, setListOfChannels] = useState([])
  const [contratoCliente, setcontratoCliente] = useState(null)
  const [loadingClient, setLoadingClient] = useState(false)
  const [modalTittle, setModalTittle] = useState(null)

  const [showAlert, setshowAlert] = useState(false)
  const [alertText, setAlertText] = useState("")


  const [cliente, setCliente] = useState({
    name: '',
    namerlegal: '',
    email: '',
    phone: '',
    address: '',
    identity: '',
    social_reason: '',
    type_of_selling: '',
    model: '',
    kam_selected: '',
    client_country: countrySelected,
    facturacion: false,
    bodega: false,
    canales: false,
    servicio: false,
    channelsOnboarding: []
  });

  useEffect(async () => {
    await getUsersStores();
    const data = await channelImports()
    setListOfChannels(data);

    const updateMainContainerHeight = () => {
      if (mainContainerRef.current) {
        const height = mainContainerRef.current.offsetHeight;
        setMainContainerHeight(height);
        setWindowWidth(window.innerWidth);
        console.log(window.innerWidth);
      }
    };

    updateMainContainerHeight();
    window.addEventListener('resize', updateMainContainerHeight);

    return () => {
      window.removeEventListener('resize', updateMainContainerHeight);
    };
  }, []);

  useEffect(() => {
    if (countryFilter === 0) {
      setStoreArrays(StoreArraysOriginal)
      setUserStoreList(StoreArraysOriginal[0])
      setStoreArraysFiltered([])
    }
    else {
      let newArrayStores = StoreArraysOriginal.flatMap(arreglo => arreglo.filter(objeto => objeto.country[0] === countryFilter))
      const grupos = newArrayStores.length > 0 ? agrupateArrays(newArrayStores) : []
      setStoreArrays(grupos)
      setUserStoreList(grupos.length > 0 ? grupos[0] : [])
      setStoreArraysFiltered(grupos)

      setPage(1)
    }
  }, [countryFilter]);

  useEffect(() => {
    if (StoreArrays.length) {
      setUserStoreList(StoreArrays[page - 1])
    }
  }, [page]);

  useEffect(() => {
    setCliente({ ...cliente, client_country: countrySelected });
  }, [countrySelected]);

  const createAlert = (text) => {
    setshowAlert(true)
    setAlertText(text)
    setisLoading(true)
    getUsersStores();
  }

  // Función para manejar el todos los datos
  const resetValues = () => {
    setCliente({
      name: '',
      namerlegal: '',
      email: '',
      phone: '',
      address: '',
      identity: '',
      social_reason: '',
      type_of_selling: '',
      model: '',
      kam_selected: '',
      client_country: null,
      facturacion: false,
      bodega: false,
      canales: false,
      servicio: false,
      channelsOnboarding: []
    })
    setCountrySelected(null);
    setcontratoCliente(null)
  };

  const getUsersStores = async () => {
    setisLoading(true);
    try {
      let userEmail = localStorage.getItem("name");
      const response = await getStores(userEmail);
      const grupos = agrupateArrays(response.message)
      setAllStores(response.message)
      setStoreArrays(grupos)
      setStoreArraysOriginal(grupos)
      setUserStoreList(grupos[0]);

      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const setContrat = (file) => {
    setcontratoCliente(file)
  }

  const agrupateArrays = (stores) => {
    const grupos = [];
    if (window.innerWidth < 600)
      return [stores]



    for (let i = 0; i < stores.length; i += totalCards) {
      grupos.push(stores.slice(i, i + totalCards));
    }
    return grupos
  }

  const getStoreDetailsHandler = (item) => {
    props.displayStoreDetailHandler(true);
    props.getStoreDetail(item);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setLoadingClient(true)
      let text = ""
      let dataResult = {}

      if (modalTittle === "Editar Cliente") {
        cliente.contrat = null
        dataResult = await putClientStore(cliente)
        text = "Woow, eres un editor/a nato"
      } else {
        cliente.contrat = await fileToBase64(contratoCliente)
        dataResult = await postClientStore(cliente)
        text = "¡Claro que sí! Cliente creado con super super éxito"
      }
      console.log(dataResult.status)
      if (dataResult.status === 200) {
        if (modalTittle !== "Editar Cliente")
          await sendWelcomeMailModal(dataResult.data.message.store.id)

        setLoadingClient(false)
        resetValues()

        if (isMobile)
          toggleMobilModal()
        else
          toggleThirdModal()

        createAlert(text)
      } else {
        await errorModalPost()
      }
    } catch (error) {
      await errorModalPost()
    }


  };

  const sendWelcomeMailModal = async (storeId) => {
    Swal.fire({
      title: 'Estas a punto de...',
      html: 'enviar el correo de bienvenida<br>a este nuevo cliente',
      imageWidth: 150,
      imageHeight: 150,
      imageUrl: pendiente,
      confirmButtonText: "¡Entendido!",
      closeButtonHtml: `<img src="${Close}" />`
    }).then(() => {
      window.location.href = window.location.origin + '/admin/clientProfile?storeId=' + storeId + '&onboarding=true';
    })

  }

  const errorModalPost = async () => {
    Swal.fire({
      title: 'Error..',
      html: `Ocurrió un error inesperado...<br>¡Intenta nuevamente<br> más tarde!`,
      imageWidth: 150,
      imageHeight: 150,
      imageUrl: Error,
      showCloseButton: true,
      confirmButtonText: "Cerrar",
      closeButtonHtml: `<img src="${Close}" />`
    }).then(() => {
      setLoadingClient(false)
    })
  }

  const closeConfirmationModal = async () => {
    return new Promise(function (resolve) {
      Swal.fire({
        title: '¿ Seguro que...',
        html: "quieres salir sin nada de <br> lo que avanzaste se guarde?",
        imageWidth: 150,
        imageHeight: 150,
        imageUrl: confirmation,
        confirmButtonText: 'Continuar',
        showDenyButton: true,
        denyButtonText: `Salir sin guardar`,
        customClass: {
          actions: 'action-close-modal',
        },
        allowOutsideClick: false

      }).then(async (result) => {
        resolve(!result.isConfirmed)
      })
    })
  }

  


  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function () {
        resolve(reader.result);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleNewClient = async () => {
    setModalTittle("Nuevo Cliente")
    await postClientStore();
    if (isMobile) {
      toggleMobilModal()
    } else {
      toggleFirstModal()
    }

  }

  const handleChangeSearch = (e) => {
    const arrayByFilter = StoreArraysFiltered.length > 0 ? StoreArraysFiltered : StoreArraysOriginal
    let newArrayStores = arrayByFilter.flatMap(arreglo => arreglo.filter(objeto => objeto.tienda.toLowerCase().startsWith(e.target.value.toLowerCase())))
    const grupos = newArrayStores.length > 0 ? agrupateArrays(newArrayStores) : []
    setStoreArrays(grupos)
    setUserStoreList(grupos.length > 0 ? grupos[0] : [])
    setPage(1)

  }

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setshowAlert(false);
      }, 4500);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  return (
    <>
      {showAlert &&
        <Alert
          icon={false}
          sx={{
            zIndex: 2,
            fontFamily: 'Montserrat',
            mt: "-69px",
            ml: "45%",
            borderRadius: "10px !important",
            color: "#22BB3D",
            backgroundColor: "#E9F9EC",
            position: 'absolute',
            minHeight: "80px",
            paddingTop: "10px",
            minWidth: "540px",
            fontSize: "20px"
          }}
          action={
            <CloseIcon
              sx={{
                fontSize: "27px",
                color: "#24BB3E",
                cursor: "pointer",
                marginTop: "12px"
              }}
              onClick={() => {
                setshowAlert(false);
              }} />
          }
        >
          <b>{alertText}</b>
          <div
            style={{
              clipPath: 'polygon(17px 0px, 100% 0px, 98% 100%, 30% 100%, 50% 80%, 90% 100%, 23px 100%)',
              position: 'absolute',
              bottom: '0px',
              left: -17,
              right: 0,
              height: '12%',
              backgroundColor: '#24BB3E',
            }}
          />
        </Alert>
      }

      {isMobile ?
        <>
          <Box display={"flex"} style={{ marginBottom: 102 }} sx={{ "flexDirection": "column" }} >
            <Box sx={{ display: 'flex', mb: 3 }}>
              <Tooltip title="Volver" arrow>

                <IconButton onClick={() => { location.reload() }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    }, mt: "-4px", mr: 1

                  }}>
                  <img src={atras} alt="atras" width={"10px"} />
                </IconButton>
              </Tooltip>
              <Typography fontFamily='Montserrat' variant="h5"><b>Clientes</b></Typography>
            </Box>

            {!isLoading ?
              <FormControl variant="standard" fullWidth>
                <InputBase sx={{
                  pl: "10px",
                  maxHeight: "40px",

                }}

                  id="buscar" name="buscar"
                  startAdornment={<div style={{ "marginRight": "10px", marginBottom: "5px" }}><img src={lupa} alt="buscar" /></div>}
                  placeholder="Busqueda de clientes..." onChange={handleChangeSearch} />
              </FormControl> :
              <Skeleton variant="rectangular" height={30} width={"100%"} sx={{ borderRadius: 2.5 }} />
            }

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "20px", mb: "20px" }}>
              {!isLoading ?

                <CountryCircles setCountry={setcountryFilter} filterCountry={true} />
                :
                <Box sx={{ '& > :not(style)': { m: 1 }, display: "flex" }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                </Box>
              }
            </Box>
            <Grid container sx={{ '& > :not(style)': { mt: 1, mb: 1 }, pb: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {
                !isLoading ?

                  UserStoreList.map((item) => (
                    <Grid item xs={6} lg={6} md={6} xl={4} key={item.id}>
                      <CardStore key={item.id}
                        client={item}
                        getStoreDetailsHandler={getStoreDetailsHandler}
                        toggleFirstModal={toggleMobilModal}
                        setCliente={setCliente}
                        setModalTittle={setModalTittle}
                        createAlert={createAlert}
                      >
                      </CardStore>
                    </Grid>

                  )) :
                  userStoreAux.map((item) => (
                    <Grid item xs={6} lg={6} md={12} xl={4} key={item} >

                      <Card
                        sx={{ margin: "10px", height: "88px" }}
                      >
                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                          <Skeleton variant="rectangular" width={150} height={30} sx={{ borderRadius: 2.5 }} />

                          <Skeleton variant="circular" width={40} height={40} sx={{ borderRadius: 4 }} />
                        </CardContent>
                      </Card>

                    </Grid>
                  ))

              }
            </Grid>

          </Box>
          <Box
            sx={{
              position: 'fixed', // Cambiar a 'fixed' para que el componente permanezca fijo en la ventana
              left: '50%', transform: 'translateX(-50%)',
              bottom: 50,
              pb: '30px',
              zIndex: 1,
            }
            }
          >
            {!isLoading ?
              <Button variant="contained" onClick={handleNewClient} sx={{ pt: "15%", pb: "15%", fontSize: "15px", fontWeight: "600" }} > <img src={addStore} style={{ marginRight: 5 }} /> Nuevo Cliente </Button>
              :
              <Skeleton variant="rectangular" width={'70%'} height={40} sx={{ borderRadius: 2.5 }} />
            }
          </Box>
        </>

        :

        <div ref={mainContainerRef} >

          <div className={classes.myStoresHeader}>

            <Grid container >

              <Grid item xl={2.5} lg={3} md={6} xs={12} sx={{ display: 'flex', alignItems: 'center' }} >
                <div>
                  <span className={classes.textTitleHeader}> Clientes </span>
                </div>
              </Grid>

              <Grid item xl={2.5} lg={2} md={6} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {!isLoading ?
                  <FormControl variant="standard" >
                    <InputBase sx={{
                      pl: "10px",
                      maxHeight: "40px"

                    }}

                      id="buscar" name="buscar"
                      startAdornment={<div style={{ "marginRight": "10px", marginBottom: "5px" }}><img src={lupa} alt="buscar" /></div>}
                      placeholder="Nombre del cliente" onChange={handleChangeSearch} />
                  </FormControl> :
                  <Skeleton variant="rectangular" height={30} width={"70%"} sx={{ borderRadius: 2.5 }} />
                }

              </Grid>
              <Grid item xl={3} lg={3} md={6} xs={12} sx={{ zIndex: 1, mt: -1, display: 'flex', justifyContent: 'flex-end' }} >
                {!isLoading ?
                  <CountryCircles setCountry={setcountryFilter} filterCountry={true} />
                  :
                  <Box sx={{ '& > :not(style)': { m: 1 }, display: "flex" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton variant="circular" width={25} height={25} />
                  </Box>
                }



              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                {!isLoading ?
                  <Button variant="contained" onClick={handleNewClient}> <b> <img src={addStore} style={{ marginRight: 5 }} /> Nuevo Cliente</b></Button>
                  :
                  <Skeleton variant="rectangular" width={'70%'} height={40} sx={{ borderRadius: 2.5 }} />
                }

              </Grid>
            </Grid>
          </div>
          <Grid container sx={{ '& > :not(style)': { mt: 1, mb: 1 } }}>

            {
              !isLoading ?

                UserStoreList.map((item) => (
                  <Grid item xs={6} lg={4} md={4} xl={4} key={item.id}>
                    <CardStore key={item.id}
                      client={item}
                      getStoreDetailsHandler={getStoreDetailsHandler}
                      toggleFirstModal={toggleFirstModal}
                      setCliente={setCliente}
                      setModalTittle={setModalTittle}
                      createAlert={createAlert}
                      windowWidth={windowWidth}
                    >
                    </CardStore>
                  </Grid>

                )) :
                userStoreAux.map((item) => (
                  <Grid item xs={6} lg={6} md={12} xl={4} key={item} >

                    <Card
                      sx={{ margin: "10px", height: "88px" }}
                    >
                      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <Skeleton variant="rectangular" width={150} height={30} sx={{ borderRadius: 2.5 }} />

                        <Skeleton variant="circular" width={40} height={40} sx={{ borderRadius: 4 }} />
                      </CardContent>
                    </Card>

                  </Grid>
                ))

            }
          </Grid >


          <div className={classes.paginationWrapper}>
            {!isLoading && mainContainerHeight !== null ? (
              <Paginacion total={StoreArrays.length} page={page} setPage={setPage} />
            ) : (
              <Box sx={{ mt: '20px', display: 'flex', justifyContent: 'center' }}>
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={30}
                  sx={{ borderRadius: 2.5 }}
                />
              </Box>
            )}
          </div>

        </div>
      }



      {
        openFirstModal &&
        <CreateStoreFirstModal
          openFirstModal={openFirstModal}
          toggleFirstModal={toggleFirstModal}
          setCountry={setCountrySelected}
          setCliente={setCliente}
          toggleSecondModal={toggleSecondModal}
          clientData={cliente}
          resetValues={resetValues}
          modalTittle={modalTittle}
          allStores={allStores}
          closeConfirmationModal={closeConfirmationModal}
        ></CreateStoreFirstModal>
      }

      {
        displayModalSecond &&
        <CreateStoreSecondModal
          displayModalSecond={displayModalSecond}
          toggleSecondModal={toggleSecondModal}
          setCliente={setCliente}
          toggleFirstModal={toggleFirstModal}
          toggleThirdModal={toggleThirdModal}
          resetValues={resetValues}
          clientData={cliente}
          setContrat={setContrat}
          contratoCliente={contratoCliente}
          modalTittle={modalTittle}
          closeConfirmationModal={closeConfirmationModal}
        ></CreateStoreSecondModal>
      }

      {
        displayModalThird &&
        <CreateStoreThirdModal
          displayModalThird={displayModalThird}
          toggleThirdModal={toggleThirdModal}
          toggleSecondModal={toggleSecondModal}
          cliente={cliente}
          setCliente={setCliente}
          listOfChannels={listOfChannels}
          resetValues={resetValues}
          handleSubmit={handleSubmit}
          modalTittle={modalTittle}
          loadingClient={loadingClient}
          closeConfirmationModal={closeConfirmationModal}
        ></CreateStoreThirdModal>
      }
      {
        openMobileModal &&
        <CreateStoreMobile
          openMobileModal={openMobileModal}
          toggleMobilModal={toggleMobilModal}
          setCountry={setCountrySelected}
          setCliente={setCliente}
          clientData={cliente}
          resetValues={resetValues}
          modalTittle={modalTittle}
          allStores={allStores}
          setContrat={setContrat}
          listOfChannels={listOfChannels}
          loadingClient={loadingClient}
          contratoCliente={contratoCliente}
          handleSubmitFinal={handleSubmit}
        />
      }



    </>
  );
};

const Paginacion = ({ page, setPage, total }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <div
        className={classes.iconWrapper}
        onClick={() => {
          if (page - 1 > 0) {
            setPage(page - 1);
          }
        }}
      >
        <img src={arrowSelect} style={{ transform: 'rotate(90deg)', height: '45%' }} />
      </div>
      <span className={classes.text}>
        Página {page} de {total}
      </span>
      <div
        className={classes.iconWrapper}
        onClick={() => {
          if (page < total) {
            setPage(page + 1);
          }
        }}
      >
        <img src={arrowSelect} style={{ transform: 'rotate(270deg)', height: '45%' }} />
      </div>
    </Box>
  );
};


export default DisplayClientStores;
