import React from 'react'

import { Modal, Table } from 'reactstrap'

import classes from './DownloadCatalogModal.module.css'

import DownloadCatalog from './DownloadCatalog'

import Close from "../../assets/img/icons/close_black.png"

const DownloadCatalogModal = ({ showModal, handleToggle, stores, refreshCatalogDownload, setRefreshCatalogDownload }) => {

  return (
    <Modal className={classes.modalWrapperModal + " forModalBorderRadius"} isOpen={showModal} toggle={handleToggle} size="lg">
        <div className={classes.modalWrapper}>
          <div className={classes.xButtonWrapper} onClick={handleToggle}>
                <img className={classes.xButton} src={Close} />
          </div>
           <DownloadCatalog 
            stores={stores}
            refreshCatalogDownload={refreshCatalogDownload}
            setRefreshCatalogDownload={setRefreshCatalogDownload}
          />
        </div>
    </Modal>
  )
}

export default DownloadCatalogModal