import React, { useEffect, useState } from 'react'
import { getBillingsParameters } from 'services/billings'
import AddBillingForm from './AddBillingForm'
import AddBillings from './AddBillings'
import BsaleBilling from './BsaleBilling'
import CurrentBackOffice from './CurrentBackoffice'
import InstanceBilling from './InstanceBilling'

const AdminBackoffice = ({fetchStoreDetails, storeData, backToStoreDetail}) => {

    const [routeBackoffice, setRouteBackoffice] = useState('current')
    const [parameters, setParameters] = useState([])
    const [loading, setLoading] = useState(true)
    const [myBillings, setMyBillings] =  useState(false)
    const [myNotBillings, setMyNotBillings] =  useState(false)
    const [countries, setCountries] = useState([])
    const [currentBilling, setCurrentBilling] = useState(null)

    useEffect(() => {
      getMyBillings(storeData)
      getParameters()
    }, [])

    const getMyBillings = (data) => {
        let auxArray1 = []
        let auxArray2 = []
        let auxCountries = []
        data.channels.map( (channel) => {
            if ( channel.channel_config.billing?.hasOwnProperty("billingCompanyId") && channel.channel_config?.habilitado) {
                if (channel.channel_config?.billing?.facturaInstance === true){
                    auxArray1.push({
                        channelId: channel.channel_config?.channelId,
                        channelName: channel.channel_config?.channelName,
                        billingCompanyId: channel.channel_config?.billing?.billingCompanyId,
                        billing: "Instance"
                    }) 
                } else if (!channel.channel_config?.billing?.facturaInstance && channel.channel_config?.billing?.billingCompanyId === 1) 
                {
                    auxArray1.push({
                        channelId: channel.channel_config?.channelId,
                        channelName: channel.channel_config?.channelName,
                        billingCompanyId: channel.channel_config?.billing?.billingCompanyId,
                        billing: "Bsale"
                    }) 
                }  else {
                    auxArray1.push({
                        channelId: channel.channel_config?.channelId,
                        channelName: channel.channel_config?.channelName,
                        billingCompanyId: channel.channel_config?.billing?.billingCompanyId
                    })
                }
            } else if (channel.channel_config?.habilitado) {
                if (channel.channel_config?.paises.length > 1) {
                    auxCountries.concat(channel.channel_config?.paises)
                } else {
                    auxCountries.push(channel.channel_config?.paises[0])
                }
                auxArray2.push({
                    channelId: channel.channel_config?.channelId,
                    channelName: channel.channel_config?.channelName,
                    paises: channel.channel_config?.paises
                }) 
            }
        })
        setMyBillings(auxArray1)
        setMyNotBillings(auxArray2)
        setCountries(auxCountries)
    }

    const updateStoreData = async (id) => {
        setLoading(true)
        const response = await fetchStoreDetails(id)
        getMyBillings(response)
        setLoading(false)
    }

    const getParameters = async () =>{
        const initialParameters = await getBillingsParameters()
        const enabledParameters = await initialParameters.filter((b) => b.config_mtd?.enabled === true)
        setParameters(enabledParameters)
        setLoading(false)
    }
    

    const handleRoute = (route) => setRouteBackoffice(route)

    const handleCurrent = (id) => setCurrentBilling(id)

  return (
    <>
        {routeBackoffice === 'current' &&   
            <CurrentBackOffice 
                loading={loading}
                handleCurrent={handleCurrent}
                handleRoute={handleRoute}
                backToStoreDetail={backToStoreDetail}
                myBillings={myBillings}
                parameters={parameters}
            />
        }
        {routeBackoffice === 'add' &&   
            <AddBillings 
                countries={countries}
                handleRoute={handleRoute}
                myBillings={myBillings}
                parameters={parameters}
                myNotBillings={myNotBillings}
                handleCurrent={handleCurrent}
            />
        }
        {routeBackoffice === 'instance' &&   
            <InstanceBilling 
                handleRoute={handleRoute}
                myBillings={myBillings}
                myNotBillings={myNotBillings}
                storeData={storeData}
                updateStoreData={updateStoreData}
                mode="connect"
            />
        }
        {routeBackoffice === 'editinstance' &&   
            <InstanceBilling 
                handleRoute={handleRoute}
                myNotBillings={myNotBillings}
                myBillings={myBillings}
                storeData={storeData}
                updateStoreData={updateStoreData}
                mode="delete"
            />
        }
        {routeBackoffice === 'bsale' &&   
            <BsaleBilling 
                handleRoute={handleRoute}
                storeData={storeData}
                myBillings={myBillings}
                myNotBillings={myNotBillings}
                updateStoreData={updateStoreData}
                mode="connect"
            />
        }
        {routeBackoffice === 'editbsale' &&   
            <BsaleBilling 
                handleRoute={handleRoute}
                storeData={storeData}
                myBillings={myBillings}
                myNotBillings={myNotBillings}
                updateStoreData={updateStoreData}
                mode="edit"
            />
        }
        {routeBackoffice === 'addform' &&   
            <AddBillingForm 
                currentBilling={currentBilling}
                handleRoute={handleRoute}
                storeData={storeData}
                parameters={parameters}
                myBillings={myBillings}
                myNotBillings={myNotBillings}
                updateStoreData={updateStoreData}
                mode="connect"
            />
        }
        {routeBackoffice === 'editform' &&   
            <AddBillingForm 
                currentBilling={currentBilling}
                handleRoute={handleRoute}
                storeData={storeData}
                parameters={parameters}
                myBillings={myBillings}
                myNotBillings={myNotBillings}
                updateStoreData={updateStoreData}
                mode="edit"
            />
        }
    </>
  )
}

export default AdminBackoffice