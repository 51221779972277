import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getFilters, getProducts } from 'services/products'
import classes from './ProductsList.module.css'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ProgressBar from './ProgressBar';
import spinnerGif from "../../assets/img/spinnerLogos.gif"
import check from "../../assets/img/check.png"
import noCheck from "../../assets/img/no-check.png"
import productsError from "../../assets/img/products-error.png"
import iconSearch from "../../assets/img/icons/iconSearch.svg"
import proximamente from "../../assets/img/proximamente.png"
import ProductImages from './ProductImages'
import iconArrowLeft from "../../assets/img/icons/iconArrowLeft.svg"
import iconArrowRight from "../../assets/img/icons/iconArrowRight.svg"
import iconArrowLeftDisabled from "../../assets/img/icons/iconArrowLeftDisabled.svg"
import iconArrowRightDisabled from "../../assets/img/icons/iconArrowRightDisabled.svg"
import noProductsInCatalogImg from "../../assets/img/searches/no-results-products.svg"
import boxes from "../../assets/img/boxes.png"
import question from "../../assets/img/question.png"
import open from "../../assets/img/icons/iconOpen.png"
import warehouse from "../../assets/img/warehouse.png"
import Select from '../UI/Select'
import Filters from './Filters';
import arrowUp from "../../assets/img/arrow-up.png"
import arrowDown from "../../assets/img/arrow-down.png"
import TableProducts from './TableProducts';

const ProductsList = () => {
  const currentProduct = useRef("")
  const totalPages = useRef(0)
  const query = useRef("?")
  const currentScore = useRef(0)
  const currentSort = useRef("created_at")
  const [showModal, setShowModal] = useState(false)
  const [products, setProducts] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [searchInput, setSearchInput] = useState("")
  const [page, setPage] = useState(undefined)
  const [sortAsc, setSortAsc] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)
  const [modalToggle, setToggle] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [storeModal, setModalStore] = useState("");
  const [selectedOptionSort, setSelectedOptionSort] = useState("")
  const [countries, setCountries] = useState([])
  const [foundResultsInFirstLoad, setFoundResultsInFirstLoad] = useState(false)

  const [openProductsModal, setOpenProductsModal] = useState(false);
  const toggleProductsModal = () => setOpenProductsModal(!openProductsModal);
  const [productSelected, setProductSelected] = useState(null)


  useEffect(() => {
    getData()
  }, [])

  const handleModal = useCallback(() => { setShowModal(prevState => !prevState) }, [])

  const handleOptionsSort = (value) => {
    setSelectedOptionSort(value)
  }

  const handleProduct = (id) => {
    currentProduct.current = id
  }

  const handleSort = async (type = "created_at", direction = "desc") => {
    currentSort.current = type
    handleOptionsSort(sortOptions.find(e => e.value === type)?.name ?? "")
    setLoadingProducts(true)
    setGlobalError(undefined)
    const newQuery = `${query.current}&sort_by=${type}&sort_direction=${direction}`
    const response = await getProducts(newQuery)
    setProducts(response.data.products)
    setPage(response.data.page)
    totalPages.current = Math.ceil(response.data.total / response.data.page_size)
    setLoadingProducts(false)
  }

  const setFilters = async (store, country, category, brand) => {
    setLoadingProducts(true)
    setGlobalError(undefined)
    const newQuery = `?store_id=${store}&country_id=${country}&category_id=${category}&brand_id=${brand}`
    const response = await getProducts(newQuery)
    query.current = newQuery
    setProducts(response.data.products)
    if (!category && !brand && !response?.data?.products?.length) {
      setFoundResultsInFirstLoad(false)
    }
    setPage(response.data.page)
    totalPages.current = Math.ceil(response.data.total / response.data.page_size)
    setLoadingProducts(false)
  }

  const handleAsc = () => {
    setSortAsc(prev => !prev)
    if (sortAsc) {
      handleSort(currentSort.current, "desc")
    } else {
      handleSort(currentSort.current, "asc")
    }
  }

  const searchHandle = async (event) => {
    event.preventDefault()
    setLoadingProducts(true)
    setGlobalError(undefined)
    const newQuery = `?general_search_fields=product_name,brand_name,ean,sku,category_name&general_search_value=${searchInput}`
    const response = await getProducts(newQuery)
    query.current = newQuery
    setFoundResultsInFirstLoad(true)
    setProducts(response.data.products)
    setPage(response.data.page)
    totalPages.current = Math.ceil(response.data.total / response.data.page_size)
    setLoadingProducts(false)
  }

  const handleSearchInput = (event) => {
    event.preventDefault()
    setSearchInput(event.target.value)
  }

  const clearInput = () => {
    setSearchInput("");
  }

  const getData = async () => {
    setLoadingProducts(true)
    setGlobalError(undefined)
    try {
      const response = await getProducts()
      if (response instanceof Error) throw response
      setProducts(response.data.products)
      setLoadingProducts(false)
      if (response?.data?.products?.length)
        setFoundResultsInFirstLoad(true)
      else
        setFoundResultsInFirstLoad(false)

      setPage(response.data.page)
      totalPages.current = Math.ceil(response.data.total / response.data.page_size)

      const filters = await getFilters()
      setCountries(filters.data)
      setLoadingProducts(false)
    } catch (error) {
      setGlobalError(error.message)
      setLoadingProducts(false)
    }
  }

  const changePage = async (page) => {
    setLoadingProducts(true)
    window.scrollTo(0, 0);
    const response = await getProducts(`${query.current}&page=${page}`)
    setProducts(response.data.products)
    setPage(page)
    setLoadingProducts(false)
  }

  const sortByPosition = (a, b) => {
    if (a.position < b.position)
      return -1;
    if (a.position > b.position)
      return 1;
    return 0;
  }

  const sortOptions = [
    { value: "base_price", name: "Precio" },
    { value: "publication_score", name: "Puntaje de publicacion" },
    { value: "created_at", name: "Fecha de creacion" }
  ]

  const handleToggleModal = (event, store) => {
    setModalStore(store);
    setToggle(!modalToggle);
    // Obtener las coordenadas del botón que se presionó
    const buttonRect = event.target.getBoundingClientRect();
    const modalLeft = buttonRect.right + 10; // 10 es un margen

    // Establecer la posición del modal al lado del botón
    setModalPosition({ top: buttonRect.top - 70, left: modalLeft });
  }

  const handleButtonPack = (product) => {
    setProductSelected(product)
    toggleProductsModal()
  }

  return (
    <>
      <Filters countries={countries} handleCountries={() => setCountries()} setFilters={setFilters}></Filters>
      <div>
        <label className={classes.label}>
          Ordenar por
        </label>
        <div className={classes.sortWrapper}>
          <Select options={sortOptions} selectedOption={selectedOptionSort} handleOptions={handleOptionsSort} handle={handleSort} name="name" />
          <button className={classes.sortButton} onClick={handleAsc}>
            <img src={sortAsc ? arrowDown : arrowUp} className={classes.imgSort} />
          </button>
        </div>
      </div>
      <form onSubmit={searchHandle} className={classes.searchWrapper}>
        <div className={classes.labelWrapper}>
          <label className={classes.searchLabel}>
            <div className={classes.iconSearchWrapper}>
              <img className={classes.img} src={iconSearch} />
            </div>
            <input
              type="text"
              name="search"
              value={searchInput}
              onChange={event => handleSearchInput(event)}
              className={classes.searchInput}
              placeholder="Busca por producto, SKU, EAN, marca o categoría."
            />
          </label>
          <p className={classes.searchText}>Ej. “Maleta”, “1987238”, “Saxoline” </p>
        </div>
        <button className={classes.button} type="submit">
          <span className={classes.buttonFilterText}>Buscar</span>
        </button>
        <button className={classes.button} onClick={clearInput}>
          <span className={classes.buttonFilterText}>Limpiar búsqueda</span>
        </button>
      </form>
      {loadingProducts && <div className={classes.spinner}><img src={spinnerGif} className={classes.imgSpinner} /></div>}
      {!loadingProducts &&
        <div className={classes.wrapper}>
          {products.length > 0 &&
            products.map((producto, i) => (
              <div key={producto.id_mtdi} className={classes.product}>
                <div className={classes.imagesIds}>
                  <div className={classes.wrapperImg}>
                    <ProductImages images={producto.images.sort(sortByPosition)} />
                  </div>
                  <div className={classes.idsWrapper}>
                    <span className={classes.sku}><b>SKU: </b>{producto.item_ids?.filter(id => id.type === "sku")[0]?.value.toUpperCase()}</span>
                    <span className={classes.ean}><b>EAN: </b> {producto.item_ids?.filter(id => id.type === "ean")[0]?.value.toUpperCase()}</span>
                  </div>
                </div>
                <div className={classes.wrapperDescription}>
                  <h5 className={classes.productName}>{producto.name}</h5>
                  <p className={classes.brand}>Tienda: {producto.store?.tienda}</p>

                  <p className={classes.brand}>Marca: {producto.brand?.name}</p>

                  <h6 className={classes.productPrice}>{`${producto.country?.currency.symbol} ${producto.base_price} ${producto.country?.currency?.code}`}</h6>
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <p className={classes.textVariant}>Variante : <b>{producto.promotion_detail ? producto.promotion_detail.type.charAt(0).toUpperCase() + 
                    producto.promotion_detail.type.slice(1) : "Unitario"}</b></p>

                    {producto.promotion_detail &&
                      <img src={open} style={{ cursor: "pointer", width: "16%", marginBottom:"10%" }} onClick={() => handleButtonPack(producto)} />
                    }
                  </div>


                  <div>
                    {producto.stock_wms.length > 0 && (
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td className={classes.textWarehouse} style={{ width: "40%" }}>Bodega:</td>
                          <td className={classes.textWarehouse} style={{ width: "40%" }}>Stock:</td>
                          <td className={classes.textWarehouse} style={{ width: "20%" }}></td>
                        </tr>
                        {producto.stock_wms.map((wms, i) => (
                          <tr key={i}>
                            <td style={{ width: "100px", maxWidth: "", overflow: "hidden", textOverflow: "ellipsis" }}>
                              <div className={classes.warehouseStock}>
                                <div className={classes.iconStock}>
                                  <img className={classes.img} src={warehouse} />
                                </div>
                                <span className={classes.textWarehouse}>{wms.bodega}</span>
                              </div>
                            </td>
                            <td style={{ width: "100px", overflow: "hidden", textOverflow: "ellipsis" }}>
                              <div className={classes.warehouseStock}>
                                <div className={classes.iconStock}>
                                  <img className={classes.img} src={boxes} />
                                </div>
                                <span className={classes.textWarehouse}>{wms.stock} Unidades</span>
                              </div>
                            </td>
                            <td className={classes.textWarehouse} style={{ width: "30px", overflow: "hidden", textOverflow: "ellipsis" }}>
                              <div className={classes.warehouseStock}>
                                <img src={question} style={{ cursor: "pointer" }} onClick={(e) => handleToggleModal(e, wms.empresa)} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </table>
                    )}
                  </div>


                  {producto.stock_wms.length === 0 &&
                    <div key={i} className={classes.stockWrapper}>
                      <div className={classes.warehouseStock}>
                        <div className={classes.iconStock}>
                          <img className={classes.img} src={warehouse} />
                        </div>
                        <span className={classes.textWarehouse}>Sin información de stock</span>
                      </div>
                    </div>
                  }
                  <p className={classes.category}>Categoría: {producto.product_category?.category_detail?.category}</p>
                </div>
                <div className={classes.progressWrapper}
                  onClick={() => {
                    currentScore.current = producto.score.total
                    handleModal()
                    handleProduct(producto.id_mtdi)
                  }}>
                  <ProgressBar percentage={producto.score.total} />
                </div>
                {/* <div className={classes.comingSoonWrapper}>
                  <img className={classes.img} src={proximamente} />
                </div> */}
              </div>
            ))}

          {globalError &&
            <div className={classes.emptyWrapper}>
              <div className={classes.emptyStateRed}>
                <img src={productsError} className={classes.itemImg} />
              </div>
              <h4 className={classes.titleEmptyRed}>¡Lo sentimos! un error ha ocurrido.</h4>
              <p className={classes.descriptionEmptyRed}>Error en el servidor. Intente de nuevo más tarde</p>
            </div>
          }

          {products.length === 0 && !globalError ?
            <div className="no-data-found-container">
              <div className={classes.noProductsInStoreCatalog}>
                <img src={noProductsInCatalogImg} className={classes.itemImg} />
              </div>
              <span className="no-data-found-text">¡Caramba...!.</span>
              <span className="no-data-found-text">{!foundResultsInFirstLoad ? "Aún no has cargado productos a tu catálogo." : "No se encontraron resultados"}</span>
            </div>
            :
            null
          }

          <div className={classes.footerProducts}>
            <span className={classes.textPagination}>{`Mostrando página ${page} de  ${totalPages.current}`}</span>
            <div className={classes.controlsPagination} style={{marginRight:"70px"}}>
              <button disabled={page === 1 ? true : false} className={classes.controlLeft} onClick={() => changePage(page - 1)}>
                {page === 1 && <img src={iconArrowLeftDisabled} />}
                {page !== 1 && <img src={iconArrowLeft} />}
              </button>
              <button disabled={page === totalPages.current ? true : false} className={classes.controlRight} onClick={() => changePage(page + 1)}>
                {page === totalPages.current && <img src={iconArrowRightDisabled} />}
                {page !== totalPages.current && <img src={iconArrowRight} />}
              </button>
            </div>
          </div>
        </div>}
      {showModal && (
        <Modal isOpen={showModal} toggle={handleModal} size="lg">
          <ModalHeader>
            <div className={classes.modalHeader}>
              <button className={classes.modalExitButton} onClick={() => {
                handleModal()
              }}>
                x
              </button>
            </div>
          </ModalHeader>
          {currentProduct.current !== "" &&
            <div className={classes.modalWrapper}>
              <div className={classes.itemsWrapper}>
                {products.find(p => p.id_mtdi === currentProduct.current).score?.score_details.map((product, i) => (
                  <div key={i} className={classes.itemWrapper}>
                    <div className={classes.itemImgWrapper}>
                      <img src={product.total > 0 ? check : noCheck} className={classes.itemImg} />
                    </div>
                    <div className={classes.itemDetailWrapper}>
                      <h3 className={classes.detailTitle}>{product.attribute_comment_locale ? `${product.attribute_spanish}: ${product.attribute_comment_locale}` : product.attribute_spanish}</h3>
                      <p className={classes.detaiDescription}>{product.rule_description_locale}</p>
                    </div>
                  </div>
                ))}


              </div>
              <div className={classes.progressModal}>
                <ProgressBar percentage={currentScore.current} />
              </div>
            </div>}
        </Modal>

      )}
      {
        modalToggle && (
          <Modal isOpen={modalToggle} toggle={handleToggleModal} size="sm" style={{ position: 'absolute', top: modalPosition.top, left: modalPosition.left }}>
            <ModalBody>
              <div>
                <span className={classes.warehouseStock}>
                  Esta información hace referencia a la tienda {storeModal}
                </span>
              </div>
            </ModalBody>
          </Modal>
        )}
      {
        openProductsModal &&
        <TableProducts
          openProductsModal={openProductsModal}
          toggleProductsModal={toggleProductsModal}
          product={productSelected}
          origin={"ProductList"}
        />
      }
    </>
  )
}

export default ProductsList