import React from 'react'
import classNames from 'classnames';
import classes from "./PublicationStatus.module.css"

const PublicationStatus = ({ statusData, isHeightFull }) => {

    const isPublicationsCaller = statusData.caller === 'publications';

    const statusClass = classNames({
        [classes.badgeWrapper]: !isPublicationsCaller,
        [classes.publicationsBadgeWrapper]: isPublicationsCaller,
        [classes.fullHeight]: isHeightFull,
    });
    
    const getPublicationStatusColors = (statusTranslated) => {
        const publicationsStyleMap = isPublicationsCaller ? {
            "Rechazada": { color: "#FF6059", backgroundColor: "#fdebe9" },
            "Pendiente": { color: "#57C2BD", backgroundColor: "#eff9f9" },
            "Publicada": { color: "#22BB3D", backgroundColor: "#cbe9d0" }
        } : {
            "Rechazado": { color: "#FF6059", backgroundColor: "rgba(255, 96, 89, 0.2)" },
            "Pendiente": { color: "#57C2BD", backgroundColor: "rgba(87, 194, 189, 0.2)" },
            "Publicado": { color: "#22BB3D", backgroundColor: "rgba(34, 187, 61, 0.2)" }
        }

        return publicationsStyleMap[statusTranslated] || { styles: { color: "black", backGroundColor: "white" } }
    }    

  return (
    <div className={isPublicationsCaller ? classes.publicationsCardWrapper : classes.cardWrapper}>
        {!isPublicationsCaller && (
            <span className={classes.textTitleDescription}>Estado de la publicación</span>
        )}
        <div className={statusClass} style={getPublicationStatusColors(statusData.translatedStatus)}>
            <span>{ statusData.translatedStatus }</span>
        </div>
    </div>
  )
}


export default PublicationStatus