//libraries
import { useState, useEffect, useRef } from "react";

import { useMediaQuery, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getChannelsParameters } from "services/channels"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import OnboardingStep from './OnboardingStep';
import Swal from "sweetalert2";

//components
import SplashScreen from "components/UI/splash-screen";

//images
import backArrow from "../../../../assets/img/icons/backArrow.png";
import arrow from "../../../../assets/img/icons/Despliegue.png"
import Error from '../../../../assets/img/icons/error.png'
import KickOffImg from "../../../../assets/img/icons/kickOffImg.png"
import setUpImg from "../../../../assets/img/icons/setupImgStep.png"
import primeraVentaImg from "../../../../assets/img/icons/primeraVentaImg.png"
import onbCompleteImg from "../../../../assets/img/icons/onbCompleteImg.png"
import atras from "../../../../assets/img/icons/despliegueAtras.png"
import Close from "../../../../assets/img/icons/close_black.png"
//styles
import classes from "./OnboardingClientView.module.css";
import CircleSteps from "./CircleSteps";

const OnboardingClientView = ({ clientDetails, backToStores, fetchStoreDetails }) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const [isLoading, setisLoading] = useState(false);
    
    const [parametersChannels, setParameterChannel] = useState(null)
    const [actualStep, setActualStep] = useState(null)
    const [movilStep, setMovilStep] = useState(0)
    const listOfSteps = [
        { id: "Bienvenida", name: "Bienvenida" },
        { id: "KickOff", name: "Kick Off" },
        { id: "Credenciales", name: "Credenciales" },
        { id: "SetUp", name: "Set-up" },
        { id: "PrimeraVenta", name: "Primera venta" }
    ]
    const [stepsCompleted, setStepsCompleted] = useState([])

    useEffect(async () => {
        try {
            calculateActualStep(clientDetails)
            console.log(actualStep)
            getStoreDetails();
        } catch (error) {
            Swal.fire({
                title: 'Error..',
                html: `Ocurrió un error inesperado...<br>¡Intenta nuevamente<br> más tarde!`,
                imageWidth: 150,
                imageHeight: 150,
                imageUrl: Error,
                showCloseButton: true,
                confirmButtonText: "Cerrar",
                closeButtonHtml: `<img src="${Close}" />`
            })
        }

    }, []);

    const calculateActualStep = (client) => {
        try {
            for (const step of client?.onboarding_steps) {
                if (Object.keys(step?.tasks).every(key => {
                    if (key === 'kam') return true;
                    return step?.tasks[key] === true;
                })) {
                    if (step.id === "PrimeraVenta")
                        setActualStep("Complete")
                    else
                        continue
                }
                else {
                    setActualStep(step.id)
                    break
                }
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    const handleChangeStepBack = () => {
        if (movilStep !== 0) {
            setMovilStep(movilStep - 1)
        }
    }

    const handleChangeStepAfter = () => {
        if (movilStep + 1 < listOfSteps.length)
            setMovilStep(movilStep + 1)
    }

    const getStoreDetails = async () => {
        setisLoading(true);
        const obj = await fetchStoreDetails(clientDetails.id)
        setParameterChannel(obj.channels);
        setisLoading(false);
    };

    const getImgOnboarding = () => {
        if (actualStep === "Setup")
            return setUpImg
        else if (actualStep === "PrimeraVenta")
            return primeraVentaImg
        else if (actualStep === "Complete")
            return onbCompleteImg
        else
            return KickOffImg
    }

    const getTextOnboarding = () => {
        if (actualStep === "Setup")
            return <span>
                ¡Oh yeah! <br />
                Estás en la cuarta <br />
                etapa del Onboarding <br />
            </span>
        else if (actualStep === "PrimeraVenta")
            return <span>
                ¡Que emoción! <br />
                Estamos a la espera de la <br />
                primera venta del cliente <br />
            </span>
        else if (actualStep === "Complete") {
            const fecha1 = new Date(clientDetails?.emails_sent.find(e=> e.id === "Bienvenida").date_sent)
            const fecha2 = new Date(clientDetails?.emails_sent.find(e=> e.id === "Primera venta").date_sent)
            const days = Math.ceil(Math.abs(fecha2 - fecha1) / (1000 * 60 * 60 * 24));

            return <span>
                ¡Magnífico! <br />
                El Onboarding duró {days} {days > 1 ? "días" : "día"} <br />
                ¡Felicitaciones! <br />
            </span>
        }
        else
            return <span>
                Tortuga a la vista...<br />
                El cliente aún no tiene las credenciales disponibles.
            </span>
    };

    return (
        <>
            {isMobile ?
                <>
                    <Box sx={{ display: 'flex', mb: 3 }}>
                        <Tooltip title="Volver" arrow>
                            <ArrowBackIosNewIcon onClick={() => { backToStores() }}
                                sx={{ color: 'black', fontSize: 20, cursor: 'pointer', mt: 0.7, mr: 2 }} />
                        </Tooltip>
                        <Typography fontFamily='Montserrat' variant="h5"><b>{clientDetails.tienda}</b></Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>
                        <div style={{ marginBottom: "50px", marginTop: "20px" }}>
                            {movilStep === listOfSteps.indexOf(actualStep) ?
                                <Typography align='center' fontFamily='Montserrat' style={{ fontSize: '13px', fontFamily: 'Montserrat' }} >Actualmente estas en esta etapa del proceso de Onboarding</Typography>
                                :
                                <Typography align='center' fontFamily='Montserrat' style={{ color: "#D3D3D3", fontSize: '13px', fontFamily: 'Montserrat' }} >Este cliente se encuentra en la etapa {listOfSteps.indexOf(actualStep) + 1} del proceso de Onboarding</Typography>
                            }
                        </div>

                        <Box sx={{ display: 'flex' }}>
                            <IconButton onClick={handleChangeStepBack} sx={{
                                mb: 20, mr: 2
                                ,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                '& img': {
                                    transform: 'rotate(180deg)',
                                }
                            }}>
                                <img src={arrow} alt="arrow" />
                            </IconButton>

                            <OnboardingStep
                                identifier={listOfSteps[movilStep].id}
                                text={listOfSteps[movilStep].name}
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                isMobile={isMobile}
                                calculateActualStep={calculateActualStep}
                            />

                            <IconButton onClick={handleChangeStepAfter} sx={{

                                mb: 20, ml: 2,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}>
                                <img src={arrow} alt="arrow"/>
                            </IconButton>

                        </Box>


                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 55, left: '50%', transform: 'translateX(-50%)' }}> <CircleSteps movilStep={movilStep} setMovilStep={setMovilStep} /></Box>
                </>


                :
                <>

                    <Box sx={{ display: 'flex', mb: 12 }}>
                        <Tooltip title="Volver" arrow>
                            <IconButton onClick={() => { backToStores() }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }

                                }}>
                                <img src={atras} alt="atras" />
                            </IconButton>

                        </Tooltip>
                        <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <Typography fontFamily='Montserrat' variant="h4"><b>{clientDetails.tienda}</b></Typography>
                        </div>

                    </Box>

                    <Grid container  >
                        <Grid item xl={2.4} lg={2.4} md={2} xs={2.2} >
                            <OnboardingStep
                                identifier="Bienvenida"
                                text="¡Bienvenida!"
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                calculateActualStep={calculateActualStep}
                                parametersChannels={parametersChannels}

                            />
                        </Grid>

                        <Grid item xl={2.4} xs={2} lg={2.4} md={2.2}>
                            <OnboardingStep
                                identifier="KickOff"
                                text="Kick Off"
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                calculateActualStep={calculateActualStep}
                                parametersChannels={parametersChannels}

                            />
                        </Grid>

                        <Grid item xl={2.4} xs={2} lg={2.4} md={2.2}>
                            <OnboardingStep
                                identifier="Credenciales"
                                text="Credenciales"
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                calculateActualStep={calculateActualStep}
                                parametersChannels={parametersChannels}
                            />
                        </Grid>

                        <Grid item xl={2.4} xs={2} lg={2.4} md={2.2}>
                            <OnboardingStep
                                identifier="SetUp"
                                text="Set-up completo"
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                calculateActualStep={calculateActualStep}
                                parametersChannels={parametersChannels}
                            />
                        </Grid>

                        <Grid item xl={2.4} xs={2} lg={2.4} md={2.2}>
                            <OnboardingStep
                                identifier="PrimeraVenta"
                                text="Primera Venta"
                                clientDetails={clientDetails}
                                actualStep={actualStep}
                                calculateActualStep={calculateActualStep}
                                parametersChannels={parametersChannels}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                       
                    }}>

                        <>
                            <img src={getImgOnboarding()} alt="OnboardingStepImg" />
                            <div style={{ marginTop: -15 }}>
                                <Typography fontFamily='Montserrat' align='center' style={{ fontSize: "12px" }} >
                                    {getTextOnboarding()}
                                </Typography>
                            </div>
                        </>

                    </Box>
                </>
            }
        </>

    );
};

export default OnboardingClientView;