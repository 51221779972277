import React, { useEffect, useState } from 'react'
import {
    Box, Typography, useMediaQuery
} from '@mui/material'

import Close from "../../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardTask from './CardTask';
import { Modal } from 'reactstrap'

const PrimeraVentaStep = ({ displayPrimeraVentaModal, togglePrimeraVentaModal, clientDetails, actualStep, editOnboardingSteps }) => {

    const [checks, setChecks] = useState(
        {
            emailFirst: clientDetails.onboarding_steps[4].tasks?.emailFirst,
            quiz: clientDetails.onboarding_steps[4].tasks?.quiz
        }
    )

    const isMobile = useMediaQuery('(max-width:600px)');
    const handleCheck = (e) => {
        const newCheck = { ...checks, [e.target.name]: !checks[e.target.name] }
        setChecks(newCheck);
        editOnboardingSteps(clientDetails.onboarding_steps[4].id, newCheck)
    }

    return (

        <Modal
            isOpen={displayPrimeraVentaModal}
            toggle={togglePrimeraVentaModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            keyboard={false}
            backdrop="static"
        >
            <Box
                id="modal-content"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: isMobile ? '100%' : '120%',
                    maxWidth: '570px',
                    bgcolor: 'white',
                    boxShadow: 24,
                    pl: 6,
                    pr: 6,
                    pb: 10,
                    pt: 2,
                    zIndex: 1,
                    borderRadius: 4,


                }}
            >

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: "30px" }}>
                    <div></div>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Typography style={{ color: "#D3D3D3", fontSize: isMobile ? '17px' : '25px', fontFamily: 'Montserrat' }} ><b>Etapa</b></Typography>
                        <Typography fontFamily='Montserrat' variant={isMobile ? "h5" : "h4"} ><b>Primera Venta</b></Typography>
                    </Box>
                    <Box sx={{ mr: -4 }}>
                        <Tooltip title="Salir" arrow>
                            <IconButton onClick={togglePrimeraVentaModal} sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                }
                            }}>
                                <img src={Close} width={'80%'} alt="cerrar" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <Box
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > :not(style)': { mt: 2, mb: 2 },
                        justifyContent: 'center',
                    }}
                >
                    {checks.emailFirst !== undefined &&
                        <CardTask
                            name={"emailFirst"}
                            text={"Envío automático de Correo de Primera venta"}
                            canCheck={false} //este prop se usa para saber si el check se puede marcar y desmarcar
                            checkValue={checks.emailFirst}
                            buttonComplete={false}
                            handleCheck={handleCheck}
                        />
                    }

                    {checks.quiz !== undefined &&
                        <CardTask
                            name={"quiz"}
                            text={"Envío automático de Correo de encuesta"}
                            canCheck={false} //este prop se usa para saber si el check se puede marcar y desmarcar
                            checkValue={checks.quiz}
                            buttonComplete={false}
                            handleCheck={handleCheck}
                        />
                    }
                </Box>

            </Box>

        </Modal>


    )
}

export default PrimeraVentaStep