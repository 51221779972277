import React, { useState } from 'react'
import classes from './ProductDetail.module.css'
import { thousandSeparator } from "../../utils/utils"

import verticalDots from '../../assets/img/vertical-dots.png'
import trash from '../../assets/img/Trash.png'

const ProductDetail = ({combo, handleDeleteProduct, product}) => {

    const [openOptions, setOpenOptions] = useState(false)

    return (
        <div className={classes.wrapper}>
            <div className={classes.imgWrapper}>
                <img className={classes.img} src={product.images.length > 0 ? product.images[0].url : undefined} />
            </div>
            <h4 className={classes.name}>
                {product.name}
            </h4>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>SKU</h5>
                <p className={classes.itemDetail}>{product.item_ids[0].value}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>Tienda/Cliente</h5>
                <p className={classes.itemDetail}>{product.store.tienda}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>Stock disponible</h5>
                <p className={classes.itemDetail}>{product.stock_wms.length > 0 ? product.stock_wms[0].stock : "No disponible"}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>Unidades para este {combo}</h5>
                <p className={classes.itemDetail}>{product.comboStock}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>% de descuento</h5>
                <p className={classes.itemDetail}>{product.comboDiscount}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>Precio de venta</h5>
                <p className={classes.itemDetail}>{"$ " + thousandSeparator(Math.floor(product.base_price), ".")}</p>
            </div>
            <div className={classes.itemWrapper}>
                <h5 className={classes.itemTitle}>Precio de venta con descuento</h5>
                <p className={classes.newPrice}>{"$ " + thousandSeparator(Math.trunc(parseInt(product.base_price)*(1-parseInt(product.comboDiscount)/100)),".")}</p>
            </div>
            <div className={classes.optionsWrapper}>
                <img src={verticalDots} className={classes.verticalDots} onClick={() => setOpenOptions(!openOptions)}/>
                {openOptions && 
                    <div 
                        className={classes.options} 
                        onClick={() => {
                            setOpenOptions(false)
                            handleDeleteProduct(product.id_mtdi)
                        }}
                    >
                        <div className={classes.trashWrapper}>
                            <img src={trash} className={classes.img}/>
                        </div>
                        <span className={classes.textOption} >Eliminar producto</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProductDetail