
import MtdiTable from "views/Orders/mtdi-table.js";
import CustomerMaintainer from "views/CustomerMaintainer/customer-maintainer";
import DataStudio from "views/dataStudio/data-studio";
import Catalog from "views/Catalog/Catalog";
import Products from "views/Products/Products";
import Promotions from "views/Promotions/Promotions";
import ClientProfile from "views/EditClientProfile/ClientProfile";
import Publications from "views/publications/Publications";
import MetricsDashboardsView from "views/Acceleration/Metrics/MetricsDashboardsView"
import Competitiveness from "views/Competitiveness/Competitiveness";


const routes = [
  {
    path: "/clientProfile",
    name: "Perfil de usuario",
    icon: "nc-icon nc-single-02",
    component: ClientProfile,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Tiendas",
    icon: "nc-icon nc-box",
    state: "pagesCollapseTiendas",
    invisible: false,
    views: [
      {
        path: "/manage-catalog",
        name: "Administrador de Catálogo",
        icon: "nc-icon nc-ruler-pencil",
        component: Catalog,
        layout: "/admin"
      },
      {
        path: "/products",
        name: "Productos",
        icon: "nc-icon nc-layout-11",
        component: Products,
        layout: "/admin"
      },
      {
        path: "/promotions",
        name: "Promociones",
        icon: "nc-icon nc-tag-content",
        component: Promotions,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/orders",
    name: "Operación",
    icon: "nc-icon nc-single-copy-04",
    component: MtdiTable,
    layout: "/admin",
    invisible: false
  },
  {
    collapse: true,
    name: "Aceleración",
    icon: "nc-icon nc-spaceship",
    state: "pagesCollapseAceleracion",
    views: [
      {
        path: "/indicadores",
        name: "Indicadores",
        icon: "nc-icon nc-ruler-pencil",
        component: MetricsDashboardsView,
        layout: "/admin"
      },
      {
        path: "/publications",
        name: "Publicaciones",
        icon: "nc-icon nc-layout-11",
        component: Publications,
        layout: "/admin"
      },
      {
        path: "/competitiveness",
        name: "Competitividad",
        icon: "nc-icon nc-layout-11",
        component: Competitiveness,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/customermaintainer",
    name: "Administrar Perfiles ",
    icon: "nc-icon nc-badge",
    component: CustomerMaintainer,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/",
    name: "Operación",
    icon: "nc-icon nc-single-copy-04",
    component: MtdiTable,
    layout: "/admin",
    invisible: true
  },
];

export default routes;
