import React, { useEffect, useState } from 'react'
import {
    Box, Typography, useMediaQuery
} from '@mui/material'

import Close from "../../../../../assets/img/icons/close_black.png"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardTask from './CardTask';
import { Modal } from 'reactstrap'
import Swal from 'sweetalert2'
import pendiente from '../../../../../assets/img/onboarding/Pendiente.png'
import { sendOnboardingEmail } from 'services/stores';
import CreateStoreFirstModal from '../../displayEditClientStores/CreateStoreFirstModal';
import { putClientStore } from "services/stores";
import Error from '../../../../../assets/img/icons/error.png'


const KickOffStep = ({ displayKickOffModal, toggleKickOffModal, clientDetails, actualStep, editOnboardingSteps }) => {

    const [checks, setChecks] = useState(
        {
            masterEmail: clientDetails.onboarding_steps[1].tasks?.masterEmail,
            kickOffEmail: clientDetails.onboarding_steps[1].tasks?.kickOffEmail,
            scheduling: clientDetails.onboarding_steps[1].tasks?.scheduling,
            masterRevition: clientDetails.onboarding_steps[1].tasks?.masterRevition,
            kam: clientDetails.onboarding_steps[1].tasks?.kam,
            sac: clientDetails.onboarding_steps[1].tasks?.sac,
            warehouseData: clientDetails.onboarding_steps[1].tasks?.warehouseData,
            billingData: clientDetails.onboarding_steps[1].tasks?.billingData,
            tokens: clientDetails.onboarding_steps[1].tasks?.tokens,
            kickOffMeeting: clientDetails.onboarding_steps[1].tasks?.kickOffMeeting  
        }
    )

    const textChecks = {
        "masterEmail": "Envío automático de Correo de Descarga de elementos",
        "kickOffEmail": "Envío automático de Correo de Kick Off",
        "scheduling": "Reunión agendada exitosamente",
        "masterRevition": "Revisión de la maestra",
        "kam": "Asignación de KAM",
        "sac": "Asignación y capacitación SAC",
        "warehouseData": "Solicitud de información de Bodega",
        "billingData": "Solicitud de información de Facturación",
        "tokens": "Solicitar los tokens de los canales de venta",
        "kickOffMeeting": "Reunión de Kick Off concretada exitosamente"
    }

    const autoCheck = ["masterEmail", "KickOffEmail", "scheduling", "kam"]
    const [checkMeeting, setCheckMeeting] = useState(clientDetails.onboarding_steps[1].id === actualStep && !clientDetails.onboarding_steps[1].tasks.kickOffMeeting && clientDetails.onboarding_steps[1].tasks.scheduling)
    const isMobile = useMediaQuery('(max-width:600px)');
    const [cliente, setCliente] = useState(null)
    const [country, setCountry] = useState(null)
    const [openFirstModal, setOpenFirstModal] = useState(false);
    const toggleFirstModal = () => setOpenFirstModal(!openFirstModal);
    const [loadingClient, setLoadingClient] = useState(false)


    const handleCheck = async (e) => {
        let check = true
        if (e.target.name === "kickOffMeeting") {
            check = await Swal.fire({
                title: 'Estas a punto de...',
                text: "Enviar el correo de credenciales",
                imageWidth: 150,
                imageHeight: 150,
                imageUrl: pendiente,
                showCloseButton: true,
                confirmButtonText: '¡Entendido!',

            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await sendOnboardingEmail(clientDetails.id, 7)
                    clientDetails.onboarding_steps[2].tasks.sentCredentials = true;
                    setCheckMeeting(false)
                    return true
                } else if (result.isDismissed) {
                    return false
                }
            })
        }
        if (check) {
            const newCheck = { ...checks, [e.target.name]: !checks[e.target.name] }
            setChecks(newCheck);

            editOnboardingSteps(clientDetails.onboarding_steps[1].id, newCheck)
        }


    }


    const resetClientValues = () => {
        setCliente({
            idCliente: clientDetails.id,
            name: clientDetails?.tienda,
            namerlegal: clientDetails?.company_data?.representative_name,
            email: clientDetails?.company_data?.representative_email,
            phone: '',
            address: clientDetails?.company_data?.company_address,
            identity: clientDetails?.company_data?.company_rut,
            social_reason: clientDetails?.company_data?.company_name,
            type_of_selling: clientDetails?.company_data?.company_giro,
            model: '',
            kam_selected: clientDetails?.company_data?.kam_email || '',
            client_country: clientDetails?.paises[0],
            facturacion: clientDetails?.company_data?.company_services?.facturacion || false,
            bodega: clientDetails?.company_data?.company_services?.almacenamiento_bodega_instance || false,
            canales: clientDetails?.company_data?.company_services?.creacion_canales_de_venta || false,
            servicio: clientDetails?.company_data?.company_services?.servicio_al_cliente || false,
            channelsOnboarding: clientDetails?.canales_por_onbordear || [],
            contratFileName: clientDetails?.company_data?.company_contract?.location || null
        })
    }

    const editClient = async (kam) => {
        console.log(kam)
        if (!kam) {
            toggleFirstModal()
            return
        }
        setLoadingClient(true)
        cliente.kam_selected = kam
        setCliente({ ...cliente, kam_selected: kam })
        let dataResult = await putClientStore(cliente)
        if (dataResult.status === 200) {
            handleCheck({ target: { name: "kam" } })
            toggleFirstModal()
            setLoadingClient(false)
        } else {
            Swal.fire({
                title: 'Error..',
                html: `Ocurrió un error inesperado...<br>¡Intenta nuevamente<br> más tarde!`,
                imageWidth: 150,
                imageHeight: 150,
                imageUrl: Error,
                showCloseButton: true,
                confirmButtonText: "Cerrar",
                closeButtonHtml: `<img src="${Close}" />`
            }).then(() => {
                toggleFirstModal()
                setLoadingClient(false)
            })
        }
    }

    const handleCompleteKam = () => {
        resetClientValues()
        toggleFirstModal()
        console.log("complete kam")
    }

    const determineCheckability = (key) => {
        if (key === "kickOffMeeting") {
            return checkMeeting;
        } else if (autoCheck.includes(key)) {
            return false;
        } else {
            return clientDetails.onboarding_steps[1].id === actualStep
        }
    }

    return (
        <>
            <Modal
                isOpen={displayKickOffModal}
                toggle={toggleKickOffModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                keyboard={false}
                backdrop="static"
            >
                <Box
                    id="modal-content"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: isMobile ? '100%' : '120%',
                        maxWidth: '570px',
                        bgcolor: 'white',
                        boxShadow: 24,
                        pl: 6,
                        pr: 6,
                        pb: 10,
                        pt: 2,
                        zIndex: 1,
                        borderRadius: 4,


                    }}
                >

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: "30px" }}>
                        <div></div>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Typography style={{ color: "#D3D3D3", fontSize: isMobile ? '17px' : '25px', fontFamily: 'Montserrat' }} ><b>Etapa</b></Typography>
                            <Typography fontFamily='Montserrat' variant={isMobile ? "h5" : "h4"} ><b>Kick Off</b></Typography>
                        </Box>
                        <Box sx={{ mr: -4 }}>
                            <Tooltip title="Salir" arrow>
                                <IconButton onClick={toggleKickOffModal} sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}>
                                    <img src={Close} width={'80%'} alt="cerrar" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': { mt: 2, mb: 2 },
                            justifyContent: 'center',
                        }}
                    >

                        {Object.keys(checks).map(key => (
                            checks[key] !== undefined && (
                                <CardTask
                                    key={key}
                                    name={key}
                                    text={textChecks[key]}
                                    canCheck={determineCheckability()}
                                    checkValue={checks[key]}
                                    handleCheck={handleCheck}
                                    buttonComplete={key === "kam"}
                                    actionComplete={key === "kam" && handleCompleteKam}
                                />
                            )
                        ))}

                    </Box>
                </Box>
            </Modal>

            {

                openFirstModal &&
                <CreateStoreFirstModal
                    openFirstModal={openFirstModal}
                    toggleFirstModal={toggleFirstModal}
                    setCountry={setCountry}
                    setCliente={setCliente}
                    toggleSecondModal={null}
                    clientData={cliente}
                    resetValues={resetClientValues}
                    modalTittle={"Editar Cliente"}
                    allStores={null}
                    welcomeStepKam={editClient}
                    loadingClient={loadingClient}
                ></CreateStoreFirstModal>

            }

        </>


    )
}

export default KickOffStep

