import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
const BallotDetailMobile = (props) => {

  return (
    <React.Fragment>
      <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
        Detalle Boleta
      </h5>
      {props.purchaser.products != undefined &&
        props.purchaser.products.map((item) => (
          <Row>
            <Col className="text-center" lg="6" md="12">
              <Card className="card-tasks" style={{ marginBottom: "20px", boxShadow: "none"}}>
                <CardHeader></CardHeader>
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td />
                        <td
                          className="text-left"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Nombre:
                        </td>

                        <td className="text-left" style={{ fontSize: "12px" }}>
                          {item.name}
                        </td>
                        <td className="td-actions text-right" style={{marginTop: "2em"}}>
                          <br />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td
                          className="text-left"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          SKU:
                        </td>

                        <td className="text-left" style={{ fontSize: "12px" }}>
                          {item.sku}
                        </td>
                        <td className="td-actions text-right">
                          <br />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td
                          className="text-left"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Ean:
                        </td>

                        <td className="text-left" style={{ fontSize: "12px" }}>
                          {item.ean}
                        </td>
                        <td className="td-actions text-right">
                          <br />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td
                          className="text-left"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Cantidad:
                        </td>

                        <td className="text-left" style={{ fontSize: "12px" }}>
                          {item.qty}
                        </td>
                        <td className="td-actions text-right">
                          <br />
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}
    </React.Fragment>
  );
};
export default BallotDetailMobile;
