import React from 'react';
import CustomModal from '../UI/CustomModal';
import CircularProgress from '@mui/material/CircularProgress';
import fourthModalStyles from './FourthAlertModal.module.css';
import styles from '../../views/publications/Components/CreateAlertModal.module.css';

const FourthAlertModal = ({
  fourthModal,
  toggleFourthModal,
  inputCreationAlertName,
  loadingAlert,
  handleFourthModal,
  handleCloseFourthModal,
  hasDuplicateNameError,
  handleInputCreationAlertNameChange,
  handleAlertName,
  newAlertName
}) => {
  return (
    <CustomModal
      isOpen={fourthModal}
      toggle={toggleFourthModal}
      onBack={handleFourthModal}
      onClose={handleCloseFourthModal}
      title="Crear alerta"
      customStyles={{ box: fourthModalStyles.customModal }}
      customClassNames={{
        form: fourthModalStyles.modalForm,
        header: fourthModalStyles.crearAlertaParent,
        title: fourthModalStyles.crearAlerta,
        closeWrapper: fourthModalStyles.closeWrapper,
        backIcon: fourthModalStyles.backIcon,
        closeIcon: fourthModalStyles.closeIcon,
        separatorContainer: fourthModalStyles.separatorContainer,
        separator: fourthModalStyles.separator,
        bodyContainer: fourthModalStyles.bodyContainer,
        footerContainer: fourthModalStyles.footerContainer,
        box: (inputCreationAlertName.length > 0 && inputCreationAlertName.length <= 35) ? styles.afterElementFourth : styles.afterElementThird
      }}
      footerActions={
        !loadingAlert && (
          <div
            className={`${fourthModalStyles.botonLogin} ${(inputCreationAlertName.length > 0 && inputCreationAlertName.length <= 35) ? fourthModalStyles.enabled : ''}`}
            onClick={() => {
              if (inputCreationAlertName.length > 0 && inputCreationAlertName.length <= 35) {
                handleAlertName(newAlertName);
              }
            }} 
          >
            <b className={fourthModalStyles.siguiente}>Crear alerta</b>
          </div>
        )
      }
    >
        <div className={fourthModalStyles.seleccionaDestinatarioContainer}>
            <div className={fourthModalStyles.seleccionaDestinatario}>¡Nombra tu alerta!</div>
        </div>        
        <div className={fourthModalStyles.emailInputContainer}>
          <span className={hasDuplicateNameError ? fourthModalStyles.inputNameError : fourthModalStyles.inputName}>
            Nombre de tu alerta
            <span className={hasDuplicateNameError ? fourthModalStyles.inputNameMaxError : fourthModalStyles.inputNameMax}>
              (máximo 35 caracteres)
            </span>
          </span>
          <div className={hasDuplicateNameError ? fourthModalStyles.inputBorderContainerError : fourthModalStyles.inputBorderContainer}>
            <input
              className={fourthModalStyles.emailInput}
              value={inputCreationAlertName}
              onChange={handleInputCreationAlertNameChange}
              placeholder="Ingresa aquí el nombre de tu alerta"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent form submission
                    if (inputCreationAlertName.length > 0 && inputCreationAlertName.length <= 35) {
                        handleAlertName(newAlertName);
                    }
                }
              }}
            />
          </div>
          {hasDuplicateNameError && (
            <span className={fourthModalStyles.nameExistsError}>Este nombre ya existe</span>
          )}
        </div>
      {loadingAlert && (
        <div className={fourthModalStyles.botonLoading} disabled={true}>
          <CircularProgress
            thickness={6}
            size={33}
            sx={{
              color: "#57C2BD",
              position: 'absolute',
              top: '43%',              
              right: '75%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
          <b className={fourthModalStyles.siguiente}>Cargando</b>
        </div>
      )}
    </CustomModal>
  );
};

export default FourthAlertModal;
