import React from 'react'
import classes from "./Login.module.css"

import newpasssuccess from "../../assets/img/newpasssuccess.png"
import close from "../../assets/img/close.svg";

const NewPasswordSuccess = ({ setView }) => {
  return (
    <div className={classes.loginWrapper}>
        <div className={classes.header}>
            <div className={classes.iconWrapper}></div>
            <h1>Nueva contraseña</h1>
            <a href="https://www.instancelatam.com" className={classes.iconWrapper}><img src={close} className={classes.iconHeader} /></a>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.wrapperContrato}><img src={newpasssuccess} /></div>
        <h3>¡Bravo!</h3>
        <p className={classes.text}>Tu contraseña se ha actualizado exitosamente.</p>
        <button className={classes.loginButton} onClick={() => setView("sign-in")}>
            Cerrar
        </button>
    </div>
  )
}

export default NewPasswordSuccess