import { Rating } from "@mui/material";
const ReviewModal = ({purchaser}) => {

 return (
    <>
      <h5 style={{ fontWeight: "700", size: "22px", textAlign: "center" }}>
      Review de la orden
      </h5>
      <p style={{ textAlign: "center" }}>Orden {purchaser.orderNo}</p>
     {purchaser.channel !== 2 && <p style={{color:'#444B54',fontStyle:'italic',fontSize:'16px', textAlign: "center" }}>
        Review no disponible aún.
        </p>}
        {(purchaser.channel === 2 && purchaser.products.length === 1 )&& purchaser.products[0].review === ''&&<p style={{color:'#444B54',fontStyle:'italic',fontSize:'16px', textAlign: "center" }}>
        Review no disponible aún.
        </p>}
        {(purchaser.channel === 2 && purchaser.products.length === 1 )&& purchaser.products[0].review !== ''&& <p style={{color:'#444B54',fontStyle:'italic',fontSize:'16px', textAlign: "center" }}>
       { purchaser.products[0].review.title !== '' && purchaser.products[0].review.title !== undefined && <h6 style={{textTransform:'capitalize'}}>{purchaser.products[0].review.title}</h6>}
       { purchaser.products[0].review.rate !== '' &&  purchaser.products[0].review.rate !== undefined && <Rating name="read-only" value={ purchaser.products[0].review.rate} readOnly />}
        </p>}
        {(purchaser.channel === 2 && purchaser.products.length === 1 )&& purchaser.products[0].review !== ''&& <p style={{color:'#444B54',fontStyle:'italic',fontSize:'16px', textAlign: "center" }}>
       { purchaser.products[0].review.content !== '' && purchaser.products[0].review.content !== undefined && <p style={{ textAlign: "center" }}>{purchaser.products[0].review.content}</p>}
       { purchaser.products[0].review.content === '' && purchaser.products[0].review.content !== undefined  && <p style={{ textAlign: "center",fontStyle:'italic' }}>El comprador no dejó mensaje.</p>} 
        </p>}  
    </>
  );
};
export default ReviewModal;
