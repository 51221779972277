//Libraries
import React, { useState, useEffect } from 'react';

//Components
import { 
    formatLastUpdateOfPublication, 
    transformTranslatedStatus, 
    translateLogisticType, 
    getPriceInfoToModal 
} from '../../../utils/utils';
import ModalPublicationDetails from '../../../components/modalComponents/ModalDetailPublication/ModalDetails';

const PublicationModalFormat = ({ displayModalDetails, toggleDisplayModal, publication, createAlert, alertsForProduct }) => {
    const [publicationInfo, setPublicationInfo] = useState([]);

    useEffect(() => {
        if (!publication) return; // Avoid running if `publication` is undefined or null

        const statusData = {
            status: publication?.status,
            translatedStatus: transformTranslatedStatus(publication.translated_status),
            caller: 'publications',
        };

        const formatImages = (images) => {
            if (!images || images.length === 0) {
                return [];
            }
            return images.map((image) => ({
                url: image?.link
            }));
        };

        const { price, priceWithDiscount } = getPriceInfoToModal(publication);

        setPublicationInfo([{
            idPublication: publication.id_publication,
            warehouse: "Sin información",
            logistica: publication.logistica || null,
            translatedLogistica: publication.logistica ? translateLogisticType(publication.logistica) : "Sin información",
            iconChannel: publication.channel_icon || "",
            url: publication.publication_url || "",
            buyboxHealthcheck: {
                hasBuybox: publication.has_buybox || false,
                hasHealthcheck: publication.has_healthcheck || false,
                buybox: publication.buybox || null,
                healthcheck: publication.healthcheck || null
            },
            statusPim: false,
            channel: publication.channel || null,
            channelId: publication.channel_id || null,
            description: publication.long_description || "No disponible",
            images: formatImages(publication.images),
            sku: publication.sku || "",
            ean: publication.ean || "",
            store: publication.client || "",
            category: publication.category || "No disponible",
            name: publication.name || "No disponible",
            price: price != priceWithDiscount ? price : null,
            priceWithDiscount: priceWithDiscount,
            statusPublicationImg: null,
            statusPublication: statusData,
            stock: publication.stock,
            alerts: alertsForProduct || [],
            suggestions: publication?.suggestion_quantity || 0,
            lastChannelUpdate: formatLastUpdateOfPublication(publication?.last_update_channel) || '',
            hasActualSalesData: publication?.statistics?.publication_graph_data?.has_actual_sales_data || false,
            hasActualConversionData: publication?.statistics?.publication_graph_data?.has_actual_conversion_data || false,
            hasActualPriceData: publication?.statistics?.publication_graph_data?.has_actual_price_data || false,
            salesXUnits: publication?.statistics?.publication_graph_data?.sales_x_units || null,
            trafficXConversion: publication?.statistics?.publication_graph_data?.traffic_x_conversion || null,
            priceXSales: publication?.statistics?.publication_graph_data?.price_x_sales || null,
            purchaseExperience: publication?.purchase_experience || null
        }]);
    }, [publication]);

    return (
        <ModalPublicationDetails
            data={publicationInfo}
            toggleModal={toggleDisplayModal}
            origin={"publications"}
            displayModal={displayModalDetails}
            createAlert={createAlert}
        />
    );
};

export default PublicationModalFormat;
