import React from 'react';
import CustomModal from '../../UI/CustomModal';
import classes from '../AlertsCenter.module.css';
import fourthModalStyles from '../../PublicationAlertModals/FourthAlertModal.module.css';


const FourthAlertModal = ({ 
    fourthModal, 
    toggleFourthModal,
    closeFourthModalCloseButton,
    loadingAlert,
    inputEditAlertName,
    originalAlertName,
    handleAlertName,
    newAlertName,
    hasDuplicateNameError,
    handleInputAlertNameChange,
    CircularProgress    
  }) => {

    return (
        <CustomModal
            isOpen={fourthModal}
            toggle={toggleFourthModal}
            onClose={closeFourthModalCloseButton}
            title="Editar alerta"
            customStyles={{ box: fourthModalStyles.customModal }}
            customClassNames={{
                form: fourthModalStyles.modalForm,
                header: classes.crearAlertaParent,
                title: classes.crearAlerta,
                closeWrapper: classes.closeWrapper,
                closeIcon: classes.closeIcon,
                separatorContainer: fourthModalStyles.separatorContainer,
                separator: fourthModalStyles.separator,
                bodyContainer: fourthModalStyles.bodyContainer,
                footerContainer: fourthModalStyles.footerContainer,
                box: classes.afterElementNone
            }}
            footerActions={
                !loadingAlert && (
                    <div
                        className={`${fourthModalStyles.botonLogin} ${(inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) ? fourthModalStyles.enabled : ''}`}
                        onClick={() => {
                            if (inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) {
                                handleAlertName(newAlertName);
                            }
                        }}                     
                    >
                        <b className={fourthModalStyles.siguiente}>Guardar cambios</b>
                    </div>
                )
            }
        >
            <div className={fourthModalStyles.seleccionaDestinatarioContainer}>
                <div className={fourthModalStyles.seleccionaDestinatario}>¡Nombra tu alerta!</div>
            </div>        
            <div className={fourthModalStyles.emailInputContainer}>
                <span className={hasDuplicateNameError ? fourthModalStyles.inputNameError : fourthModalStyles.inputName}>
                    Nombre de tu alerta
                    <span className={hasDuplicateNameError ? fourthModalStyles.inputNameMaxError : fourthModalStyles.inputNameMax}>
                        (máximo 35 caracteres)
                    </span>
                </span>
                <div className={hasDuplicateNameError ? fourthModalStyles.inputBorderContainerError : fourthModalStyles.inputBorderContainer}>
                    <input
                        className={fourthModalStyles.emailInput}
                        value={inputEditAlertName}
                        onChange={handleInputAlertNameChange}
                        placeholder="Ingresa aquí el nombre de tu alerta"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent form submission
                                if (inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) {
                                    handleAlertName(newAlertName);
                                }
                            }
                        }}
                    />
                </div>
                {hasDuplicateNameError && (
                    <span className={fourthModalStyles.nameExistsError}>Este nombre ya existe</span>
                )}
            </div>
            {loadingAlert && (
                <div className={fourthModalStyles.botonLoading} disabled={true}>
                <CircularProgress
                    thickness={6}
                    size={33}
                    sx={{
                    color: "#57C2BD",
                    position: 'relative',             
                    }}
                />
                    <b className={fourthModalStyles.siguiente}>Cargando</b>
                </div>
            )}
        </CustomModal>
    );
};

export default FourthAlertModal;
