import React, { useState, useEffect } from "react";
import "./edit-document-tributario.css";
import {
  Card,
  // Row,
  Col,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Container, FormCheck, FormControl, Row } from "react-bootstrap";
import {performanceMetric} from "../../utils/utils";
var configFile = require("../../config/url");
const EditDocumentTributario = (props) => {
  const [selectedCategoryId, setselectedCategoryId] = useState("");
  const [showeditSuccessfulModal, setshoweditSuccessfulModal] = useState(false);
  const [storeName, setstoreName] = useState(props.storeName);
const [storeId, setstoreId] = useState(props.id);
const [color, setcolor] = useState(props.companyData.storeData.email_color);
const [emailText, setemailext] = useState(props.companyData.storeData.email_text);
const [categories, setcategories] = useState(props.companyData.storeData.categories);
  const [corporateName, setCorporateName] = useState(
    props.companyData. company_data.company_name
  );
  const [mail, setMail] = useState(props.companyData.
    company_data.representative_email);
  const [rut, setRut] = useState(props.companyData.company_data.company_rut);
   const [address, setAddress] = useState(props.companyData.company_data.company_address);
   const [name, setName] = useState(props.companyData.company_data.representative_name);
   const [phone, setPhone] = useState(props.companyData.company_data.representative_phone);
   const [showEditModal, setshowEditModal] = useState(false);
   const [logo, setlogo] = useState(props.companyData.company_data.company_logo);
  const [banner, setbanner] = useState(props.companyData.company_data.company_banner);
  const toggle2 = () => setshowEditModal(!setshowEditModal);
  const toggle3 = () => showeditSuccessfulModal(!setshoweditSuccessfulModal);
  const editModalHandler = ()=>{
    setshowEditModal(true);
  }
  const navigateToAllStoreViewHandler = () => {
    props.displayStoreHandler();
  };

  const EditStoreHandler = (event) => {
   
    event.preventDefault();
    const StoreDetails = {
       tienda: storeName,
      company_data: {
        company_name: corporateName,
        company_rut: rut,
        company_address: address,
        representative_name: name,
        representative_email: mail,
        representative_phone: phone,
        //  company_logo: logo,
        //  company_banner: banner,
      },
      // store_data: {
 
      //  email_color: color,
      //   email_text:emailText,
      //   categories:categories,
      // },
    };
  

    let accessToken = localStorage.getItem("AccessToken");
    let bearerToken = `Bearer ${accessToken}`;
    

   
   
   
    var myHeaders = new Headers();
    // myHeaders.append("x-api-key", "3pTvuFxcs79dzls8IFteY5JWySgfvswL9DgqUyP8");
      myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append("Authorization", bearerToken);
    myHeaders.append("Content-Type", "text/plain");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(StoreDetails),
      redirect: "follow",
    };
    const start = Date.now();
    fetch(
       `${configFile.config.ambiente}store?id=${storeId}`,
      requestOptions
    )
        .then((response) => {
            performanceMetric("/store", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
            return response.text();
        })
      .then((result) => {
       
        let Storestatus = JSON.parse(result);
      
        if(typeof Storestatus.message === "number"){
          setshowEditModal(false);
          setshoweditSuccessfulModal(true);
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div id="editHeader">
        <h4
          id="edittribu"
          style={{
            //  float:'right',
            //  display:'inline-block',
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "24px",
            textAlign: "center",
            marginLeft: "20px",
            //  paddingLeft:'10px',
            //  paddingLeft:"10rem",
            marginBottom: "5px",
          }}
        >
          <button
            onClick={navigateToAllStoreViewHandler}
            id="bttnSubmit"
            type="button"
            style={{
              justifyContent: "flex-start",
              // float:'left',
              backgroundColor: "#051B2F",
              textAlign: "center",
              color: "white",
              // width: "296px",
              // height: "64px",
              padding: "10px 20px",
              borderRadius: "50%",
              color: "#FFFFFF",
              marginLeft: "1em",
              marginRight: "5em",
              textTransform: "none",
              fontWeight: "bold",
              border: "0",
            }}
          >
            &#60;
          </button>
          {/* <span>&#60;&nbsp;&nbsp;</span> */}
          Editar datos tributario
        </h4>
      </div>

      <Form>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib"> Razón social </Label>
          <FormControl
            type="text"
            value={corporateName}
            name="corporateName"
            onChange={(e) => setCorporateName(e.target.value)}
            placeholder="Ej. Mi tienda Sociedad Ltda"
            className="input-taxdata"
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib"> RUT/DNI/NIE Empresa </Label>
          <FormControl
            type="text"
            value={rut}
            name="rut"
            onChange={(e) => setRut(e.target.value)}
            placeholder="77.597.948-K"
            className="input-taxdata"
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib">
            {" "}
            Dirección de facturación{" "}
          </Label>
          <FormControl
            type="text"
            value={address}
            name="address"
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Avenida El Cerro 520"
            className="input-taxdata"
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib">
            {" "}
            Nombre persona de contacto{" "}
          </Label>
          <FormControl
            type="text"
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Jenny Brown"
            className="input-taxdata"
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib">
            {" "}
            Correo electrónico persona de contacto{" "}
          </Label>
          <FormControl
            type="email"
            value={mail}
            name="mail"
            onChange={(e) => setMail(e.target.value)}
            placeholder="contact@luastore.com"
            className="input-taxdata"
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
          <Label className="editCompanyDataTrib">
            {" "}
            Teléfono persona de contacto{" "}
          </Label>
          <FormControl
            type="text"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="+56 9 8114 8425"
            className="input-taxdata"
          />
        </FormGroup>
        {/* {showErrorMessage && (
                  <p
                    style={{
                      color: "#DE4444",
                      textAlign: "initial",
                      fontSize: "12px",
                      marginTop: "1em",
                    }}
                  >
                    {" "}
                    {errorMessage}{" "}
                  </p>
                )} */}
        {/* </Col>
              <Col id="column-datos-tributarios" lg={6} md={6} xs={12}>
                <FormLabel
                  id="title-label-datos-tributarios"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {" "}
                  Logo{" "}
                </FormLabel>
                              </Col>
            </Row> */}
      </Form>
      <div class="text-center">
        <button
          onClick={editModalHandler}
          // id="bttnSubmit"
          type="button"
          style={{
            backgroundColor: "#051B2F",
            textAlign: "center",
            color: "white",

            width: "296px",
            height: "64px",
            // padding: "22px 81px",
            borderRadius: "20px",
            color: "#FFFFFF",
            marginLeft: "1em",
            textTransform: "none",
            fontWeight: "bold",
            border: "0",
            marginTop: "2em",
            fontSize: "18px",
          }}
        >
          Editar
        </button>
      </div>
      {/* Edit confirmation modal */}
      {showEditModal && 
      <Modal isOpen={showEditModal} toggle={toggle2} size="lg">
        <ModalHeader>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              style={{
                background: "none",
                position: "relative",
                marginLeft: "14em",
                color: "black",
                border: "none",
              }}
              onClick={toggle2}
            >
              x
            </button>
          </div>
        </ModalHeader>
        <h3 style={{textAlign:'center',fontSize:'22px',fontWeight:'700'}}>¿Está seguro que desea editar esta información?</h3>
        <div class="text-center">
          <button
            id="bttnSubmit"
            type="submit"
            onClick={EditStoreHandler}
            style={{
              backgroundColor: "#051B2F",
              textAlign: "center",
              color: "white",
              width: "296px",
              height: "64px",
              padding: "22px 81px",
              borderRadius: "33px",
              color: "#FFFFFF",
              marginLeft: "1em",
              textTransform: "none",
              fontWeight: "bold",
              border: "0",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          
          >
            Si,seguro
          </button>
          <br />
          <h3 className="closeEditcompanyModal">No, quiero volver atrás</h3>
        </div>
        <br />
      </Modal>}


      {/* EDIT SUCCESSFUL MODAL */}
      {showeditSuccessfulModal && 
      <Modal isOpen={showeditSuccessfulModal} toggle={toggle3} size="lg">
        <ModalHeader>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              style={{
                background: "none",
                position: "relative",
                marginLeft: "14em",
                color: "black",
                border: "none",
              }}
              onClick={toggle3}
            >
              x
            </button>
          </div>
        </ModalHeader>
        <h3 style={{textAlign:'center',fontSize:'22px',fontWeight:'700'}}>información editada correctamente</h3>
        <div class="text-center">
          <button
            id="bttnSubmit"
            type="submit"
             onClick= {()=>{setshoweditSuccessfulModal(false)}}
            style={{
              backgroundColor: "#051B2F",
              textAlign: "center",
              color: "white",
              width: "296px",
              height: "64px",
              padding: "22px 81px",
              borderRadius: "33px",
              color: "#FFFFFF",
              marginLeft: "1em",
              textTransform: "none",
              fontWeight: "bold",
              border: "0",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          
          >
    Entendido
          </button>
          <br />
     
        </div>
        <br />
      </Modal>}

    </>
  );
};
export default EditDocumentTributario;
