
export const validateChile = (rut) => {
    try {
        // Eliminar puntos y guiones del RUT
        rut = rut.replace(/[.-]/g, '');

        const rutNumero = rut.slice(0, -1);
        const rutDV = rut.slice(-1).toUpperCase();

        // Verificar el formato del número y el dígito verificador
        if (!/^\d+$/.test(rutNumero) || !/^[0-9Kk]{1}$/.test(rutDV)) {
            return false;
        }

        let suma = 0;
        let multiplicador = 2;

        for (let i = rutNumero.length - 1; i >= 0; i--) {
            suma += parseInt(rutNumero.charAt(i)) * multiplicador;

            multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
        }

        const digitoVerificadorEsperado = String(11 - suma % 11);

        // Manejar casos especiales para K (10) y 0 (11)
        const dv = digitoVerificadorEsperado === '10' ? 'K' : digitoVerificadorEsperado === '11' ? '0' : digitoVerificadorEsperado;

        // Verificar el dígito verificador
        return rutDV === dv;
    } catch (error) {
        console.log(error)
    }
}

export const validateColombia = (nit) => {
    try {
        // Expresión regular para validar NIT en Colombia
        const regex = /^[0-9]{1,3}(\.[0-9]{3}){0,2}-?[0-9kK]$/i;
        return regex.test(nit)

    } catch (error) {
        console.log(error)
    }
}

export const validatePeru = (ruc) => {
    try {
        // Verificar que el RUC tenga 11 dígitos
        if (!/^\d{11}$/.test(ruc)) {
            return false;
        }

        // Verificar que los dos primeros dígitos correspondan al tipo de RUC válido
        const tipoRUC = ruc.substring(0, 2);
        if (!['10', '15', '20'].includes(tipoRUC)) {
            return false;
        }

        // Verificar el dígito verificador
        const digitos = Array.from(ruc, Number);
        const sum = digitos.slice(0, 10).reduce((acc, digit, index) => {
            const factor = index % 2 === 0 ? 2 : 1;
            let product = digit * factor;
            if (product > 9) {
                product -= 9;
            }
            return acc + product;
        }, 0);
        const expectedDigit = (10 - (sum % 10)) % 10;
        const actualDigit = digitos[10];

        return expectedDigit === actualDigit;
    } catch (error) {
        console.log(error)
    }
}

export const validateMexico = (rfc) => {
    // Expresión regular para validar el RFC
    const regexRFC = /^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{3}$/;
    return (regexRFC.test(rfc.toUpperCase()))

}