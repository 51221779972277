import React, { useEffect } from 'react';
import { Modal } from 'reactstrap';
import Box from '@mui/material/Box';
import Close from "../../assets/img/icons/CloseModal.svg";
import Back from "../../assets/img/icons/backModal.svg";
import styles from "./CustomModal.module.css";
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
const CustomModal = ({
  isOpen,
  toggle,
  onBack,
  onClose,
  title,
  children,
  footerActions,
  customStyles = {},
  customClassNames = {}
}) => {
  //Mobile configuration
  const isMobile = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        e.preventDefault();
        if (onClose) {
          onClose();
        } else {
          toggle();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <Box
        id="modal-content"
        className={customClassNames.box || styles.afterElementNone}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%)',
          width: "569px",
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: '30px',
          zIndex: 1,
          border: '1px solid #d3d3d3',
          ...customStyles.box,
          '@media (max-width:700px)': {
            width: '90%', // width when screen size is less than 700px
          },
        }}
      >
        <div className={customClassNames.modal || styles.defaultBox}>
          <form className={customClassNames.form || styles.defaultForm}>
            <div className={customClassNames.header || styles.defaultHeader}>
              {onBack && (
                <div className={customClassNames.closeWrapper || styles.defaultBackWrapper}>
                  <img className={customClassNames.backIcon || styles.defaultBackIcon} src={Back} onClick={onBack} />
                </div>
              )}
              <b style={{marginLeft:isMobile?"60px":""}} className={customClassNames.title || styles.defaultTitle}>{title}</b>
              <div className={customClassNames.closeWrapper || styles.defaultCloseWrapper}>
                <img className={customClassNames.closeIcon || styles.defaultCloseIcon} src={Close} onClick={onClose} />
              </div>
            </div>
            <div className={customClassNames.separatorContainer || styles.defaultSeparatorContainer}>
              <div className={customClassNames.separator || styles.defaultSeparator} />
            </div>
            <div className={customClassNames.bodyContainer || styles.defaultBodyContainer}>
              {children}
            </div>
            {footerActions && (
              <div className={customClassNames.footerContainer || styles.defaultFooterContainer}>
                {footerActions}
              </div>
            )}
          </form>
        </div>
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footerActions: PropTypes.node,
  customStyles: PropTypes.object,
  customClassNames: PropTypes.object
};

export default CustomModal;
