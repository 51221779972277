import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import kamRoutes from "kamRoutes";
import operatorRoutes from "operatorRoutes";
import ClientRoutes from "clientRoutes";

var ps;

function Admin(props) {

  const userName = localStorage.getItem('first')
  const userType = localStorage.getItem('ut')
  const userLastName = localStorage.getItem('last')

  const location = useLocation();
  const mainPanel = React.useRef();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse)  return getRoutes(prop.views);
      if (prop.layout === "/admin")  return ( <Route path={prop.layout + prop.path} component={prop.component} key={key} /> );
      else return null;
    });
  };

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      {userType === "2" && (
        <Sidebar
          pathname={location.pathname}
          name={userName}
          lastName={userLastName}
          userType={userType}
          routes={kamRoutes}
          bgColor="white"
          activeColor="white"
        />
      )}
      {userType === "3" && (
        <Sidebar
          pathname={location.pathname}
          name={userName}
          lastName={userLastName}
          userType={userType}
          routes={ClientRoutes}
          bgColor="white"
          activeColor="white"
        />
      )}
      {userType === "5" && (
        <Sidebar
          pathname={location.pathname}
          name={userName}
          lastName={userLastName}
          userType={userType}
          routes={operatorRoutes}
          bgColor="white"
          activeColor="white"
        />
      )}
      {userType === "1" && <Sidebar
        pathname={location.pathname}
        name={userName}
        lastName={userLastName}
        userType={userType}
        routes={routes}
        bgColor='white'
        activeColor='white'
      />}
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        {userType === "1" && <Switch>{getRoutes(routes)}</Switch>}
        {userType === "2" && <Switch>{getRoutes(kamRoutes)}</Switch>}
        {userType === "3" && <Switch>{getRoutes(ClientRoutes)}</Switch>}
        {userType === "5" && <Switch>{getRoutes(operatorRoutes)}</Switch>}
        {/* {
          // we don't want the Footer to be rendered on full screen maps page
          location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        } */}
      </div>
    </div>
  );
}

export default Admin;