import React from 'react'
import { Card } from "reactstrap";
import classes from "../../EditClientProfile.module.css";

//images
import avatar from "../../../../assets/img/User.png";
import Client from "../../../../assets/img/icons/ClientIcon.png";
import customizationIcon from "../../../../assets/img/icons/cusomization-for-menu.svg"
import settingsIcon from "../../../../assets/img/icons/settings-for-menu.svg"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function SideBar({ displayWelcomeEditHandler, displayStoreHandler, displayer }) {
    const classSelected = displayer.displayStores ? classes.optionsWrapperSelected : classes.optionsWrapper;

    return (
        <div className={classes.colLeft}>
            <Card
                style={{ backgroundColor: "#FBFBFB" }}
                className={classes.nameCard}
                onClick={displayWelcomeEditHandler}
            >
                <div className={classes.wrapperProfile}>
                    <div className={classes.wrapperImg}>
                        <img src={avatar} className={classes.img} />
                    </div>
                    <span className={classes.name}>
                        {localStorage.getItem("first")}
                    </span>
                </div>
            </Card>
            <Card className={classes.optionsCard} style={{ backgroundColor: "#FBFBFB" }}>
                <div></div>
                <span style={{ marginLeft: "20px", marginBottom: "10px" }} className={classes.optionTableName}>
                    Información
                </span>
                <List
                    sx={{ width: '100%', bgcolor: '#FBFBFB' }}
                    component="nav"
                >
                    <ListItemButton onClick={displayStoreHandler} sx={{ backgroundColor: displayer.displayStores ? "#EEEFF0" : "", "&:hover": { backgroundColor: displayer.displayStores ? "#EEEFF0" : "transparent" } }} >
                        <ListItemIcon>
                            <img src={Client} alt="icon-client" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Clientes" />
                    </ListItemButton>
                    {/* <ListItemButton sx={{ backgroundColor: displayer.fulfillmentView ? "#EEEFF0" : "", "&:hover": { backgroundColor: displayer.fulfillmentView ? "#EEEFF0" : "transparent" } }} >
                        <ListItemIcon>
                            <img src={customizationIcon} alt="icon-client" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Personalización" />
                    </ListItemButton>
                    <ListItemButton sx={{ backgroundColor: "", "&:hover": { backgroundColor: "transparent" } }} >
                        <ListItemIcon>
                            <img src={settingsIcon} alt="icon-client" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Configuración" />
                    </ListItemButton> */}
                </List>
            </Card>

        </div>
    )
}

export default SideBar