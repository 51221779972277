import React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Card } from "reactstrap";
import classes from "../../EditClientProfile.module.css";

//images
import avatar from "../../../../assets/img/User.png";
import Client from "../../../../assets/img/icons/ClientIcon.png";
import onbIcon from "../../../../assets/img/icons/onbIcon.png"
import channelIcon from "../../../../assets/img/icons/channelIcon.png"
import ffIcon from "../../../../assets/img/icons/ffIcon.png"

function SideBarClientSelected(props) {

  return (
    <div className={classes.colLeft}>
      {!props.isMobile ? <Card
        className={classes.nameCard}
        onClick={props.displayWelcomeEditHandler}
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div className={classes.wrapperProfile}>
          <div className={classes.wrapperImg}>
            <img src={avatar} className={classes.img} />
          </div>
          <span className={classes.name}>
            {localStorage.getItem("first")}
          </span>
        </div>
      </Card> : <></>
      
      }
      
      <Card className={classes.optionsCard} style={{ backgroundColor: "#FBFBFB" }}>
        <List
          sx={{ width: '100%', bgcolor: '#FBFBFB' }}
          component="nav"


        >
          <ListItemButton onClick={props.displayOnboardingView} sx={{ backgroundColor: props.displayer.onboardingView ? "#EEEFF0" : "", "&:hover": { backgroundColor:  props.displayer.onboardingView ? "#EEEFF0" : "transparent" } }} >
            <ListItemIcon>
              <img src={onbIcon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Onboarding" />
          </ListItemButton>
          <ListItemButton onClick={props.displayChannelsView} sx={{ backgroundColor: props.displayer.channelsView ? "#EEEFF0" : "", "&:hover": { backgroundColor:  props.displayer.channelsView ? "#EEEFF0" : "transparent" } }}>
            <ListItemIcon>
              <img src={channelIcon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Canal de venta" />
          </ListItemButton>
          <ListItemButton /*onClick={props.displayFulfillmentView}*/ sx={{ "&:hover": { backgroundColor: "inherit" } }}>
            <ListItemIcon>
              <img src={ffIcon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: "13px", fontWeight: 700 }} primary="Fulfillment" />
          </ListItemButton>
        </List>
      </Card>
    </div>
  )
}

export default SideBarClientSelected