// Libraries
import React, { useEffect, useState } from 'react';

// MUI Libraries
import { Box, Typography, useMediaQuery, Menu, MenuItem, IconButton } from "@mui/material";

// Components
import BarsAndLinesChart from "../../UI/BarsAndLinesChart";
import CustomPieChart from "../../UI/CustomPieChart";
import { 
    formatDateForBarsGraphs, 
    toSpanishNumberFormatWithNull, 
    formatConversionRate, 
    toSpanishNumberFormat 
} from '../../../utils/utils';

// Images
import NoGraph from "../../../assets/img/no-graph.png";
import GraphMenu from "../../../assets/img/icons/options-icon.svg";
import Rocket from "../../../assets/img/rocket-img.png";

const PublicationGraphs = ({ 
    salesXUnits, 
    trafficXConversion, 
    priceXSales, 
    purchaseExperience, 
    origin, 
    channelId, 
    CustomTooltip, 
    statusPublication, 
    hasActualSalesData, 
    hasActualConversionData, 
    hasActualPriceData 
}) => {

    const [currentGraphData, setCurrentGraphData] = useState([]);
    const [currentGraphTitle, setCurrentGraphTitle] = useState('Ventas x unidades vendidas');
    const [selectedGraphType, setSelectedGraphType] = useState('salesXUnits');
    const [anchorEl, setAnchorEl] = useState(null);
    const [xAxisKey, setXAxisKey] = useState("day");
    const [yAxisBarKey, setYAxisBarKey] = useState("quantitySold");
    const [yAxisLineKey, setYAxisLineKey] = useState("sales");
    const [yAxisBarLabel, setYAxisBarLabel] = useState("Unidades vendidas");
    const [yAxisLineLabel, setYAxisLineLabel] = useState("Ventas");
    const isMobile = useMediaQuery('(max-width:600px)');

    // Initial setup for default graph
    useEffect(() => {
        setCurrentGraphData(mapSalesXUnitsData(salesXUnits));
    }, [salesXUnits]);

    // Functions to map different datasets to the chart format
    const mapSalesXUnitsData = (data) => {
        if (!Array.isArray(data)) return [];
        return data.map(item => ({
            day: formatDateForBarsGraphs(item.date),
            sales: typeof item.total_sales_in_money === 'number' && !Number.isInteger(item.total_sales_in_money)
                ? parseFloat(item.total_sales_in_money.toFixed(2)) 
                : item.total_sales_in_money,
            quantitySold: item.total_quantity_sold,
        }));
    };

    const mapTrafficXConversionData = (data) => {
        if (!Array.isArray(data)) return [];
        return data.map(item => ({
            day: formatDateForBarsGraphs(item.date),
            visits: item.visits,
            conversion: parseFloat((item.conversion * 100).toFixed(2)),
        }));
    };

    const mapPriceXSalesData = (data) => {
        if (!Array.isArray(data)) return [];
        return data.map(item => ({
            day: formatDateForBarsGraphs(item.date),
            numberOfSales: item.number_of_sales,
            price: item.price,
        }));
    };

    const handleGraphMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (graphType) => {  
        setSelectedGraphType(graphType);

        switch (graphType) {
            case 'salesXUnits':
                setCurrentGraphData(mapSalesXUnitsData(salesXUnits));
                setCurrentGraphTitle('Ventas x unidades vendidas');
                setXAxisKey("day");
                setYAxisBarKey("quantitySold");
                setYAxisLineKey("sales");
                setYAxisBarLabel("Unidades vendidas");
                setYAxisLineLabel("Ventas");
                break;
            case 'trafficXConversion':
                setCurrentGraphData(mapTrafficXConversionData(trafficXConversion));
                setCurrentGraphTitle('Tráfico x conversión');
                setXAxisKey("day");
                setYAxisBarKey("conversion");
                setYAxisLineKey("visits");
                setYAxisBarLabel("Conversión");
                setYAxisLineLabel("Tráfico");
                break;
            case 'priceXSales':
                setCurrentGraphData(mapPriceXSalesData(priceXSales));
                setCurrentGraphTitle('Precio x ventas');
                setXAxisKey("day");
                setYAxisBarKey("numberOfSales");
                setYAxisLineKey("price");
                setYAxisBarLabel("Ventas");
                setYAxisLineLabel("Precio");
                break;
            case 'purchaseExperience':
                setCurrentGraphData([]);  // Clear data for CustomPieChart as it doesn't need it
                setCurrentGraphTitle('Experiencia de compra');
                break;
            default:
                break;
        }
        handleCloseMenu();
    };

    const customTooltip = ({ axisData, series }) => {
        const graphType = currentGraphTitle;
        
        return (
            <div style={{
                padding: '18px',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>
                <strong>{axisData?.x?.value}</strong>
                
                {graphType === 'Ventas x unidades vendidas' && (
                    <span>
                        Vendiste <strong>{toSpanishNumberFormatWithNull(series[0]?.data[axisData?.x?.index])} unidades </strong> 
                        y lograste <strong>${toSpanishNumberFormatWithNull(series[1]?.data[axisData?.x?.index])}</strong>
                    </span>
                )}

                {graphType === 'Tráfico x conversión' && (
                    <span>
                        Tuviste <strong>{toSpanishNumberFormatWithNull(series[1]?.data[axisData?.x?.index])} visitas </strong> 
                        y una conversión de <strong>{formatConversionRate(series[0]?.data[axisData?.x?.index], false, true)}</strong>
                    </span>
                )}

                {graphType === 'Precio x ventas' && (
                    <span>
                        Vendiste <strong>{toSpanishNumberFormatWithNull(series[0]?.data[axisData?.x?.index])} unidades </strong> 
                        a un precio de <strong>${toSpanishNumberFormatWithNull(series[1]?.data[axisData?.x?.index])}</strong>
                    </span>
                )}
            </div>
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", p: "10px", width: "100%", height: "100%" }}>
            {/* Check if status is 'Pendiente' */}
            {(statusPublication?.translatedStatus === 'Pendiente' && !hasActualSalesData && !hasActualConversionData) || (!hasActualPriceData && !hasActualSalesData && !hasActualConversionData && (!purchaseExperience && purchaseExperience?.reputation?.value !== -1)) ? (
                <Box sx={{ display: "flex", width: "100%", height: "100%", alignContent: "center", justifyContent: "center", alignItems: 'center' }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "65%", height: "65%", alignContent: "center", justifyContent: "center" }}>
                        <img src={NoGraph} alt="no-graph" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
                        <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                            {"Vaya vaya... Acá no hay nada para mostrar :("}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <>
                    {/* Top Bar with Title and Menu Icon */}
                    <Box sx={{ display: "flex", width: "100%", height: "17%", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: '#D3D3D3', ml: '10px' }}>
                            {currentGraphTitle}
                        </Typography>
                        <IconButton onClick={handleGraphMenu}>
                            <img src={GraphMenu} alt="menu" style={{ width: "40px", height: "40px", objectFit: 'contain', cursor: 'pointer' }}/>
                        </IconButton>                
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                            <MenuItem onClick={() => handleMenuClick('salesXUnits')} sx={{ fontWeight: selectedGraphType === 'salesXUnits' ? 700 : 500 }}>Ventas x unidades vendidas</MenuItem>
                            <MenuItem onClick={() => handleMenuClick('trafficXConversion')} sx={{ fontWeight: selectedGraphType === 'trafficXConversion' ? 700 : 500 }}>Tráfico x conversión</MenuItem>
                            <MenuItem onClick={() => handleMenuClick('priceXSales')} sx={{ fontWeight: selectedGraphType === 'priceXSales' ? 700 : 500 }}>Precio x ventas</MenuItem>
                            {channelId === 2 && (
                                <MenuItem onClick={() => handleMenuClick('purchaseExperience')} sx={{ fontWeight: selectedGraphType === 'purchaseExperience' ? 700 : 500 }}>Experiencia de compra</MenuItem>
                            )}
                        </Menu>
                    </Box>

                    {/* Graph Area */}
                    <Box sx={{ display: "flex", width: "100%", height: "83%", alignContent: "center", justifyContent: "center" }}>
                        {currentGraphTitle === 'Experiencia de compra' ? (
                            <>
                                {purchaseExperience && purchaseExperience?.reputation?.value !== -1 ? 
                                    (
                                        <CustomPieChart
                                            reputation={purchaseExperience?.reputation}
                                            problems={purchaseExperience?.metrics_details?.problems || []}
                                            subtitles={purchaseExperience?.subtitles}
                                            CustomTooltip={CustomTooltip}
                                        />
                                    ) 
                                    : 
                                    (
                                        <Box sx={{ display: "flex", flexDirection: "column", width: "60%", height: "80%", alignContent: "center", justifyContent: "center" }}>
                                            <img src={NoGraph} alt="no-graph" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
                                            <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                                                {"Vaya vaya... Acá no hay nada para mostrar :("}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </>
                        ) : currentGraphData.length > 0 ? (
                            (() => {
                                switch (selectedGraphType) {
                                    case 'salesXUnits':
                                        return hasActualSalesData ? (
                                            <BarsAndLinesChart
                                                data={currentGraphData}
                                                xAxisKey={xAxisKey}
                                                yAxisBarKey={yAxisBarKey}
                                                yAxisLineKey={yAxisLineKey}
                                                yAxisBarLabel={yAxisBarLabel}
                                                yAxisLineLabel={yAxisLineLabel}
                                                barColor="#CDEDEC"
                                                lineColor="#000000"
                                                tooltipContent={customTooltip}
                                            />
                                        ) : (
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "60%", height: "80%", alignContent: "center", justifyContent: "center", marginTop: '20px' }}>
                                                <img src={Rocket} alt="no-graph" style={{ width: "100%", height: "70%", objectFit: 'contain' }} />
                                                <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                                                    {"Cuando logres una venta verás el performance... ¡Vamos que se puede!"}
                                                </Typography>
                                            </Box>
                                        );
                                    case 'trafficXConversion':
                                        return hasActualConversionData ? (
                                            <BarsAndLinesChart
                                                data={currentGraphData}
                                                xAxisKey={xAxisKey}
                                                yAxisBarKey={yAxisBarKey}
                                                yAxisLineKey={yAxisLineKey}
                                                yAxisBarLabel={yAxisBarLabel}
                                                yAxisLineLabel={yAxisLineLabel}
                                                barColor="#CDEDEC"
                                                lineColor="#000000"
                                                tooltipContent={customTooltip}
                                            />
                                        ) : (
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "60%", height: "80%", alignContent: "center", justifyContent: "center", marginTop: '20px' }}>
                                                <img src={Rocket} alt="no-graph" style={{ width: "100%", height: "70%", objectFit: 'contain' }} />
                                                <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                                                    {"Cuando logres una visita verás el performance... ¡Vamos que se puede!"}
                                                </Typography>
                                            </Box>
                                        );
                                    case 'priceXSales':
                                        return hasActualPriceData ? (
                                            <BarsAndLinesChart
                                                data={currentGraphData}
                                                xAxisKey={xAxisKey}
                                                yAxisBarKey={yAxisBarKey}
                                                yAxisLineKey={yAxisLineKey}
                                                yAxisBarLabel={yAxisBarLabel}
                                                yAxisLineLabel={yAxisLineLabel}
                                                barColor="#CDEDEC"
                                                lineColor="#000000"
                                                tooltipContent={customTooltip}
                                            />
                                        ) : (
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "60%", height: "80%", alignContent: "center", justifyContent: "center", marginTop: '20px' }}>
                                                <img src={Rocket} alt="no-graph" style={{ width: "100%", height: "70%", objectFit: 'contain' }} />
                                                <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                                                    {"Cuando logres una venta verás el performance... ¡Vamos que se puede!"}
                                                </Typography>
                                            </Box>
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "column", width: "60%", height: "80%", alignContent: "center", justifyContent: "center" }}>
                                <img src={NoGraph} alt="no-graph" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
                                <Typography fontFamily='Montserrat' fontSize={"15px"} sx={{ fontWeight: 300, color: "#000000", textAlign: 'center' }}>
                                    {"Vaya vaya... Acá no hay nada para mostrar :("}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default PublicationGraphs;
