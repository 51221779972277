import React, { useState } from 'react'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
  } from 'reactstrap'
import classes from "./ProductImages.module.css"
import { useHistory } from "react-router-dom";

import emptyImages from "../../assets/img/empty-images.png"

const ProductImages = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [animating, setAnimating] = useState(false)

    const history = useHistory();

    const next = () => {
        if (animating) return
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    const previous = () => {
        if (animating) return
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1
        setActiveIndex(nextIndex)
    }

    const goToIndex = (newIndex) => {
        if (animating) return
        setActiveIndex(newIndex)
    }

  return (
    <>
        {images.length === 0 && (
            <div className={classes.wrapperEmpty}>
                <div className={classes.wrapperImg}>
                    <img src={emptyImages} className={classes.img} />
                </div>
                <h2 className={classes.titleEmpty}>Producto sin imágenes </h2>
                <button className={classes.buttonEmpty} onClick={() => history.push('manage-catalog/images')}>
                    <span className={classes.buttonTextEmpty}>Subir imágenes</span>
                </button>
            </div>
        )}
        { images.length > 0 && (
            <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            >
                <CarouselIndicators
                    items={images}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {images.map( image => (
                    <CarouselItem
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                        key={image.url}
                        slide={false}
                    >
                    <img src={image.url} alt={image.description}/>
                    <CarouselCaption
                    captionText=""
                    captionHeader=""
                    />
                    </CarouselItem>
                ))}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        )}
    </>
  )
}

export default ProductImages