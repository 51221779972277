const configFile = require('../config/url');
import {performanceMetric} from "../utils/utils";

export const getProducts = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append("Content-Type", "application/json")
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }
    try {
        const start = Date.now();    
        const response = await fetch(`${configFile.config.ambientePim}products${query}`, requestOptions)
        performanceMetric("/products", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    } 
    catch(err) {
        return err
    }
}


export const getFilters = async () => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: 'follow'
    }
    try {
        const start = Date.now();    
        const response = await fetch(`${configFile.config.ambientePim}filters/products`, requestOptions)
        performanceMetric("/filters/products", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch(err) {
        return err
    }     
}
