import React, { useState, useRef, useEffect } from "react";
import {
  ModalBody,
  FormGroup,
  Form,
  Label,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { FormCheck } from "react-bootstrap";
import CheckboxDropdown from "components/CheckboxDropdown/CheckboxDropdown";
import {performanceMetric} from "../../utils/utils";
const configFile = require('../../config/url');
const NewUserProfileModal = (props) => {

  const [filter, setFilter] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [name, setName] = useState(props.profileInfo.first_name);
  const [clientOptions, setclientOptions] = useState([]);
  const [countryOptions, setcountryOptions] = useState([]);
  const [stores, setstores] = useState([]);
  const [country, setCountry] = useState([]);
  const [userType, setUserType] = useState(props.profileInfo.profile);
  const [selfServiceType, setselfServiceType] = useState(
    props.profileInfo.enabled
  );

  const emailRef = useRef("");

  useEffect(() => {
    fetchFilterData();
  }, []);

  useEffect(() => {
    let filterCountry = [];

    if (props.flag === 1) {

      let userCountries = [];
      props.profileInfo.countries.forEach(element => {
        userCountries.push(element.id);
      });
      setCountry(userCountries);

      let userStores = [];
      props.profileInfo.stores.forEach(element => {
        userStores.push(element.id);
      });
      setstores(userStores);

    }
    let countriesArray = filter.filter(getCountries);
    let selectedCountry = countriesArray.map((item) => {
      return [{ id: item.value, name: item.country }];
    });
    let allSelectedCountry = [].concat.apply([], selectedCountry);

    setcountryOptions(allSelectedCountry);

    countriesArray.forEach((element) => {
      filterCountry.push({
        id: element.value,
        name: element.country
      });
    });
    setcountryOptions(filterCountry);
  }, [filter]);


  useEffect(() => {
    let filterStore = [];
    let storesArray = filter.filter(getStores);
    storesArray.forEach(element => {
      element.stores.forEach(store => {
        filterStore.push({
          id: store.value,
          name: store.store
        })
      }
      )
    })
    const filterUniqueStore = [...new Map(filterStore.map((item) => [item["name"], item])).values(),];
    setclientOptions(filterUniqueStore);
  }, [country])


  useEffect(() => {
    if (userType === "1") {
      const storeIds = clientOptions.map(store => store.id);
      setstores(storeIds);
    }
    else {
      setstores([]);
    }
  }, [userType]);


  function getStores(elemento) {
    return country.includes(elemento.value)
  }
  function getCountries(elemento) {
    return elemento.value !== 0
  }

  const fetchFilterData = async () => {
    const accessToken = localStorage.getItem('Token')
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append(
      "Authorization",
      `Bearer ${accessToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const start = Date.now();
    fetch(
      `${configFile.config.ambiente}dashboard/filtersorders`,
      requestOptions
    )
        .then((response) => {
            performanceMetric("/dashboard/filtersorders", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
            return response.text();
        })
      .then((result) => {
        let data = JSON.parse(result);
        setFilter(data.message);
      })
      .catch((error) => console.log("error", error));
  };

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };
  const handleSelectChange = (event) => {
    let selectedStore = event;
    const selectedStoreId = selectedStore.map((item) => {
      return item.id;
    });
    setstores(selectedStoreId);
  };
  const editProfileHandler = (event) => {
    let accessToken = localStorage.getItem("AccessToken");
    let bearerToken = `Bearer ${accessToken}`;
    setisLoading(true);
    let userEmail = localStorage.getItem("dtm");

    event.preventDefault();
    const profile = {
      first_name: name,
      last_name: " ",
      email: emailRef.current.value,
      profile: userType,
      stores: stores,
      countries: country,
      enabled: selfServiceType,
    };

    let myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append("Authorization", bearerToken);
    myHeaders.append("Content-Type", "text/plain");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(profile),
      redirect: "follow",
    };
    let url = `${configFile.config.ambiente}user?user=${userEmail}`
    const start = Date.now();
    fetch(url, requestOptions)
        .then((response) => {
            performanceMetric("/user", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
            return response.text();
        })
      .then((result) => {
        let responseResult = JSON.parse(result);
        if (responseResult.message === "Success!") {
          window.location.reload();
        }
        if (responseResult.message === "Invalid user") {
          seterrorMessage("the email cannot be changed");
          setShowModal(false);
        }
        if (
          responseResult.message ===
          "Elevation of privileges not possible, contact an administrator"
        ) {
          seterrorMessage(
            "Elevation of privileges not possible, contact an administrator"
          );
        }
        setisLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  const handleSelectCountryChange = (event) => {
    let selectedCountry = event;
    const selectedCountryId = selectedCountry.map((item) => {
      return item.id;
    });
    setCountry(selectedCountryId);
  };

  const handleSelectUserTypeChange = (event) => {
    setUserType(event.target.value);

  }

  const addProfileHandler = async (event) => {
    setisLoading(true);
    let accessToken = localStorage.getItem("AccessToken");
    let bearerToken = `Bearer ${accessToken}`;
    let userEmail = localStorage.getItem("dtm");
    event.preventDefault();
    const profile = {
      first_name: name,
      last_name: " ",
      email: emailRef.current.value,
      profile: +userType,
      stores: stores,
      countries: country,
      enabled: selfServiceType,
    };

    let myHeaders = new Headers();
    myHeaders.append("x-api-key", configFile.config.apiKey);
    myHeaders.append("Authorization", bearerToken);
    myHeaders.append("Content-Type", "text/plain");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(profile),
      redirect: "follow",
    };
    const start = Date.now();
    fetch(
      `${configFile.config.ambiente}user?user=${userEmail}`, requestOptions)
        .then((response) => {
            performanceMetric("/user", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
            return response.text();
        })
      .then((result) => {
        let responseResult = JSON.parse(result);
        if (responseResult.message === "Success!") {
          window.location.reload();
        }
        setisLoading(false);
      })
      .catch((error) => console.log("error", error));

  };
  return (
    <ModalBody>
      {props.flag === 0 && (
        <h3
          style={{
            fontWeight: "700",
            size: "24px",
            textAlign: "center",
          }}
        >
          Crear nuevo perfil
        </h3>
      )}
      {props.flag === 1 && (
        <h3
          style={{
            fontWeight: "700",
            size: "24px",
            textAlign: "center",
          }}
        >
          Editar perfil
        </h3>
      )}

      <Form>
        <FormGroup>
          <Label for="Name" style={{ fontWeight: "600", size: "14px" }}>
            Nombre:
          </Label>
          <input
            className="input"
            type="name"
            name="name"
            id="exampleEmail"
            style={{ fontSize: "12px" }}
            // value={editedName}
            defaultValue={props.profileInfo.first_name}
            onChange={nameChangeHandler}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail" style={{ fontWeight: "600", size: "14px" }}>
            Correo Electrónico:
          </Label>
          {props.flag === 1 && <input
            className="input"
            ref={emailRef}
            type="email"
            defaultValue={props.profileInfo.email}
            disabled
          />}
          {props.flag === 0 && <input
            className="input"
            ref={emailRef}
            type="email"
            defaultValue={props.profileInfo.email}

          />}
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="Name" style={{ fontWeight: "600", size: "14px" }}>
                Usuario
              </Label>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ borderRadius: "10px" }}
                //ref={userType}
                defaultValue={userType}
                onChange={handleSelectUserTypeChange}
              >
                <option selected>Selccione tipo de usuario</option>
                <option value={1}>Administrador</option>
                <option value={2}>KAM</option>
                <option value={3}>Cliente</option>
                <option value={5}>Operador</option>

              </select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="Name" style={{ fontWeight: "600", size: "14px" }}>
                País
              </Label>
              <CheckboxDropdown
                placeholder="Selccione un país"
                options={countryOptions}
                handleSelectChange={handleSelectCountryChange}
                // defaultValue={props.profileInfo.stores}
                defaultValue={props.profileInfo.countries}
              ></CheckboxDropdown>

            </FormGroup>
          </Col>
        </Row>

        {
          userType != "1" && userType != '5' &&
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="cliente" style={{ fontWeight: "600", size: "14px" }}>
                  Cliente
                </Label>
                <CheckboxDropdown
                  placeholder="Seleccione un cliente"
                  options={clientOptions}
                  handleSelectChange={handleSelectChange}
                  // defaultValue={props.profileInfo.stores}
                  defaultValue={props.profileInfo.stores}
                ></CheckboxDropdown>

              </FormGroup>
            </Col>
          </Row>
        }
        <br />
        <FormGroup>
          <Label for="Name" style={{ fontWeight: "600", size: "14px" }}>
            Activado:
          </Label>

          <FormCheck
            style={{ width: "5em", height: "3em" }}
            type="switch"
            id="custom-switch"
            defaultChecked={selfServiceType}
            onChange={() => setselfServiceType(!selfServiceType)}
          />
        </FormGroup>
        {props.flag === 0 && (
          <div className="text-center">
            {!isLoading && (
              <button
                id="bttnSubmit"
                type="submit"
                style={{
                  backgroundColor: "#051B2F",
                  textAlign: "center",
                  width: "296px",
                  height: "64px",
                  padding: "22px 81px",
                  borderRadius: "33px",
                  color: "#FFFFFF",
                  marginLeft: "1em",
                  textTransform: "none",
                  fontWeight: "bold",
                  border: "0",
                }}
                onClick={addProfileHandler}
              >
                Crear perfil &nbsp;
              </button>
            )}

            {isLoading && (
              <button
                id="bttnSubmit"
                type="submit"
                style={{
                  backgroundColor: "#51cbce",
                  textAlign: "center",
                  width: "296px",
                  height: "64px",
                  padding: "22px 81px",
                  borderRadius: "33px",
                  color: "#FFFFFF",
                  marginLeft: "1em",
                  textTransform: "none",
                  fontWeight: "bold",
                  border: "0",
                }}
                disabled
              >
                <Spinner
                  style={{ width: "0.7rem", height: "0.7rem" }}
                  type="grow"
                  color="light"
                />
                &nbsp; Creando...

              </button>
            )}
          </div>
        )}

        {props.flag === 1 && (
          <div className="text-center">
            {!isLoading && (
              <button
                id="bttnSubmit"
                type="submit"
                style={{
                  backgroundColor: "#051B2F",
                  textAlign: "center",
                  width: "296px",
                  height: "64px",
                  padding: "22px 81px",
                  borderRadius: "33px",
                  color: "#FFFFFF",
                  marginLeft: "1em",
                  textTransform: "none",
                  fontWeight: "bold",
                  border: "0",
                }}
                onClick={editProfileHandler}
              >
                Editar perfil &nbsp;
              </button>
            )}

            {isLoading && (
              <button
                id="bttnSubmit"
                type="submit"
                style={{
                  backgroundColor: "#51cbce",
                  textAlign: "center",
                  width: "296px",
                  height: "64px",
                  padding: "22px 81px",
                  borderRadius: "33px",
                  color: "#FFFFFF",
                  marginLeft: "1em",
                  textTransform: "none",
                  fontWeight: "bold",
                  border: "0",
                }}
                disabled
              >
                <Spinner
                  style={{ width: "0.7rem", height: "0.7rem" }}
                  type="grow"
                  color="light"
                />
                &nbsp; Editando...

              </button>
            )}
          </div>
        )}
      </Form>
    </ModalBody>
  );
};
export default NewUserProfileModal;
