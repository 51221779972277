import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import ComboImagesThumbnails from "./ComboImagesThumbnails"
import ComboDetailDescription from "./ComboDetailDescription"
import ComboMainImage from './ComboMainImage' 
import classes from './ModalCombo.module.css'
import { postCombo } from 'services/combos'

import spinnerGif from "../../assets/img/spinnerLogos.gif"
import successIcon from '../../assets/img/success.png'
import errorImage from '../../assets/img/error.png'

const ModalCombo = ({showModalCombo, handleModalCombo, closeModal, combo, images, products}) => {

    const [activeImage, setActiveImage] = useState(images?.length ? images[0] : null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [prices, setPrices] = useState({basePrice: 0, priceWithDiscount: null, thousandSeparator: "."})
    const [stock, setStock] = useState({isNotAvailable: false, stock: 0})

    const handleThumbnailImageChange = (image=null) => {
        setActiveImage(image)
    }

    const createCombo = async () => {
        try {
            setLoading(true)
            const response = await postCombo({ "promotions": [combo]})
            if (response instanceof Error) {
                throw response
            }
            setSuccess(true)
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    const calculateComboPrice = () => {
        let basePrice = 0
        let priceWithDiscount = 0
        let thousandSeparator = "."
        for(const product of products){
            const productInPromotionFound = combo.products_in_promotion?.find(pip => pip.id_mtdi === product.id_mtdi)
            if(productInPromotionFound){
                thousandSeparator = productInPromotionFound.country?.currency?.thousand_separator || "."

                const quantityProducts = productInPromotionFound.promotion_detail?.qty_products_to_pay || productInPromotionFound.quantity
                basePrice = basePrice + (product.base_price * quantityProducts)

                priceWithDiscount = priceWithDiscount + ( (product.base_price - (product.base_price * (productInPromotionFound.discount_percentage/100))) * quantityProducts)
                
            }
        }
        
        setPrices({basePrice, priceWithDiscount, thousandSeparator})
    }

    const calculateStock = () => {
        let lowerStock = 0
        let isNotAvailable = false
        for(const product of products){
            const productInPromotionFound = combo.products_in_promotion?.find(pip => pip.id_mtdi === product.id_mtdi)
            if(productInPromotionFound){
                const stockProduct = product.stock_wms[0]
                if(!stockProduct || productInPromotionFound.quantity === 0){
                    isNotAvailable = true
                    break
                }
                else if(productInPromotionFound.quantity !== 0){
                    const stockComboProduct = Math.floor(stockProduct.stock/productInPromotionFound.quantity)
                    if(lowerStock === 0 || stockComboProduct < lowerStock){
                        lowerStock = stockComboProduct
                    }
                }
            }
        }
       
        setStock({isNotAvailable, stock: lowerStock})
    }
    
    useEffect(() => {
        calculateComboPrice()
        calculateStock()
    }, [products, combo])
    

    return (
        <Modal isOpen={showModalCombo} toggle={handleModalCombo} size="lg" onExit={closeModal}>
             <ModalHeader>
                <div className={classes.modalHeader}>
                    <button className={classes.modalExitButton} onClick={() => {
                        handleModalCombo()
                    }}>
                        x
                    </button>
                </div>
            </ModalHeader>
            {loading && 
                <div className={classes.spinner}>
                    <img src={spinnerGif} className={classes.imgSpinner} />
                    <p className={classes.textSpinner}>Estamos creando el nuevo combo,<br/>
                    por favor espera un momento.</p>
                </div> 
            }
            {!loading && success && !error &&
                <div className={classes.successWrapper}>
                    <div className={classes.iconSuccessWrapper}>
                        <img src={successIcon} />
                    </div>
                    <h2 className={classes.title}>{`${combo.promotion_detail?.type || "Combo"} creado exitosamente`}</h2>
                    <button className={classes.buttonGreen} onClick={() => handleModalCombo()}>
                        <p className={classes.buttonGreenText}>
                            Entendido
                        </p>
                    </button>
                </div>
            }
            {!loading && error &&
                <div className={classes.successWrapper}>
                    <div className={classes.iconSuccessWrapper}>
                        <img src={errorImage} />
                    </div>
                    <h2 className={classes.titleRed}>¡Lo sentimos! tu combo no pudo ser creado.</h2>
                    <button className={classes.buttonGreen} onClick={() => handleModalCombo()}>
                        <p className={classes.buttonGreenText}>
                            Entendido
                        </p>
                    </button>
                </div>
            }
            {!loading && !success && !error &&
                <div className={classes.wrapperModal}>
                    <h3 className={classes.titleModal}>Confirma tu {combo.promotion_detail?.type || ""}</h3>
                    <div className={classes.comboDetails}>
                        {images && images.length ? 
                            <ComboImagesThumbnails 
                                handleThumbnailImageChange={handleThumbnailImageChange}
                                images={images}
                            />
                        :
                            null
                        }
                        <ComboMainImage activeImage={activeImage}/>
                        <ComboDetailDescription combo={combo} prices={prices} stock={stock}/>
                    </div>
                    <div className={classes.actionButtonWrapper}>
                        <button
                            onClick={createCombo}
                            className={classes.buttonGreen}
                            >
                            <span className={classes.buttonGreenText}>Crear {combo.promotion_detail?.type || ""}</span>
                        </button> 
                    </div>
                </div>
            }
        </Modal>
    )
}

export default ModalCombo