import React, { useEffect, useState, useRef } from "react"
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'; 
import { Row, Col } from "react-bootstrap";

import { removeAccentsFromString } from "../../../../utils/utils"



const QuickSightVisuals = ({ dashboardGroup, filtersValuesForDashboards, loaders }) => {
    
    const ID_IFRAME_CONTAINER = "dashboardEmbedContainer"

    const embeddingContext = useRef(null)
    const previousContainerId = useRef(null)
    const embeddedDashboardExperience = useRef(null)

    const [selectedDashboardSheet, setSelectedDashboardSheet] = useState(null)

    const stylesDivIframe = { 
        border: "solid 1px #D3D3D3",
        borderRadius: "17px",
        margin: "10px 0px",
        padding: "5px"
    }

    const embedDashboard = async(dashboardGroup) => {
           
        try {
            setTimeout(async () => {
                if(previousContainerId.current){
                    const previousContainer = document.getElementById(ID_IFRAME_CONTAINER + dashboardGroup?.dashboards?.[0]?.quickSightDashboardId);
                    previousContainer.innerHTML = ""
                }
                const containerDiv = document.getElementById(ID_IFRAME_CONTAINER + dashboardGroup?.dashboards?.[0]?.quickSightDashboardId);
        
                const frameOptions = {
                    url: dashboardGroup?.dashboards?.[0]?.embedUrl,
                    container: containerDiv
                };
                
                const contentOptions = {
                    parameters: filtersValuesForDashboards,
                    locale: "es-ES",
                    sheetOptions: {
                        initialSheetId: dashboardGroup?.dashboards?.[0].quickSightSheetId,
                        singleSheet: true,                        
                        emitSizeChangedEventOnSheetChange: false,
                    }
                };


                
                if(!embeddingContext?.current){
                    embeddingContext.current = await createEmbeddingContext();
                }
                
                const embeddedVisualExperience = await embeddingContext?.current?.embedDashboard(frameOptions, contentOptions);

                embeddedDashboardExperience.current = embeddedVisualExperience
                previousContainerId.current = ID_IFRAME_CONTAINER + dashboardGroup?.dashboards?.[0]?.quickSightDashboardId
            }, 100);
            

        } catch (error) {
            console.log(error)
        }
            

    };

    const changeCurrentDashboard = (dashboardGroup) => {
        try {
            const contentOptions = {
                parameters: filtersValuesForDashboards,
                locale: "es-ES",
                sheetOptions: {
                    initialSheetId: dashboardGroup?.dashboards?.[0]?.quickSightSheetId,
                    singleSheet: true,                        
                    emitSizeChangedEventOnSheetChange: false,
                },
                onMessage: async (messageEvent, experienceMetadata) => {
                    console.log("contentOptions, onMessage", messageEvent.eventName, experienceMetadata)
                },
            };

           //CAMBIAR,COMBINAR CON LA FORMA ANTIGUA, YA QUE ESTA ES MUY LENTA
            embeddedDashboardExperience?.current.navigateToDashboard( dashboardGroup?.dashboards?.[0].quickSightDashboardId, contentOptions)

        } catch (error) {
            console.log(error)
        }
    }

    const getfiltersWithoutAccentsInCountries = (filters) => {
        try {
            const filterCountries = filters?.find(e => e?.Name === "pais")
            if(filterCountries){
                const countriesWithoutAccents = []
                for(const countryValue of filterCountries.Values || []){
                    const countryWithoutAccent = removeAccentsFromString(countryValue)
                    countriesWithoutAccents.push(countryWithoutAccent)
                }
                filterCountries.Values = countriesWithoutAccents
            }

            return filters
        } catch (error) {
            console.log(error)
            return filters
        }
    }

    function handleChangeSheet(dashboardSheet){
        try {
            setSelectedDashboardSheet(dashboardSheet)
            if(embeddedDashboardExperience?.current){
                embeddedDashboardExperience.current.setSelectedSheetId(dashboardSheet?.quickSightSheetId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(async () => {
        if(dashboardGroup != null){
            // if(!selectedDashboardSheet){
                await embedDashboard(dashboardGroup)
            // }
            // else{
            //      changeCurrentDashboard(dashboardGroup)
            // }
            setSelectedDashboardSheet(dashboardGroup?.dashboards?.[0])
        }
        
    }, [dashboardGroup])

    useEffect(() => {
        if(filtersValuesForDashboards?.length){
            const filterswithoutAccentInCountries = getfiltersWithoutAccentsInCountries(JSON.parse(JSON.stringify(filtersValuesForDashboards)))
            if(embeddedDashboardExperience?.current){
                embeddedDashboardExperience.current.setParameters(filterswithoutAccentInCountries)
            }
        }
    }, [filtersValuesForDashboards])


    
  return (
    loaders.isLoadingUserDashboards?
        (<Row>
            <Col>
                Cargando ...
            </Col>
        </Row>)
    : 
        (<Row>
        {
            <>
             <Col xs={{span: 12, offset: 0}} style={{ marginTop: 20 }}>
                <Row>
                    <Col>
                        {
                            dashboardGroup?.dashboards.map(e => {
                                return (
                                    <div 
                                        style={{
                                            border: "solid 1px #D3D3D3",
                                            borderRadius: "5px",
                                            margin: "0px 5px",
                                            padding: "5px 10px",
                                            display: "inline-block",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            background: selectedDashboardSheet?.quickSightSheetId === e?.quickSightSheetId ? "#57C2BD" : null,
                                            color:  selectedDashboardSheet?.quickSightSheetId === e?.quickSightSheetId ? "white" : "black",

                                        }}
                                        onClick={() => { handleChangeSheet(e) }}
                                        key={e?.quickSightSheetId}
                                    >
                                        {e.name}
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 12, offset: 0}}>
                    {
                        <div 
                            style={{
                                ...stylesDivIframe,
                                height:  "calc(100vh)"
                            }} 
                            id={ID_IFRAME_CONTAINER + dashboardGroup?.dashboards?.[0]?.quickSightDashboardId}
                        >
                        </div>
                        
                    }
                    </Col>
                </Row>
            </Col>
            </>

        }
        </Row>)
  )
}

export default QuickSightVisuals