import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './CheckboxDropdown.css';

const CheckboxDropdown = (props) => {
  
  const animatedComponents = makeAnimated();
  
  return (
    <div className="App">
      {

      }
      {props.placeholder.includes('categoría') && <Select
      id="select-checkbox-enterprise"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.category}
      closeMenuOnSelect={true}
      components={animatedComponents}
        defaultValue={props.defaultValue}
      isMulti
      options={props.options}
      placeholder={props.placeholder}
      onChange={props.handleSelectChange}
    />}

    {!props.placeholder.includes('categoría') &&  <Select
      id="select-checkbox-enterprise"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      closeMenuOnSelect={true}
      components={animatedComponents}
        defaultValue={props.defaultValue}
      isMulti
      options={props.options}
      placeholder={props.placeholder}
     onChange={props.handleSelectChange}
    />}
    </div>
  );
}

export default CheckboxDropdown;